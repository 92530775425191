import React from "react";

const Header = ({
  className = "",
  title = "",
  subTitle = null,
  icon = null,
  align = "",
}) => {
  return (
    <div
      className={`${className} ${align === "left" ? "" : "text-center px-6"}`}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <h2 className="text-lg sm:text-2xl text-black font-bold capitalize leading-none">
          {title}
        </h2>
        {icon}
      </div>

      {subTitle && (
        <p className="text-sm text-custom-gray-400 mt-1">{subTitle}</p>
      )}
    </div>
  );
};

export default Header;
