import { FC, useEffect, useMemo } from "react";

import { Box } from "components";
import { ITeamSelector } from "../../types/ITeamSelector";

import { ITeam } from "types/Team";
import defaultLogo from "assets/images/default-logo.png";
import SingleTeam from "./SingleTeam";
import MultiTeam from "./MultiTeam";
import { generateMediaPath, getCardBG, useFlag } from "utils";
import { FEATURE_FLAGS } from "constants/flags";
import { setProfile, setTeam, useUserDispatch, useUserState } from "context";
import { IProfile } from "types/Profile";

const TeamSelector: FC<ITeamSelector> = ({ teamsData, profileData }) => {
  const isMultiTeamsEnabled = useFlag(FEATURE_FLAGS.multiTeams);
  const { currentTeam, currentProfile } = useUserState();
  const dispatch = useUserDispatch();

  const { teams } = teamsData || {};
  const currentTeamLogo = currentProfile?.logo
    ? generateMediaPath(currentProfile?.logo)
    : defaultLogo;
  const logoBackground = useMemo(
    () =>
      getCardBG(
        typeof currentProfile?.design === "undefined"
          ? {}
          : currentProfile?.design,
      ),
    [currentProfile?.design],
  );

  const renderMultiTeam =
    isMultiTeamsEnabled && currentTeam && (teams?.length as number) > 1;

  const handleTeamChange = (value: string | string[]) => {
    const selectedTeam = teams?.find(team => team.id === Number(value));

    const profile = profileData?.profiles.find(
      profile => profile.team_id === selectedTeam?.id,
    );
    dispatch(setTeam(selectedTeam as ITeam));
    dispatch(setProfile(profile!));
  };

  useEffect(() => {
    if (!currentTeam || !currentProfile) {
      dispatch(setTeam(teamsData?.teams?.[0] as ITeam));
      dispatch(setProfile(profileData?.profiles?.[0] as IProfile));
    }
  }, []);

  return (
    <>
      {renderMultiTeam ? (
        <Box paddingX="16px">
          <MultiTeam
            profile={currentProfile}
            currentTeam={currentTeam}
            teams={teamsData?.teams as ITeam[]}
            onChange={handleTeamChange}
          />
        </Box>
      ) : (
        <SingleTeam
          logo={currentTeamLogo}
          background={logoBackground}
          title={currentTeam?.title || "..."}
        />
      )}
    </>
  );
};

export default TeamSelector;
