import { USER_TYPE_LABELS } from "features/manageUsers/types";
import { IProfile } from "./Profile";

export interface ITeamsUserInvite {
  message: string;
}

export interface ITeamUserChange {
  message: string;
}

export interface ITeamUsersResponse {
  count: number;
  next: number | null;
  previous: number | null;
  results: ITeamUserObject[];
}

interface IMetadata {
  label: USER_TYPE_LABELS;
  description?: string;
}

interface IUserRole {
  created: string;
  updated: string;
  status: string;
}
export interface ITeamUserObject {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  status: USER_SEATS_STATUS;
  card_ids: string[];
  profile: IProfile;
  user_iam_role: USER_IAM_ROLE;
  permission_metadata: IMetadata;
  user_role: IUserRole;
}

export interface ITeamUserTypeObject {
  id: number;
  user_type: "Team" | "Solo" | "Default";
  state: "active" | "limited" | "legacy";
  role: "owner" | "admin" | "member" | "readonly";
  user_iam_role: USER_IAM_ROLE;
  metadata: IMetadata;
}

export enum USER_SEATS_STATUS {
  APPOINTED = "appointed",
  ASSIGNED = "assigned",
}

export enum USER_IAM_ROLE {
  TEAM_LEGACY_OWNER = "Team_legacy_owner",
  TEAM_LIMITED_OWNER = "Team_limited_owner",
  TEAM_ACTIVE_OWNER = "Team_active_owner",
  TEAM_LEGACY_ADMIN = "Team_legacy_admin",
  TEAM_LIMITED_ADMIN = "Team_limited_admin",
  TEAM_ACTIVE_ADMIN = "Team_active_admin",
  TEAM_LEGACY_MEMBER = "Team_legacy_member",
  TEAM_LIMITED_MEMBER = "Team_limited_member",
  TEAM_ACTIVE_MEMBER = "Team_active_member",
  TEAM_ACTIVE_READONLY = "Team_active_readonly",
  TEAM_LIMITED_READONLY = "Team_limited_readonly",
  TEAM_LEGACY_READONLY = "Team_legacy_readonly",
  SOLO_ACTIVE_DEFAULT = "Solo_active_default",
  DEFAULT_DEFAULT_DEFAULT = "Default_default_default",
  SOLO_ESSENTIAL_DEFAULT = "Solo_essential_default",
  SOLO_LIMITED_DEFAULT = "Solo_limited_default",
  SOLO_TRIAL_DEFAULT = "Solo_trial_default",
  TEAM_TRIAL_ADMIN = "Team_trial_admin",
  TEAM_TRIAL_OWNER = "Team_trial_owner",
}

export const TEAM_OWNER_ROLES = [
  USER_IAM_ROLE.TEAM_LEGACY_OWNER,
  USER_IAM_ROLE.TEAM_LIMITED_OWNER,
  USER_IAM_ROLE.TEAM_ACTIVE_OWNER,
];

export enum USER_ROLE_STATUS {
  APPOINTED = "appointed",
}
