import { useAnalytics } from "context/Analytics";
import React, { useEffect } from "react";

import { useUserDispatch, logoutUser } from "../../../context";
import { removeToken } from "../../../utils";
import { Loader } from "../../../components/Spinner";

const LogoutPage = () => {
  const dispatch = useUserDispatch();
  const { reset: resetAnalytics } = useAnalytics();

  useEffect(() => {
    dispatch(logoutUser());
    removeToken();
    resetAnalytics && resetAnalytics();
    window.location.href = `${process.env.REACT_APP_SITE_URL}/o/auth/logout`;
  }, [dispatch, resetAnalytics]);

  return <Loader />;
};

export default LogoutPage;
