import React from "react";

const PointClick = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.96406 11.5352L7.03564 13.4394C6.31543 14.1506 5.90127 15.1182 5.90884 16.1351C5.9164 17.1521 6.32151 18.1256 7.0759 18.8475C7.80694 19.5924 8.79308 19.9924 9.82282 19.9999C10.876 20.0075 11.8328 19.6216 12.5531 18.9104L14.4815 17.0063M16.9447 14.6206L18.8731 12.7164C19.5933 12.0053 20.0075 11.0377 19.9999 10.0207C19.9923 9.00375 19.5872 8.03022 18.8328 7.30833C18.102 6.58664 17.116 6.18661 16.0861 6.17914C15.0562 6.17167 14.0761 6.55741 13.3558 7.26858L11.4274 9.17274M10.03 15.9145L15.8153 10.2021M7.12078 7.21611L6.16557 6.27291M10.4376 5.55467L10.5939 4M4 10.5107L5.63244 10.3507"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default React.memo(PointClick);
