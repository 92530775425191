export { TEXTS } from "./Texts";
export { VALIDATION_MESSAGES } from "./validationMessages";
export const ACCESS_TOKEN_KEY = "accessToken";
export const REFRESH_TOKEN_KEY = "refreshToken";
export const API_URL = process.env.REACT_APP_API_URL;
export const MEDIA_URL = process.env.REACT_APP_MEDIA_URL;
export const ENV_URL = process.env.REACT_APP_OVOU_MAIN_ENV_URL;
export const ERROR = {
  NETWORK: {
    TYPE: "Network Error",
    MESSAGE: "An unknown network error has occurred.",
  },
};

export const LOCAL_STORAGE_KEYS = {
  FEATURE_FLAGS: "FEATURE_FLAGS",
};

export const MAIN_SITE = process.env.REACT_APP_SITE_URL;
export const ENVIRONMENT = process.env.REACT_APP_APP_ENV || "development";
export const PROJECT_ID = process.env.REACT_APP_PROJECT_ID;
export const PROJECT_ACCESS_TOKEN = process.env.REACT_APP_PROJECT_ACCESS_TOKEN;
export const API_MOCKING = process.env.REACT_APP_API_MOCKING;
export const UNLEASH_FRONT_END = process.env.REACT_APP_UNLEASH_FRONT_END;
export const UNLEASH_CLIENT_KEY = process.env.REACT_APP_UNLEASH_CLIENT_KEY;
export const STRIPE_URL = process.env.REACT_APP_STRIPE_URL;
export const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
export const SEGMENT_WRITE_KEY = process.env.REACT_APP_SEGMENT_WRITE_KEY;
export const BUSINESS_SITE_URL = process.env.REACT_APP_BUSINESS_SITE_URL;
export const PARAGON_PROJECT_ID =
  process.env.REACT_APP_PARAGON_PROJECT_ID || "";

// Datadog
export const DATADOG_APPLICATION_ID =
  process.env.REACT_APP_DATADOG_APPLICATION_ID;
export const DATADOG_CLIENT_TOKEN = process.env.REACT_APP_DATADOG_CLIENT_TOKEN;
export const DATADOG_SITE = process.env.REACT_APP_DATADOG_SITE;

export const ACTION = {
  EDIT: "edit",
  ADD: "add",
  PRE_ADD: "preAdd",
  PRE_EDIT: "preEdit",
};

export const LOCATION = "location";
export const MANAGE_USERS_ROUTE = "/manage-users";

export const LOGO_MAX_WIDTH = 512;
export const COVER_MAX_WIDTH = 1600;

export const PROFILE_REPRESENTATIVE_STATE = "is_representative";

export const HIGHER_ENVIRONMENT = ["sandbox", "production"];

export const PAGES = {
  PROFILE_SETUP: `${process.env.REACT_APP_SITE_URL}/o/card/setup`,
  ONBOARDING: "/onboarding",
  MANAGE_USERS: "/manage-users",
  RESET_PASSWORD: "/auth/reset-password",
  SIGN_IN: `${process.env.REACT_APP_SITE_URL}/a/sign-in`,
  LOGOUT: "/auth/logout",
};

export const NO_NEED_WORKFLOW_CONFIG = ["monday.com"];
