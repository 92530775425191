import { useQuery } from "react-query";

import api from "../../../api";

const cardsPoolStatsService = async (_key: any, { teamID }: any) => {
  const { data } = await api.get(`/team/card/pool/of/${teamID}/stats`);
  return data;
};

export const useCardsPoolStatsQuery = ({
  onSuccess,
  teamID,
}: {
  onSuccess?: any | null;
  teamID?: number;
}) => {
  return useQuery(["cardsPoolStats", { teamID }], cardsPoolStatsService, {
    onSuccess,
    enabled: !!teamID,
    refetchOnWindowFocus: false,
  });
};
