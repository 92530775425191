import React from "react";

import Icon from "../Icon";

const Header = ({ title, onEdit, onDelete }) => {
  return (
    <div className="flex bg-custom-gray-200 rounded-xl items-start justify-between py-4 px-6 cursor-pointer">
      <span className="text-black text-base font-bold capitalize">{title}</span>
      <div className="ml-auto flex items-center text-black">
        <span className="mr-3 py-4 -my-4">
          <Icon name="trash" strokeWidth="1.5" onClick={onDelete} />
        </span>
        <span className="py-4 -my-4 mr-8">
          <Icon name="pen" strokeWidth="1.5" onClick={onEdit} />
        </span>
      </div>
    </div>
  );
};

export default Header;
