import React, { FC } from "react";
import { Button } from "@chakra-ui/react";
import { theme } from "ovou-ui";

import { Flex, Spinner, Text } from "components";

interface IStepSubmitProps {
  isDisabled?: boolean;
  isLoading?: boolean;
  submitText?: string;
  onSkip?: () => void;
}

export const StepSubmit: FC<IStepSubmitProps> = ({
  isDisabled,
  isLoading,
  submitText = "Next",
  onSkip,
}) => {
  return (
    <Flex flexDirection="column" alignItems="center" gap={6} marginTop={10}>
      <Button
        type="submit"
        width="full"
        height={14}
        backgroundColor={theme.palette.brand.primary.black}
        isDisabled={isDisabled}
        _hover={{
          opacity: 0.75,
        }}
        _focus={{
          opacity: 0.75,
        }}
      >
        {isLoading ? (
          <Spinner color="white" />
        ) : (
          <Text
            fontSize={14}
            fontWeight="extrabold"
            textColor={theme.palette.brand.primary.white}
          >
            {submitText}
          </Text>
        )}
      </Button>

      {Boolean(onSkip) && (
        <Text
          fontSize={14}
          fontWeight="semibold"
          textColor={theme.palette.brand.primary.orange}
          cursor="pointer"
          onClick={onSkip}
        >
          Skip and do it later
        </Text>
      )}
    </Flex>
  );
};
