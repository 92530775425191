import React, { FC } from "react";
import { theme } from "ovou-ui";
import { BiPlus } from "react-icons/bi";

import { IContact } from "../types/Contact";
import { TagColorIndicator } from "./TagColorIndicator";
import { ButtonOutlined, Flex, Text } from "../../../components";

interface ITableTagsColumnProps {
  currentContact: IContact;
  onManageContactTags: (currentContact: IContact) => void;
}

export const TableTagsColumn: FC<ITableTagsColumnProps> = ({
  currentContact,
  onManageContactTags,
}) => {
  const currentTags = currentContact.tags;

  return (
    <Flex justifyContent="center">
      {currentTags.length > 0 && (
        <Flex
          alignItems="center"
          flexWrap="wrap"
          width={14}
          gap={1}
          marginRight={2}
        >
          {currentTags.map(tag => (
            <TagColorIndicator color={tag.color} />
          ))}
        </Flex>
      )}

      <ButtonOutlined
        padding={2}
        onClick={() => onManageContactTags(currentContact)}
      >
        <BiPlus
          color={theme.palette.brand.primary.black}
          style={{ marginRight: 0 }}
          size={16}
        />
        {currentTags.length === 0 && (
          <Text
            fontWeight="semibold"
            fontSize={14}
            color={theme.palette.brand.primary.black}
            marginLeft={1}
          >
            Set Tags
          </Text>
        )}
      </ButtonOutlined>
    </Flex>
  );
};
