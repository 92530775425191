import React, { useEffect, useMemo } from "react";

import { ChakraProvider, Center } from "@chakra-ui/react";
import { themeProvider } from "ovou-ui";
import { hotjar } from "react-hotjar";
import { QueryCache, ReactQueryCacheProvider } from "react-query";
import { Switch, Redirect } from "react-router-dom";

import { RouteWrapper} from "./components";
import { MANAGE_USERS_ROUTE } from "./constants";
import { useIdentify, useValidateToken } from "./hooks";
import { routes } from "./routes";
import { getToken } from "./utils";
import "cropperjs/dist/cropper.css";
import Loader from "components/Spinner/Loader";

const cache = new QueryCache();

const App = () => {
  useIdentify();
  const isValidating = useValidateToken();

  const baseRoute = routes.find(route => route.path === MANAGE_USERS_ROUTE);

  const { accessToken } = useMemo(() => getToken(), []);
  useEffect(() => {
    const HOTJAR_HJID = process.env.REACT_APP_HJID;
    const HOTJAR_HJSV = process.env.REACT_APP_HJSV;
    if (HOTJAR_HJID && HOTJAR_HJSV && process.env.NODE_ENV === "production") {
      hotjar.initialize(HOTJAR_HJID, HOTJAR_HJSV);
    }
  }, []);

  return (
    <ReactQueryCacheProvider queryCache={cache}>
      <div className="min-h-screen h-screen bg-white">
        <ChakraProvider theme={themeProvider}>
          {isValidating ? (
            <Center height="full">
              <Loader />
            </Center>
          ) : (
            <Switch>
              {routes.map((route, index) => (
                <RouteWrapper exact key={index} {...route} />
              ))}
              <Redirect
                from="/"
                to={accessToken ? baseRoute.path : "/auth/login"}
              />
            </Switch>
          )}
        </ChakraProvider>
      </div>
    </ReactQueryCacheProvider>
  );
};

export default App;
