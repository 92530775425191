import { useEffect, useState } from "react";

import { useFlag as useUnleashFlag } from "@unleash/proxy-client-react";

export const useFlag = name => {
  return useUnleashFlag(name);
};

export const useWindowSize = () => {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return width;
};
