import { rest } from "msw";

import analytics from "./data/analytics.json";
import exchange_count from "./data/exchange_count.json";
import save_count from "./data/save_count.json";
import social_media_click_count from "./data/social_media_click_count.json";
import thumbnail_link_click_count from "./data/thumbnail_link_click_count.json";
import view_count from "./data/view_count.json";
import interactions from "./data/interactions.json";
import contacts from "./data/contacts.json";
import teams from "./data/teams.json";
import profiles from "./data/profiles.json";
import permissions from "./data/permissions.json";

export const handlers = [
  rest.get("/analytics/teams", (req, res, ctx) => {
    return res(ctx.status(200), ctx.delay(1000), ctx.json(analytics));
  }),

  rest.get(`/analytics/metrics/:metric`, (req, res, ctx) => {
    const { params } = req;
    const { metric } = params;
    let response;
    if (metric === "exchange_count") response = exchange_count;
    if (metric === "save_count") response = save_count;
    if (metric === "social_media_click_count")
      response = social_media_click_count;
    if (metric === "thumbnail_link_click_count")
      response = thumbnail_link_click_count;
    if (metric === "view_count") response = view_count;
    return res(ctx.status(200), ctx.delay(1000), ctx.json(response));
  }),
  rest.get("/analytics/interactions", (req, res, ctx) => {
    return res(ctx.status(200), ctx.delay(1000), ctx.json(interactions));
  }),
  rest.get("/profile/teams/:teamId/contacts", (req, res, ctx) => {
    return res(ctx.status(200), ctx.delay(500), ctx.json(contacts));
  }),
  rest.get("/profile?state=business", (req, res, ctx) => {
    return res(ctx.status(200), ctx.delay(500), ctx.json(profiles));
  }),
  rest.get("/team/my", (req, res, ctx) => {
    return res(ctx.status(200), ctx.delay(500), ctx.json(teams));
  }),
  rest.get("/permissions", (req, res, ctx) => {
    return res(ctx.status(200), ctx.delay(500), ctx.json(permissions));
  }),
];
