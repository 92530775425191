import React, { FC } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { theme } from "ovou-ui";

import { Table, Th, Thead, Tr, Tbody, Td, Flex } from "@chakra-ui/react";
import { TableTagColumn } from "./components/TableTagColumn";
import { Box, Spinner } from "../../components";
import { TableAssignColumn } from "./components/TableAssignColumn";
import { ICard } from "../../types/Card";
import { IGetDevicesTableColumnsProps } from "./types";
import { TableProfileColumn } from "./components/TableProfileColumn";

const columnHelper = createColumnHelper<ICard>();

const getDevicesTableColumns = ({
  onAssignCard,
  onUnassignCard,
}: IGetDevicesTableColumnsProps) => [
  columnHelper.accessor("id", {
    header: "Card Identifier",
    size: 100,
    cell: ({ row }) => (
      <TableTagColumn tag={row.original.tag || "No card identifier"} />
    ),
  }),
  columnHelper.accessor("profile", {
    header: "Linked User",
    size: 500,
    cell: ({ row }) => <TableProfileColumn profile={row.original.profile} />,
  }),
  columnHelper.display({
    id: "assignOrUnassign",
    size: 10,
    cell: ({ row }) => (
      <TableAssignColumn
        currentCard={row.original}
        onAssignCard={onAssignCard}
        onUnassignCard={onUnassignCard}
      />
    ),
  }),
];

interface IDeviceTableProps {
  devices: ICard[];
  isFetching?: boolean;
}

export const DevicesTable: FC<
  IDeviceTableProps & IGetDevicesTableColumnsProps
> = ({ devices, isFetching, onAssignCard, onUnassignCard }) => {
  const table = useReactTable({
    data: devices,
    columns: getDevicesTableColumns({
      onAssignCard,
      onUnassignCard,
    }),
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Box>
      <Table marginTop={6}>
        <Thead backgroundColor={theme.palette.ui.greys.grey5}>
          {table.getHeaderGroups().map(headerGroup => (
            <Tr key={headerGroup.id} height="3rem">
              {headerGroup.headers.map(header => {
                const meta: any = header.column.columnDef.meta;
                return (
                  <Th
                    key={header.id}
                    isNumeric={meta?.isNumeric}
                    style={{
                      width: header.getSize(),
                    }}
                    fontWeight="semibold"
                    fontSize="0.875rem"
                    borderColor={theme.palette.ui.greys.grey3}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
        {!isFetching && (
          <Tbody>
            {table.getRowModel().rows.map(row => (
              <Tr key={row.id} height="6rem">
                {row.getVisibleCells().map(cell => {
                  const meta: any = cell.column.columnDef.meta;
                  return (
                    <Td
                      key={cell.id}
                      isNumeric={meta?.isNumeric}
                      borderColor={theme.palette.ui.greys.grey3}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </Td>
                  );
                })}
              </Tr>
            ))}
          </Tbody>
        )}
      </Table>

      {isFetching && (
        <Flex width="full" justifyContent="center" marginY={8}>
          <Spinner />
        </Flex>
      )}
    </Box>
  );
};
