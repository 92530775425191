import { useMutation } from "react-query";
import { useToasts } from "react-toast-notifications";

import api from "../../../api";

const batchActiveDirectoryService = async ({
  batchActiveDirectoryData,
  teamId,
}) => {
  const { data } = await api.post(
    `/directory/${teamId}/activate_batch_directories`,
    batchActiveDirectoryData,
  );
  return data;
};

export const useBatchActiveDirectoryMutation = () => {
  const { addToast } = useToasts();

  const [mutate, { status, error, isLoading }] = useMutation(
    batchActiveDirectoryService,
    {
      onSuccess: () => {
        addToast("Success", {
          appearance: "success",
        });
      },
      onError: () => {
        addToast("Failed to update profile, please contact support for assistance.", {
          appearance: "error",
        });
      },
    },
  );

  return {
    mutate,
    status,
    error,
    isLoading,
  };
};
