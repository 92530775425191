import React, { FC } from "react";
import { FiSearch } from "react-icons/fi";
import { useDebouncedCallback } from "use-debounce/lib";
import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { theme } from "ovou-ui";

interface ISearchInputProps {
  defaultValue: string;
  onChange: (event: any) => void;
  placeholder?: string;
}

const SearchInput: FC<ISearchInputProps> = ({
  defaultValue = "",
  onChange,
  placeholder = "Search name, email or company",
}) => {
  const [onChangeDebounced] = useDebouncedCallback(searchQuery => {
    onChange(searchQuery);
  }, 500);

  return (
    <InputGroup>
      <InputLeftElement pointerEvents="none">
        <FiSearch size="24" color={theme.palette.ui.greys.grey2} />
      </InputLeftElement>
      <Input
        placeholder={placeholder}
        fontSize={16}
        defaultValue={defaultValue?.trim()}
        onChange={e => onChangeDebounced(e.target.value?.trim())}
      />
    </InputGroup>
  );
};

export default SearchInput;
