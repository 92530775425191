import React, { useState, useCallback } from "react";

import styled from "styled-components";

import CardsLoader from "./CardsLoader";
import { useCardsPoolQuery, useCardsPoolStatsQuery } from "./queries";
import {
  PageTopBar,
  Button,
  TemplateCard,
  Card,
  WaitingCard,
  SigninCard,
} from "../../components";
import Helmet from "../../components/Helmet/Helmet";
import { TEXTS } from "../../constants";
import { useUserState } from "../../context";
import { useSearchQuery } from "../../hooks";
import { AssignCardModal, UnassignCardModal } from "../../modals";
import { openLoginAsUserPage } from "../../utils";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 25px;
`;

const openUserProfile = username => {
  const a = document.createElement("a");
  a.href = `${process.env.REACT_APP_SITE_URL}/${username}`;
  a.target = "_blank";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

const Cards = () => {
  const { currentTeam } = useUserState();
  const [state, setState] = useState("signIn"); // signIn, delete
  const [isAssignCardModalOpen, setIsAssignCardModalOpen] = useState(false);
  const [isUnassignCardModalOpen, setIsUnassignCardModalOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState();
  const searchQuery = useSearchQuery();
  const teamID = currentTeam?.id;

  const { data, isLoading, refetch } = useCardsPoolQuery({
    searchQuery,
    teamID,
    states: ["assigned", "appointed"],
    profile_state: ["representative"],
    sort: "tag",
  });

  const {
    data: cardsPoolStats,
    isLoading: isCardsPoolStatsLoading,
    refetch: refetchStats,
  } = useCardsPoolStatsQuery({
    teamID: currentTeam?.id,
  });

  const handleCardClick = useCallback(
    card => {
      setSelectedCard(card.id);
      if (state === "signIn") {
        if (card?.state === "appointed") {
          if (!card?.profile?.is_user_editable) {
            openLoginAsUserPage({
              profileID: card?.profile?.id,
              teamID: currentTeam?.id,
            });
          }
        }
        if (card?.state === "assigned") {
          if (card?.profile?.is_user_editable) {
            openUserProfile(card?.profile?.handle);
          } else {
            openLoginAsUserPage({
              profileID: card?.profile?.id,
              teamID: currentTeam?.id,
            });
          }
        }
      } else if (state === "delete") {
        setIsUnassignCardModalOpen(true);
      }
    },
    [currentTeam, state],
  );

  return (
    <>
      <Helmet title="OVOU | Profiles" />
      <PageTopBar
        pageTitle="Profiles"
        sideTitle={() => (
          <p className="hidden sm:block ml-5">
            <span className="text-base font-bold text-primary">
              {isCardsPoolStatsLoading
                ? ".."
                : (cardsPoolStats?.appointed || 0) +
                  (cardsPoolStats?.assigned || 0)}{" "}
              Active{" "}
            </span>
            <span className="text-base text-black font-light">
              / {isCardsPoolStatsLoading ? ".." : cardsPoolStats?.total || 0}{" "}
              Cards
            </span>
          </p>
        )}
        pageIcon="cards"
        action={() => (
          <Button
            style={{ height: "46px" }}
            className="ml-auto"
            size="small"
            variant={state === "delete" ? "white" : "primaryLight"}
            onClick={() =>
              setState(prev => (prev === "delete" ? "signIn" : "delete"))
            }
            uppercase
            data-testid="unassign-cards-button"
          >
            {state !== "delete"
              ? TEXTS.common.UNASSIGN_CARD
              : TEXTS.common.CANCEL}
          </Button>
        )}
      />
      <Wrapper data-testid="assign-card-wrapper">
        <TemplateCard
          icon="cardsStack"
          title={`${TEXTS.common.ASSIGN_CARD}`}
          className="bg-primary-light text-primary"
          disabled={state === "delete"}
          onClick={() => state !== "delete" && setIsAssignCardModalOpen(true)}
        />
        {isLoading && <CardsLoader />}
        {!isLoading &&
          data?.cards?.map((card, index) => {
            let cardJsx;
            if (card?.state === "appointed") {
              if (card?.profile?.is_user_editable) {
                cardJsx = (
                  <WaitingCard
                    onClick={() => handleCardClick(card)}
                    state={state}
                    key={card.id}
                    email={card?.profile?.appointed_email}
                    testid={index}
                    cardData={card?.profile}
                    refetch={refetch}
                    tag={card?.tag}
                    cardID={card?.id}
                  />
                );
              } else {
                cardJsx = (
                  <SigninCard
                    onClick={() => handleCardClick(card)}
                    state={state}
                    key={card.id}
                    email={card?.profile?.appointed_email}
                    cardData={card?.profile}
                    refetch={refetch}
                    tag={card?.tag}
                  />
                );
              }
            }
            if (card?.state === "assigned") {
              cardJsx = (
                <Card
                  key={card.id}
                  onClick={() => handleCardClick(card)}
                  state={state}
                  cardData={card?.profile}
                  refetch={refetch}
                  tag={card?.tag}
                />
              );
            }
            return cardJsx;
          })}
      </Wrapper>
      {isAssignCardModalOpen && (
        <AssignCardModal
          isOpen={isAssignCardModalOpen}
          onClose={() => setIsAssignCardModalOpen(false)}
          refetch={() => {
            refetchStats();
            refetch();
          }}
          label="Assign Card Modal"
          teamID={teamID}
        />
      )}
      <UnassignCardModal
        isOpen={isUnassignCardModalOpen}
        onClose={() => setIsUnassignCardModalOpen(false)}
        label="Unassign Card Modal"
        refetch={() => {
          refetchStats();
          refetch();
        }}
        cardID={selectedCard}
        teamID={teamID}
      />
    </>
  );
};

export default Cards;
