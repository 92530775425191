import { FC } from "react";
import { Text, TextProps } from "@chakra-ui/react";
import { theme } from "ovou-ui";

interface IDateTimeDisplay extends TextProps {
  dateString: string;
  onlyDate?: boolean;
  onlyTime?: boolean;
}

const DateTimeDisplay: FC<IDateTimeDisplay> = ({
  dateString,
  onlyDate,
  onlyTime,
  fontSize = 14,
  fontWeight = 400,
  color = theme.palette.ui.greys.grey2,
  ...props
}) => {
  const dateTime = new Date(dateString);
  const time = dateTime.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  const date = dateTime.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });

  return (
    <Text color={color} fontSize={fontSize} fontWeight={fontWeight} {...props}>
      {[
        onlyTime && !onlyDate ? null : date,
        onlyDate && !onlyTime ? null : time,
      ]
        .filter(Boolean)
        .join(" ・ ")}
    </Text>
  );
};

export default DateTimeDisplay;
