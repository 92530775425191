import React from "react";
import { Button, ButtonProps, forwardRef } from "@chakra-ui/react";
import { theme } from "ovou-ui";

export const ButtonOutlined = forwardRef<ButtonProps, "div">(
  ({ children, ...buttonProps }, ref) => (
    <Button
      ref={ref}
      boxSizing="border-box"
      minWidth="max-content"
      height="2rem"
      paddingX="0.75rem"
      paddingY="0.5rem"
      backgroundColor={theme.palette.brand.primary.white}
      borderWidth="1px"
      borderColor={theme.palette.ui.greys.grey3}
      borderRadius="0.375rem"
      fontWeight="semibold"
      fontSize="0.875rem"
      textColor={theme.palette.brand.primary.black}
      _hover={{
        backgroundColor: theme.palette.ui.greys.grey5,
      }}
      _focus={{
        backgroundColor: theme.palette.ui.greys.grey5,
      }}
      {...buttonProps}
    >
      {children}
    </Button>
  ),
);
