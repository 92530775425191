/**
 * Customer Portal
 * @returns {Object} { url: string}
 */

import { useQuery } from "react-query";
import api from "./index";
import { AxiosRequestConfig } from "axios";

interface IParagonUserTokenResponse {
  data: { user_token: string };
}
interface IParagonUserToken {
  team_id?: number;
}

interface IWorkflowDetails {
  workflow_id: string;
  status: string;
  timestamp: string;
  output_details: object;
}

interface IParagonTaskHistoryResponse {
  results: IWorkflowDetails[];
  total: number;
}

export const getParagonUserToken = (
  payload: IParagonUserToken,
  options?: AxiosRequestConfig,
): Promise<IParagonUserTokenResponse> => {
  return api.post("/services/paragon/user_token", payload, options);
};

const getParagonTaskHistory = (
  integration: string,
  teamId?: number,
  offset?: number,
  options?: AxiosRequestConfig,
): Promise<IParagonTaskHistoryResponse> => {
  const config: AxiosRequestConfig = {
    ...options,
    params: {
      integration,
      team_id: teamId,
      offset,
    },
  };
  return api
    .get(`/services/paragon/task-history`, config)
    .then(response => response.data);
};

export const useGetParagonTaskHistory = (
  integration: string,
  teamId?: number,
  offset?: number,
  options: AxiosRequestConfig = {},
  queryOptions = {},
) => {
  return useQuery(
    ["paragonTaskHistory", integration, teamId, offset],
    () => getParagonTaskHistory(integration, teamId, offset, options),
    { ...queryOptions },
  );
};
