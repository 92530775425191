import React from "react";

const Drop = ({
  className,
  role,
  ignoreDefaultSize,
  width = 36,
  height = 36,
  color,
  onClick,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={ignoreDefaultSize ? undefined : width}
      height={ignoreDefaultSize ? undefined : height}
      viewBox="0 0 24 24"
      role={role}
      className={className}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.3766 0.98066C21.069 -0.326887 18.9485 -0.326887 17.641 0.98066L14.629 3.99198L12.5052 1.86821L10.6377 3.73575L19.6215 12.7195L21.489 10.852L19.3652 8.7282L22.3766 5.71622C23.6841 4.40868 23.6841 2.28821 22.3766 0.98066Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.91349 18.8038L4.80028 19.8611L4.24094 20.4191C3.87509 20.7843 3.28075 20.7843 2.91556 20.4191C2.73858 20.2421 2.64151 20.007 2.64151 19.7567C2.64151 19.5064 2.73858 19.2714 2.91556 19.0944L3.4683 18.5423L4.54736 16.4496L10.7787 10.2182L13.1382 12.5784L6.91349 18.8038ZM2.38858 14.8732L1.31019 16.9647L1.04802 17.2262C0.371792 17.9031 0 18.8012 0 19.7567C0 20.713 0.371792 21.6111 1.04802 22.2866C1.74604 22.9847 2.66198 23.3333 3.57858 23.3333C4.49453 23.3333 5.41047 22.9847 6.10783 22.2873L6.36405 22.0317L8.47792 20.9745L16.874 12.5784L10.7787 6.48315L2.38858 14.8732Z"
        fill={color}
      />
    </svg>
  );
};

export default React.memo(Drop);
