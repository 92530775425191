import React, { FC, useEffect, useState } from "react";
import { useWizard } from "react-use-wizard";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import { useMediaLayout } from "use-media";

import { Box, Flex, TextFormField } from "../../../components";
import { generateMediaPath } from "../../../utils";
import { useProfileDataQuery } from "features/auth/queries";
import ImageUploadFormField from "./ImageUploadFormField";
import {
  useCreateIndividualQuery,
  useUpdateIndividualProfileQuery,
} from "features/individualProfile/queries";
import { VALIDATION_MESSAGES } from "../../../constants";
import { IProfile } from "types/Profile";
import { checkImageData } from "features/settings/queries/useUpdateProfileQuery";
import { ProfilePreview } from "./ProfilePreview";
import { StepSection } from "./StepSection";
import { StepSubmit } from "./StepSubmit";
import { useUser } from "context";
import { ImageType } from "../helpers/constants";
import { useUpdateTeamMutation } from "features/settings/queries";
import { WIZARD_STEP } from "types/Team";
import { useTracking } from "hooks";
import { ANALYTICS_EVENTS } from "constants/analyticEvents";

const validationSchema = yup.object({
  first_name: yup
    .string()
    .trim()
    .max(30, VALIDATION_MESSAGES.MAX_LENGTH("First Name", 30))
    .required(VALIDATION_MESSAGES.IS_REQUIRED("First name")),
  last_name: yup
    .string()
    .trim()
    .required(VALIDATION_MESSAGES.IS_REQUIRED("Last name"))
    .max(150, VALIDATION_MESSAGES.MAX_LENGTH("Last Name", 150)),
  job_title: yup.string(),
});

export const AdminProfileStep: FC = () => {
  const track = useTracking();
  const isMobile = useMediaLayout({ maxWidth: "768px" });
  const { nextStep } = useWizard();

  const [adminProfile, setAdminProfile] = useState<IProfile>();

  const [{ currentTeam }] = useUser();
  const { getProfileData } = useProfileDataQuery({
    state: "representative",
  });

  const [updateTeam] = useUpdateTeamMutation({});
  const { mutate: updateProfile, status } = useUpdateIndividualProfileQuery({
    showToasts: true,
    updateType: "profile",
    successMsg: "Info updated successfully",
  });
  const isLoading = status === "loading";

  const { mutate: uploadProfilePicture } = useCreateIndividualQuery({
    createType: "picture",
  });

  const handleSubmit = async ({ picture, ...values }: any) => {
    const uploadPicture = checkImageData(picture);

    if (Boolean(uploadPicture)) {
      await uploadProfilePicture({
        handle: adminProfile?.handle,
        profileData: { file: picture },
      });
    }

    await updateProfile({
      profileData: {
        ...values,
        ...(!Boolean(picture) && { picture }),
      },
      handle: adminProfile?.handle,
    });

    track({
      eventName: ANALYTICS_EVENTS.TEAM_ONBOARDING_ADMIN_PROFILE_SUBMIT,
      customTrackingProps: {
        ...values,
      },
    });

    nextStep();
  };

  const handleSkip = () => {
    track({
      eventName: ANALYTICS_EVENTS.TEAM_ONBOARDING_ADMIN_PROFILE_SKIP,
    });

    nextStep();
  };

  const handleAdminProfileChange = (key: string, value: string | null) => {
    setAdminProfile(adminProfile => {
      return {
        ...adminProfile,
        [key]: value,
      } as IProfile;
    });
  };

  const handleGetAdminProfile = async () => {
    const data = await getProfileData();
    const adminProfile = data?.profiles?.find(profile =>
      Boolean(profile.is_team_admin),
    );
    setAdminProfile(adminProfile);
  };

  const handleProfilePictureChange = (croppedImage: string) => {
    handleAdminProfileChange("picture", croppedImage);

    track({
      eventName:
        ANALYTICS_EVENTS.TEAM_ONBOARDING_ADMIN_PROFILE_UPLOAD_PROFILE_PICTURE,
    });
  };

  const handleProfilePictureDelete = () => {
    handleAdminProfileChange("picture", null);

    track({
      eventName:
        ANALYTICS_EVENTS.TEAM_ONBOARDING_ADMIN_PROFILE_DELETE_PROFILE_PICTURE,
    });
  };

  useEffect(() => {
    handleGetAdminProfile();
  }, []);

  useEffect(() => {
    updateTeam({
      teamID: currentTeam?.id,
      teamData: {
        ...currentTeam,
        wizard_step: WIZARD_STEP.ADMIN_PROFILE,
      },
    });
  }, []);

  return (
    <Flex justifyContent="center" gap={100}>
      <StepSection title="Let’s create your profile">
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            first_name: adminProfile?.first_name || "",
            last_name: adminProfile?.last_name || "",
            job_title: adminProfile?.job_title || "",
            picture: adminProfile?.picture
              ? generateMediaPath(adminProfile?.picture || "") || ""
              : null,
          }}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ isValid }) => (
            <Form id="settings-form" autoComplete="off">
              <Flex flexDirection="column">
                <Field
                  name="first_name"
                  label="First name"
                  maxLength={30}
                  charCounter
                  component={TextFormField}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handleAdminProfileChange(
                      event.target.name,
                      event.target.value,
                    )
                  }
                />
                <Box marginTop={6}>
                  <Field
                    name="last_name"
                    label="Last name"
                    maxLength={150}
                    charCounter
                    component={TextFormField}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleAdminProfileChange(
                        event.target.name,
                        event.target.value,
                      )
                    }
                  />
                </Box>
                <Box marginTop={6}>
                  <Field
                    name="job_title"
                    label="Job title"
                    maxLength={80}
                    charCounter
                    component={TextFormField}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleAdminProfileChange(
                        event.target.name,
                        event.target.value,
                      )
                    }
                  />
                </Box>
              </Flex>

              <StepSection
                sectionType="subSection"
                title="Upload Profile Picture"
                subTitle="For best result, upload your logo in PNG format with transparent background!"
              >
                <Field
                  component={ImageUploadFormField}
                  label="Picture"
                  name="picture"
                  imageType={ImageType.PROFILE_PICTURE}
                  onChange={(croppedImage: string) =>
                    handleProfilePictureChange(croppedImage)
                  }
                  onDelete={handleProfilePictureDelete}
                />
              </StepSection>

              <StepSubmit
                isDisabled={isLoading || !isValid || !adminProfile?.handle}
                isLoading={isLoading}
                onSkip={handleSkip}
              />
            </Form>
          )}
        </Formik>
      </StepSection>

      {!isMobile && <ProfilePreview profile={adminProfile} />}
    </Flex>
  );
};
