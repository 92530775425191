import { useQuery } from "react-query";

import api from "../api";

const featureFlagsService = async () => {
  const { data } = await api.get("/settings/flags");

  return data;
};

export const useFeatureFlagsQuery = () => {
  return useQuery("feature-flags", featureFlagsService);
};
