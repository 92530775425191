import React from "react";

import classNames from "classnames";
import ReactTooltip from "react-tooltip";

import { Icon } from "..";

const Section = ({
  children,
  label,
  info = "",
  showBorder = true,
  subLabel = "",
  fontWeight = "font-extrabold",
  textColor = "text-black",
  alignCenter = true,
}) => {
  return (
    <div
      className={classNames({
        "sm:grid sm:grid-cols-3 sm:gap-8  mt-6 sm:mt-12": true,
        "sm:border-t sm:border-gray-200 sm:pt-12": showBorder,
        "sm:items-center": alignCenter,
      })}
    >
      <div
        className={classNames({
          "flex sm:mt-px sm:pt-2": true,
          "items-center": !subLabel && alignCenter,
          "flex-col": !!subLabel,
        })}
      >
        <label className={`block text-lg ${fontWeight} leading-5 ${textColor}`}>
          {label}
        </label>
        {info && (
          <div data-tip={info} className="ml-2">
            <Icon name="info" />
          </div>
        )}
        <ReactTooltip place="top" type="dark" effect="solid" />
        {subLabel && typeof subLabel === "string" ? (
          <span className="block text-sm font-medium leading-5 text-gray-500 mt-2">
            {subLabel}
          </span>
        ) : (
          subLabel
        )}
      </div>
      <div className="mt-4 sm:mt-0 sm:col-span-2">{children}</div>
    </div>
  );
};

export default Section;
