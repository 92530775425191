import React, { FC } from "react";
import { HStack, StackProps, Text } from "@chakra-ui/react";
import { theme } from "ovou-ui";

import { TagColorIndicator } from "../../features/contacts/components/TagColorIndicator";

interface ITagColorOptionProps {
  name: string;
  color: string;
  stackProps?: StackProps;
  onDelete?: () => void;
}

export const TagColorOption: FC<ITagColorOptionProps> = ({
  name,
  color,
  stackProps,
}) => {
  return (
    <HStack
      direction="row"
      width="max-content"
      paddingX={3}
      paddingY={2}
      gap={1}
      {...stackProps}
    >
      <TagColorIndicator color={color} />
      <Text
        fontWeight="semibold"
        fontSize="14px"
        lineHeight="16px"
        align="center"
        color={theme.palette.brand.primary.black}
      >
        {name}
      </Text>
    </HStack>
  );
};
