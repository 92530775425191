import { useMutation } from "react-query";
import { ITeamUserChange } from "types/TeamUsersResponse";

import api from "../../../api";

type teamsUsersMutation = {
  teamId: number;
  userId: number;
  userEmail?: string;
};
const teamUserChangeService = async ({
  teamId,
  userId,
  userEmail,
}: teamsUsersMutation) => {
  const { data } = await api.post<ITeamUserChange>(
    `/profile/teams/${teamId}/users/change`,
    {
      user_id: userId,
      assigned_to_user_email: userEmail,
    },
  );
  return data;
};

export const useTeamUsersChangeMutation = () => {
  const [mutate, { ...mutationProps }] = useMutation(teamUserChangeService);

  return {
    mutate,
    ...mutationProps,
  };
};

const teamUserResendService = async ({
  teamId,
  userId,
}: teamsUsersMutation) => {
  const { data } = await api.post(
    `/profile/teams/${teamId}/users/resend-verification`,
    {
      user_id: userId,
    },
  );
  return data;
};

export const useTeamUsersResendMutation = () => {
  const [mutate, { ...mutationProps }] = useMutation(teamUserResendService);

  return {
    mutate,
    ...mutationProps,
  };
};
