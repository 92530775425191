import { useQuery } from "react-query";

import api from "../../../api";
import { IProfileResponse } from "../../../types/Profile";

const profileService = async (_key: any, { state }: any) => {
  const { data } = await api.get<IProfileResponse>("/profile", {
    params: { state },
  });
  return data;
};

export const useProfileDataQuery = (
  {
    onSuccess,
    state = "business",
    filterByState = true,
  }: {
    onSuccess?: any | null;
    state?: string;
    filterByState?: boolean;
  },
  enabled = false,
) => {
  const { data, status, refetch, error, isLoading, isSuccess } =
    useQuery<IProfileResponse>(
      ["profile-data", { state: filterByState ? state : undefined }],
      profileService,
      {
        onSuccess,
        enabled: enabled,
      },
    );

  return {
    getProfileData: refetch,
    data,
    status,
    error,
    isLoading,
    isSuccess,
  };
};
