import {
  FEATURES,
  FEATURE_ACTION,
  TPermission,
  useGetPermissions,
} from "ovou-ui";

import { usePermissionsState, useUserState } from "context";

export interface IProfilePermission {
  canViewCustomColor: boolean;
  canEditCustomColor: boolean;
  canViewLogo: boolean;
  canEditLogo: boolean;
  canViewCover: boolean;
  canEditCover: boolean;
}

const useProfilePermission = (): IProfilePermission => {
  const { permission } = usePermissionsState();
  const { default_permission: permissionSet } = permission || {};
  const logo = useGetPermissions(permissionSet, FEATURES.SETTING_BRANDING_LOGO);
  const cover = useGetPermissions(
    permissionSet,
    FEATURES.SETTING_BRANDING_COVER,
  );

  const customColor = useGetPermissions(
    permissionSet,
    FEATURES.SETTING_BRANDING_CUSTOM_COLOR,
  );

  return {
    canViewCustomColor: customColor?.view.enabled,
    canEditCustomColor: customColor?.edit.enabled,
    canViewLogo: logo?.view?.enabled,
    canEditLogo: logo?.edit.enabled,
    canViewCover: cover?.view?.enabled,
    canEditCover: cover?.edit?.enabled,
  };
};
export default useProfilePermission;
