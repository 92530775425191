import { useQuery } from "react-query";
import { IAccountResponse } from "types/AccountResponse";
import api from "../api";

const getAccount = async () => {
  const { data } = await api.get<IAccountResponse>("/account");

  return data;
};

export const useGetAccountQuery = () => {
  return useQuery<IAccountResponse>("get-account", getAccount);
};
