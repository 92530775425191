import React, { FC } from "react";
import GroupsList from "./GroupsList";
import TeamList from "./TeamList";
import { TabNavigation } from "components";
import { ITeamAndGroupsList } from "../types";

const TeamAndGroupsList: FC<ITeamAndGroupsList> = ({
  teamMembers,
  groups,
  isLoading,
}) => {
  const tabs = [
    {
      title: "Team Members",
      content: <TeamList teamMembers={teamMembers} isLoading={isLoading} />,
    },
    {
      title: "Groups",
      content: <GroupsList groups={groups} isLoading={isLoading} />,
    },
  ];
  return (
    <TabNavigation
      tabs={tabs}
      tabProps={{
        flexGrow: 1,
        flexBasis: 0,
      }}
    />
  );
};

export default TeamAndGroupsList;
