import { useMutation } from "react-query";

import api from "../../../api";

const confirmationEmailService = async ({ email }) => {
  const { data } = await api.post("/auth/verify/", {
    email,
  });
  return data;
};

export const useSendConfirmationEmailMutation = ({ onSuccess }) => {
  const [mutate, { status, error, isLoading }] = useMutation(
    confirmationEmailService,
    {
      onSuccess,
    },
  );

  return {
    mutate,
    status,
    error,
    isLoading,
  };
};
