import { FC, ReactNode, useEffect } from "react";

import { FlagProvider } from "@unleash/proxy-client-react";
import {
  UNLEASH_CLIENT_KEY,
  UNLEASH_FRONT_END,
  ENVIRONMENT,
} from "../../constants";

const UNLEASH_FALLBACK_URL = "https://us.app.unleash-hosted.com";
const UNLEASH_FALLBACK_CLIENT_KEY = "Teams:development";

const config = {
  url: UNLEASH_FRONT_END || UNLEASH_FALLBACK_URL,
  clientKey: UNLEASH_CLIENT_KEY || UNLEASH_FALLBACK_CLIENT_KEY,
  appName: "teams",
  refreshInterval: 60,
  environment: ENVIRONMENT,
  customHeaders: {
    // have to set this to empty string to avoid 304 redirect
    "If-None-Match": "",
  },
};

const FeatureFlagProvider: FC<{ children: ReactNode }> = ({ children }) => {
  return <FlagProvider config={config}>{children}</FlagProvider>;
};

export default FeatureFlagProvider;
