import React, { FC, useMemo } from "react";
import { components } from "react-select";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";

import { TextFormField, Spinner, SelectFormField } from "..";
import { VALIDATION_MESSAGES } from "../../constants";
import { ITagColor } from "../../features/contacts/types/Tag";
import { ISelectOption } from "../../utils/types";
import { TagColorOption } from "./TagColorOption";
import { Box, Button } from "@chakra-ui/react";

const validationSchema = yup.object({
  name: yup.string().required(VALIDATION_MESSAGES.IS_REQUIRED("Tag name")),
  color: yup.string().required(VALIDATION_MESSAGES.IS_REQUIRED("Tag color")),
});

interface ICreateTagForm {
  tagColors: ITagColor[];
  areSettingsLoading?: boolean;
  isCreatingTag?: boolean;
  onSubmit: (tagColor: ITagColor) => void;
}

export const CreateTagForm: FC<ICreateTagForm> = ({
  tagColors,
  areSettingsLoading = false,
  isCreatingTag = false,
  onSubmit,
}) => {
  const tagColorOptions: ISelectOption[] = useMemo(() => {
    return (
      tagColors?.map((tagColor: ITagColor) => ({
        label: tagColor?.name || "",
        value: tagColor?.color || "",
      })) || []
    );
  }, [tagColors]);

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        name: "",
        color: "",
      }}
      onSubmit={(values, { resetForm }) => {
        onSubmit(values);
        resetForm();
      }}
      enableReinitialize
    >
      {({ isValid, dirty, submitForm }) => (
        <Form noValidate autoComplete="off">
          <Field label="Tag name" name="name" component={TextFormField} />
          <Box marginTop={4}>
            <Field
              name="color"
              placeholder="Select tag color"
              options={tagColorOptions}
              component={SelectFormField}
              isLoading={areSettingsLoading}
              disabled={areSettingsLoading}
              renderInPortal
              components={{ Option, SingleValue }}
            />
          </Box>
          <Button
            width="full"
            marginTop={4}
            isDisabled={isCreatingTag || !isValid || !dirty}
            onClick={submitForm}
          >
            {isCreatingTag ? <Spinner color="white" /> : "Create Tag"}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

const Option = (props: any) => {
  return (
    <components.Option {...props}>
      <TagColorOption name={props.label} color={props.value} />
    </components.Option>
  );
};

const SingleValue = (props: any) => {
  return (
    <components.SingleValue {...props}>
      <TagColorOption name={props.data.label} color={props.data.value} />
    </components.SingleValue>
  );
};

export default CreateTagForm;
