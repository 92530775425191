import styled from "styled-components";

export const Box = styled.div`
  position: ${({ position }) => position};
  bottom: ${({ bottom }) => bottom};
  left: ${({ left }) => left};
  transform: ${({ transform }) => transform};
  min-width: ${({ minWidth }) => minWidth};
  min-height: ${({ minHeigt }) => minHeigt};
  background: ${({ background }) => background};
  /* background: #FF643A; */
  box-shadow: 0px 8px 36px rgba(0, 0, 0, 0.05);
  border-width: ${({ borderWidth }) => borderWidth};
  /* border-width: 4px; */
  border-style: ${({ borderStyle }) => borderStyle};
  border-color: ${({ borderColor }) => borderColor};
  overflow: hidden;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border-radius: ${({ borderRadius }) => borderRadius};
`;
