import { useQuery } from "react-query";

import api from "../../../api";

const cardsPoolService = async (
  _key,
  {
    states,
    profile_state,
    assignable,
    searchQuery,
    teamID,
    offset,
    limit,
    sort,
    has_user,
    hasProfileDirectory,
  },
) => {
  const { data } = await api.get(`/card/pool/of/${teamID}`, {
    params: {
      states: states?.length ? states.join() : [],
      profile_state: profile_state?.length ? profile_state.join() : [],
      assignable,
      search: searchQuery,
      offset,
      limit,
      sort,
      has_user,
      has_profile_directory: hasProfileDirectory,
    },
  });
  return data;
};

export const useCardsPoolQuery = ({
  onSuccess,
  states = [],
  profile_state = [],
  searchQuery,
  assignable,
  teamID,
  offset,
  limit,
  sort,
  has_user,
  hasProfileDirectory,
  queryAutomatically = true,
}) => {
  const { status, isLoading, isFetching, data, error, refetch } = useQuery(
    [
      "cards-pool",
      {
        states,
        profile_state,
        assignable,
        searchQuery,
        teamID,
        offset,
        limit,
        sort,
        has_user,
        hasProfileDirectory,
      },
    ],
    cardsPoolService,
    {
      onSuccess,
      enabled: teamID !== undefined && queryAutomatically,
      refetchOnWindowFocus: !queryAutomatically,
    },
  );

  return {
    isFetching,
    isLoading,
    status,
    data,
    error,
    refetch,
  };
};
