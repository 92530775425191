import { FC, ReactElement } from "react";
import { Redirect } from "react-router-dom";

import { getToken } from "../utils";

export const AccessTokenGuard: FC<{ children: ReactElement }> = ({
  children,
}) => {
  const { accessToken } = getToken();
  return accessToken ? children : <Redirect to="/auth/login" />;
};
