import React, { FC, useCallback, useMemo, useState } from "react";

import { NavLink } from "react-router-dom";
import { Box, Link as ChakraLink, LinkProps, Text } from "@chakra-ui/react";
import { theme } from "ovou-ui";

import { IMenuItem } from "../../types/IMenuItem";
import { useCheckPermission, useTracking } from "hooks";
import { FEATURES, FEATURE_ACTION } from "constants/features";
import UpgradeModal from "../../components/NewModal/UpgradeModal";

const activeStyle = {
  bg: theme.palette.ui.greys.grey5,
  textColor: theme.palette.brand.primary.black,
};

const MenuItem: FC<IMenuItem & LinkProps> = ({
  title,
  icon,
  to,
  isExternal = false,
  isUpgrade = false,
  onTrial = false,
  close,
  feature,
  canEdit = true,
  ...props
}) => {
  const track = useTracking();
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const canEditMenuItem = useCheckPermission(
    FEATURE_ACTION.EDIT,
    feature as FEATURES,
  );

  const routingProps = useMemo(() => {
    if (isUpgrade || !canEdit) return { href: "#" };
    if (!isExternal) return { to, as: NavLink };
    if (isExternal && canEditMenuItem && canEdit) {
      return { href: to, isExternal };
    }
  }, [isExternal, to, canEditMenuItem]);
  const handleClick = useCallback(() => {
    if (isUpgrade || !canEditMenuItem || !canEdit) {
      track({ upgrade: true, customTrackingProps: { source: feature } });
      setShowUpgradeModal(true);
    }
    close?.();
  }, [isUpgrade, canEditMenuItem, feature]);

  return (
    <ChakraLink
      display="flex"
      alignItems="center"
      borderRadius="lg"
      cursor="pointer"
      p={2}
      textColor={theme.palette.ui.greys.grey1}
      fontSize="lg"
      fontWeight={600}
      transition="all 150ms ease-in-out"
      textDecoration="none"
      _hover={activeStyle}
      _activeLink={activeStyle}
      onClick={handleClick}
      {...props}
      {...routingProps}
    >
      {icon}
      <Text marginX={3}>{title}</Text>
      {onTrial && <TrialTag />}
      {showUpgradeModal && <UpgradeModal showUpgradeModal={showUpgradeModal} />}
    </ChakraLink>
  );
};

const TrialTag: FC = () => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    width={10}
    height={5}
    paddingX={1}
    borderRadius="base"
    backgroundColor={theme.palette.brand.primary.black}
  >
    <Text
      fontSize="2xs"
      lineHeight="taller"
      fontWeight="semibold"
      textTransform="uppercase"
      color={theme.palette.brand.primary.white}
    >
      TRIAL
    </Text>
  </Box>
);

export default MenuItem;
