import { useQuery } from "react-query";

import api from "../../../api";

const workCategoryService = async () => {
  const { data } = await api.get(`/work_category`);
  return data;
};

export const useWorkCategoryQuery = ({ onSuccess = () => {} }) => {
  const { status, isLoading, data, error, refetch } = useQuery(
    "work-category",
    workCategoryService,
    {
      onSuccess,
    },
  );

  return {
    isLoading,
    status,
    data,
    error,
    refetch,
  };
};
