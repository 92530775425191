import React, { useState, useEffect } from "react";

import { Draggable } from "react-beautiful-dnd";
import styled from "styled-components";

import NoResultsFound from "./NoResultsFound";
import {
  useDirectoryQuery,
  useDeleteGroupQuery,
  useGroupCreateEditQuery,
} from "./queries";
import {
  Button,
  Icon,
  Accordion,
  Card,
  Spinner,
  DragAndDropWrapper,
  DragHandle,
  PageSetup,
} from "../../components";
import { Box, Flex } from "../../components/Container";
import { TEXTS } from "../../constants";
import { useUserState } from "../../context";
import { useSearchQuery, useTracking } from "../../hooks";
import { CreateEditGroup } from "../../modals";
import { reorderItems } from "../../utils";
import SearchInput from "features/contacts/components/SearchInput";
import { useHistory } from "react-router-dom";
import { FEATURES } from "constants/features";
import { ANALYTICS_EVENTS } from "constants/analyticEvents";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-gap: 25px;
`;

const Directory = () => {
  const track = useTracking();
  const { currentProfile, currentTeam } = useUserState();
  const [isCreateEditGroupModalOpen, setIsCreateEditGroupModalOpen] =
    useState(false);
  const [selectedDirectory, setSelectedDirectory] = useState(null);
  const [directories, setDirectories] = useState([]);

  const searchQuery = useSearchQuery();
  const history = useHistory();
  const { data, isLoading, refetch } = useDirectoryQuery({
    searchQuery,
    businessProfileHandle: currentProfile?.handle,
    teamID: currentTeam?.id,
  });
  const { mutate: editCreateGroup } = useGroupCreateEditQuery({
    onSuccess: () => {
      refetch();
    },
  });

  useEffect(() => {
    if (!data) return;
    setDirectories(data?.directories || []);
  }, [data]);

  const { mutate: deleteGroup } = useDeleteGroupQuery({
    onSuccess: () => {
      refetch();
    },
  });

  const createNewGroupButton = ({ className }) => (
    <Button
      className={className}
      size="small"
      variant="primary"
      onClick={() => {
        setSelectedDirectory(null);
        setIsCreateEditGroupModalOpen(true);
        track({ eventName: ANALYTICS_EVENTS.GROUPS_OPEN_GROUP_MODAL });
      }}
      capitalize
      data-testid="create-group-button"
    >
      <Icon name="plusCircle" className="mr-2" />
      Create New Group
    </Button>
  );
  const handleDragEnd = result => {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;

    const reorderDirectories = reorderItems(
      directories,
      result.source.index,
      result.destination.index,
    );

    const updateData = reorderDirectories.map(directory => ({
      directoryID: directory.id,
      formData: {
        title: directory.title,
        profile_handles: directory.profiles.map(item => item.handle),
        order: directory.order,
      },
    }));
    for (let index = 0; index < updateData.length; index++) {
      const directory = updateData[index];
      editCreateGroup({
        directoryID: directory.directoryID,
        formData: directory.formData,
        teamID: currentTeam?.id,
      });
    }
    setDirectories(reorderDirectories);
  };
  return (
    <PageSetup
      title={TEXTS.routes.DIRECTORY}
      subTitle={TEXTS.routes.DIRECTORY_SUB_TITLE}
      rightBodyElement={
        Boolean(directories.length) &&
        createNewGroupButton({ className: "ml-auto" })
      }
      feature={FEATURES.GROUPS}
    >
      <Box mb="25px" w="500px">
        {Boolean(directories.length) && (
          <SearchInput
            defaultValue={searchQuery}
            onChange={query => history.replace(`?query=${query}`)}
          />
        )}
      </Box>
      {isLoading && <Spinner />}
      {!isLoading && !searchQuery && !directories.length && (
        <NoResultsFound>
          {createNewGroupButton({ className: "ml-0" })}
        </NoResultsFound>
      )}
      {!isLoading && searchQuery && !directories.length && (
        <div className="p-5 sm:py-10 sm:px-12 bg-white rounded-xl shadow-sm text-center">
          <p>No Results Found</p>
        </div>
      )}
      <DragAndDropWrapper onDragEnd={handleDragEnd} droppableId="directoryList">
        {!isLoading && !!directories.length && (
          <>
            {directories?.map((directory, index) => (
              <Draggable
                draggableId={directory?.id?.toString()}
                key={directory?.id}
                index={index}
              >
                {(provided, snapshot) => (
                  <Flex
                    margin="0 0 1.25rem 0"
                    alignItem="center"
                    width="100%"
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                    isDragging={snapshot.isDragging}
                    {...provided.dragHandleProps}
                  >
                    <DragHandle />
                    <Accordion
                      title={directory?.title}
                      id={directory?.id}
                      onDelete={directoryID =>
                        deleteGroup(
                          { directoryID },
                          {
                            onSuccess: () => {
                              track({
                                eventName: ANALYTICS_EVENTS.GROUPS_DELETE_GROUP,
                                customTrackingProps: {
                                  directoryId: directoryID,
                                },
                              });
                            },
                          },
                        )
                      }
                      onEdit={directoryID => {
                        setSelectedDirectory(directoryID);
                        setIsCreateEditGroupModalOpen(true);
                      }}
                      testid={index}
                      isOpen={!!searchQuery}
                      width="100%"
                    >
                      <Wrapper data-testid="directory-card-wrapper">
                        {directory?.profiles?.map(profile => (
                          <Card
                            key={profile?.handle}
                            hasHover={false}
                            cardData={profile}
                          />
                        ))}
                      </Wrapper>
                    </Accordion>
                  </Flex>
                )}
              </Draggable>
            ))}
          </>
        )}
      </DragAndDropWrapper>
      {isCreateEditGroupModalOpen && (
        <CreateEditGroup
          isOpen={isCreateEditGroupModalOpen}
          onClose={() => setIsCreateEditGroupModalOpen(false)}
          directoryID={selectedDirectory}
          label="Group Editor"
          refetch={refetch}
        />
      )}
    </PageSetup>
  );
};

export default Directory;
