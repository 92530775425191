import React, { FC, useState } from "react";
import { MenuItem, TableFilterMenuButton, Menu, MenuList } from "components";
import { theme } from "ovou-ui";

import { ITeamUserTypeObject } from "types/TeamUsersResponse";
import { TUserTypeFilter, USER_TYPE_LABELS } from "./types";

const ManageUserTypeFilter: FC<{
  userTypes?: ITeamUserTypeObject[];
  onUserTypeChange: (permission: TUserTypeFilter | null) => void;
}> = ({ onUserTypeChange, userTypes }) => {
  const [userType, setUserType] = useState<USER_TYPE_LABELS | null>(
    USER_TYPE_LABELS.USER_TYPE,
  );

  const handleChange = (permission: TUserTypeFilter | null) => {
    onUserTypeChange(permission);
  };

  const handleClear = () => {
    setUserType(USER_TYPE_LABELS.USER_TYPE);
    handleChange(null);
  };

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <TableFilterMenuButton
            buttonText={userType!}
            isOpen={isOpen}
            minW="110px"
          />

          <MenuList>
            {userTypes?.map(({ id, metadata, user_iam_role }) => (
              <MenuItem
                key={id}
                value={id}
                _focus={{
                  backgroundColor: theme.palette.ui.greys.grey5,
                  outline: "none",
                }}
                onClick={() => {
                  setUserType(metadata.label);
                  handleChange({
                    filter: user_iam_role,
                    label: metadata.label,
                  });
                }}
              >
                {metadata.label}
              </MenuItem>
            ))}

            {userType !== USER_TYPE_LABELS.USER_TYPE && (
              <MenuItem
                marginTop={2}
                fontWeight="semibold"
                fontSize={14}
                cursor="pointer"
                onClick={handleClear}
                color={theme.palette.brand.primary.orange}
                _focus={{
                  outline: "none",
                }}
              >
                Clear
              </MenuItem>
            )}
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export default ManageUserTypeFilter;
