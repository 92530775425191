import { FC, ReactElement, ReactNode } from "react";
import { Flex, InfoPopover, Text } from "components";

interface ISection {
  title: string;
  children: ReactNode;
  rightElement?: ReactElement;
  showPopover?: boolean;
  popoverTitle?: string;
  popoverText?: string;
}
const AnalyticsSection: FC<ISection> = ({
  title,
  children,
  rightElement,
  showPopover = false,
  popoverTitle,
  popoverText,
}) => {
  return (
    <Flex flexDirection="column">
      <Flex justifyContent="space-between" alignItems="center" mb="37px">
        <Flex alignItems="center">
          <Text fontSize={21} fontWeight="800">
            {title}
          </Text>
          {showPopover && popoverTitle && popoverText && (
            <InfoPopover title={popoverTitle} text={popoverText} />
          )}
        </Flex>
        {rightElement}
      </Flex>
      {children}
    </Flex>
  );
};

export default AnalyticsSection;
