import React from "react";

import { Formik, Form, Field } from "formik";
import * as yup from "yup";

import { TextFormField, SelectFormField } from "../../components";
import { VALIDATION_MESSAGES } from "../../constants";

const validationSchema = yup.object({
  email: yup
    .string()
    .required(VALIDATION_MESSAGES.IS_REQUIRED("Email"))
    .email(VALIDATION_MESSAGES.FORMAT_IS_WRONG("Email")),
  card: yup.string().required(VALIDATION_MESSAGES.IS_REQUIRED("Card")),
});

const AssignCardForm = ({
  children,
  cardOptions,
  isFetchingCardOptions,
  onSubmit,
  selectedCard,
}) => {
  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        email: "",
        card: selectedCard?.id || "",
        editPermission: true,
      }}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {() => (
        <Form noValidate>
          {!selectedCard && (
            <div className="mb-4" data-testid="select-card-wrapper">
              <Field
                name="card"
                placeholder="Select Card"
                options={cardOptions}
                component={SelectFormField}
                isLoading={isFetchingCardOptions}
                disabled={isFetchingCardOptions || selectedCard}
                renderInPortal
              />
            </div>
          )}
          <div className="mb-4">
            <Field
              label="Email"
              name="email"
              component={TextFormField}
              type="email"
            />
          </div>
          {children}
        </Form>
      )}
    </Formik>
  );
};

export default AssignCardForm;
