import { datadogRum } from "@datadog/browser-rum";
import { ENVIRONMENT, HIGHER_ENVIRONMENT, DATADOG_APPLICATION_ID, DATADOG_CLIENT_TOKEN, DATADOG_SITE } from "constants/index";

export const initializeDatadogRum = () => {
    if (HIGHER_ENVIRONMENT.includes(ENVIRONMENT)) {
        datadogRum.init({
            applicationId: DATADOG_APPLICATION_ID,
            clientToken: DATADOG_CLIENT_TOKEN,
            site: DATADOG_SITE,
            service: "admin-front",
            env: ENVIRONMENT,
            version: "1.0.0",
            sessionSampleRate: 100,
            sessionReplaySampleRate: 20,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: "mask-user-input",
        });
    }
};