import { createContext, useContext, useMemo } from "react";
import { IIntegrationService } from "../services/IIntegrationService";

const IntegrationServiceContext = createContext<IIntegrationService | null>(null);

type IntegrationServiceProviderProps = {
    service: IIntegrationService;
    children: any;
}

export const IntegrationServiceProvider = ({ service, children }: IntegrationServiceProviderProps) => {
    return (
        <IntegrationServiceContext.Provider value={service}>
            {children}
        </IntegrationServiceContext.Provider>
    );
};

export const useIntegrationServiceContext = (): IIntegrationService => {
    const service = useContext(IntegrationServiceContext);

    if (!service) {
        throw new Error("Integration Service not provided.");
    }

    return service;
};