import React from "react";

const Youtube = ({ className }) => {
  return (
    <svg
      width="24"
      height="23"
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M20.9829 7.22409C20.8747 6.79379 20.6646 6.40203 20.3738 6.08807C20.083 5.7741 19.7215 5.54896 19.3257 5.43519C17.8653 5.0127 12.0077 5.0127 12.0077 5.0127C12.0077 5.0127 6.15012 5.0127 4.68896 5.43519C4.29315 5.54896 3.93172 5.7741 3.64089 6.08807C3.35005 6.40203 3.14002 6.79379 3.03183 7.22409C2.51022 10.4451 2.51022 13.7408 3.03183 16.9618C3.14118 17.3884 3.35204 17.7759 3.64305 18.0851C3.93406 18.3943 4.29489 18.6142 4.68896 18.7224C6.14796 19.148 12.0077 19.148 12.0077 19.148C12.0077 19.148 17.8653 19.148 19.3265 18.7255C19.7205 18.6173 20.0814 18.3974 20.3724 18.0883C20.6634 17.7791 20.8742 17.3915 20.9836 16.9649C21.5052 13.7439 21.5052 10.4482 20.9836 7.22723L20.9829 7.22409ZM10.0919 15.0841V9.10566L14.9913 12.0945L10.0919 15.0841Z"
        fill="#F40000"
      />
    </svg>
  );
};

export default React.memo(Youtube);
