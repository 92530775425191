import { useEffect } from "react";
import { usePermissionsState, useUserState } from "context";
import { useAnalytics } from "context/Analytics";

const useIdentify = () => {
  const { identify } = useAnalytics();
  const { permission, status } = usePermissionsState();
  const { currentProfile, currentTeam } = useUserState();

  useEffect(() => {
    if (identify && permission && currentProfile) {
      identify(
        currentProfile.id,
        currentProfile?.handle,
        currentProfile?.public_id,
        permission?.state,
        currentTeam?.id,
        permission.user_iam_role as string,
        permission.user_type,
        status as string,
      );
    }
  }, [identify, permission, currentProfile]);
};

export default useIdentify;
