import { FEATURES, FEATURE_ACTION } from "constants/features";
import { usePermissionsState } from "context";
import { useHasPermission } from "ovou-ui";
import { useMemo } from "react";

const useCheckPermission = (
  action: FEATURE_ACTION,
  feature: FEATURES,
): boolean => {
  const { permission } = usePermissionsState();
  const { default_permission: permissionSet } = permission || {};
  return useMemo(() => {
    return useHasPermission(permissionSet, feature, action) as boolean;
  }, [permissionSet, feature, action]);
};

export default useCheckPermission;
