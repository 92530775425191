import { FC } from "react";
import { Box, Text } from "components";
import { theme } from "ovou-ui";
import { usePermissionsState, useUserState } from "context";
import { USER_IAM_ROLE } from "types/TeamUsersResponse";
import { TEXTS } from "../../constants/Texts";

interface ISeatsCounter {
  assignedSeats: number;
  numberOfSeats: number;
  pendingSeats: number;
  showAvailable?: boolean
  availableSeats?: number
}

export const isTeamLegacy = (userIamRole: USER_IAM_ROLE): boolean => {
  return (
    userIamRole === USER_IAM_ROLE.TEAM_LEGACY_OWNER ||
    userIamRole === USER_IAM_ROLE.TEAM_LEGACY_ADMIN ||
    userIamRole === USER_IAM_ROLE.TEAM_LEGACY_READONLY
  );
};
const SeatsCounter: FC<ISeatsCounter> = ({
  assignedSeats,
  numberOfSeats,
  pendingSeats,
  showAvailable = true,
  availableSeats = 0
}) => {
  const { permission } = usePermissionsState();

  const isLegacy = isTeamLegacy(permission?.user_iam_role as USER_IAM_ROLE)

  const seat = isTeamLegacy(permission?.user_iam_role as USER_IAM_ROLE) ? TEXTS.USERS : TEXTS.SEATS;

  return showAvailable ? <Box display="flex">
    {assignedSeats > 0 && (
      <>
        <Text
          color={theme.palette.brand.primary.orange}
          fontSize="16px"
          fontWeight="600"
        >
          {assignedSeats} Active
        </Text>
        {Boolean(pendingSeats) && (
          <Text
            color={theme.palette.brand.primary.orange}
            fontSize="16px"
            fontWeight="600"
          >
            &nbsp;({pendingSeats} Pending)
          </Text>
        )}
        {!isLegacy && <Text
          color={theme.palette.ui.greys.grey3}
          fontSize="16px"
          fontWeight="400"
          marginX="5px"
        >
          /
        </Text>}
      </>
    )}
    {!isLegacy && <Text
      color={theme.palette.brand.primary.black}
      fontSize="16px"
      fontWeight="400"
    >
      {numberOfSeats} {!Boolean(assignedSeats) && "Available"} {seat}
    </Text>}
  </Box>
    : <Box display="flex"> {
      availableSeats > 0 &&
      <Text
        color={theme.palette.brand.primary.orange}
        fontSize="16px"
        fontWeight="600"
      >
        {availableSeats} Available Seats
      </Text>}</Box>;
};

export default SeatsCounter;
