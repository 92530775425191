import React from "react";

import { Helmet } from "react-helmet";

import ResetPasswordForm from "./Form";

const ResetPassword = () => {
  return (
    <>
      <Helmet title="OVOU | Reset Password" />
      <div className="flex flex-col items-center justify-center bg-white h-full">
        <div className="w-full">
          <div className="mt-16">
            <h1 className="text-black font-extrabold text-3xl">
              Reset password
            </h1>
          </div>
          <div className="flex flex-col mt-8">
            <ResetPasswordForm />
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
