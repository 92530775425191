import styled, { css } from "styled-components";

const Wrapper = styled.div`
  margin-bottom: ${({ marginBottom }) => marginBottom};

  position: relative;
  label {
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
    display: block;
    cursor: text;
    font-size: 14px;
    color: #adadad;
  }
  label.filled {
    transform: translateY(0);
  }
  label.focused {
    color: #ff643a;
  }

  input,
  textarea {
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
    border: none;
    border-bottom: 1px solid #d1d2d3;
    border-radius: 0;
    font-size: 16px;
    outline: none;
    width: 100%;
    padding-bottom: 4px;
    // background-color: #ffffff;

    &:disabled {
      // background-color: #ffffff;
      color: #adadad;
    }
    ${({ icon }) =>
      icon &&
      css`
        padding-right: 23px;
      `}
  }
  input:focus,
  textarea:focus {
    border-color: #ff643a;
  }
  svg {
    position: absolute;
    right: 0;
    top: 21px;
  }
  ${({ error }) =>
    error &&
    css`
      input,
      textarea {
        border-color: #e53e3e;
      }
      label {
        color: #e53e3e;
      }
    `}
`;

const StyledInput = styled.div`
  display: flex;
  &.inputWithIcon {
    position: relative;
  }

  .left-icon {
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
    svg {
      fill: black;
      transition: 0.3s;
    }
  }
  .right-icon {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    svg {
      fill: black;
      transition: 0.3s;
    }
  }
  .pre-val {
    color: #a1a1a1;
    border-bottom: 1px solid rgb(209, 210, 211);
  }

  button.right-icon {
    background: none;
    border: none;
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    svg {
      fill: black;
      transition: 0.3s;
    }
  }
`;

export const Input = styled.input`
  padding: ${({ padding }) => padding};
  padding-left: ${({ paddingLeft }) => paddingLeft};
  background-color: ${({ backgroundColor }) =>
    backgroundColor || "transparent"};
`;

export { Wrapper, StyledInput };
