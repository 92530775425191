import React from "react";

const Person = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M19.1219 20.0001V18.2223C19.1219 17.2793 18.7473 16.3749 18.0805 15.7081C17.4137 15.0414 16.5093 14.6667 15.5663 14.6667H8.45521C7.51222 14.6667 6.60785 15.0414 5.94106 15.7081C5.27426 16.3749 4.89966 17.2793 4.89966 18.2223V20.0001"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0109 11.1111C13.9746 11.1111 15.5664 9.51923 15.5664 7.55555C15.5664 5.59187 13.9746 4 12.0109 4C10.0472 4 8.45532 5.59187 8.45532 7.55555C8.45532 9.51923 10.0472 11.1111 12.0109 11.1111Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default React.memo(Person);
