import React from "react";

import { Flex } from "../Container";

const DragHandle = () => {
  return (
    <Flex flexDirection="column" marginTop="15px">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9 6L12 3L15 6"
          stroke="#A0AEC0"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15 18L12 21L9 18"
          stroke="#A0AEC0"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 3V9"
          stroke="#A0AEC0"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 15V21"
          stroke="#A0AEC0"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Flex>
  );
};

export default DragHandle;
