import React from "react";

import classNames from "classnames";

const Switch = ({ label, id, onChange, checked }) => {
  const wrapperClasses = classNames({
    "absolute block w-6 h-6 rounded-full border-4 appearance-none cursor-pointer transition-transform duration-150 ease-in-out": true,
    "bg-white border-primary transform translate-x-full": checked,
    "bg-white ": !checked,
  });
  const thumbClasses = classNames({
    "block overflow-hidden h-6 rounded-full cursor-pointer": true,
    "bg-primary": checked,
    "bg-gray-300": !checked,
  });

  return (
    <>
      <div className="relative inline-block w-12 mr-2 align-middle select-none">
        <label htmlFor={id} className={thumbClasses}>
          <input
            className={wrapperClasses}
            id={id}
            name={id}
            type="checkbox"
            checked={checked}
            onChange={e => onChange(e.target.checked)}
          />
        </label>
      </div>
      {label && (
        <label htmlFor={id} className="ml-2 text-md text-gray-700">
          {label}
        </label>
      )}
    </>
  );
};

export default Switch;
