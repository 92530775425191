import { FC, useEffect, useRef, useState } from "react";

import { useHistory } from "react-router-dom";
import { Table, Button, Flex, Box, Text } from "../../components";
import { TEXTS } from "../../constants";
import {
  useCheckPermission,
  useIsActionAllowed,
  useSearchQuery,
} from "../../hooks";
import {
  ITeamUserObject,
  USER_IAM_ROLE,
  USER_SEATS_STATUS,
} from "types/TeamUsersResponse";
import DeleteUserModal from "./DeleteUserModal";
import ProfileActionCol from "./ProfileActionCol";
import ProfileNameCol from "./ProfileNameCol";
import { useTeamUsersQuery } from "./api/useTeamUsersQuery";
import ChangeInvitationModal from "./ChangeInvitationModal";
import ManageUsersStatusFilter, {
  FILTER_DISPLAY_VALUES,
} from "./ManageUsersStatusFilter";
import SearchInput from "features/contacts/components/SearchInput";
import {
  IManageUsersTable,
  ManageUsersTableColumnParams,
  SelectedRow,
  ISelectedUserToEdit,
  TUserTypeFilter,
  USER_TYPE_LABELS,
} from "./types";
import { useUnassignSeatsMutation } from "./api/useUnassignSeatsMutation";
import ChangeRoleModal from "./ChangeRoleModal";
import ChangeRoleColumn from "./ChangeRoleColumn";
import ManageUserTypeFilter from "./ManageUserTypeFilter";
import { useTeamUserTypesQuery } from "./api/useTeamUserTypesQuery";
import { FEATURES, FEATURE_ACTION } from "constants/features";
import { useQueryCache } from "react-query";
import { useMyTeamsQuery } from "features/auth/queries";
import { ITeamResponse } from "types/Team";
import { setTeam, useUserDispatch } from "context";

const useManageUsersTableColumns = ({
  selectedRows,
  setSelectedUserToEdit,
  setEditRole,
  teamId,
  refetch,
}: ManageUsersTableColumnParams) => {
  const dispatch = useUserDispatch();
  const { handleAction } = useIsActionAllowed();
  const canViewRoleModal = useCheckPermission(
    FEATURE_ACTION.VIEW,
    FEATURES.MANAGE_USERS_CHANGE_ROLE,
  );
  const queryCache = useQueryCache();
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const { getMyTeams } = useMyTeamsQuery({
    onSuccess: (data: ITeamResponse) => {
      const team = data?.teams?.[0];
      dispatch(setTeam(team));
    },
  });

  const { mutate: fireUnassignSeats, isLoading: isUnassignSeatsLoading } =
    useUnassignSeatsMutation();

  const handleSeatUnAssign = () => {
    const userIds = selectedRows?.map((row: { id: any }) => row.id);
    fireUnassignSeats(
      { teamId, userIds },
      {
        onSuccess: async () => {
          queryCache.invalidateQueries([`team-users`, { teamId }]);
          await Promise.all([refetch(), getMyTeams()]);

          setShowConfirmDeleteModal(false);
        },
      },
    );
  };
  return [
    {
      id: "name",
      Header: "User",
      accessor: (row: ITeamUserObject) => (
        <ProfileNameCol
          row={row}
          setSelectedUserToEdit={setSelectedUserToEdit}
        />
      ),
      minWidth: 400,
    },
    {
      id: "role",
      Header: "User Role",
      accessor: (row: ITeamUserObject) => {
        const { user_iam_role: userIamRole } = row;
        return (
          <>
            {!!userIamRole && (
              <ChangeRoleColumn
                row={row}
                onClick={selectedRow => {
                  handleAction(
                    canViewRoleModal,
                    FEATURES.MANAGE_USERS_CHANGE_ROLE,
                    () => setEditRole(selectedRow),
                  );
                }}
              />
            )}
          </>
        );
      },
      minWidth: 100,
    },
    {
      id: "cards",
      Header: "Assigned Card",
      accessor: ({ card_ids: cardIds }: ITeamUserObject) => {
        return (
          <Box noOfLines={2} overflow="hidden" width="70%">
            {cardIds?.map((card, index) => (
              <Text key={`${card}-${index}`} display="inline">
                {`${card}`}
                {cardIds.length - (index + 1) !== 0 && ", "}
              </Text>
            ))}
          </Box>
        );
      },
      minWidth: 100,
    },
    {
      id: "actions",
      Header: () => (
        <DeleteUserModal
          selectedRows={selectedRows}
          isLoading={isUnassignSeatsLoading}
          isOpen={showConfirmDeleteModal}
          openDeleteModal={() => setShowConfirmDeleteModal(true)}
          closeDeleteModal={() => setShowConfirmDeleteModal(false)}
          handleSeatUnAssign={handleSeatUnAssign}
        />
      ),
      accessor: (row: ITeamUserObject) => (
        <div style={{ maxWidth: "140px", width: "100%" }}>
          <ProfileActionCol row={row} teamId={teamId} refetchUsers={refetch} />
        </div>
      ),
      width: 160,
    },
  ];
};

const ManageUsersTable: FC<IManageUsersTable> = ({
  teamId,
  openAssignSeatsModal,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const history = useHistory();
  const [paginationData, setPaginationData] = useState({
    pageSize: 20,
    offset: 0,
  });

  const [newFilters, setNewFilters] = useState<{
    status?: USER_SEATS_STATUS;
    value?: string;
  }>({});
  const [userTypeFilter, setUserTypeFilter] = useState<{
    filter: USER_IAM_ROLE;
    label: USER_TYPE_LABELS;
  } | null>();

  const {
    data: teamUsers,
    isLoading: isUsersLoading,
    refetch,
  } = useTeamUsersQuery({
    searchQuery,
    teamId,
    limit: paginationData?.pageSize,
    offset: paginationData?.offset,
    ...(userTypeFilter !== null && { permission: userTypeFilter?.filter }),
    ...(newFilters?.status !== null && { status: newFilters?.status }),
  });
  const { data: userTypes } = useTeamUserTypesQuery();
  const users = teamUsers?.results || [];

  const totalDataCount = teamUsers?.count || 0;
  const prevDataCountRef = useRef(totalDataCount);

  useEffect(() => {
    prevDataCountRef.current = totalDataCount;
  }, [totalDataCount]);
  const [editRole, setEditRole] = useState<SelectedRow | null>(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedUserToEdit, setSelectedUserToEdit] =
    useState<ISelectedUserToEdit | null>(null);
  const columns = useManageUsersTableColumns({
    teamId,
    selectedRows,
    setSelectedUserToEdit: (user: ISelectedUserToEdit) =>
      setSelectedUserToEdit(user),
    setEditRole: (user: SelectedRow) => setEditRole(user),
    refetch,
  });

  const handleRowSelect = (rows: any) => {
    if (rows.length !== selectedRows.length) {
      setSelectedRows(rows);
    }
  };

  const handleFetchTableData = ({
    pageIndex,
    pageSize,
  }: {
    pageIndex: any;
    pageSize: any;
  }) => {
    setPaginationData({
      pageSize,
      offset: pageIndex * pageSize,
    });
  };

  const pageCount = Math.ceil(
    (isUsersLoading ? prevDataCountRef.current : totalDataCount) /
    paginationData.pageSize,
  );
  const noResultsText = searchQuery
    ? "Your search did not match any profiles"
    : `You don’t have any ${newFilters?.status !== null
      ? newFilters.value?.toLowerCase() ===
        FILTER_DISPLAY_VALUES.ACTIVE.toLowerCase()
        ? FILTER_DISPLAY_VALUES.ACTIVE
        : FILTER_DISPLAY_VALUES.PENDING
      : ""
    } ${userTypeFilter?.label ? userTypeFilter?.label : ""}
      profiles in your Team!`;

  return (
    <>
      <Flex flexDirection="column" w="500px">
        <SearchInput defaultValue={searchQuery} onChange={setSearchQuery} />
        <Flex marginY={6}>
          <Box>
            <ManageUsersStatusFilter
              onFiltersChange={(newFilters: any) => {
                setNewFilters(filter => ({ ...filter, ...newFilters }));
              }}
            />
          </Box>
          <Box marginLeft={2}>
            <ManageUserTypeFilter
              userTypes={userTypes}
              onUserTypeChange={(permission: TUserTypeFilter | null) => {
                setUserTypeFilter(permission);
              }}
            />
          </Box>
        </Flex>
      </Flex>
      <Table
        columns={columns}
        data={users}
        isLoading={isUsersLoading}
        onRowSelect={handleRowSelect}
        fetchData={handleFetchTableData}
        pageCount={pageCount}
        footer={
          <Button onClick={openAssignSeatsModal} size="small" block>
            {TEXTS.common.INVITE_USERS}
          </Button>
        }
        noResultsText={noResultsText}
        showPagination
      />

      {selectedUserToEdit && (
        <ChangeInvitationModal
          isOpen={!!selectedUserToEdit}
          onClose={() => setSelectedUserToEdit(null)}
          teamId={teamId}
          selectedUser={selectedUserToEdit}
          refetch={refetch}
        />
      )}
      {editRole && (
        <ChangeRoleModal
          isOpen={!!editRole}
          onClose={() => setEditRole(null)}
          user={editRole!}
          userTypes={userTypes}
          refetch={refetch}
        />
      )}
    </>
  );
};

export default ManageUsersTable;
