import React, { FC } from "react";
import {
  Avatar,
  Box,
  FormatNumber,
  GlobalListItem,
  Skeleton,
} from "components";
import { ITeamMember } from "../types";
import defaultPictureSmall from "../../../assets/images/profile-pic-placeholder-sm.svg";

const TeamList: FC<{ teamMembers?: ITeamMember[]; isLoading?: boolean }> = ({
  teamMembers,
  isLoading,
}) => {
  return (
    <Box py="24px">
      {isLoading ? (
        [...Array(5)].map((_, index) => (
          <Skeleton h="24px" mb="24px" key={index} />
        ))
      ) : (
        <>
          {teamMembers?.map(({ profile, count }) => (
            <Box mb="24px" key={profile.id}>
              <GlobalListItem
                title={`${profile.first_name} ${profile.last_name}`}
                subTitle={`/${profile.handle}`}
                leftElement={
                  <Avatar
                    name={profile.first_name}
                    src={profile.picture || defaultPictureSmall}
                    width="26px"
                    height="36px"
                    borderRadius="4px"
                  />
                }
                rightElement={<FormatNumber count={count} />}
              />
            </Box>
          ))}
        </>
      )}
    </Box>
  );
};

export default TeamList;
