import React from "react";

import { Redirect } from "react-router-dom";

import { Spinner } from "../../components";
import { Business } from "../../features";
import { useFeatureFlagsQuery } from "../../hooks";
import Loader from "components/Spinner/Loader";

const BusinessPage = () => {
  const { data: featureFlags, isLoading: isFeatureFlagsLoading } =
    useFeatureFlagsQuery();
  const isBusinessPageEnabled = JSON.parse(
    featureFlags?.flags?.business?.isBusinessPageEnabled || "false",
  );

  if (!isBusinessPageEnabled && !isFeatureFlagsLoading) {
    return <Redirect to="/" />;
  }

  return isFeatureFlagsLoading ? (
    <>
      <Loader />
    </>
  ) : (
    <>
      <Business />
    </>
  );
};

export default BusinessPage;
