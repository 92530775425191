import React, { FC, useEffect, useState } from "react";
import { components } from "react-select";
import { Formik, Form, Field } from "formik";
import { Button } from "@chakra-ui/react";
import * as yup from "yup";
import { RiCloseFill } from "react-icons/ri";
import { theme } from "ovou-ui";

import { Spinner, MultiSelectFormField } from "..";
import { VALIDATION_MESSAGES } from "../../constants";
import { ITag } from "../../features/contacts/types/Tag";
import { ISelectOption } from "../../utils/types";
import { TagColorOption } from "./TagColorOption";
import { IContact, IContactTag } from "../../features/contacts/types/Contact";

const validationSchema = yup.object({
  colors: yup
    .array()
    .of(yup.string())
    .min(1, VALIDATION_MESSAGES.IS_REQUIRED("Tag color")),
});

interface ITagsToContactForm {
  tags: ITag[];
  contact: IContact;
  isAddingTagsToContact?: boolean;
  onSubmit: (tagIds: number[]) => void;
}

export const TagsToContactForm: FC<ITagsToContactForm> = ({
  tags,
  contact,
  isAddingTagsToContact = false,
  onSubmit,
}) => {
  const [tagColorOptions, setTagColorOptions] = useState<ISelectOption[]>([]);

  useEffect(() => {
    const tagColorOptions: ISelectOption[] =
      tags?.map((tag: ITag) => ({
        ...tag,
        label: tag?.name || "",
        value: String(tag?.id) || "",
      })) || [];

    setTagColorOptions(tagColorOptions);
  }, [tags]);

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        tags: contact.tags.map((tag: IContactTag) => String(tag?.id)),
      }}
      onSubmit={values => {
        onSubmit(values.tags.map(tag => Number(tag)));
      }}
      enableReinitialize
    >
      {({ isValid, dirty, submitForm }) => (
        <Form noValidate autoComplete="off">
          <Field
            name="tags"
            placeholder="Select tags"
            options={tagColorOptions}
            component={MultiSelectFormField}
            renderInPortal
            isMulti
            components={{ Option, MultiValue, MultiValueRemove }}
            styles={{
              multiValueRemove: (base: any) => ({
                ...base,
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "transparent",
                },
              }),
              multiValue: (base: any) => ({
                ...base,
                borderRadius: "6px",
                borderColor: theme.palette.ui.greys.grey3,
                borderWidth: 1,
                backgroundColor: "transparent",
                paddingRight: "8px",
              }),
              indicatorsContainer: (base: any) => ({
                ...base,
                cursor: "pointer",
              }),
            }}
          />
          <Button
            width="full"
            marginTop={4}
            isDisabled={isAddingTagsToContact || !isValid || !dirty}
            onClick={submitForm}
          >
            {isAddingTagsToContact ? <Spinner color="white" /> : "Set Tags"}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

const Option = (props: any) => {
  return (
    <components.Option {...props}>
      <TagColorOption name={props.data.name} color={props.data.color} />
    </components.Option>
  );
};

const MultiValue = (props: any) => {
  return (
    <components.MultiValue {...props}>
      <TagColorOption
        name={props.data.name}
        color={props.data.color}
        stackProps={{ paddingRight: 1 }}
      />
    </components.MultiValue>
  );
};

const MultiValueRemove = (props: any) => {
  return (
    <components.MultiValueRemove {...props}>
      <RiCloseFill size={20} color={theme.palette.ui.greys.grey2} />
    </components.MultiValueRemove>
  );
};

export default TagsToContactForm;
