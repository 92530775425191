import { useQuery } from "react-query";

import api from "../../../api";
import { ITeamResponse } from "../../../types/Team";

const profileService = async () => {
  const { data } = await api.get<ITeamResponse>("/team/my");

  const teamsData: ITeamResponse = {
    teams: data.teams.sort((a, b) => a.id - b.id),
  };
  return teamsData;
};

export const useMyTeamsQuery = (
  { onSuccess }: { onSuccess?: any | null },
  enabled = false,
) => {
  const { data, status, refetch, error, isSuccess, isLoading } =
    useQuery<ITeamResponse>(["my-teams"], profileService, {
      onSuccess,
      enabled: enabled,
    });

  return {
    getMyTeams: refetch,
    data,
    status,
    error,
    isSuccess,
    isLoading,
  };
};
