import { useState, useEffect, useCallback } from "react";

const useChangeDebounce = (handleChange: any, delay = 300) => {
  const [searchQuery, setSearchQuery] = useState("");

  const setDebouncedValue = useCallback((value: any) => {
    setSearchQuery(value);
  }, []);

  useEffect(() => {
    if (typeof handleChange !== "function") {
      return;
    }

    const handler = setTimeout(() => {
      handleChange(searchQuery);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery, delay, handleChange]);
  return setDebouncedValue;
};

export default useChangeDebounce;
