import React from "react";

// type DefaultProfilePic = {
//   width: number;
//   height: number;
// };

const DefaultProfilePic = ({ width, height, BORDER_RADIUS }) => {
  return (
    <div
      style={{
        borderTopLeftRadius: `${BORDER_RADIUS}px`,
        borderBottomLeftRadius: `${BORDER_RADIUS}px`,
        backgroundColor: "#292929",
        width: width,
        height: height,
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
      }}
    >
      <svg
        width={width}
        height={height * (2 / 3)}
        viewBox="0 0 122 160"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.4"
          d="M61.0005 79C79.2248 79 94 61.3154 94 39.5002C94 17.6846 89.1491 0 61.0005 0C32.8518 0 28 17.6846 28 39.5002C28 61.3154 42.7752 79 61.0005 79Z"
          fill="#686868"
        />
        <path
          opacity="0.4"
          d="M121.932 138.151C121.334 99.8308 116.405 88.9117 78.6861 82C78.6861 82 73.3766 88.8694 61.0014 88.8694C48.6261 88.8694 43.3157 82 43.3157 82C6.00888 88.8363 0.7801 99.5934 0.0925852 136.906C0.036209 139.953 0.0100836 140.113 0 139.759C0.00229172 140.422 0.0925852 160 0.0925852 160H61H122C122 160 121.999 141.457 122 140.807C121.99 141.026 121.97 140.602 121.932 138.151Z"
          fill="#686868"
        />
      </svg>
    </div>
  );
};

export default DefaultProfilePic;
