import { useMemo } from 'react'

interface FullName {
    first_name: string;
    last_name: string;
}

const useFullName = (primaryName: FullName, alternateName: FullName): string => {
    return useMemo(() => {
        if (primaryName.first_name && primaryName.last_name) {
            return `${primaryName.first_name} ${primaryName.last_name}`;
        }

        if (alternateName.first_name && alternateName.last_name) {
            return `${alternateName.first_name} ${alternateName.last_name}`;
        }

        return "";
    }, [primaryName, alternateName]);
};

export default useFullName;