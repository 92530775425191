import React from "react";

const Error = ({ error, className, ...props }) => {
  return (
    <p
      {...props}
      className={`mt-2 text-small italic text-red-500 text-left ${className}`}
    >
      {typeof error === "string"
        ? error
        : error?.response?.data?.detail ||
          error?.response?.data?.message ||
          error?.message ||
          "Something went wrong"}
    </p>
  );
};

export default Error;
