import { useState, useEffect } from "react";

import { useLocation } from "react-router-dom";

import { getParsedQueryParams } from "../utils";

const useSearchQuery = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const { search } = useLocation();

  useEffect(() => {
    const { query } = getParsedQueryParams(search);
    setSearchQuery(query);
  }, [search, setSearchQuery]);
  return searchQuery;
};

export default useSearchQuery;
