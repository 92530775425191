import React from "react";

const Card = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 19.5H20C21.1046 19.5 22 18.6046 22 17.5V6.5C22 5.39543 21.1046 4.5 20 4.5H4C2.92853 4.49899 2.04661 5.34255 2 6.413V17.5C2 18.6046 2.89543 19.5 4 19.5ZM4 17.5V8.5V7.5V6.5H20V7.5V8.5V17.5H4ZM5 15C5 14.4477 5.44772 14 6 14H10C10.5523 14 11 14.4477 11 15C11 15.5523 10.5523 16 10 16H6C5.44772 16 5 15.5523 5 15ZM6 11C5.44772 11 5 11.4477 5 12C5 12.5523 5.44772 13 6 13H14C14.5523 13 15 12.5523 15 12C15 11.4477 14.5523 11 14 11H6Z"
      />
    </svg>
  );
};

export default React.memo(Card);
