import { FC } from "react";

import { PageSetup } from "../../components";
import SettingsTabs from "./SettingsTabs";
import { TEXTS } from "../../constants";
import { FEATURES } from "constants/features";

const Settings: FC = () => {
  return (
    <PageSetup
      title={TEXTS.routes.SETTINGS}
      subTitle={TEXTS.routes.SETTINGS_SUB_TITLE}
      feature={FEATURES.SETTINGS}
    >
      <SettingsTabs />
    </PageSetup>
  );
};

export default Settings;
