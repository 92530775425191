import {
  forwardRef,
  Skeleton,
  SkeletonCircle,
  SkeletonProps,
} from "@chakra-ui/react";
import { theme } from "ovou-ui";

export const SkeletonComponent = forwardRef<SkeletonProps, "div">(
  (
    {
      children,
      startColor = theme.palette.ui.greys.grey5,
      endColor = theme.palette.ui.greys.grey4,
      ...restProps
    },
    ref,
  ) => (
    <Skeleton
      ref={ref}
      startColor={startColor}
      endColor={endColor}
      {...restProps}
    >
      {children}
    </Skeleton>
  ),
);

export const SkeletonCircleComponent = forwardRef<SkeletonProps, "div">(
  (
    {
      children,
      startColor = theme.palette.ui.greys.grey5,
      endColor = theme.palette.ui.greys.grey4,
      ...restProps
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ref,
  ) => (
    <SkeletonCircle startColor={startColor} endColor={endColor} {...restProps}>
      {children}
    </SkeletonCircle>
  ),
);
