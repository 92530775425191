import { FC } from "react";
import { useWizard } from "react-use-wizard";
import { theme } from "ovou-ui";

import { Box, Flex, Icon, Image } from "../../components";
import logo from "../../assets/images/logo.svg";
import { TEXTS } from "../../constants";
import MenuItem from "features/sidebar/MenuItem";
import { useTracking } from "hooks";
import { ANALYTICS_EVENTS } from "constants/analyticEvents";

const IconComponent = Icon as any;

export const Header: FC = () => {
  const { isFirstStep, previousStep } = useWizard();

  const track = useTracking();

  const goToPreviousStep = () => {
    track({
      eventName: ANALYTICS_EVENTS.TEAM_ONBOARDING_GO_TO_PREVIOUS_STEP,
    });

    previousStep();
  };

  return (
    <Flex
      width="full"
      height={12}
      marginBottom={12}
      justifyContent="space-between"
      alignItems="center"
    >
      <Flex
        alignItems="center"
        gap={10}
        sx={{
          svg: {
            color: theme.palette.brand.primary.black,
          },
        }}
      >
        {!isFirstStep && (
          <Box onClick={goToPreviousStep}>
            <IconComponent name="leftArrow" />
          </Box>
        )}

        <Image width={36} src={logo} alt="OVOU Logo" />
      </Flex>

      <MenuItem
        icon={<IconComponent name="logoutArrow" />}
        title={TEXTS.menuItems.LOG_OUT}
        to="/auth/logout"
        textColor={theme.palette.brand.primary.black}
        sx={{
          svg: {
            color: theme.palette.brand.primary.black,
          },
        }}
      />
    </Flex>
  );
};
