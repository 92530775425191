import { FC, useCallback } from "react";
import { Spinner } from "@chakra-ui/react";
import { AiOutlineFile } from "react-icons/ai";

import { TeamContactsSortOption, useExportTeamContacts } from "../queries/useContactsTable";
import { useToasts } from "react-toast-notifications";

import { ButtonOutlined } from "components";
export type IExportContactsProps = {
  canExport: boolean;
  filters: {
    teamId: number,
    searchQuery?: string,
    tags?: number[],
    teamMembers?: number[],
    dateLowerBound?: string,
    dateUpperBound?: string,
    sort?: TeamContactsSortOption,
  },
};

export const ExportContacts: FC<IExportContactsProps> = ({
  canExport,
  filters: {
    teamId,
    searchQuery,
    tags,
    teamMembers,
    dateLowerBound,
    dateUpperBound,
    sort
  },
}) => {
  const { addToast } = useToasts();

  const [exportTeamContacts, { isLoading }] = useExportTeamContacts({
    teamId,
    searchQuery,
    tags,
    teamMembers,
    dateLowerBound,
    dateUpperBound,
    sort
  });

  const handleExportContacts = useCallback(async () => {
    if (!canExport) {
      addToast("There are no available contacts to export.", {
        appearance: "error",
      });

      return;
    }

    addToast("Processing your request...", {
      appearance: "info",
    });

    try {
      await exportTeamContacts();

      addToast("Your contacts have been exported successfully.", {
        appearance: "success",
      });
    } catch (error) {
      addToast(
        "There was an issue exporting your contacts. Please try again.",
        {
          appearance: "error",
        },
      );
    }
  }, [canExport, exportTeamContacts, addToast]);

  return (
    <>
      <ButtonOutlined
        minWidth="9rem"
        gap="0.5rem"
        isDisabled={!canExport || isLoading}
        onClick={handleExportContacts}
      >
        {isLoading ? (
          <Spinner width="1rem" height="1rem" />
        ) : (
          <>
            <AiOutlineFile />
            Export CSV file
          </>
        )}
      </ButtonOutlined>
    </>
  );
};
