export const theme = {
  breakpoints: {
    keys: ["md", "lg"],
    values: {
      md: 1024,
      lg: 1440,
    },
  },
  direction: "ltr",
  palette: {
    background: "#e5e5e5",
    ui: {
      greys: {
        grey1: "#686868",
        grey2: "#A1A1A1",
        grey3: "#D4D4D4",
        grey4: "#E3E3E3",
        grey5: "#F5F5F5",
      },
      cta: {
        yellow: "#FD9F1C",
        green: "#008A1E",
        red: "#D50000",
        blue: "#0D62FF",
      },
      states: {
        error: "#F40000",
        success: "#469D50",
        info: "#155CB7",
      },
    },
    brand: {
      primary: {
        white: "#FFFFFF",
        black: "#010101",
        orange: "#FF643A",
      },
    },
  },
  shadows: {
    light: {
      level0: { boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.12)" },
      level1: { boxShadow: "0px 8px 36px rgba(0, 0, 0, 0.05)" },
    },
  },
  typography: {
    fontSize: 32,
    h1: {
      fontSize: "32px",
      fontFamily: "Inter",
      letterSpacing: "-1%",
      lineHeight: "110%",
      fontStyle: "normal",
      fontWeight: "800",
    },
    h2: {
      fontSize: "32px",
      fontFamily: "Inter",
      letterSpacing: "-1%",
      lineHeight: "125%",
      fontStyle: "normal",
      fontWeight: "800",
    },
    h3: {
      fontSize: "21px",
      fontFamily: "Inter",
      letterSpacing: "0%",
      lineHeight: "125%",
      fontStyle: "normal",
      fontWeight: "800",
    },
    h4: {
      fontSize: "18px",
      fontFamily: "Inter",
      letterSpacing: "0%",
      lineHeight: "125%",
      fontStyle: "normal",
      fontWeight: "800",
    },
    h5: {
      fontSize: "16px",
      fontFamily: "Inter",
      letterSpacing: "0%",
      lineHeight: "125%",
      fontStyle: "normal",
      fontWeight: "800",
    },

    subtitle: {
      fontSize: "18px",
      fontFamily: "Inter",
      letterSpacing: "0px",
      lineHeight: "150%",
      fontStyle: "normal",
      fontWeight: "Regular",
    },

    subtitle1: {
      fontSize: "16px",
      fontFamily: "Inter",
      letterSpacing: "0px",
      lineHeight: "150%",
      fontStyle: "normal",
      fontWeight: "Semi bold",
    },

    subtitle2: {
      fontSize: "14px",
      fontFamily: "Inter",
      letterSpacing: "0px",
      lineHeight: "150%",
      fontStyle: "normal",
      fontWeight: "Semi bold",
    },

    body16: {
      fontSize: "16px",
      fontFamily: "Inter",
      letterSpacing: "0px",
      lineHeight: "150%",
      fontStyle: "normal",
      fontWeight: "Regular",
    },
    body14: {
      fontSize: "14px",
      fontFamily: "Inter",
      letterSpacing: "0px",
      lineHeight: "150%",
      fontStyle: "normal",
      fontWeight: "Regular",
    },
    body12: {
      fontSize: "12px",
      fontFamily: "Inter",
      letterSpacing: "0px",
      lineHeight: "150%",
      fontStyle: "normal",
      fontWeight: "Regular",
    },
    caption: {},
    link: {},
  },
  transitions: {},
  zIndex: {
    context: 800,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
    rail: 1600,
    calendar: 3,
  },
  common: {
    column: `
      display: flex;
      flex-direction: column;
    `,
    row: `
      display: flex;
      flex-direction: row;
    `,
    padding: {
      p24: "24px",
    },
    center: `
    display: flex;
    justify-content: center;
    align-items: center;`,
  },
};
