import { useMutation } from "react-query";
import { useToasts } from "react-toast-notifications";

import api from "../../../api";

type TBatchCardUnassignProps = {
  card_ids: string[];
};

const batchCardUnAssignService = async (body: TBatchCardUnassignProps) => {
  const { data } = await api.post(`/card/unassign_batch_cards/`, body);
  return data;
};

export const useUnassignCardsMutation = (showToasts = true) => {
  const { addToast } = useToasts();

  return useMutation(batchCardUnAssignService, {
    onSuccess: () => {
      showToasts &&
        addToast("Success", {
          appearance: "success",
        });
    },
    onError: () => {
      showToasts &&
        addToast("Failed to unassign card, please contact support for assistance.", {
          appearance: "error",
        });
    },
  });
};
