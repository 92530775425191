import React from "react";

import { Formik, Form, Field } from "formik";
import * as yup from "yup";

import { TextFormField, MultiSelectFormField } from "../../components";
import { VALIDATION_MESSAGES } from "../../constants";

const validationSchema = yup.object({
  title: yup.string().required(VALIDATION_MESSAGES.IS_REQUIRED("Name")),
  profile_handles: yup
    .array()
    .required(VALIDATION_MESSAGES.IS_REQUIRED("Members")),
});

const CreateEditGroupForm = ({
  children,
  onSubmit,
  options,
  isFetchingOptions,
  title,
  profileHandles,
  isEditing,
}) => {
  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        title,
        profile_handles: profileHandles,
        ...(isEditing ? {} : { active: false }),
      }}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {() => (
        <Form
          noValidate
          className="w-full sm:w-100"
          data-testid="create-group-wrapper"
        >
          <div className="mb-4">
            <Field label="Name" name="title" component={TextFormField} />
          </div>
          <div className="mb-8">
            <Field
              placeholder="Members"
              name="profile_handles"
              options={options}
              component={MultiSelectFormField}
              isLoading={isFetchingOptions}
              disabled={isFetchingOptions}
              renderInPortal
              isMulti
            />
          </div>
          {children}
        </Form>
      )}
    </Formik>
  );
};

export default CreateEditGroupForm;
