import { useMemo, useEffect, useState } from "react";

import { TEXTS } from "../../../constants/Texts";
import { useUserState } from "../../../context";
import { useCheckPermission, useFeatureFlagsQuery } from "../../../hooks";
import { getLoginToOVOULink } from "../../../utils/openLoginAsUserPage";
import { IMenuItem } from "../../../types/IMenuItem";
import { Icon } from "../../../components";
import { FEATURES, FEATURE_ACTION } from "constants/features";
import { PAGES } from "constants/pages";
import { useCustomerPortal } from "api/customerportal";
import { BUSINESS_SITE_URL, STRIPE_URL } from "../../../constants";

const IconComponent = Icon as any;

export const useGetMenuItems = (): {
  mainItems: IMenuItem[];
  secondaryItems: IMenuItem[];
} => {
  const { currentTeam } = useUserState();

  const { data: featureFlags } = useFeatureFlagsQuery();

  const { mutate: fireCustomerPortal } = useCustomerPortal();

  const [manageSubscriptionLink, setManageSubscriptionLink] =
    useState<string>("");

  useEffect(() => {
    const getCustomerPortal = async (): Promise<string | undefined> => {
      const successUrl = `${BUSINESS_SITE_URL}${PAGES.MANAGE_USERS}`;
      const cancelUrl = `${BUSINESS_SITE_URL}${PAGES.MANAGE_USERS}`;

      const data = await fireCustomerPortal({
        data: {
          cancel_url: cancelUrl,
          success_url: successUrl,
          type: "SUBSCRIPTION_UPDATE",
        },
      });
      return data?.data?.url || "";
    };

    const fetchData = async () => {
      const manageSubscriptionLink = await getCustomerPortal();
      manageSubscriptionLink &&
        setManageSubscriptionLink(manageSubscriptionLink);
    };
    fetchData();
  }, []);

  const canViewManageSubscription = useCheckPermission(
    FEATURE_ACTION.VIEW,
    FEATURES.MANAGE_SUBSCRIPTION,
  );
  const canEditManageSubscription = useCheckPermission(
    FEATURE_ACTION.EDIT,
    FEATURES.MANAGE_SUBSCRIPTION,
  );
  const canViewOrderMoreCards = useCheckPermission(
    FEATURE_ACTION.VIEW,
    FEATURES.ORDER_MORE_CARDS,
  );
  const canViewUpgradeNow = useCheckPermission(
    FEATURE_ACTION.VIEW,
    FEATURES.UPGRADE_NOW,
  );

  const canViewIntegrations = useCheckPermission(
    FEATURE_ACTION.VIEW,
    FEATURES.INTEGRATIONS,
  );
  console.log(featureFlags);
  const isBusinessPageEnabled = JSON.parse(
    featureFlags?.flags?.business?.isBusinessPageEnabled || "false",
  );

  return useMemo(() => {
    const OVOULink = getLoginToOVOULink({
      teamID: String(currentTeam?.id),
      redirectTo: "/o/my-profile",
    });

    const mainItems: IMenuItem[] = [
      {
        icon: <IconComponent name="squareStack" />,
        title: TEXTS.menuItems.MANAGE_USERS,
        to: "/manage-users",
        feature: FEATURES.MANAGE_USERS,
      },
      {
        icon: <IconComponent name="card" />,
        title: TEXTS.menuItems.MANAGE_DEVICES,
        to: "/manage-devices",
        feature: FEATURES.MANAGE_DEVICES,
      },
      isBusinessPageEnabled && {
        icon: (
          <IconComponent name="company" customClassName="icon stroke-current" />
        ),
        title: TEXTS.menuItems.COMPANY_PROFILE,
        to: "/business",
        feature: FEATURES.COMPANY_PROFILE,
      },
      {
        icon: (
          <IconComponent
            name="personStack"
            customClassName="icon stroke-current"
          />
        ),
        title: TEXTS.menuItems.GROUPS,
        to: "/directory",
        feature: FEATURES.GROUPS,
      },
      {
        icon: <IconComponent name="contacts" />,
        title: TEXTS.menuItems.CONTACTS,
        to: "/contacts",
        feature: FEATURES.CONTACTS,
      },
      {
        icon: <IconComponent name="analytics" />,
        title: TEXTS.menuItems.ANALYTICS,
        to: "/analytics",
        feature: FEATURES.ANALYTICS,
      },

      {
        icon: <IconComponent name="cog" />,
        title: TEXTS.menuItems.SETTINGS,
        to: "/settings",
        feature: FEATURES.SETTINGS,
      },

      canViewIntegrations && {
        icon: <IconComponent name="integrations" />,
        title: TEXTS.menuItems.INTEGRATIONS,
        to: "/integrations",
        feature: FEATURES.INTEGRATIONS,
      },
      {
        icon: <IconComponent name="tutorial" />,
        title: TEXTS.menuItems.TUTORIALS,
        to: "/tutorials",
        feature: FEATURES.TUTORIALS,
      },
      canViewManageSubscription && {
        icon: <IconComponent name="card" />,
        title: TEXTS.menuItems.MANAGE_SUBSCRIPTION,
        to: manageSubscriptionLink,
        isExternal: true,
        feature: FEATURES.MANAGE_SUBSCRIPTION,
        canEdit: canEditManageSubscription,
      },
      canViewOrderMoreCards && {
        icon: <IconComponent name="card" />,
        title: TEXTS.menuItems.ORDER_MORE_CARDS,
        to: `${process.env.REACT_APP_OVOU_MAIN_SITE_URL}/checkout/?add-to-cart=7146&team_id=${currentTeam?.id}`,
        isExternal: true,
        feature: FEATURES.ORDER_MORE_CARDS,
      },
      canViewUpgradeNow && {
        icon: <IconComponent name="card" />,
        title: TEXTS.menuItems.UPGRADE_NOW,
        isUpgrade: true,
        feature: FEATURES.UPGRADE_NOW,
      },
    ].filter(Boolean);

    const secondaryItems: IMenuItem[] = [
      {
        icon: (
          <IconComponent name="person" customClassName="icon stroke-current" />
        ),
        title: TEXTS.menuItems.MY_OVOU_PROFILE,
        to: OVOULink,
        isExternal: true,
      },
      {
        icon: <IconComponent name="logoutArrow" />,
        title: TEXTS.menuItems.LOG_OUT,
        to: "/auth/logout",
      },
    ];

    return { mainItems, secondaryItems };
  }, [
    currentTeam?.id,
    isBusinessPageEnabled,
    canViewManageSubscription,
    canViewOrderMoreCards,
    canViewUpgradeNow,
    manageSubscriptionLink,
    canEditManageSubscription,
  ]);
};
