import { useQuery } from "react-query";

import api from "../../../api";

const tutorialsService = async () => {
  const { data } = await api.get("/team/tutorials");
  return data;
};

export const useTutorialsQuery = () => {
  return useQuery("tutorials", tutorialsService);
};
