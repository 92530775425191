import React from "react";

const LogOut = ({
  className,
  role,
  ignoreDefaultSize,
  width = 24,
  height = 24,
  strokeWidth,
  onClick,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={ignoreDefaultSize ? undefined : width}
      height={ignoreDefaultSize ? undefined : height}
      viewBox="0 0 24 24"
      role={role}
      className={className}
      strokeWidth={strokeWidth}
      onClick={onClick}
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill="none"
        stroke="currentColor"
        d="M7.581 17a2 2 0 001.478 2.058l6.99 1.873a2 2 0 002.451-1.414l2.433-9.08a2 2 0 00-1.414-2.45l-5.28-1.415"
      />
      <path
        fill="none"
        stroke="currentColor"
        d="M3 5v10a2 2 0 002 2h7.237a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2z"
      />
    </svg>
  );
};

export default React.memo(LogOut);
