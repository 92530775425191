import React from "react";

import MeetTheTeamForm from "./MeetTheTeamForm";
import ProfileForm from "./ProfileForm";
import EmbeddedContent from "./EmbeddedContent";

const BusinessForm = () => {
  return (
    <>
      <EmbeddedContent />
      <ProfileForm />
      <MeetTheTeamForm />
    </>
  );
};

export default BusinessForm;
