import { useQuery } from "react-query";

import api from "../../../api";

interface IUseGetContactNotesProps {
  contactId: number;
  queryAutomatically?: boolean;
}

export const useGetContactNotes: (props: IUseGetContactNotesProps) => any = ({
  contactId,
  queryAutomatically = true,
}) => {
  return useQuery(
    [
      "contact-notes",
      {
        contactId,
      },
    ],
    async () => {
      const { data } = await api.get(
        `/contacts/lead_captures/${contactId}/notes`,
      );
      return data;
    },
    {
      enabled: contactId !== undefined && queryAutomatically,
      refetchOnWindowFocus: !queryAutomatically,
    },
  );
};
