import React from "react";

import { Formik, Form, Field } from "formik";
import { useHistory } from "react-router-dom";
import * as yup from "yup";

import { TextFormField, Button, Spinner, Error } from "../../../components";
import { VALIDATION_MESSAGES, TEXTS } from "../../../constants";
import { useResetPasswordMutation } from "../queries";

const validationSchema = yup.object({
  verification_code: yup
    .string()
    .required(VALIDATION_MESSAGES.IS_REQUIRED("Verification Code")),
  password: yup
    .string()
    .min(8, VALIDATION_MESSAGES.MIN_LENGTH("Password", 8))
    .required(VALIDATION_MESSAGES.IS_REQUIRED("Password")),
});

const ResetPasswordForm = () => {
  const history = useHistory();

  const {
    isLoading,
    error,
    mutate: resetPassword,
  } = useResetPasswordMutation({
    onSuccess: () => {
      localStorage.removeItem("forgotPasswordEmail");
      history.push("/auth/login");
    },
  });

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{ verification_code: "", password: "" }}
      onSubmit={values =>
        resetPassword({
          resetPasswordData: {
            ...values,
            email: localStorage.getItem("forgotPasswordEmail"),
          },
        })
      }
      enableReinitialize
    >
      {({ isValid }) => (
        <Form>
          <div className="mb-8">
            <Field
              label="Password"
              name="password"
              type="password"
              component={TextFormField}
            />
          </div>
          <div>
            <Field
              label="Verification Code"
              name="verification_code"
              component={TextFormField}
            />
          </div>
          <div className="mb-32 text-right">
            {error && <Error {...{ error }} />}
          </div>
          <div>
            <Button
              variant="black"
              disabled={!isValid || isLoading}
              block
              uppercase
            >
              {isLoading ? (
                <Spinner color="white" />
              ) : (
                TEXTS.resetPasswordPage.BUTTON
              )}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ResetPasswordForm;
