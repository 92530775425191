import { useQuery } from "react-query";

import api from "../../../api";

const cardDesignsQuery = async ({ teamId }) => {
  const { data } = await api.get(`team/${teamId}/profile_designs`);
  return data;
};

export const useCardDesignsQuery = ({ teamId }) => {
  const { status, isLoading, data, error, refetch } = useQuery(
    ["card-designs", teamId],
    () => cardDesignsQuery({ teamId }),
  );
  return {
    isLoading,
    status,
    data,
    error,
    refetch,
  };
};

export const cardDesign = async options => {
  const { data } = await api.get(`/profile/design`, options);
  return data;
  // return fetcher(
  //   { url: `/profile/design`, method: "get" },
  //   options,
  // );
};

export const getCardDesignQueryKey = () => [`/profile/design`];

export const useCardDesign = options => {
  const { query: queryOptions, request: requestOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getCardDesignQueryKey();

  const queryFn = () => cardDesign(requestOptions);

  const query = useQuery(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query,
  };
};

/* const profileDeletePicture = async ({handle}) => {
  const { data } = await api.delete(`/p/${handle}/picture`);
  return data;
  // return fetcher<void>(
  //   { url: `/p/${handle}/picture`, method: "delete" },
  //   options,
  // );
};

export const useProfileDeletePicture = () => {
  // const { mutation: mutationOptions, request: requestOptions } = options || {};

  // const mutationFn = props => {
  //   const { handle } = props || {};

  //   return profileDeletePicture(handle, requestOptions);
  // };

  // return (mutationFn, mutationOptions);
  const [mutate, { status, error }] = useMutation(profileDeletePicture);

  return {
    mutate,
    status,
    error,
  };
}; */
