import { useQuery } from "react-query";
import {
  ITeamUsersResponse,
  USER_IAM_ROLE,
  USER_SEATS_STATUS,
} from "types/TeamUsersResponse";

import api from "../../../api";

type TeamsUsersProp = {
  teamId?: number;
  sort?: string;
  offset?: number;
  limit?: number;
  status?: USER_SEATS_STATUS;
  permission?: USER_IAM_ROLE;
  searchQuery?: string;
  serializer?: "basic_info";
};
const teamsUsersService = async ({
  teamId,
  sort,
  offset = 0,
  limit = 20,
  status,
  permission,
  searchQuery,
  serializer,
}: TeamsUsersProp) => {
  const { data } = await api.get<ITeamUsersResponse>(
    `/profile/teams/${teamId}/users`,
    {
      params: {
        sort,
        offset,
        limit,
        status,
        permission,
        search: searchQuery,
        serializer,
      },
    },
  );
  return data;
};

export const useTeamUsersQuery = ({
  teamId,
  sort,
  offset,
  limit,
  status,
  permission,
  searchQuery,
  serializer,
}: TeamsUsersProp) => {
  const query = useQuery<ITeamUsersResponse>(
    [
      `team-users`,
      { teamId, sort, offset, limit, status, permission, searchQuery, serializer },
    ],
    () =>
      teamsUsersService({
        teamId,
        sort,
        offset,
        limit,
        status,
        permission,
        searchQuery,
        serializer,
      }),
    {
      enabled: teamId !== undefined,
      refetchOnWindowFocus: false,
    },
  );

  return {
    ...query,
  };
};
