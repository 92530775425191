import { FC } from "react";

import {
  Avatar,
  Box,
  DateTimeDisplay,
  Flex,
  GlobalListItem,
  Image,
  Skeleton,
  Text,
} from "components";
import AnalyticsSection from "./AnalyticsSection";
import { IInteraction, IInteractionsList } from "./types";
import { ANALYTICS_TEXT } from "constants/Texts";
import { theme } from "ovou-ui";
import defaultPictureSmall from "../../assets/images/profile-pic-placeholder-sm.svg";

const InteractionListItemLoaders: FC = () => {
  return (
    <Flex justifyContent="space-between" alignItems="start" padding="8px">
      <Flex alignItems="center">
        <Box mr="10px">
          <Skeleton height={6} width={6} />
        </Box>
        <Flex flexDirection="column">
          <Skeleton height={4} width="12rem" margin="5px" />
          <Skeleton height={4} width="6rem" margin="5px" />
        </Flex>
      </Flex>
    </Flex>
  );
};
const { EMPTY_STATE } = ANALYTICS_TEXT;
const InteractionsList: FC<IInteractionsList> = ({
  interactions,
  isLoading,
}) => {
  const getInteractionType = (interaction: IInteraction) => {
    if (interaction?.exchanged_contact) {
      return "exchanged contact";
    }

    return "interacted";
  };

  return (
    <AnalyticsSection title="Interactions">
      {isLoading ? (
        [...Array(8)].map((_, index) => (
          <InteractionListItemLoaders key={index} />
        ))
      ) : (
        <>
          {!Boolean(interactions?.count) ? (
            <Text
              as="p"
              fontSize="16px"
              fontWeight={400}
              color={theme.palette.ui.greys.grey1}
            >
              {EMPTY_STATE.INTERACTIONS}
            </Text>
          ) : (
            interactions?.results.map(interaction => (
              <Box mb="24px" key={interaction?.id}>
                <GlobalListItem
                  title={
                    <Text fontWeight={600} fontSize="14px" maxW="180px">
                      {`${
                        interaction?.full_name || "Someone"
                      } ${getInteractionType(interaction)} with ${
                        interaction?.handle
                      }`}
                    </Text>
                  }
                  subTitle={
                    <DateTimeDisplay dateString={interaction?.timestamp} />
                  }
                  leftElement={
                    <Avatar
                      name={interaction?.full_name}
                      src={interaction?.profile?.picture || defaultPictureSmall}
                      width="26px"
                      height="36px"
                      borderRadius="4px"
                      mb="20px"
                    />
                  }
                  rightElement={
                    <Image p="2px" mr="14px" src={interaction?.source?.icon} />
                  }
                />
              </Box>
            ))
          )}
        </>
      )}
    </AnalyticsSection>
  );
};

export default InteractionsList;
