import { useMutation } from "react-query";

import api from "../../../api";

const deleteGroupService = async ({ directoryID }) => {
  const { data } = await api.delete(`/directory/${directoryID}`);
  return data;
};

export const useDeleteGroupQuery = ({ onSuccess }) => {
  const [mutate, { status, error }] = useMutation(deleteGroupService, {
    onSuccess,
  });

  return {
    mutate,
    status,
    error,
  };
};
