import React from "react";

import ReactModal from "react-modal";

import { styles } from "./styles";

const Modal = ({ isOpen, label, children, onClose, closable = true }) => {
  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel={label}
      onRequestClose={onClose}
      bodyOpenClassName="overflow-hidden"
      style={styles}
      shouldCloseOnEsc={closable}
      shouldCloseOnOverlayClick={closable}
    >
      {children}
    </ReactModal>
  );
};

export default Modal;
