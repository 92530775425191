import React, { useCallback, useEffect, useState } from "react";

import styled from "styled-components";

import defaultLogo from "../../../assets/images/default-logo.png";
import { LOGO_MAX_WIDTH, MEDIA_URL } from "../../../constants";
import Button from "../../Button";
import CropperModal from "../../CropperModal";
import { useUserState } from "context/User";

const logoRatioOptions = [
  { ratio: 1, label: "1:1", example: "h-4 w-4" },
  { ratio: 2, label: "2:1", example: "h-4 w-8" },
];

const Image = styled.img`
  background: ${({ bg }) => bg};
  background-size: cover;
`;

const LogoUploadFormField = ({
  field,
  form,
  attachMediaURL,
  background,
  onLogoDelete,
}) => {
  const { permissions } = useUserState();
  const canEditLogo = permissions?.canEditLogo

  const [image, setImage] = useState();
  const [isCropperModalOpen, setIsCropperModalOpen] = useState(false);

  const handleInputChange = useCallback(e => {
    if (!canEditLogo) {
      return;
    }
    e.preventDefault();
    if (!e.target.files?.length) return;
    setImage(URL.createObjectURL(e.target.files[0]));
    setIsCropperModalOpen(true);
  }, [canEditLogo]);

  const handleLogoDelete = () => {
    if (!canEditLogo) {
      return;
    }
    form.setFieldValue(field.name, "");
    setImage(undefined);
    onLogoDelete();
  };

  const setCroppedImage = useCallback(
    croppedImage => {
      setImage(croppedImage);

      if (!!croppedImage) {
        form.setFieldValue(field.name, croppedImage);
      }
    },
    [form],
  );

  return (
    <>
      <div className="flex flex-col items-start md:flex-row md:items-center">
        <div className="ml-1 mb-1 h-20 w-40 overflow-hidden">
          {image && (
            <Image
              bg={background}
              src={image}
              alt=""
              className="h-full rounded-md p-1"
            />
          )}
          {!image && field.value && (
            <Image
              bg={background}
              alt=""
              src={`${
                attachMediaURL ? `${MEDIA_URL}${field.value}` : field.value
              }`}
              className="h-full rounded-md p-1"
            />
          )}
          {!image && !field.value && (
            <Image
              bg={background}
              alt=""
              src={defaultLogo}
              className="h-full rounded-md p-1"
            />
          )}
        </div>
        <div className="flex flex-row items-start">
          <label
            className={`ml-1 ${canEditLogo ? "cursor-pointer" : ""}`}
            htmlFor={field.name}
          >
            <Button
              className="pointer-events-none"
              htmlFor={field.name}
              variant="white"
              size="small"
              type="button"
              disabled={!canEditLogo}
            >
              Change
            </Button>
          </label>
          <input
            type="file"
            accept=".png, .jpg, .jpeg"
            id={field.name}
            className="invisible w-0 h-0 absolute left-0 top-0"
            onChange={handleInputChange}
            data-testid="logo-upload-input"
            disabled={!canEditLogo}
          />
          {(image || field.value) && (
            <Button
              onClick={handleLogoDelete}
              className="ml-4"
              variant="red"
              size="small"
              type="button"
              disabled={!canEditLogo}
            >
              Delete
            </Button>
          )}
        </div>
      </div>
      {isCropperModalOpen && (
        <CropperModal
          closable
          isOpen={isCropperModalOpen}
          onClose={() => setIsCropperModalOpen(false)}
          image={image}
          getCroppedImage={setCroppedImage}
          title="Crop Your Logo"
          format="png"
          maxWidth={LOGO_MAX_WIDTH}
          restrictPosition={false}
          ratioOptions={logoRatioOptions}
        />
      )}
    </>
  );
};

export default LogoUploadFormField;
