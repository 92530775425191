/**
 * Customer Portal
 * @returns {Object} { url: string}
 */

import { useQuery } from "react-query";
import api from "./index";
import { AxiosRequestConfig } from "axios";

interface IStep {
  message: string;
  picture: string;
  order: number;
  cta_label?: string;
  cta_link?: string;
}

export interface IWalkthrough {
  title?: string;
  sections?: IStep[];
}

const getWalkthrough = async (
  walkthrough: string,
  options?: AxiosRequestConfig,
): Promise<IWalkthrough> => {
  const { data } = await api.get(`/walkthroughs/${walkthrough}`, options);
  return data;
};

export const useGetWalkthrough = (
  walkthrough: string,
  options: AxiosRequestConfig = {},
  queryOptions = {},
) => {
  return useQuery(
    ["Walkthrough", walkthrough],
    () => getWalkthrough(walkthrough, options),
    { ...queryOptions, enabled: !!walkthrough },
  );
};
