import { useMutation } from "react-query";
import { useToasts } from "react-toast-notifications";

import api from "../../../api";

const updateProfileService = async ({ profileData, handle }) => {
  const { background, logo, ...restProfileData } = profileData;

  const { data } = await api.put(`/p/${handle}/info`, {
    ...restProfileData,
    background: checkImageData(background),
    logo: checkImageData(logo),
  });
  return data;
};

export const checkImageData = imageData =>
  typeof imageData === "string"
    ? imageData?.startsWith("data:image")
      ? imageData
      : undefined
    : null;

export const addProfileDesignQuery = async ({ cardSchema }) => {
  const { data } = await api.put(`/design/${cardSchema.id}`, {
    ...cardSchema,
  });
  return data;
};

export const useUpdateProfileQuery = ({
  onSuccess = () => {},
  showToasts = true,
}) => {
  const { addToast } = useToasts();

  const [mutate, { status, error, isLoading }] = useMutation(
    updateProfileService,
    {
      onSuccess: () => {
        onSuccess();
        showToasts &&
          addToast("Profile updated successfully.", {
            appearance: "success",
          });
      },
      onError: () => {
        showToasts &&
          addToast("Failed to update profile data.", { appearance: "error" });
      },
    },
  );

  return {
    mutate,
    status,
    error,
    isLoading,
  };
};
