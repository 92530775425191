import { FC } from "react";

import {
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Spinner,
  Error,
  TextFormField,
} from "../../components";
import { TEXTS, VALIDATION_MESSAGES } from "../../constants";

import * as yup from "yup";
import { Field, Form, Formik } from "formik";
import { useTeamUsersChangeMutation } from "./api/useTeamUsersMutation";
import { IChangeInvitationModal } from "./types";
import { useToasts } from "react-toast-notifications";
import { useTracking } from "hooks";
import { ANALYTICS_EVENTS } from "constants/analyticEvents";

const validationSchema = yup.object({
  email: yup
    .string()
    .required(VALIDATION_MESSAGES.IS_REQUIRED("Email"))
    .email(VALIDATION_MESSAGES.FORMAT_IS_WRONG("Email")),
});

const ChangeInvitationModal: FC<IChangeInvitationModal> = ({
  isOpen,
  onClose,
  teamId,
  selectedUser,
  refetch,
}) => {
  const { addToast } = useToasts();
  const track = useTracking();
  const { id } = selectedUser;

  const {
    mutate: fireChangeInvitation,
    isLoading,
    isError,
  } = useTeamUsersChangeMutation();

  const handleSubmit = ({ email }: { email: string }) => {
    fireChangeInvitation(
      { teamId: teamId, userEmail: email, userId: id },
      {
        onSuccess: async ({ message }) => {
          addToast(message, {
            appearance: "success",
          });
          onClose();
          track({
            eventName: ANALYTICS_EVENTS.MANAGE_USERS_CHANGE_INVITATION,
            customTrackingProps: { userEmail: email, userId: id },
          });
          await refetch();
        },
      },
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} label="Change User Invitation">
      <ModalHeader
        className="max-w-lg mx-auto"
        title="Change User Invitation"
      />
      <ModalBody className="mb-0">
        <div className="max-w-none min-w-0 md:min-w-400">
          <Formik
            validationSchema={validationSchema}
            initialValues={{
              email: "",
            }}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {() => (
              <Form noValidate>
                <div className="mb-4">
                  <Field
                    label="Email"
                    name="email"
                    component={TextFormField}
                    type="email"
                  />
                </div>
                <ModalFooter>
                  <Button
                    type="submit"
                    variant="black"
                    data-testid="assign-card-submit-button"
                    block
                    capitalize
                  >
                    {isLoading ? (
                      <Spinner color="white" />
                    ) : (
                      TEXTS.assignCardModal.EDITING_BUTTON
                    )}
                  </Button>
                  {isError && (
                    <Error
                      className="text-center mt-6"
                      {...{ error: isError }}
                    />
                  )}
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ChangeInvitationModal;
