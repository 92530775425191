import { ANALYTICS_EVENTS } from "constants/analyticEvents";

export interface ITeamMember {
  profile: {
    id: number;
    handle: string;
    first_name: string;
    last_name: string;
    picture: string;
    background: string;
    bio: string;
    user: number;
    view_stats: string;
    sections: string;
    injected: string;
    profile_hits: string;
    profile_exchanges: string;
    profile_saves: string;
    appointed_email: string;
    work_category: number;
    public_id: string;
    workplace: string;
  };
  count: number;
}

export interface IGroup {
  group: {
    id: number;
    title: string;
    active: boolean;
    order: number;
    profiles: [
      {
        handle: string;
        picture: string;
        job_title: string;
        workplace: string;
        first_name: string;
        last_name: string;
      },
    ];
  };
  count: number;
}

export interface ISource {
  id: number;
  name: string;
  icon: string;
  count: number;
}
export interface IStatsResponse {
  view_count: number;
  save_count: number;
  exchange_count: number;
  social_media_click_count: number;
  thumbnail_link_click_count: number;
  profiles: {
    details: ITeamMember[];
    total_count: number;
  };
  groups: {
    details: IGroup[];
    total_count: number;
  };
  sources: {
    details: ISource[];
    total_count: number;
  };
}

export interface ISourceList {
  sources?: ISource[];
  isLoading?: boolean;
}

export interface IStatCounters {
  viewCount: number;
  saveCount: number;
  exchangeCount: number;
  socialClickCount: number;
  thumbnailClickCount: number;
  handleStatChange: (stat: IStat) => void;
  isLoading?: boolean;
}

export interface IStat {
  title: string;
  icon: string;
  count: number;
  metric: METRIC;
  event: ANALYTICS_EVENTS;
  iconStyles: {
    color?: string;
    stroke?: string;
    height: string;
    width: string;
  };
}

export enum METRIC {
  VIEW_COUNT = "view_count",
  SAVE_COUNT = "save_count",
  EXCHANGE_COUNT = "exchange_count",
  SOCIAL_MEDIA_CLICK_COUNT = "social_media_click_count",
  THUMBNAIL_LINK_CLICK_COUNT = "thumbnail_link_click_count",
}

export interface ITeamAndGroupsList {
  teamMembers?: ITeamMember[];
  groups?: IGroup[];
  isLoading?: boolean;
}

export interface IMetrics {
  metrics: IMetric[];
}
interface IMetric {
  date: string;
  count: number;
}

export interface IInteractions {
  count: number;
  results: IInteraction[];
}

export interface IInteraction {
  id: number;
  timestamp: string;
  handle: string;
  full_name: string;
  saved_contact: string | null;
  exchanged_contact: string | null;
  contact_id: string;
  device_type: string;
  device_model: string;
  social_media: any[];
  thumbnail_links: any[];
  source: {
    id: number;
    name: string;
    icon: string;
  };
  profile: {
    id: number;
    public_id: string;
    first_name: string;
    last_name: string;
    handle: string;
    picture: string;
  };
}

export interface IInteractionsList {
  interactions?: IInteractions;
  isLoading?: boolean;
}

export interface IDateSelector {
  dateTime?: Date;
  menuButtonType?: "dateRange" | "tableFilter";
  handleChange: (lowerBoundDate: string, upperBoundDate: string) => void;
}
export enum DATE_OPTIONS {
  ALL_TIME = "all-time",
  CURRENT_MONTH = "current-month",
  PREV_MONTH = "prev-month",
  LAST_YEAR = "last-year",
  CUSTOM = "custom",
}
export interface IMenuOptions {
  value?: string;
  onChange?: (value: string | string[]) => void;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export type MetricsQueryProps = {
  metric: METRIC;
  window_lower_bound: string;
  window_upper_bound: string;
  teamId?: number;
};
