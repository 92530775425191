import React from "react";

import { ErrorMessage, FastField } from "formik";
import { useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import * as Yup from "yup";

import Contact from "./Contact";
import PhoneNumberInput from "../../../components/FormFields/PhoneNumberInput/PhoneNumberInput";
import { VALIDATION_MESSAGES } from "../../../constants";
import { useDeleteIndividualQuery } from "../../../features/individualProfile/queries";
import { SECTION_EMPTY_FIELD_VALUE } from "../editContactInformationPage";

export const phoneValidationSchema = Yup.array().of(
  Yup.object()
    .shape({
      field_type_id: Yup.string().required(
        VALIDATION_MESSAGES.IS_REQUIRED("Phone number type"),
      ),
      value: Yup.string().required(
        VALIDATION_MESSAGES.IS_REQUIRED("Phone number"),
      ),
      id: Yup.string().nullable(),
      extension: Yup.string().matches(
        /^\d+$/,
        VALIDATION_MESSAGES.FORMAT_IS_WRONG("Extension"),
      ),
      order: Yup.number(),
    })
    .optional(),
);

const Phone = props => {
  const {
    phones,
    options,
    push,
    remove,
    form: { setFieldValue },
    errors,
  } = props;

  const { profileHandle } = useParams();
  const { addToast } = useToasts();

  const { mutate: deleteIndividual } = useDeleteIndividualQuery({
    type: "generic",
    onSuccess: () => {
      addToast("Phone Number Removed Successfully", {
        appearance: "success",
      });
    },
  });

  const handleAddNewField = e => {
    e.preventDefault();
    push({
      ...SECTION_EMPTY_FIELD_VALUE,
      field_type_id: "cell_phone",
      id: `new-${phones.length + 1}`,
      order: phones.length + 1,
      extension: "",
    });
  };

  const handleRemove = (phoneId, index) => {
    if (phoneId.toString().includes("new")) {
      remove(index);
      return;
    }
    deleteIndividual(
      {
        handle: profileHandle,
        category: "field",
        id: phoneId,
      },
      {
        onSuccess: () => {
          remove(index);
          // const updatedSocialAccounts = socialMediaItems.filter(account => account.id !== socialAccountId);
          // updateSocialMediaItems(updatedSocialAccounts);
        },
      },
    );
  };

  return (
    <Contact
      title="Phone Number"
      label="phone"
      handleAddNewField={handleAddNewField}
      handleRemove={handleRemove}
    >
      {phones?.map((phone, index) => (
        <div key={`phones.${index}`}>
          <FastField
            name={`phones.${index}`}
            label="Phone"
            options={options || []}
            component={PhoneNumberInput}
            inputPlaceholder="Phone"
            isSelectSearchable={false}
            isPhoneNumber={true}
            showPhoneExtension={phone?.field_type_id !== "cell_phone"}
            phone={phone}
            handleRemoveItem={() => handleRemove(phone.id, index)}
          />
        </div>
      ))}
    </Contact>
  );
};

export default Phone;
