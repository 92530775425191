import React from "react";

const Company = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M18.4 8H5.6C4.71634 8 4 8.71634 4 9.6V17.6C4 18.4837 4.71634 19.2 5.6 19.2H18.4C19.2837 19.2 20 18.4837 20 17.6V9.6C20 8.71634 19.2837 8 18.4 8Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2003 19.2V6.40005C15.2003 5.9757 15.0317 5.56874 14.7317 5.26868C14.4316 4.96862 14.0246 4.80005 13.6003 4.80005H10.4003C9.97595 4.80005 9.56898 4.96862 9.26892 5.26868C8.96886 5.56874 8.80029 5.9757 8.80029 6.40005V19.2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default React.memo(Company);
