import React, { useCallback, useMemo } from "react";

import CreateEditGroupForm from "./Form";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Error,
  Button,
  Spinner,
} from "../../components";
import { TEXTS } from "../../constants";
import { useUserState } from "../../context";
import { useCardsPoolQuery } from "../../features/cards/queries";
import { useDirectoryQuery } from "../../features/directory/queries";
import { useGroupCreateEditQuery } from "../../features/directory/queries/useGroupCreateEditQuery";
import { mapProfileToSelectOption } from "../../utils";
import { useTracking } from "hooks";
import { ANALYTICS_EVENTS } from "constants/analyticEvents";
import { useTeamUsersQuery } from "../../features/manageUsers/api/useTeamUsersQuery";

const CreateEditGroup = ({ isOpen, onClose, label, directoryID, refetch }) => {
  const track = useTracking();
  const { currentTeam } = useUserState();
  const teamID = currentTeam?.id;
  const { data, isFetching, error } = useDirectoryQuery({
    teamID,
  });
  const { data: allCards } = useCardsPoolQuery({
    states: ["assigned_without_business", "appointed"],
    hasProfileDirectory: false,
    teamID,
    sort: "tag",
  });
  const { data: { results: teamUsers } = {} } = useTeamUsersQuery({
    teamId: teamID,
  });
  const {
    mutate: editCreateGroup,
    status,
    error: editCreateGroupError,
  } = useGroupCreateEditQuery({
    onSuccess: () => {
      onClose();
      refetch();
    },
  });

  const profileOption = useMemo(() => {
    return mapProfileToSelectOption(teamUsers);
  }, [teamUsers]);

  const editingProfileHandles = useMemo(() => {
    if (directoryID) {
      return data?.directories
        ?.find(directory => directory.id === directoryID)
        ?.profiles?.map(profile => {
          return profile?.handle;
        });
    }
    return [];
  }, [data, directoryID]);

  const editingCardTitle = useMemo(() => {
    if (directoryID) {
      return data?.directories?.find(directory => directory.id === directoryID)
        ?.title;
    }
    return "";
  }, [data, directoryID]);

  const handleFormSubmit = useCallback(
    values => {
      editCreateGroup(
        { directoryID, formData: values, teamID },
        {
          onSuccess: () => {
            track({
              eventName: ANALYTICS_EVENTS.GROUPS_CREATE_NEW_GROUP,
              customTrackingProps: {
                values,
              },
            });
          },
        },
      );
    },
    [editCreateGroup, directoryID, teamID],
  );

  const isLoading = useMemo(() => status === "loading", [status]);

  const existingUsers = data?.directories?.find(
    directory => directory.id === directoryID,
  )?.profiles;
  const existingUsersOptions =
    existingUsers?.map(user => {
      const firstName = user?.firstName;
      const lastName = user?.lastName;
      return {
        label:
          firstName || lastName ? `${firstName} ${lastName}` : user?.handle,
        value: user?.handle,
      };
    }) || [];

  return (
    <Modal isOpen={isOpen} onClose={onClose} label={label}>
      <ModalHeader
        title={
          directoryID
            ? TEXTS.createEditGroupModal.EDIT_TITLE
            : TEXTS.createEditGroupModal.CREATE_TITLE
        }
      />
      <ModalBody className="mb-0">
        <CreateEditGroupForm
          onSubmit={handleFormSubmit}
          options={[...profileOption, ...existingUsersOptions]}
          isFetchingOptions={isFetching}
          title={editingCardTitle}
          profileHandles={editingProfileHandles}
          isEditing={!!directoryID}
        >
          <ModalFooter>
            <Button type="submit" variant="black" block capitalize>
              {isLoading ? (
                <Spinner color="white" />
              ) : directoryID ? (
                TEXTS.createEditGroupModal.EDIT_BUTTON
              ) : (
                TEXTS.createEditGroupModal.CREATE_BUTTON
              )}
            </Button>
            {editCreateGroupError && (
              <Error
                className="text-center mt-6"
                {...{
                  error:
                    editCreateGroupError?.response?.data?.title?.[0] ||
                    editCreateGroupError,
                }}
              />
            )}
            {error && <Error className="text-center mt-6" {...{ error }} />}
          </ModalFooter>
        </CreateEditGroupForm>
      </ModalBody>
    </Modal>
  );
};

export default CreateEditGroup;
