import { FC } from "react";
import { theme } from "ovou-ui";

import { Flex, Text } from "..";

interface IEmptyStateProps {
  title?: string;
  subTitle?: string;
}

const EmptyState: FC<IEmptyStateProps> = ({ title, subTitle }) => {
  return (
    <Flex
      height="80vh"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      borderWidth={1}
      borderColor={theme.palette.ui.greys.grey4}
      borderRadius="10px"
      padding="4"
    >
      {title && (
        <Text
          fontSize="2xl"
          fontWeight="extrabold"
          textAlign="center"
          color={theme.palette.brand.primary.black}
        >
          {title}
        </Text>
      )}
      {subTitle && (
        <Text
          marginTop={4}
          fontSize="md"
          textAlign="center"
          color={theme.palette.ui.greys.grey1}
        >
          {subTitle}
        </Text>
      )}
    </Flex>
  );
};

export default EmptyState;
