import { useQuery } from "react-query";

import api from "../../../api";
import { IMetrics, METRIC, MetricsQueryProps } from "../types";

const metricsService = async ({
  metric,
  window_lower_bound,
  window_upper_bound,
  teamId,
}: MetricsQueryProps) => {
  const { data } = await api.get<IMetrics>(
    `analytics/teams/${teamId}/metrics/${metric}`,
    {
      params: {
        window_lower_bound,
        window_upper_bound,
      },
    },
  );
  return data;
};

export const useMetricsQuery = ({
  metric,
  window_lower_bound,
  window_upper_bound,
  teamId,
}: MetricsQueryProps) => {
  const query = useQuery<IMetrics>(
    ["analytics", { teamId, metric, window_lower_bound, window_upper_bound }],
    () =>
      metricsService({
        metric,
        window_lower_bound,
        window_upper_bound,
        teamId,
      }),
  );

  return {
    ...query,
  };
};
