import React, { FC, ReactElement, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useUserState } from "context";
import { isNeedsWizard } from "utils/userStatus";
import { PAGES } from "constants/index";

const Layout: FC<{ children: ReactElement }> = ({ children }) => {
  const { currentTeam } = useUserState();
  const history = useHistory();

  useEffect(() => {
    if (isNeedsWizard(currentTeam?.wizard_step)) {
      history.push(PAGES.ONBOARDING);
    }
  }, []);

  return <div>{children}</div>;
};

export default Layout;
