import React, { FC, useEffect } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { Flex } from "ovou-ui";
import { theme } from "ovou-ui";

import { IContact } from "./types/Contact";

import { Table, Th, Thead, Tr, Tbody, Td } from "@chakra-ui/react";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";
import { TableNameColumn } from "./components/TableNameColumn";
import { TableTagsColumn } from "./components/TableTagsColumn";
import { TableDateColumn } from "./components/TableDateColumn";
import { TableViewDetailsColumn } from "./components/TableViewDetailsColumn";
import { Spinner } from "components";

const columnHelper = createColumnHelper<IContact>();

interface IGetContactsTableColumnsProps {
  onManageContactTags: (contact: IContact) => void;
}

const getContactsTableColumns = ({
  onManageContactTags,
}: IGetContactsTableColumnsProps) => [
  columnHelper.accessor("full_name", {
    header: "Name",
    size: 500,
    cell: ({ row }) => <TableNameColumn contactDetails={row.original} />,
  }),
  columnHelper.display({
    id: "tags",
    header: "Tags",
    size: 100,
    cell: ({ row }) => (
      <TableTagsColumn
        currentContact={row.original}
        onManageContactTags={onManageContactTags}
      />
    ),
  }),
  columnHelper.accessor("created", {
    header: "Date",
    size: 100,
    cell: ({ row }) => <TableDateColumn createdAt={row.original.created} />,
  }),
  columnHelper.display({
    id: "viewDetails",
    size: 10,
    cell: ({ row }) => <TableViewDetailsColumn contactId={row.original.id} />,
  }),
];

interface IContactTableProps {
  contacts: IContact[];
  isLoadingContacts?: boolean;
  onManageContactTags: (contact: IContact) => void;
  onSortStateChange: (sortingState: SortingState) => void;
}

export const ContactsTable: FC<IContactTableProps> = ({
  contacts,
  isLoadingContacts = false,
  onManageContactTags,
  onSortStateChange,
}) => {
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const table = useReactTable({
    data: contacts,
    columns: getContactsTableColumns({ onManageContactTags }),
    getCoreRowModel: getCoreRowModel(),
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    manualSorting: true,
  });

  useEffect(() => {
    onSortStateChange && onSortStateChange(sorting);
  }, [sorting]);

  return (
    <>
      <Table marginTop={6}>
        <Thead backgroundColor={theme.palette.ui.greys.grey5}>
          {table.getHeaderGroups().map(headerGroup => (
            <Tr key={headerGroup.id} height="3rem">
              {headerGroup.headers.map(header => {
                // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                const meta: any = header.column.columnDef.meta;
                return (
                  <Th
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    isNumeric={meta?.isNumeric}
                    style={{
                      width: header.getSize(),
                    }}
                    fontWeight="semibold"
                    fontSize="0.875rem"
                    textAlign="center"
                    borderColor={theme.palette.ui.greys.grey3}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                    {{
                      asc: (
                        <FiArrowDown
                          color={theme.palette.brand.primary.black}
                        />
                      ),
                      desc: (
                        <FiArrowUp color={theme.palette.brand.primary.black} />
                      ),
                    }[header.column.getIsSorted() as string] ?? null}
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map(row => (
            <Tr key={row.id} height="6rem">
              {row.getVisibleCells().map(cell => {
                // see https://tanstack.com/table/v8/docs/api/core/column-def#meta to type this correctly
                const meta: any = cell.column.columnDef.meta;
                return (
                  <Td
                    key={cell.id}
                    isNumeric={meta?.isNumeric}
                    borderColor={theme.palette.ui.greys.grey3}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                );
              })}
            </Tr>
          ))}
        </Tbody>
      </Table>

      {isLoadingContacts && (
        <Flex width="full" justifyContent="center" marginY={8}>
          <Spinner />
        </Flex>
      )}
    </>
  );
};
