import { FC, ReactElement, useEffect } from "react";

import { TopBar, PageBody, Helmet, Box } from "components";
import { useCheckPermission, useIsActionAllowed, useRouter } from "hooks";
import { FEATURES, FEATURE_ACTION } from "constants/features";

import UpgradeModal from "../../components/NewModal/UpgradeModal";

interface IPageSetup {
  title: string;
  feature: FEATURES;
  titleElement?: ReactElement;
  subTitle?: string;
  showBackArrow?: boolean;
  backUrl?: string;
  backArrowText?: string;
  centerElement?: ReactElement;
  rightElement?: ReactElement;
  rightBodyElement?: ReactElement;
  visibleInMobile?: boolean;
  children: ReactElement;
}

const PageSetup: FC<IPageSetup> = ({
  title = "",
  titleElement,
  subTitle = "",
  showBackArrow = false,
  backUrl,
  backArrowText,
  centerElement,
  rightElement,
  rightBodyElement,
  visibleInMobile,
  feature,
  children,
}) => {
  const { push, back } = useRouter();
  const { handleAction } = useIsActionAllowed();

  const isPageVisible: boolean = useCheckPermission(
    FEATURE_ACTION.VIEW,
    feature as FEATURES,
  );

  useEffect(() => {
    handleAction(isPageVisible, feature);
  }, [isPageVisible, feature]);

  return (
    <Box>
      <Helmet title={`OVOU | ${title}`} />
      <TopBar
        showBackArrow={showBackArrow}
        backArrowText={backArrowText}
        centerElement={centerElement}
        rightElement={rightElement}
        handleBackButton={() => (backUrl ? push(backUrl) : back())}
      />
      <PageBody
        title={title}
        titleElement={titleElement}
        subTitle={subTitle}
        rightBodyElement={rightBodyElement}
        visibleInMobile={visibleInMobile}
        blurContent={!isPageVisible}
      >
        <>
          {children}
          <UpgradeModal showUpgradeModal={!isPageVisible} />
        </>
      </PageBody>
    </Box>
  );
};

export default PageSetup;
