import {
  ChangeEvent,
  KeyboardEvent,
  useEffect,
  useMemo,
  useState,
} from "react";

import { IEmailObject, IUseMultiEmail } from "types/IMultiEmailInput";
import { isValidEmail } from "utils";

export const useMultiEmail = ({
  limit,
  userList = [],
  emailsEntered,
}: IUseMultiEmail) => {
  const [inputValue, setInputValue] = useState("");
  const [emails, setEmails] = useState([] as IEmailObject[]);
  const [isLimitExceeded, setIsLimitExceeded] = useState(false);
  const [emailsThatAlreadyExist, setEmailsThatAlreadyExist] = useState(
    [] as IEmailObject[],
  );
  useEffect(() => {
    if (Boolean(limit) && emails.length > limit!) {
      setIsLimitExceeded(true);
    } else {
      setIsLimitExceeded(false);
    }
  }, [emails, limit]);

  const emailChipExists = (email: string) =>
    emails.some(emailObject => emailObject.email === email);
  const emailExistsInList = (email: string) => userList.includes(email);

  const addEmails = (emailsToAdd: string[]) => {
    const validatedEmails = emailsToAdd
      .map(e => e.trim())
      .map(e => {
        return {
          email: e,
          alreadyExists: emailExistsInList(e),
        };
      })
      .filter(({ email }) => isValidEmail(email) && !emailChipExists(email));
    const newEmails = [...emails, ...validatedEmails];
    if (Boolean(limit) && newEmails.length > limit!) {
      setIsLimitExceeded(true);
    }
    setEmails(newEmails);
    setInputValue("");
    emailsEntered && emailsEntered(userList.length + newEmails.length);
  };

  const removeEmail = (email: string) => {
    const index = emails.findIndex(e => e.email === email);
    if (index !== -1) {
      const newEmails = [...emails];
      newEmails.splice(index, 1);
      setEmails(newEmails);
      emailsEntered && emailsEntered(userList.length + newEmails.length);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!isLimitExceeded) {
      setInputValue(e.target.value);
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (["Enter", "Tab", ","].includes(e.key) && isValidEmail(inputValue)) {
      e.preventDefault();

      addEmails([inputValue]);
    }
  };

  const handleBlur = () => {
    addEmails([inputValue]);
  };

  const handleCloseClick = (email: string) => {
    removeEmail(email);
  };

  const errorText = useMemo(() => {
    const existingEmails = emails.filter(
      ({ alreadyExists }) => alreadyExists === true,
    );
    setEmailsThatAlreadyExist(existingEmails);

    if (!Boolean(existingEmails.length)) {
      return "";
    }
    return existingEmails.length > 1
      ? "These users are already invited to the platform."
      : "This user is already invited to the platform.";
  }, [emails]);

  return {
    emails,
    setEmails,
    emailsThatAlreadyExist,
    errorText,
    inputValue,
    isLimitExceeded,
    handleChange,
    handleCloseClick,
    handleKeyDown,
    handleBlur,
  };
};
