import { useQuery } from "react-query";
import { ITeamUserTypeObject } from "types/TeamUsersResponse";

import api from "../../../api";

const teamsUserTypesService = async () => {
  const { data } = await api.get<ITeamUserTypeObject[]>(`/auth/user/types`);
  return data;
};

export const useTeamUserTypesQuery = () => {
  const query = useQuery<ITeamUserTypeObject[]>(
    [`user-types`],
    teamsUserTypesService,
  );

  return {
    ...query,
  };
};
