import React from "react";

import DefaultProfilePic from "./defaultProfile";

/* interface UserDetails {
  firstName?: string;
  lastName?: string;
  profileImage?: any;
}

interface TradeDetails {
  designation?: string;
  organization?: string;
  logo?: string;
}

interface ProfileCard {
  user?: UserDetails;
  trade?: TradeDetails;
  cardConfig: {
    width: number;
    template?: {
      backgroundColor?: string | undefined;
      fullNameForegroundColor?: string | undefined;
      careerForegroundColor?: string | undefined;
    };
  };
  canvasContext?: CanvasContext;
  isEditEnable?: boolean;
  EditUrl?: any;
  UpdateComponent?: any;
}

interface CanvasContext {
  callback?: Function;
  mode?: string;
}

interface profileCardDimensions {
  WIDTH: number;
  isLogo?: boolean;
  userDetails?: UserDetails;
  tradeDetails?: TradeDetails;
  context?: CanvasContext;
}

interface CalculateTextWidth {
  context: CanvasContext;
  string?: string;
  fontSize: number;
  fontWeight?: number;
} */

const NameStyle = WIDTH => {
  return {
    width: `${Math.floor(WIDTH * (519 / 1080))}px`,
    display: "inline-block",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    paddingBottom: "4px",
  };
};

export const ProfileCard = ({
  user,
  trade,
  cardConfig,
  canvasContext,
  UpdateComponent,
}) => {
  const WIDTH = cardConfig.width;
  const designs = cardConfig?.template;
  const isLogo = trade?.logo ? true : false;

  const {
    HEIGHT,
    PHOTO_WIDTH,
    BORDER_RADIUS,
    LOGO_DIMENSION,
    FULL_NAME_HEIGHT,
    CARD_CONTENT_PADDING,
    FULL_NAME_MARGIN_TOP,
    CAREER_CONTENT_HEIGHT,
    CAREER_CONTENT_MARGIN_TOP,
    CAREER_CONTENT_MARGIN_BOTTOM,
    FULL_NAME_MAX_FONT,
    MINIMUM_NAME_FONT_SIZE,
    CONTENT_WIDTH,
    MAXIMUM_CAREER_FONT_SIZE,
  } = profileCardDimensions({
    WIDTH: WIDTH,
    isLogo: isLogo,
    userDetails: user,
    tradeDetails: trade,
    context: canvasContext,
  });

  return (
    <div>
      <div
        style={{
          width: WIDTH,
          height: HEIGHT,
          borderRadius: `${BORDER_RADIUS}px`,
          boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.12)",
          color: designs?.fullNameForegroundColor || "#FFFFFF",
          background: designs?.backgroundColor || "#ffffff",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          {/* Profile Picture */}
          {UpdateComponent ? (
            UpdateComponent
          ) : user?.profileImage ? (
            <img
              style={{
                width: `${Math.ceil(PHOTO_WIDTH)}px`,
                height: `${HEIGHT}px`,
                borderTopLeftRadius: `${BORDER_RADIUS}px`,
                borderBottomLeftRadius: `${BORDER_RADIUS}px`,
              }}
              alt="Select Profile Pic"
              src={user?.profileImage}
            />
          ) : (
            <DefaultProfilePic
              BORDER_RADIUS={BORDER_RADIUS}
              width={PHOTO_WIDTH}
              height={HEIGHT}
            />
          )}

          {/* Profile Content */}
          <div
            style={{
              width: `${CONTENT_WIDTH}px`,
              height: Math.floor(WIDTH / (43 / 27)),
              paddingLeft: `${CARD_CONTENT_PADDING}px`,
              paddingRight: `${CARD_CONTENT_PADDING}px`,
              background: designs?.backgroundColor || "#ffffff",
              borderBottomRightRadius: `${BORDER_RADIUS}px`,
              borderTopRightRadius: `${BORDER_RADIUS}px`,
            }}
          >
            {/* FullName */}
            <div
              style={{
                marginTop: `${
                  FULL_NAME_MARGIN_TOP + CAREER_CONTENT_MARGIN_TOP
                }px`,
                height: FULL_NAME_HEIGHT,
                display: "flex",
                flexDirection: "column-reverse",
                fontFamily: "'Inter', sans-serif",
                fontStyle: "normal",
                fontWeight: 800,
                lineHeight: `${
                  MINIMUM_NAME_FONT_SIZE >= FULL_NAME_MAX_FONT
                    ? MINIMUM_NAME_FONT_SIZE
                    : FULL_NAME_MAX_FONT
                }px`,
                fontSize: `${
                  MINIMUM_NAME_FONT_SIZE >= FULL_NAME_MAX_FONT
                    ? MINIMUM_NAME_FONT_SIZE
                    : FULL_NAME_MAX_FONT
                }px`,
              }}
            >
              <div>
                <span style={NameStyle(WIDTH)}>
                  {user?.firstName}
                  <br />
                  {user?.lastName}
                </span>
              </div>
            </div>

            {/* Career Information */}
            <div
              style={{
                height: `${CAREER_CONTENT_HEIGHT}px`,
                fontFamily: "Inter",
                fontWeight: 600,
                opacity: 0.8,
                color: designs?.careerForegroundColor || "'#A1A1A1'",
                lineHeight: `${MAXIMUM_CAREER_FONT_SIZE + 1.5}px`,
                fontSize: `${MAXIMUM_CAREER_FONT_SIZE}px`,
                overflow: "hidden",
                display: "flex",
                // flexDirection: 'column-reverse',
                marginBottom: `${CAREER_CONTENT_MARGIN_BOTTOM}px`,
              }}
            >
              <span
                style={{
                  maxWidth: "100%",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 3,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {trade?.designation}
                <br />
                at&nbsp;{trade?.organization}
              </span>
            </div>

            {/* Company Logo */}
            {isLogo && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                }}
              >
                <img
                  style={{
                    width: `${LOGO_DIMENSION}px`,
                    height: `${LOGO_DIMENSION}px`,
                    objectFit: "fill",
                    borderRadius: "10%",
                  }}
                  alt="logo"
                  src={trade?.logo}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

// Todo: Optimize using memoisation
const calculateTextWidth = ({ context, string, fontSize, fontWeight = 800 }) =>
  context.mode === "node"
    ? typeof context.callback === "function" &&
      context.callback(string, fontSize)
    : getTextWidth(string, fontSize, fontWeight);

function getTextWidth(text, maxFontSize, fontWeight) {
  const canvas = document.createElement("canvas");
  let ctx;
  if (!(ctx = canvas.getContext("2d"))) {
    throw new Error(`2d context not supported or canvas already initialized`);
  }
  ctx.font = `${fontWeight} ${maxFontSize || 0}px Inter`;
  const metrics = ctx.measureText(text || "");
  return metrics.width;
}

// Todo: add memoisation prevent unnecessary calculation
export const profileCardDimensions = ({
  WIDTH,
  isLogo,
  userDetails,
  tradeDetails,
  context,
}) => {
  const BORDER_RADIUS = WIDTH * 0.05;
  const HEIGHT = Math.floor(WIDTH / (43 / 27));
  const PHOTO_WIDTH = Math.floor(WIDTH * (485 / 1080));
  const CONTENT_WIDTH = Math.floor(WIDTH * ((519 + 76) / 1080));
  const GOLDEN_NUMBER_MAXIMUM = 46 / 2; // 22.5 / 2;
  const DIFFERENCE_NUMBER_MAXIMUM = WIDTH - 270;
  const PIXEL_RATIO_MAXIMUM =
    (GOLDEN_NUMBER_MAXIMUM / 280) * DIFFERENCE_NUMBER_MAXIMUM;
  const MAXIMUM_NAME_FONT_SIZE = 23 + PIXEL_RATIO_MAXIMUM; // 11

  const GOLDEN_NUMBER_MAXIMUM_CAREER = 22.5 / 2; // 10.75 / 2;
  const DIFFERENCE_NUMBER_MAXIMUM_CAREER = WIDTH - 280;
  const PIXEL_RATIO_MAXIMUM_CAREER =
    (GOLDEN_NUMBER_MAXIMUM_CAREER / 280) * DIFFERENCE_NUMBER_MAXIMUM_CAREER;
  const MAXIMUM_CAREER_FONT_SIZE = 10.75 + PIXEL_RATIO_MAXIMUM_CAREER; // 11

  let maxFontSize = parseFloat(MAXIMUM_NAME_FONT_SIZE.toFixed(2));
  const originalFontSize = maxFontSize;

  const UpdatedContentWidth = Math.floor(WIDTH * (519 / 1080));

  const textWidthFirstName = calculateTextWidth({
    context: {
      callback: context?.callback,
      mode: context?.mode,
    },
    string: userDetails?.firstName,
    fontSize: maxFontSize,
  });

  const textWidthLastName = calculateTextWidth({
    context: {
      callback: context?.callback,
      mode: context?.mode,
    },
    fontSize: maxFontSize,
    string: userDetails?.lastName,
  });

  const text =
    textWidthFirstName >= textWidthLastName
      ? {
          width: textWidthFirstName,
          string: userDetails?.firstName,
        }
      : {
          width: textWidthLastName,
          string: userDetails?.lastName,
        };

  while (text.width >= UpdatedContentWidth) {
    text.width = calculateTextWidth({
      context: {
        callback: context?.callback,
        mode: context?.mode,
      },
      string: text?.string,
      fontSize: maxFontSize,
    });
    maxFontSize = maxFontSize - 0.1;
  }

  if (WIDTH > 580) {
    maxFontSize = 46;
  }

  const CARD_CONTENT_PADDING = Math.floor(WIDTH * (38 / 1080));

  const GOLDEN_NUMBER_MINIMUM = 26 / 2; // 22.5 / 2;
  const DIFFERENCE_NUMBER = WIDTH - 280;
  const PIXEL_RATIO_MINIMUM = (GOLDEN_NUMBER_MINIMUM / 280) * DIFFERENCE_NUMBER;
  const MINIMUM_NAME_FONT_SIZE = 12.5 + PIXEL_RATIO_MINIMUM; // 11
  const FULL_NAME_MAX_FONT = Math.floor(maxFontSize);

  const designationWidth = calculateTextWidth({
    context: {
      callback: context?.callback,
      mode: context?.mode,
    },
    string: tradeDetails?.designation,
    fontSize: MAXIMUM_CAREER_FONT_SIZE,
    fontWeight: 600,
  });

  const organizationWidth = calculateTextWidth({
    context: {
      callback: context?.callback,
      mode: context?.mode,
    },
    string: tradeDetails?.organization,
    fontSize: MAXIMUM_CAREER_FONT_SIZE,
    fontWeight: 600,
  });

  const isCareerDetailsLong =
    Math.ceil(designationWidth) > CONTENT_WIDTH - 2 * CARD_CONTENT_PADDING ||
    Math.ceil(organizationWidth) > CONTENT_WIDTH - 2 * CARD_CONTENT_PADDING
      ? true
      : false;

  // Full Name
  const FULL_NAME_MARGIN_TOP_LOGO = HEIGHT * (76 / 667);
  const FULL_NAME_MARGIN_TOP_WITHOUT = isCareerDetailsLong
    ? HEIGHT * (148 / 667)
    : HEIGHT * (168 / 667);
  const FULL_NAME_MARGIN_TOP = isLogo
    ? FULL_NAME_MARGIN_TOP_LOGO
    : FULL_NAME_MARGIN_TOP_WITHOUT;
  const FULL_NAME_HEIGHT = `${Math.floor(
    HEIGHT * ((202 - (originalFontSize - maxFontSize) * 1.5) / 667),
  )}px`;

  // Career Details
  const CAREER_CONTENT_HEIGHT_LOGO = isCareerDetailsLong
    ? Math.floor(HEIGHT * (140 / 667))
    : Math.floor(HEIGHT * (101 / 667));
  const CAREER_CONTENT_HEIGHT_WITHOUT = isCareerDetailsLong
    ? Math.floor(HEIGHT * (140 / 667))
    : Math.floor(HEIGHT * (101 / 667));
  const CAREER_CONTENT_HEIGHT = isLogo
    ? CAREER_CONTENT_HEIGHT_LOGO
    : CAREER_CONTENT_HEIGHT_WITHOUT;

  const CAREER_CONTENT_MARGIN_TOP_LOGO = isCareerDetailsLong
    ? HEIGHT * (28 / 667)
    : Math.floor(HEIGHT * (38 / 667));
  const CAREER_CONTENT_MARGIN_TOP_WITHOUT = isCareerDetailsLong
    ? HEIGHT * (28 / 667)
    : Math.floor(HEIGHT * (38 / 667));
  const CAREER_CONTENT_MARGIN_TOP = isLogo
    ? CAREER_CONTENT_MARGIN_TOP_LOGO
    : CAREER_CONTENT_MARGIN_TOP_WITHOUT;

  const CAREER_CONTENT_MARGIN_BOTTOM_LOGO = isCareerDetailsLong
    ? Math.floor(HEIGHT * (31 / 667))
    : Math.floor(HEIGHT * (70 / 667)); // TODO: actual value 82 instead of 70

  const CAREER_CONTENT_MARGIN_BOTTOM_WITHOUT = Math.floor(HEIGHT * (168 / 667));
  const CAREER_CONTENT_MARGIN_BOTTOM = isLogo
    ? CAREER_CONTENT_MARGIN_BOTTOM_LOGO
    : CAREER_CONTENT_MARGIN_BOTTOM_WITHOUT;

  const LOGO_DIMENSION = (WIDTH / (43 / 27)) * (164 / 677);

  return {
    HEIGHT,
    PHOTO_WIDTH,
    BORDER_RADIUS,
    LOGO_DIMENSION,
    FULL_NAME_HEIGHT,
    CARD_CONTENT_PADDING,
    FULL_NAME_MARGIN_TOP,
    CAREER_CONTENT_HEIGHT,
    CAREER_CONTENT_MARGIN_TOP,
    CAREER_CONTENT_MARGIN_BOTTOM,
    CONTENT_WIDTH,
    FULL_NAME_MAX_FONT,
    MINIMUM_NAME_FONT_SIZE,
    MAXIMUM_CAREER_FONT_SIZE,
  };
};
