import React from "react";

const Analytics = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6667 3C15.219 3 15.6667 3.44772 15.6667 4V20C15.6667 20.5523 15.219 21 14.6667 21C14.1144 21 13.6667 20.5523 13.6667 20V4C13.6667 3.44772 14.1144 3 14.6667 3ZM4 7.22989C4.55228 7.22989 5 7.6776 5 8.22989V20C5 20.5523 4.55228 21 4 21C3.44772 21 3 20.5523 3 20V8.22989C3 7.6776 3.44772 7.22989 4 7.22989ZM20 7.22989C20.5523 7.22989 21 7.6776 21 8.22989V20C21 20.5523 20.5523 21 20 21C19.4477 21 19 20.5523 19 20V8.22989C19 7.6776 19.4477 7.22989 20 7.22989ZM9.33333 12.1341C9.88562 12.1341 10.3333 12.5818 10.3333 13.1341V20C10.3333 20.5523 9.88562 21 9.33333 21C8.78105 21 8.33333 20.5523 8.33333 20V13.1341C8.33333 12.5818 8.78105 12.1341 9.33333 12.1341Z"
      />
    </svg>
  );
};

export default React.memo(Analytics);
