import React, { FC, useEffect, useMemo, useRef, useState } from "react";

import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  TabProps,
  Tabs,
  Text,
  TypographyProps,
} from "@chakra-ui/react";

interface ITab {
  title: string;
  content: React.ReactNode;
  track?: string;
}
interface ITabNavigationProps {
  tabs: ITab[];
  activeTab?: number;
  fontSize?: TypographyProps["fontSize"];
  tabProps?: TabProps;
  onTabChange?: (index: number) => void;
}

const TabNavigation: FC<ITabNavigationProps> = ({
  tabs,
  activeTab = 0,
  fontSize,
  tabProps,
  onTabChange,
}) => {
  const tabRefs = useRef<Array<HTMLDivElement | null>>([]);
  const [internalActiveTab, setInternalActiveTab] = useState(
    activeTab ? activeTab : 0,
  );

  const setTabRef = (index: number, ref: HTMLDivElement | null): void => {
    tabRefs.current[index] = ref;
  };

  const handleTabChange = (currentIndex: number): void => {
    setInternalActiveTab(currentIndex);
    if (onTabChange) {
      onTabChange(currentIndex);
    }
  };

  useEffect(() => {
    setInternalActiveTab(activeTab);
  }, [activeTab]);

  useEffect(() => {
    if (tabRefs.current[internalActiveTab]) {
      tabRefs.current[internalActiveTab]?.scrollTo(0, 0);
    }
  }, [internalActiveTab]);

  return (
    <Tabs
      index={internalActiveTab}
      onChange={handleTabChange}
      align="start"
      sx={{
        "@media (max-width: 360px)": {
          ".chakra-tabs__tablist": {
            overflowX: "scroll",
            overflowY: "hidden",
            whiteSpace: "nowrap",
          },
        },
      }}
      isLazy
    >
      <TabList>
        {tabs.map(tab => (
          <Tab
            key={tab.title}
            {...tabProps}
            outline="none"
            _focus={{ outline: "none" }}
          >
            <Text fontSize={fontSize || 16} fontWeight="800">
              {tab.title}
            </Text>
          </Tab>
        ))}
      </TabList>
      <TabPanels>
        {tabs.map((tab, index) => (
          <TabPanel key={tab.title} padding="0">
            <div ref={ref => setTabRef(index, ref)}>
              {internalActiveTab === index && tab.content}
            </div>
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

export default TabNavigation;
