import React, { useMemo } from "react";

import { Link, Redirect } from "react-router-dom";

import logo from "../assets/images/logo.svg";
import { routes } from "../routes";
import { getToken } from "../utils";

const AuthLayout = ({ children }) => {
  const { accessToken } = useMemo(() => getToken(), []);

  return !accessToken ? (
    <div className="bg-white h-screen px-4 py-8">
      <div className="flex flex-col max-w-lg m-auto">
        <div className="bg-white">
          <Link to="/">
            <img src={logo} alt="Logo" />
          </Link>
        </div>
        {children}
      </div>
    </div>
  ) : (
    <Redirect to={routes[0].path} />
  );
};

export default AuthLayout;
