import React from "react";

import BeatLoader from "react-spinners/BeatLoader";
import styled from "styled-components";

const StyledSpinner = styled.div`
  min-width: 10%;
  min-height: 10%;
  text-align: center;
  margin: 0 auto;
`;

const Spinner = ({
  size = 10,
  sizeUnit = "px",
  color = "#000",
  className = "",
}) => {
  return (
    <StyledSpinner className={className}>
      <BeatLoader size={`${size}${sizeUnit}`} color={color} />
    </StyledSpinner>
  );
};

export default Spinner;
