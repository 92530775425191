import styled from "styled-components";

import { theme } from "../../theme";

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: ${({ maxWidth }) => maxWidth || "575px"};
  width: ${({ width }) => width || "100%"};
  min-height: ${({ height }) => height || "50px"};
  background: ${({ background, isDisabled }) =>
    isDisabled
      ? theme.palette.ui.greys.grey3
      : background || theme.palette.brand.primary.orange};
  border-radius: ${({ borderRadius }) => borderRadius || "10px"};
  border: ${({ border }) => border || "transparent"};
  margin: ${({ margin }) => margin};
  color: ${({ color }) => color};
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  box-sizing: border-box;
  font-weight: bold;
  box-shadow: ${({ boxShadow, isDisabled }) =>
    isDisabled
      ? "none"
      : boxShadow || " 0 6px 15px 0 rgb(255 101 58 / 45%) !important"};
  cursor: ${({ cursor }) => cursor || "pointer"};
  position: ${({ position }) => position};
  top: ${({ top }) => top};
  bottom: ${({ bottom }) => bottom};
  left: ${({ left }) => left};
  right: ${({ right }) => right};

  &:focus {
    outline: none;
  }
  &:hover {
    box-shadow: none;
    background: ${({ hoverBackground, isDisabled }) =>
      isDisabled ? theme.palette.ui.greys.grey3 : hoverBackground};
    color: ${({ hoverColor }) => hoverColor};
  }
`;
