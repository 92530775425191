import React from "react";

import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.75rem;
  width: 4rem;
  height: 4rem;
  --bg-opacity: 1;
  background-color: rgba(245, 245, 245, var(--bg-opacity));
  &:hover {
    background-color: rgba(226, 232, 240, var(--bg-opacity));
  }
  .inner {
    display: flex;
    justify-content: center;
    padding: 0.5rem;
    width: 3rem;
    height: 3rem;

    &__img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
`;

const SocialMediaItem = ({ socialMediaItem }) => {
  const { field_type } = socialMediaItem;
  const isFullURL = url => {
    const pattern = new RegExp(
      /((?:https?:\/\/|www\.)(?:[-a-z0-9]+\.)*[-a-z0-9]+.*)/,
      "i",
    );

    return pattern.test(url);
  };
  const link = isFullURL(socialMediaItem?.value || "")
    ? socialMediaItem?.value
    : `${field_type?.base_value || ""}${socialMediaItem?.value}`;

  return (
    <Wrapper>
      <a href={link}>
        <div className="inner">
          <img
            src={field_type.icon}
            alt={socialMediaItem.field_type.id}
          />
        </div>
      </a>
    </Wrapper>
  );
};

export default SocialMediaItem;
