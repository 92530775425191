import { Axios } from "axios";

type GetUpgradeUrlParams = {
    cancelUrl: string;
    successUrl: string;
    currency: string;
    interval?: string;
    teamId?: number;
};

type GetUpgradeUrlResponse = {
    url: string;
};

const URLS = {
    UPGRADE_PORTAL: "/payments/upgrade",
};

export class PaymentsAPIClient {
    constructor(private client: Axios) { }

    public getUpgradeURL = async (
        params: GetUpgradeUrlParams,
    ): Promise<string> => {
        const response = await this.client.post<GetUpgradeUrlResponse>(
            URLS.UPGRADE_PORTAL,
            {
                cancel_url: params.cancelUrl,
                success_url: params.successUrl,
                team_id: params.teamId,
                interval: params.interval,
                currency: params.currency,
            },
        );
        return response.data.url;
    };
}
