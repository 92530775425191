import React from "react";

// type DefaultProfilePic = {
//   width?: number;
//   height?: number;
// };

const DefaultProfilePic = ({ width = "154", height = "215" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 154 215"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 10C0 4.47716 4.47715 0 10 0H154V215H10C4.47716 215 0 210.523 0 205V10Z"
        fill="#292929"
      />
      <path
        opacity="0.4"
        d="M77.0005 134C95.2248 134 110 116.315 110 94.5002C110 72.6846 105.149 55 77.0005 55C48.8518 55 44 72.6846 44 94.5002C44 116.315 58.7752 134 77.0005 134Z"
        fill="#686868"
      />
      <path
        opacity="0.4"
        d="M137.932 193.151C137.334 154.831 132.405 143.912 94.6861 137C94.6861 137 89.3766 143.869 77.0014 143.869C64.6261 143.869 59.3157 137 59.3157 137C22.0089 143.836 16.7801 154.593 16.0926 191.906C16.0362 194.953 16.0101 195.113 16 194.759C16.0023 195.422 16.0926 215 16.0926 215H77H138C138 215 137.999 196.457 138 195.807C137.99 196.026 137.97 195.602 137.932 193.151Z"
        fill="#686868"
      />
    </svg>
  );
};

export default DefaultProfilePic;
