import {
  BackgroundUploadFormField,
  Button,
  LogoUploadFormField,
  SchemePickerFormField,
  Section,
  Spinner,
} from "components";
import { useUserDispatch, useUserState, setProfile } from "context";
import { useProfileDataQuery } from "features/auth/queries";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import React, { FC, useEffect, useState } from "react";
import { addSharpToColorHex, generateMediaPath, getCardBG } from "utils";
import {
  addProfileDesignQuery,
  useCardDesignsQuery,
  useUpdateProfileQuery,
} from "./queries";
import classNames from "classnames";
import { useTracking } from "hooks";
import { ANALYTICS_EVENTS } from "constants/analyticEvents";
import useProfilePermission from "hooks/useProfilePermission";

const validationSchema = yup.object({});

const BrandingForm: FC = () => {
  const { canViewLogo, canViewCover, canViewCustomColor } =
    useProfilePermission();
  const track = useTracking();
  const { getProfileData } = useProfileDataQuery({});
  const dispatch = useUserDispatch();
  const { currentProfile, currentTeam } = useUserState();
  const { data: cardDesigns, refetch } = useCardDesignsQuery({
    teamId: currentTeam?.id,
  });
  const [defaultColorSchema, setDefaultColorScheme] = useState(
    cardDesigns?.designs,
  );

  const { mutate: updateProfile, isLoading } = useUpdateProfileQuery({
    onSuccess: async () => {
      const data = await getProfileData();
      if (data) dispatch(setProfile(data?.profiles[0]));
    },
  });

  useEffect(() => {
    if (currentProfile && cardDesigns) {
      let selectedId =
        currentProfile?.design?.id ?? (cardDesigns?.designs?.[0].id || 1);
      let cardDesign = cardDesigns?.designs.filter(
        (item: { id: number }) => item.id === selectedId,
      );
      setDefaultColorScheme(cardDesign[0]);
    }
  }, [cardDesigns, currentProfile]);
  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        logo: currentProfile?.logo
          ? generateMediaPath(currentProfile?.logo || "") || ""
          : "",
        card_design_id: currentProfile?.design?.id,
        background: currentProfile?.background
          ? generateMediaPath(currentProfile?.background || "") || ""
          : "",
        cardSchema:
          currentProfile?.design?.id ?? (cardDesigns?.designs?.[0].id || 1),
      }}
      onSubmit={async ({ cardSchema, ...values }) => {
        const isCardSchemaObject = typeof cardSchema == "object";
        const cardDesignId = cardDesigns?.designs?.find(
          (design: { id: any }) =>
            design.id === (isCardSchemaObject ? cardSchema?.id : cardSchema),
        )?.id;

        if (isCardSchemaObject) {
          await addProfileDesignQuery({ cardSchema });
          refetch();
        }

        updateProfile(
          {
            profileData: {
              ...values,
              card_design_id: cardDesignId,
            },
            handle: currentProfile?.handle,
          },
          {
            onSuccess: () => {
              track({
                eventName: ANALYTICS_EVENTS.SETTINGS_SAVE_BRANDING_CHANGES,
                customTrackingProps: {
                  values,
                },
              });
            },
          },
        );
      }}
      enableReinitialize
    >
      {({ setFieldValue, values, isValid, dirty }) => (
        <Form autoComplete="off">
          <Section
            label="Logo"
            subLabel="For best result, upload PNG file with transparent background. Ratio is 1:1"
            showBorder={false}
          >
            <Field
              component={LogoUploadFormField}
              label="Logo"
              name="logo"
              background={getCardBG(
                cardDesigns?.designs?.find(
                  (design: { id: any }) => design?.id === values?.cardSchema,
                ),
              )}
              onLogoDelete={() => setFieldValue("logo", null)}
            />
          </Section>

          <Section
            label="Cover photo"
            subLabel="Customize your profiles by adding cover photo. Ratio is 16x9"
            showBorder={false}
          >
            <Field
              component={BackgroundUploadFormField}
              label="background-image"
              name="background"
              onBGDelete={() => setFieldValue("background", null)}
              background={addSharpToColorHex(
                cardDesigns?.designs?.find(
                  (design: { id: any }) => design?.id === values?.cardSchema,
                )?.foreground_active_color,
              )}
            />
          </Section>

          <Section
            label="Change your profile design"
            subLabel="Select from our templates."
            showBorder={false}
          >
            <Field
              component={SchemePickerFormField}
              label="Profile URL"
              name="cardSchema"
              schemes={cardDesigns?.designs}
              setDefaultColorScheme={setDefaultColorScheme}
            />
          </Section>

          <div
            className={classNames({
              "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center mt-6 sm:mt-5 md:mt-12":
                true,
            })}
          >
            <div className="col-start-2">
              <Button
                disabled={
                  isLoading || !isValid || !dirty || !currentProfile?.handle
                }
                size="small"
                block
                data-testid="company-visual-save-button"
              >
                {isLoading ? <Spinner color="white" /> : "Save Changes"}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default BrandingForm;
