import { useMutation } from "react-query";

import api from "../../../api";

const passwordChangeService = async ({ formData }) => {
  const { data } = await api.post("/auth/password/change", { ...formData });
  return data;
};

export const usePasswordChangeMutation = ({ onSuccess, onError }) => {
  return useMutation(passwordChangeService, {
    onSuccess,
    onError,
  });
};
