import { FC } from "react";
import { Flex, Image, Text } from "components";
import { ISingleTeam } from "../../types/ITeamSelector";

import { theme } from "ovou-ui";

const SingleTeam: FC<ISingleTeam> = ({ title, logo, background }) => {
  return (
    <Flex
      flexDirection="column"
      alignItems="flex-start"
      marginTop={4}
      paddingX={4}
    >
      <Flex
        justifyContent="flex-start"
        alignItems="center"
        width="12rem"
        height="8.25rem"
        overflow="hidden"
      >
        <Image
          maxHeight={logo ? "full" : "24"}
          background={logo ? background : "transparent"}
          backgroundSize="cover"
          borderRadius="md"
          src={logo}
          alt={logo ? "Brand Logo" : "Default Logo"}
        />
      </Flex>
      <Text
        textColor={theme.palette.brand.primary.black}
        fontSize="xl"
        lineHeight="base"
        fontWeight="extrabold"
      >
        {title}
      </Text>
    </Flex>
  );
};

export default SingleTeam;
