import React, { CSSProperties, useCallback, useState } from "react";
import { FormLabel, Input } from "@chakra-ui/react";
import { theme } from "ovou-ui";
import { useMediaLayout } from "use-media";

import { Flex, Grid, Icon, Text } from "../../../components";
import { COVER_MAX_WIDTH, LOGO_MAX_WIDTH } from "../../../constants";
import CropperModal from "../../../components/CropperModal";
import { imageRatio, imageRatioOptions, ImageType } from "../helpers/constants";

const IconComponent = Icon as any;

const ImageUploadFormField = ({
  field,
  form,
  onChange,
  onDelete,
  imageType,
}: any) => {
  const isMobile = useMediaLayout({ maxWidth: "768px" });

  const [image, setImage] = useState<string>();
  const [isCropperModalOpen, setIsCropperModalOpen] = useState<boolean>(false);

  const handleInputChange = useCallback((e: any) => {
    e.preventDefault();
    if (!e.target.files?.length) return;
    setImage(URL.createObjectURL(e.target.files[0]));
    setIsCropperModalOpen(true);

    // Clear the input value to force a change so the same image can be uploadad as many times as wanted.
    // Otherwise the onChange handler won't trigger as the target value would be the same.
    e.target.value = "";
  }, []);

  const handleDelete = () => {
    form.setFieldValue(field.name, null);
    setImage(undefined);
    onDelete();
  };

  const setCroppedImage = useCallback(
    (croppedImage: string) => {
      setImage(croppedImage);

      if (!!croppedImage) {
        form.setFieldValue(field.name, croppedImage);
        onChange(croppedImage);
      }
    },
    [form],
  );

  const hasImage = image || field.value;

  return (
    <>
      <>
        <Grid templateColumns={`repeat(${hasImage ? 2 : 1}, 1fr)`} gap={4}>
          <FormLabel
            width="full"
            htmlFor={field.name}
            cursor="pointer"
            margin={0}
          >
            <Flex
              width="full"
              height={24}
              justifyContent="center"
              alignItems="center"
              padding={6}
              backgroundColor={theme.palette.ui.greys.grey5}
              borderColor={theme.palette.ui.greys.grey4}
              borderWidth="1px"
              borderRadius="10px"
            >
              <IconComponent name="upload" />
              <Text fontSize="14px" fontWeight="semibold" marginLeft={2}>
                Upload Image
              </Text>
            </Flex>
          </FormLabel>

          {hasImage && (
            <Flex
              width="full"
              height={24}
              justifyContent="center"
              alignItems="center"
              padding={6}
              backgroundColor="#e94647"
              borderRadius="10px"
              cursor="pointer"
              onClick={handleDelete}
              sx={{
                svg: {
                  color: theme.palette.brand.primary.white,
                },
              }}
            >
              <IconComponent name="delete" />
              <Text
                fontSize="14px"
                fontWeight="semibold"
                marginLeft={2}
                textColor={theme.palette.brand.primary.white}
              >
                Delete Image
              </Text>
            </Flex>
          )}
        </Grid>
        <Input
          type="file"
          accept=".png, .jpg, .jpeg"
          id={field.name}
          className="invisible w-0 h-0 absolute left-0 top-0"
          onChange={handleInputChange}
        />
      </>
      {isCropperModalOpen && (
        <CropperModal
          closable
          isOpen={isCropperModalOpen}
          onClose={() => setIsCropperModalOpen(false)}
          image={image}
          getCroppedImage={setCroppedImage}
          title="Crop Your Image"
          format="png"
          maxWidth={
            imageType === ImageType.LOGO ? LOGO_MAX_WIDTH : COVER_MAX_WIDTH
          }
          restrictPosition={false}
          ratio={imageRatio[imageType]}
          ratioOptions={imageRatioOptions[imageType] as any}
          customCropperStyles={
            isMobile
              ? ({
                  width: "100%",
                } as CSSProperties)
              : {}
          }
        />
      )}
    </>
  );
};

export default ImageUploadFormField;
