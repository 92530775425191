import React from "react";

const Delete = ({
  className,
  role,
  ignoreDefaultSize,
  width = 27,
  height = 27,
  strokeWidth,
  onClick,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={ignoreDefaultSize ? undefined : width}
      height={ignoreDefaultSize ? undefined : height}
      viewBox="0 0 27 27"
      role={role}
      className={className}
      strokeWidth={strokeWidth}
      onClick={onClick}
    >
      <path
        d="M16.594 12H3"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="currentColor"
        transform="translate(2.513 1.594)"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="currentColor"
        d="M3.947 14.851a8.2 8.2 0 1 0 0-7.293"
        transform="translate(5.431 2.389)"
      />
      <path
        d="M12 9l3.4 3.4-3.4 3.4"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        transform="translate(3.708 1.195)"
      />
    </svg>
  );
};

export default React.memo(Delete);
