import { TEventProperties } from "context/Analytics/types";

export enum ANALYTICS_EVENTS {
  TEAM_LIMITED_OWNER_UPGRADE = "Team_Limited_Owner_Upgrade",
  TEAM_LIMITED_ADMIN_UPGRADE = "Team_Limited_Admin_Upgrade",
  TEAM_ACTIVE_ADMIN_MANAGE_SUBSCRIPTION = "Team_Active_Admin_Manage_Subscription",
  TEAM_LEGACY_OWNER_UPGRADE = "Team_Legacy_Owner_Upgrade",
  TEAM_LEGACY_ADMIN_UPGRADE = "Team_Legacy_Admin_Upgrade",
  TEAM_MANAGE_USERS = "TEAM_MANAGE_USERS",
  TEAM_CONTACTS = "TEAM_CONTACTS",
  TEAM_ANALYTICS = "TEAM_ANALYTICS",
  TEAM_GROUPS = "TEAM_GROUPS",
  TEAM_SETTINGS = "TEAM_SETTINGS",
  TEAM_COMPANY_PROFILE = "TEAM_COMPANY_PROFILE",
  TEAM_MANAGE_DEVICES = "TEAM_MANAGE_DEVICES",
  TEAM_INTEGRATIONS = "TEAM_INTEGRATIONS",
  TEAM_TUTORIALS = "TEAM_TUTORIALS",
  TEAM_ONBOARDING_BUSINESS_DETAILS_SUBMIT = "TEAM_ONBOARDING_BUSINESS_DETAILS_SUBMIT",
  TEAM_ONBOARDING_BUSINESS_DESIGN_UPLOAD_LOGO = "TEAM_ONBOARDING_BUSINESS_DESIGN_UPLOAD_LOGO",
  TEAM_ONBOARDING_BUSINESS_DESIGN_DELETE_LOGO = "TEAM_ONBOARDING_BUSINESS_DESIGN_DELETE_LOGO",
  TEAM_ONBOARDING_BUSINESS_DESIGN_UPLOAD_COVER = "TEAM_ONBOARDING_BUSINESS_DESIGN_UPLOAD_COVER",
  TEAM_ONBOARDING_BUSINESS_DESIGN_DELETE_COVER = "TEAM_ONBOARDING_BUSINESS_DESIGN_DELETE_COVER",
  TEAM_ONBOARDING_BUSINESS_DESIGN_SELECT_PROFILE_DESIGN = "TEAM_ONBOARDING_BUSINESS_DESIGN_SELECT_PROFILE_DESIGN",
  TEAM_ONBOARDING_BUSINESS_DESIGN_SUBMIT = "TEAM_ONBOARDING_BUSINESS_DESIGN_SUBMIT",
  TEAM_ONBOARDING_BUSINESS_DESIGN_SKIP = "TEAM_ONBOARDING_BUSINESS_DESIGN_SKIP",
  TEAM_ONBOARDING_ADMIN_PROFILE_UPLOAD_PROFILE_PICTURE = "TEAM_ONBOARDING_ADMIN_PROFILE_UPLOAD_PROFILE_PICTURE",
  TEAM_ONBOARDING_ADMIN_PROFILE_DELETE_PROFILE_PICTURE = "TEAM_ONBOARDING_ADMIN_PROFILE_DELETE_PROFILE_PICTURE",
  TEAM_ONBOARDING_ADMIN_PROFILE_SUBMIT = "TEAM_ONBOARDING_ADMIN_PROFILE_SUBMIT",
  TEAM_ONBOARDING_ADMIN_PROFILE_SKIP = "TEAM_ONBOARDING_ADMIN_PROFILE_SKIP",
  TEAM_ONBOARDING_ASSIGN_SEATS_SUBMIT = "TEAM_ONBOARDING_ASSIGN_SEATS_SUBMIT",
  TEAM_ONBOARDING_ASSIGN_SEATS_SKIP = "TEAM_ONBOARDING_ASSIGN_SEATS_SKIP",
  TEAM_ONBOARDING_GO_TO_PREVIOUS_STEP = "TEAM_ONBOARDING_GO_TO_PREVIOUS_STEP",
  MANAGE_USERS_OPEN_INVITE_USERS_MODAL = "OPEN_INVITE_USERS_MODAL",
  MANAGE_USERS_INVITE_USERS = "INVITE_USERS",
  MANAGE_USERS_OPEN_CHANGE_ROLE_MODAL = "OPEN_CHANGE_ROLE_MODAL",
  MANAGE_USERS_CHANGE_ROLE = "CHANGE_ROLE",
  MANAGE_USERS_EDIT_PROFILE = "EDIT_PROFILE",
  MANAGE_USERS_SETUP_PROFILE = "SETUP_PROFILE",
  MANAGE_USERS_RESEND_INVITE = "RESEND_INVITE",
  MANAGE_USERS_OPEN_CHANGE_INVITATION_MODAL = "OPEN_CHANGE_INVITATION_MODAL",
  MANAGE_USERS_CHANGE_INVITATION = "CHANGE_INVITATION",
  ANALYTICS_INTERACTIONS = "ANALYTICS_INTERACTIONS",
  ANALYTICS_SAVE_CONTACTS = "ANALYTICS_SAVE_CONTACTS",
  ANALYTICS_EXCHANGE_CONTACT = "ANALYTICS_EXCHANGE_CONTACT",
  ANALYTICS_SOCIAL_CLICKS = "ANALYTICS_SOCIAL_CLICKS",
  ANALYTICS_LINK_CLICKS = "ANALYTICS_LINK_CLICKS",
  ANALYTICS_ALL_TIME = "ANALYTICS_ALL_TIME",
  ANALYTICS_CURRENT_MONTH = "ANALYTICS_CURRENT_MONTH",
  ANALYTICS_PREV_MONTH = "ANALYTICS_PREV_MONTH",
  ANALYTICS_LAST_YEAR = "ANALYTICS_LAST_YEAR",
  ANALYTICS_CUSTOM_DATE_RANGE = "ANALYTICS_CUSTOM_DATE_RANGE",
  GROUPS_OPEN_GROUP_MODAL = "GROUPS_OPEN_GROUP_MODAL",
  GROUPS_CREATE_NEW_GROUP = "GROUPS_CREATE_NEW_GROUP",
  GROUPS_DELETE_GROUP = "GROUPS_DELETE_GROUP",
  SETTINGS_SAVE_BRANDING_CHANGES = "SETTINGS_SAVE_BRANDING_CHANGES",
  SETTINGS_SAVE_COMPANY_DETAILS_CHANGES = "SETTINGS_SAVE_COMPANY_DETAILS_CHANGES",
  SETTINGS_SAVE_ACCOUNT_CHANGES = "SETTINGS_SAVE_ACCOUNT_CHANGES",
  COMPANY_PROFILE_PUBLIC_VIEW = "COMPANY_PROFILE_PUBLIC_VIEW",
  COMPANY_PROFILE_EDIT_PROFILE = "COMPANY_PROFILE_EDIT_PROFILE",
  COMPANY_PROFILE_ASSIGN_CARD = "COMPANY_PROFILE_ASSIGN_CARD",
  COMPANY_PROFILE_MEET_THE_TEAM = "COMPANY_PROFILE_MEET_THE_TEAM",
  COMPANY_PROFILE_BUSINESS_PAGE_URL = "COMPANY_PROFILE_BUSINESS_PAGE_URL",
}

export const trackingEvents: Record<
  ANALYTICS_EVENTS,
  { action: string; category: string }
> = {
  [ANALYTICS_EVENTS.TEAM_LIMITED_OWNER_UPGRADE]: {
    action: "limited-owner-upgrade",
    category: "upgrade",
  },
  [ANALYTICS_EVENTS.TEAM_LIMITED_ADMIN_UPGRADE]: {
    action: "limited-admin-upgrade",
    category: "upgrade",
  },
  [ANALYTICS_EVENTS.TEAM_ACTIVE_ADMIN_MANAGE_SUBSCRIPTION]: {
    action: "active-admin-manage-subscription",
    category: "upgrade",
  },
  [ANALYTICS_EVENTS.TEAM_LEGACY_OWNER_UPGRADE]: {
    action: "legacy-owner-upgrade",
    category: "upgrade",
  },
  [ANALYTICS_EVENTS.TEAM_LEGACY_ADMIN_UPGRADE]: {
    action: "legacy-admin-upgrade",
    category: "upgrade",
  },
  [ANALYTICS_EVENTS.TEAM_ANALYTICS]: {
    action: "analytics",
    category: "sidebar-menu",
  },
  [ANALYTICS_EVENTS.TEAM_MANAGE_USERS]: {
    action: "manage-users",
    category: "sidebar-menu",
  },
  [ANALYTICS_EVENTS.TEAM_CONTACTS]: {
    action: "contacts",
    category: "sidebar-menu",
  },
  [ANALYTICS_EVENTS.TEAM_GROUPS]: {
    action: "groups",
    category: "sidebar-menu",
  },
  [ANALYTICS_EVENTS.TEAM_SETTINGS]: {
    action: "settings",
    category: "sidebar-menu",
  },
  [ANALYTICS_EVENTS.TEAM_COMPANY_PROFILE]: {
    action: "company-profile",
    category: "sidebar-menu",
  },
  [ANALYTICS_EVENTS.TEAM_MANAGE_DEVICES]: {
    action: "manage-devices",
    category: "sidebar-menu",
  },
  [ANALYTICS_EVENTS.TEAM_INTEGRATIONS]: {
    action: "integrations",
    category: "sidebar-menu",
  },
  [ANALYTICS_EVENTS.TEAM_TUTORIALS]: {
    action: "tutorials",
    category: "sidebar-menu",
  },
  [ANALYTICS_EVENTS.TEAM_ONBOARDING_BUSINESS_DETAILS_SUBMIT]: {
    action: "Submit business details",
    category: "onboarding",
  },
  [ANALYTICS_EVENTS.TEAM_ONBOARDING_BUSINESS_DESIGN_UPLOAD_LOGO]: {
    action: "Upload logo",
    category: "onboarding",
  },
  [ANALYTICS_EVENTS.TEAM_ONBOARDING_BUSINESS_DESIGN_DELETE_LOGO]: {
    action: "Delete logo",
    category: "onboarding",
  },
  [ANALYTICS_EVENTS.TEAM_ONBOARDING_BUSINESS_DESIGN_UPLOAD_COVER]: {
    action: "Upload cover",
    category: "onboarding",
  },
  [ANALYTICS_EVENTS.TEAM_ONBOARDING_BUSINESS_DESIGN_DELETE_COVER]: {
    action: "Delete cover",
    category: "onboarding",
  },
  [ANALYTICS_EVENTS.TEAM_ONBOARDING_BUSINESS_DESIGN_SELECT_PROFILE_DESIGN]: {
    action: "Select profile design",
    category: "onboarding",
  },
  [ANALYTICS_EVENTS.TEAM_ONBOARDING_BUSINESS_DESIGN_SUBMIT]: {
    action: "Submit business design",
    category: "onboarding",
  },
  [ANALYTICS_EVENTS.TEAM_ONBOARDING_BUSINESS_DESIGN_SKIP]: {
    action: "Skip business design",
    category: "onboarding",
  },
  [ANALYTICS_EVENTS.TEAM_ONBOARDING_ADMIN_PROFILE_UPLOAD_PROFILE_PICTURE]: {
    action: "Upload profile picture",
    category: "onboarding",
  },
  [ANALYTICS_EVENTS.TEAM_ONBOARDING_ADMIN_PROFILE_DELETE_PROFILE_PICTURE]: {
    action: "Delete profile picture",
    category: "onboarding",
  },
  [ANALYTICS_EVENTS.TEAM_ONBOARDING_ADMIN_PROFILE_SUBMIT]: {
    action: "Submit admin profile",
    category: "onboarding",
  },
  [ANALYTICS_EVENTS.TEAM_ONBOARDING_ADMIN_PROFILE_SKIP]: {
    action: "Skip admin profile",
    category: "onboarding",
  },
  [ANALYTICS_EVENTS.TEAM_ONBOARDING_ASSIGN_SEATS_SUBMIT]: {
    action: "Submit assign seats",
    category: "onboarding",
  },
  [ANALYTICS_EVENTS.TEAM_ONBOARDING_ASSIGN_SEATS_SKIP]: {
    action: "Skip assign seats",
    category: "onboarding",
  },
  [ANALYTICS_EVENTS.TEAM_ONBOARDING_GO_TO_PREVIOUS_STEP]: {
    action: "Go to previous step",
    category: "onboarding",
  },
  [ANALYTICS_EVENTS.MANAGE_USERS_OPEN_INVITE_USERS_MODAL]: {
    action: "clicked on invite user button to open modal",
    category: "manage-users",
  },
  [ANALYTICS_EVENTS.MANAGE_USERS_INVITE_USERS]: {
    action: "invite users",
    category: "manage-users",
  },
  [ANALYTICS_EVENTS.MANAGE_USERS_OPEN_CHANGE_ROLE_MODAL]: {
    action: "clicked on change role to open modal",
    category: "manage-users",
  },
  [ANALYTICS_EVENTS.MANAGE_USERS_CHANGE_ROLE]: {
    action: "changed role",
    category: "manage-users",
  },
  [ANALYTICS_EVENTS.MANAGE_USERS_EDIT_PROFILE]: {
    action: "click on edit profile",
    category: "manage-users",
  },
  [ANALYTICS_EVENTS.MANAGE_USERS_SETUP_PROFILE]: {
    action: "click on setup profile",
    category: "manage-users",
  },
  [ANALYTICS_EVENTS.MANAGE_USERS_RESEND_INVITE]: {
    action: "Resend invitation",
    category: "manage-users",
  },
  [ANALYTICS_EVENTS.MANAGE_USERS_OPEN_CHANGE_INVITATION_MODAL]: {
    action: "click on change button to open modal",
    category: "manage-users",
  },
  [ANALYTICS_EVENTS.MANAGE_USERS_CHANGE_INVITATION]: {
    action: "change invitation",
    category: "manage-users",
  },
  [ANALYTICS_EVENTS.ANALYTICS_INTERACTIONS]: {
    action: "clicked on interactions",
    category: "analytics",
  },
  [ANALYTICS_EVENTS.ANALYTICS_SAVE_CONTACTS]: {
    action: "clicked on save contact",
    category: "analytics",
  },
  [ANALYTICS_EVENTS.ANALYTICS_EXCHANGE_CONTACT]: {
    action: "clicked on exchange contact",
    category: "analytics",
  },
  [ANALYTICS_EVENTS.ANALYTICS_SOCIAL_CLICKS]: {
    action: "clicked on social clicks",
    category: "analytics",
  },
  [ANALYTICS_EVENTS.ANALYTICS_LINK_CLICKS]: {
    action: "clicked on link clicks",
    category: "analytics",
  },
  [ANALYTICS_EVENTS.ANALYTICS_ALL_TIME]: {
    action: "clicked on all time",
    category: "analytics",
  },
  [ANALYTICS_EVENTS.ANALYTICS_CURRENT_MONTH]: {
    action: "clicked on current month",
    category: "analytics",
  },
  [ANALYTICS_EVENTS.ANALYTICS_PREV_MONTH]: {
    action: "clicked on prev month",
    category: "analytics",
  },
  [ANALYTICS_EVENTS.ANALYTICS_LAST_YEAR]: {
    action: "clicked on last year",
    category: "analytics",
  },
  [ANALYTICS_EVENTS.ANALYTICS_CUSTOM_DATE_RANGE]: {
    action: "clicked on custom date range",
    category: "analytics",
  },
  [ANALYTICS_EVENTS.GROUPS_OPEN_GROUP_MODAL]: {
    action: "click to open groups modal",
    category: "groups",
  },
  [ANALYTICS_EVENTS.GROUPS_CREATE_NEW_GROUP]: {
    action: "create new group",
    category: "groups",
  },
  [ANALYTICS_EVENTS.GROUPS_DELETE_GROUP]: {
    action: "delete group",
    category: "groups",
  },
  [ANALYTICS_EVENTS.SETTINGS_SAVE_BRANDING_CHANGES]: {
    action: "save branding changes",
    category: "settings",
  },
  [ANALYTICS_EVENTS.SETTINGS_SAVE_COMPANY_DETAILS_CHANGES]: {
    action: "save company details changes",
    category: "settings",
  },
  [ANALYTICS_EVENTS.SETTINGS_SAVE_ACCOUNT_CHANGES]: {
    action: "save account changes",
    category: "settings",
  },
  [ANALYTICS_EVENTS.COMPANY_PROFILE_PUBLIC_VIEW]: {
    action: "clicked on public view",
    category: "company_profile",
  },
  [ANALYTICS_EVENTS.COMPANY_PROFILE_EDIT_PROFILE]: {
    action: "clicked on edit profile",
    category: "company_profile",
  },
  [ANALYTICS_EVENTS.COMPANY_PROFILE_ASSIGN_CARD]: {
    action: "save assign card",
    category: "company_profile",
  },
  [ANALYTICS_EVENTS.COMPANY_PROFILE_MEET_THE_TEAM]: {
    action: "save meet the team",
    category: "company_profile",
  },
  [ANALYTICS_EVENTS.COMPANY_PROFILE_BUSINESS_PAGE_URL]: {
    action: "save business page url",
    category: "company_profile",
  },
};

export const getTrackingProps = (
  eventName: ANALYTICS_EVENTS,
  properties: TEventProperties,
): {
  action: string;
  category: string;
  label: string;
  value: string;
} => {
  return {
    action: trackingEvents[eventName]?.action,
    category: trackingEvents[eventName]?.category,
    label: (properties?.handle as string) || "",
    value: (properties?.publicId as string) || "",
    ...properties,
  };
};
