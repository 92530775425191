import React, { FC } from "react";

import { Contacts } from "../../features";
import { useUserState } from "../../context";

export const ContactsPage: FC = () => {
  const { currentTeam }: { currentTeam: any } = useUserState();
  const teamId = currentTeam?.id as number;

  return <Contacts teamId={teamId} />;
};
