import React from "react";

const Link = ({ onClick }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M10 12.4503C10.3596 12.894 10.8183 13.2612 11.3451 13.5269C11.8719 13.7926 12.4545 13.9505 13.0532 13.9901C13.652 14.0297 14.253 13.95 14.8154 13.7563C15.3779 13.5626 15.8886 13.2596 16.313 12.8677L18.8248 10.549C19.5874 9.82019 20.0094 8.84404 19.9998 7.83081C19.9903 6.81758 19.55 5.84834 18.7738 5.13185C17.9977 4.41536 16.9477 4.00895 15.85 4.00015C14.7524 3.99134 13.6949 4.38085 12.9053 5.08478L11.4652 6.4064"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 11.5497C13.6404 11.106 13.1817 10.7388 12.6549 10.4731C12.1281 10.2074 11.5455 10.0495 10.9468 10.0099C10.348 9.97029 9.74701 10.05 9.18456 10.2437C8.62212 10.4374 8.11138 10.7404 7.68697 11.1323L5.17516 13.451C4.41258 14.1798 3.99062 15.156 4.00016 16.1692C4.0097 17.1824 4.44997 18.1517 5.22616 18.8681C6.00234 19.5846 7.05233 19.991 8.14998 19.9999C9.24763 20.0087 10.3051 19.6192 11.0947 18.9152L12.5264 17.5936"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default React.memo(Link);
