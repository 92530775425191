import { ReactElement, FC } from "react";

import { HStack } from "@chakra-ui/layout";
import {
  ModalOverlay,
  Modal as ChakraModal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  ModalProps,
} from "@chakra-ui/modal";
import { SystemStyleObject } from "@chakra-ui/react";
import { theme } from "ovou-ui";
import { Text } from "../Typography";

export interface IModalProps
  extends Pick<ModalProps, "isOpen" | "onClose" | "onEsc" | "onOverlayClick"> {
  title: ReactElement | string;
  modalBody: ReactElement;
  modalFooter?: ReactElement;
  dismissible?: boolean;
  size?: string;
  subtitle?: string;
  borderRadius?: string;
  bodyStyle?: SystemStyleObject;
}

const NewModal: FC<IModalProps> = ({
  isOpen,
  onClose,
  title,
  dismissible = true,
  onEsc,
  onOverlayClick,
  modalBody: ModalBodyContent,
  modalFooter: ModalFooterContent,
  size,
  subtitle,
  borderRadius,
  bodyStyle,
}) => {
  return (
    <ChakraModal
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc={dismissible}
      closeOnOverlayClick={dismissible}
      motionPreset="slideInBottom"
      onEsc={onEsc}
      onOverlayClick={onOverlayClick}
      scrollBehavior="inside"
      size={size}
      returnFocusOnClose={false}
    >
      <ModalOverlay />
      <ModalContent
        sx={{
          maxWidth: "lg",
          paddingX: "22px",
          position: "fixed",
          bottom: "inherit",
          top: "20%",
          borderRadius: `${borderRadius ? borderRadius : "16px"}`,
        }}
      >
        <ModalHeader>
          {title}
          {subtitle && (
            <Text
              fontSize="16px"
              fontWeight="400"
              marginTop="8px"
              marginBottom="16px"
              color={theme.palette.ui.greys.grey1}
            >
              {subtitle}
            </Text>
          )}
        </ModalHeader>
        <ModalCloseButton _focus={{ outline: "none" }} />
        <ModalBody
          sx={{
            pb: ModalFooterContent ? 0 : 4,
            ...(typeof bodyStyle === "object" ? bodyStyle : {}),
          }}
        >
          {ModalBodyContent}
        </ModalBody>

        {ModalFooterContent && (
          <ModalFooter>
            <HStack w="full" spacing="2">
              {ModalFooterContent}
            </HStack>
          </ModalFooter>
        )}
      </ModalContent>
    </ChakraModal>
  );
};

export default NewModal;
