import React, { FC, useEffect } from "react";
import { useHistory } from "react-router-dom";
import api from "api";
import { useUserDispatch, userActions } from "context";

import { Onboarding } from "../../features";

export const OnboardingPage: FC = () => {
  const history = useHistory();
  const dispatch = useUserDispatch();

  const permissionsServiceApi = async () => {
    const { data } = await api.get(`auth/role`);
    if (data)
      // TODO: Move this Parent component, this is also begin in SettingsPage
      dispatch({ type: userActions.SET_PERMISSIONS, payload: data[0] && data[0]?.permission?.default_permission });
  };

  useEffect(() => {
    permissionsServiceApi();
  }, []);


  return <Onboarding />;
};
