import React, { FC } from "react";

import { Devices } from "../../features/devices";
import { useUser } from "../../context";

const DevicesPage: FC = () => {
  const [{ currentTeam }] = useUser();

  // TODO: Member should always have a teamID
  const teamId = currentTeam?.id;

  return teamId ? (
    <Devices teamId={teamId} />
  ) : (
    <></>
  );
};

export default DevicesPage;
