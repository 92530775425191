import { useAnalytics } from "context/Analytics";
import React, { useEffect } from "react";

import { Route } from "react-router-dom";

// This will be used instead of react-router Route
// with this we can pass different layouts to the route
const RouteWrapper = ({ component: Component, layout: Layout, ...rest }) => {
  const { location } = rest;
  const { page } = useAnalytics();

  useEffect(() => {
    if (page) {
      page(location.pathname + location.search, window.location.href);
    }
  }, [location]);

  return (
    <Route
      {...rest}
      render={props =>
        Component && (
          <Layout {...props}>
            <Component {...props} />
          </Layout>
        )
      }
    />
  );
};

export default RouteWrapper;
