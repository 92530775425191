import React from "react";

const Users = ({
  className,
  role,
  ignoreDefaultSize,
  width = 24,
  height = 24,
  strokeWidth,
  onClick,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={ignoreDefaultSize ? undefined : width}
      height={ignoreDefaultSize ? undefined : height}
      viewBox="0 0 24 24"
      role={role}
      className={className}
      strokeWidth={strokeWidth}
      onClick={onClick}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="currentColor"
        d="M13.768 10.982a2.5 2.5 0 1 1-3.536 0 2.5 2.5 0 0 1 3.536 0"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="currentColor"
        d="M19.254 4.982a2.5 2.5 0 1 1-3.536 0 2.5 2.5 0 0 1 3.536 0"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="currentColor"
        d="M18 12.25a6.551 6.551 0 0 1 4 1.333"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="currentColor"
        d="M8.282 4.982a2.5 2.5 0 1 1-3.536 0 2.5 2.5 0 0 1 3.536 0"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="currentColor"
        d="M2 13.583a6.551 6.551 0 0 1 4-1.333"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="currentColor"
        d="M16.591 19.75a7.776 7.776 0 0 0-9.182 0"
      />
    </svg>
  );
};

export default React.memo(Users);
