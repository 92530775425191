import { FC } from "react";

import { Box, MenuItemOption, MenuList, MenuOptionGroup } from "components";
import { ITeam } from "types/Team";
import { theme } from "ovou-ui";

interface IMultiTeamMenuList {
  currentTeam?: ITeam;
  teamsData?: ITeam[];
  onChange?: (value: string | string[]) => void;
}
const MultiTeamMenuList: FC<IMultiTeamMenuList> = ({
  currentTeam,
  onChange,
  teamsData,
}) => {
  return (
    <MenuList width="296px" borderRadius="10px">
      <MenuOptionGroup
        defaultValue={String(currentTeam?.id)}
        type="radio"
        onChange={onChange}
      >
        {teamsData?.map(team => (
          <MenuItemOption
            as={Box}
            closeOnSelect
            value={String(team.id)}
            flexDir="row-reverse"
            key={team.id}
            color={theme.palette.ui.greys.grey1}
            background={
              currentTeam?.id === team.id ? theme.palette.ui.greys.grey5 : ""
            }
            width="90%"
            marginLeft="8px"
            borderRadius="6px"
            sx={{
              ".chakra-menu__icon-wrapper": {
                color: theme.palette.brand.primary.orange,
              },
            }}
          >
            {team.title}
          </MenuItemOption>
        ))}
      </MenuOptionGroup>
    </MenuList>
  );
};

export default MultiTeamMenuList;
