import React from "react";

import classNames from "classnames";

const Button = ({
  children,
  disabled = false,
  variant = "primary",
  size = "medium",
  capitalize = false,
  uppercase = false,
  onClick = () => {},
  className = "",
  block,
  bold = true,
  ...restProps
}) => {
  const buttonClasses = classNames({
    "flex relative rounded-lg focus:outline-none": true,
    "focus:shadow-outline": variant !== "transparent",
    "w-full justify-center": block,
    "tracking-wide leading-relaxed": true,
    "font-bold": bold,
    // transition
    "transition-all duration-200 ease-in-out": true,
    // size
    "py-2 px-2 text-xs": size === "xs",
    "py-3 px-4 text-sm": size === "small",
    "py-3 px-6 text-base": size === "medium",
    "py-4 px-10 text-xl": size === "large",
    // colors
    "bg-primary text-white shadow-custom": variant === "primary",
    "bg-primary-light text-primary": variant === "primaryLight",
    "bg-black text-white": variant === "black",
    "bg-white text-black": variant === "white",
    "bg-transparent text-black focus:opacity-75": variant === "transparent",
    "bg-transparent text-white bg-red-500": variant === "red",
    // borders
    "border border-custom-gray-300": variant === "white",
    // hover
    "hover:shadow-none hover:opacity-75": variant === "primary" && !disabled,
    "hover:opacity-75":
      variant !== "primary" && variant !== "white" && !disabled,
    "hover:bg-black hover:border-black hover:text-white":
      variant === "white" && !disabled,
    // other props
    capitalize,
    uppercase,
    "opacity-50 cursor-not-allowed": disabled,
    "cursor-pointer opacity-100": !disabled,
  });

  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`${buttonClasses} ${className}`}
      {...restProps}
    >
      {children}
    </button>
  );
};

export default Button;
