import { FC } from "react";

import { Avatar, Stack, Text } from "components";
import { AvatarProps } from "@chakra-ui/react";
import { theme } from "ovou-ui";

interface IStackedAvatars extends AvatarProps {
  max?: number;
  images: string[];
  text?: string;
}

const getLeftPosition = (index: number) => {
  if (index === 0) return index;
  if (index === 1) return 15;
  return index * 20 - 5;
};

const StackedAvatars: FC<IStackedAvatars> = ({
  max,
  images,
  text,
  border = "2px solid #FFFFFF",
  borderRadius = "4px",
  width = "30px",
  height = "40px",
  ...props
}) => {
  const avatarsToDisplay =
    !max || max > images.length ? images : images.slice(0, max);

  return (
    <Stack direction="row" position="relative" alignItems="center">
      {avatarsToDisplay.map((image, index) => {
        return (
          <Avatar
            key={index}
            position={Boolean(index) ? "absolute" : "relative"}
            objectFit="cover"
            src={image}
            name={`Avatar ${index}`}
            width={width}
            height={height}
            border={border}
            borderRadius={borderRadius}
            left={getLeftPosition(index)}
            {...props}
          />
        );
      })}

      {max && max < images.length && (
        <Text
          position="absolute"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width={width}
          height={height}
          left={max * 20 - 5}
          border={border}
          borderRadius={borderRadius}
          background={theme.palette.ui.greys.grey5}
          fontWeight={600}
          fontSize={12}
          color={theme.palette.ui.greys.grey1}
        >
          +{images.length - max}
        </Text>
      )}
      {text && (
        <Text
          fontWeight={600}
          fontSize="14px"
          position="absolute"
          left={getLeftPosition(
            !max || max >= images.length
              ? avatarsToDisplay.length + 1
              : max + 2,
          )}
        >
          {text}
        </Text>
      )}
    </Stack>
  );
};

export default StackedAvatars;
