import { FC } from "react";

import { theme } from "ovou-ui";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";

import { ButtonOutlined, Flex, Image, MenuButton, Text } from "components";

interface IMultiTeamMenuButton {
  isOpen: boolean;
  logo?: string;
  title?: string;
}
const MultiTeamMenuButton: FC<IMultiTeamMenuButton> = ({
  isOpen,
  logo,
  title,
}) => {
  return (
    <MenuButton
      as={ButtonOutlined}
      borderRadius="8px"
      border={`1px solid ${theme.palette.ui.greys.grey3}`}
      height="48px"
      width="100%"
      paddingX="11px"
      paddingY="8px"
      rightIcon={
        isOpen ? (
          <HiChevronUp color={theme.palette.ui.greys.grey2} size={16} />
        ) : (
          <HiChevronDown color={theme.palette.ui.greys.grey2} size={16} />
        )
      }
      _hover={{
        backgroundColor: theme.palette.ui.greys.grey5,
      }}
      _focus={{
        backgroundColor: theme.palette.ui.greys.grey5,
      }}
    >
      <Flex alignItems="center" maxWidth="210px">
        <Image
          width="32px"
          height="32px"
          backgroundSize="cover"
          borderRadius="4px"
          src={logo}
          alt={logo ? "Brand Logo" : "Default Logo"}
          marginRight="8px"
        />
        <Text fontSize="14px" fontWeight={600} isTruncated>
          {title}
        </Text>
      </Flex>
    </MenuButton>
  );
};

export default MultiTeamMenuButton;
