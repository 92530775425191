import React from "react";

import classNames from "classnames";
import { Formik, Form, Field } from "formik";
import { useToasts } from "react-toast-notifications";
import styled from "styled-components";
import * as yup from "yup";
import { useWorkCategoryQuery } from "../business/queries";
import {
  SelectFormField,
  Button,
  Section, 
  Spinner,
  TextFormField 
} from "../../components";

import { useUser,setTeam,setProfile } from "../../context";
import { usePasswordChangeMutation, useUpdateTeamMutation, useUpdateProfileQuery} from "./queries";
import { VALIDATION_MESSAGES } from "../../constants";
import { useProfileDataQuery } from "../auth/queries";
import { useTracking } from "hooks";
import { ANALYTICS_EVENTS } from "constants/analyticEvents";

const Wrapper = styled.div`
  > div > div {
    margin-bottom: auto;
  }
`;

const validationSchema = yup.object({
  company_name: yup.string()
    .trim()
    .max(80, VALIDATION_MESSAGES.MAX_LENGTH("Company name", 80))
    .when(['currentPassword', 'newPassword'], {
      is: (currentPassword, newPassword) => !currentPassword && !newPassword,
      then: yup.string().required(VALIDATION_MESSAGES.IS_REQUIRED("Company name")),
      otherwise: yup.string().notRequired(),
    }),
  currentPassword: yup.string()
    .when('newPassword', {
      is: newPassword => !!newPassword,
      then: yup.string().required(VALIDATION_MESSAGES.IS_REQUIRED("Current password")),
      otherwise: yup.string().nullable().notRequired(),
    }),
  newPassword: yup.string()
    .min(8, VALIDATION_MESSAGES.MIN_LENGTH("New password", 8))
    .nullable()
    .notRequired(),
  passwordConfirmation: yup.string()
    .oneOf([yup.ref("newPassword"), null], "Passwords must match")
    .when('newPassword', {
      is: newPassword => !!newPassword,
      then: yup.string().required(VALIDATION_MESSAGES.IS_REQUIRED("Password confirmation")),
      otherwise: yup.string().nullable().notRequired(),
    }),
}).test('custom-test', 'New password and current password should both be null or both be provided', function (value) {
  const { newPassword, currentPassword } = value;
  if ((newPassword === null || currentPassword === null) && (newPassword !== currentPassword)) {
    return this.createError({ path: 'newPassword', message: 'Both newPassword and currentPassword must be filled or both be null' });
  }
  return true;
});

const ChangePasswordForm = () => {
  const { addToast } = useToasts();
  const track = useTracking();
  const [{ currentTeam, currentProfile }, dispatch] = useUser();

  const [fireChangePassword, { isLoading: isChangingPassword }] =
    usePasswordChangeMutation({
      onSuccess: () => {
        addToast("Your password has been changed successfully", {
          appearance: "success",
        });
      },
      onError: error => {
        addToast(error?.response?.data?.message || "Something went wrong", {
          appearance: "error",
        });
      },
    });

    const [updateTeam, { isLoadingTeam }] = useUpdateTeamMutation({
      onSuccess: (
        _data,
        { teamData: { title, allow_self_assign, non_editable_profile_fields } },
      ) => {
        dispatch(
          setTeam({
            ...currentTeam,
            title,
            allow_self_assign,
            non_editable_profile_fields,
          }),
        );
      },
    });
  
    const { getProfileData } = useProfileDataQuery({});
  
    const { mutate: updateProfile, isLoading: isUpdatingProfile } =
      useUpdateProfileQuery({
        onSuccess: async () => {
          const data = await getProfileData();
          debugger
          dispatch(setProfile({...currentProfile, ...data.profiles[0]}));
        },
      });

  const { data: workCategoryData, isLoading: isWorkCategoryDataLoading } =
    useWorkCategoryQuery({});

  const workCategoryOptions =
    workCategoryData?.work_categories?.map(workCategoryItem => ({
      label: workCategoryItem?.title || "",
      value: workCategoryItem?.id?.toString() || "",
    })) || [];

  const handleSubmit = (values, { resetForm }) => {

    debugger
    values.newPassword && values.currentPassword && fireChangePassword(
      {
        formData: {
          password: values.newPassword,
          old_password: values.currentPassword,
        },
      },
      {
        onSuccess: () => {
          resetForm();
          track({ eventName: ANALYTICS_EVENTS.SETTINGS_SAVE_ACCOUNT_CHANGES });
        },
      },
    );

    values?.company_name !== currentTeam?.title && updateTeam(
      {
        teamID: currentTeam.id,
        teamData: {
          title: values?.company_name,
          allow_self_assign: currentTeam?.allow_self_assign,
          non_editable_profile_fields: currentTeam?.non_editable_profile_fields,
        },
      },
      {
        onError: () => {
          addToast(
            "Failed to update team, please contact support for assistance.",
            {
              appearance: "error",
            },
          );
        },
        onSuccess: () => {
          track({
            eventName: ANALYTICS_EVENTS.SETTINGS_SAVE_COMPANY_DETAILS_CHANGES,
            customTrackingProps: {
              values,
            },
          });
        },
      },
    );

    values.work_category !== currentProfile?.work_category && updateProfile(
      {
        handle: currentProfile?.handle,
        profileData: {
          work_category: values.work_category ? Number(values.work_category) : null,
        },
      },
      {
        onError: () => {
          addToast(
            "Failed to update profile, please contact support for assistance.",
            {
              appearance: "error",
            },
          );
        },
      },
    );
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        company_name: currentTeam?.title,
        work_category: currentProfile?.work_category,
        currentPassword: "",
        newPassword: "",
        passwordConfirmation: "",
      }}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ isValid, dirty }) => (
        <Form autoComplete="off">
          <Wrapper>
            <Section
              label="Company name"
              subLabel="This applies to all profiles."
              showBorder={false}
            >
              <Field
                component={TextFormField}
                label="Company"
                name="company_name"
                autoComplete="off"
              />
            </Section>
            <Section
              label="Select Industry"
              subLabel="Choose the best category that describes your business."
              showBorder={false}
            >
              <Field
                component={SelectFormField}
                label="Category"
                name="work_category"
                options={workCategoryOptions}
                disabled={isWorkCategoryDataLoading}
              />
            </Section>
            <Section
              label="Change Password"
              subLabel="You can change password for your account here."
              showBorder={false}
            >
              <Field
                component={TextFormField}
                label="Current password"
                name="currentPassword"
                autoComplete="new-password"
                type="password"
              />
              <div className="mt-4">
                <Field
                  component={TextFormField}
                  label="New password"
                  name="newPassword"
                  autoComplete="new-password"
                  type="password"
                />
              </div>
              <div className="mt-4">
                <Field
                  component={TextFormField}
                  label="Confirm new password"
                  name="passwordConfirmation"
                  autoComplete="new-password"
                  type="password"
                />
              </div>
            </Section>
            <div
              className={classNames({
                "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center mt-6 sm:mt-5 md:mt-12": true,
              })}
            >
              <div className="col-start-2">
                <Button
                  type="submit"
                  disabled={!isValid || isUpdatingProfile || isLoadingTeam || !dirty}
                  size="small"
                  block
                  data-testid="company-secret-save-button"
                >
                  {isChangingPassword ? (
                    <Spinner color="white" />
                  ) : (
                    "Save Changes"
                  )}
                </Button>
              </div>
            </div>
          </Wrapper>
        </Form>
      )}
    </Formik>
  );
};

export default ChangePasswordForm;
