/**
 * Customer Portal
 * @returns {Object} { url: string}
 */

import { useMutation } from "react-query";
import api from "./index";

export const customerPortal = async (data, options) => {
  return await api.post("/payments/stripe-portal", {
    data,
    ...options,
  });
};

export const useCustomerPortal = options => {
  const { mutation: mutationOptions, request: requestOptions } = options || {};

  const mutationFn = props => {
    const { data } = props || {};

    return customerPortal(data, requestOptions);
  };

  const [mutate, { status, error }] = useMutation(mutationFn, mutationOptions);
  return { mutate, status, error };
};
