import React, { FC } from "react";
import { Text, Flex, Divider } from "@chakra-ui/react";
import { PiWarningOctagonBold } from "react-icons/pi";
import { AiOutlineInfoCircle } from "react-icons/ai";

import { Modal } from "../Modal";
import {
  useGetTeamTags,
  useCreateTeamTag,
  useDeleteTag,
} from "../../features/contacts/queries/useTags";
import { ITag } from "../../features/contacts/types/Tag";
import { TagListItem } from "../../features/contacts/components/TagListItem";
import { theme } from "ovou-ui";
import { useGetTeamContacts } from "features/contacts/queries/useContactsTable";
import { CreateTagForm } from "./CreateTagForm";
import { useSettings } from "../../features/contacts/queries/useSettings";

const MAX_CREATED_TAGS = 10;

interface ManageTagsModalProps {
  teamId: number;
  isOpen: boolean;
  onClose: () => void;
}

export const ManageTagsModal: FC<ManageTagsModalProps> = ({
  teamId,
  isOpen,
  onClose,
}) => {
  const { refetch: refetchContacts } = useGetTeamContacts({
    teamId,
    queryAutomatically: false,
  });
  const { data: teamTagsData, refetch: getTeamTags } = useGetTeamTags({
    teamId,
  });
  const { mutate: createTag, isLoading } = useCreateTeamTag({
    teamId,
    refetchTags: getTeamTags,
  });
  const { mutate: deleteTag } = useDeleteTag({
    refetchTags: () => {
      getTeamTags();
      refetchContacts();
    },
  });
  const { data: settingsData, isLoading: areSettingsLoading } = useSettings();

  const tagList = teamTagsData?.results;

  return (
    <Modal isOpen={isOpen} onClose={onClose} label={"Tags Modal"}>
      <Flex flexDirection="column" width={408}>
        <Flex alignItems="center" height="36px">
          <Text
            fontWeight="extrabold"
            fontSize={24}
            color={theme.palette.brand.primary.black}
          >
            Tags
          </Text>
          <Text
            fontWeight="semibold"
            fontSize={16}
            color={theme.palette.brand.primary.black}
            marginLeft={3}
          >
            max 10
          </Text>
        </Flex>

        <Flex height="48px" marginTop={3}>
          <Text
            fontWeight="normal"
            fontSize={16}
            color={theme.palette.ui.greys.grey1}
          >
            Link a new card to a team member by inviting them to join OVOU.
          </Text>
        </Flex>

        {tagList?.length === 0 ? (
          <Flex
            alignItems="center"
            marginTop={6}
            padding={4}
            backgroundColor={theme.palette.ui.greys.grey5}
            borderRadius="8px"
          >
            <AiOutlineInfoCircle
              color={theme.palette.brand.primary.black}
              size={48}
            />
            <Text
              marginLeft={4}
              fontSize={14}
              lineHeight={5}
              color={theme.palette.brand.primary.black}
            >
              You have not created any tags. Please create one on the form
              below.
            </Text>
          </Flex>
        ) : (
          <Flex flexDirection="column" marginTop={6}>
            <Flex flexWrap="wrap" gap={2}>
              {tagList?.map((tag: ITag) => (
                <TagListItem
                  name={tag.name}
                  color={tag.color}
                  onDelete={() => deleteTag({ tagId: tag.id })}
                />
              ))}
            </Flex>

            <Divider
              marginTop={8}
              backgroundColor={theme.palette.ui.greys.grey4}
            />
          </Flex>
        )}

        {tagList?.length < MAX_CREATED_TAGS ? (
          <Flex flexDirection="column">
            <Text
              marginTop={8}
              fontWeight="extrabold"
              fontSize={24}
              color={theme.palette.brand.primary.black}
            >
              Create New Tag
            </Text>

            <CreateTagForm
              tagColors={settingsData?.tag_colors || []}
              areSettingsLoading={areSettingsLoading}
              isCreatingTag={isLoading}
              onSubmit={createTag}
            />
          </Flex>
        ) : (
          <Flex
            alignItems="center"
            padding={4}
            backgroundColor="#FFF0EF"
            borderRadius="8px"
            marginTop={8}
          >
            <PiWarningOctagonBold
              color={theme.palette.ui.states.error}
              size={48}
            />
            <Text
              marginLeft={4}
              fontSize={14}
              lineHeight={5}
              color={theme.palette.ui.states.error}
            >
              You've reached the 10 tags limit. Please delete one of them to be
              able to create a new one.
            </Text>
          </Flex>
        )}
      </Flex>
    </Modal>
  );
};
