import React from "react";
import Button from "../Button";

/**
 * CropperRatioSelector component
 * @param {number} activeRatio
 * @param {Array} ratioOptions
 * @param {function} onSelect
 * @returns {JSX.Element}
 * @constructor
 */
const CropperRatioSelector = ({ activeRatio, ratioOptions = [], onSelect }) => {
  return (
    <div className="mt-1 flex justify-center items-center">
      {ratioOptions.map(option => (
        <Button
          key={option.ratio}
          variant="transparent"
          size="small"
          className={`${
            activeRatio === option.ratio ? "text-black" : "text-custom-gray-150"
          } flex items-center`}
          onClick={() =>
            typeof onSelect == "function" && onSelect(option.ratio)
          }
        >
          {option.label}
          {option.example && (
            <span
              className={`${option.example} ml-4 border-2 rounded ${
                activeRatio === option.ratio
                  ? "border-black"
                  : "border-custom-gray-150"
              }`}
            />
          )}
        </Button>
      ))}
    </div>
  );
};

export default CropperRatioSelector;
