import React, { FC } from "react";
import { theme } from "ovou-ui";
import { NavLink } from "react-router-dom";
import { MdOutlineRemoveRedEye } from "react-icons/md";

import { ButtonOutlined, Flex } from "../../../components";

interface ITableViewDetailsColumnProps {
  contactId: string;
}

export const TableViewDetailsColumn: FC<ITableViewDetailsColumnProps> = ({
  contactId,
}) => {
  return (
    <Flex justifyContent="center">
      <ButtonOutlined padding={2} as={NavLink} to={`/contacts/${contactId}`}>
        <MdOutlineRemoveRedEye
          size={16}
          color={theme.palette.brand.primary.black}
        />
      </ButtonOutlined>
    </Flex>
  );
};
