import { useMutation } from "react-query";
import { useToasts } from "react-toast-notifications";

import api from "../../../api";

type TBatchCardAssignProps = {
  batchAssignCardData: {
    card_ids: string[];
  };
  handle: string;
  isMember?: boolean;
};

const batchCardAssignService = async ({
  batchAssignCardData,
  handle,
  isMember = false,
}: TBatchCardAssignProps) => {
  const { data } = await api.post(
    `/p/${handle}/assign_batch_cards${isMember ? "?profile=member" : ""}`,
    batchAssignCardData,
  );
  return data;
};

export const useBatchCardAssignMutation = (showToasts = true) => {
  const { addToast } = useToasts();

  const [mutate, { status, error, isLoading }] = useMutation(
    batchCardAssignService,
    {
      onSuccess: () => {
        showToasts &&
          addToast("Success", {
            appearance: "success",
          });
      },
      onError: () => {
        showToasts &&
          addToast("Failed to assign cards, please contact support for assistance.", {
            appearance: "error",
          });
      },
    },
  );

  return {
    mutate,
    status,
    error,
    isLoading,
  };
};
