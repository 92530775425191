import { useMutation } from "react-query";
import { useToasts } from "react-toast-notifications";

import api from "../../../api";

const assignRoleService = async ({
  email,
  user_iam_role,
}: {
  email: string;
  user_iam_role: string;
}) => {
  const { data } = await api.put(`auth/role`, {
    email,
    user_iam_role,
  });
  return data;
};

export const useAssignRoleMutation = () => {
  const { addToast } = useToasts();

  const [mutate, { ...mutationProps }] = useMutation(assignRoleService, {
    onSuccess: () => {
      addToast("Success", {
        appearance: "success",
      });
    },
    onError: () => {
      addToast("Failed to assign role, please contact support for assistance.", {
        appearance: "error",
      });
    },
  });
  return {
    mutate,
    ...mutationProps,
  };
};
