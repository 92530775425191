import React, { useMemo, useEffect, useState } from "react";

import { getIn } from "formik";
import Select from "react-select";

import { customSelectStyles } from "./styles";
import FormError from "../../FormError";

const normalizeFieldValues = values => {
  if (!values) return [];
  return values.map(value => {
    if (value === null || value === undefined) return "";
    return value.toString();
  });
};

const findSelectedOptions = (options, values) => {
  return options.filter(option => values.indexOf(option.value) !== -1);
};

const MultiSelectFormField = ({
  field,
  form,
  options,
  disabled,
  isLoading,
  placeholder,
  maxMenuHeight = 250,
  renderInPortal,
  components,
  styles,
  ...props
}) => {
  const errorMessage = useMemo(
    () => getIn(form.errors, field.name),
    [field.name, form.errors],
  );

  const [selected, setSelected] = useState(() =>
    findSelectedOptions(options, normalizeFieldValues(field.value)),
  );

  useEffect(() => {
    setSelected(
      findSelectedOptions(options, normalizeFieldValues(field.value)),
    );
  }, [field.value, options, setSelected]);

  const onChange = selected => {
    setSelected(selected);
    form.setFieldValue(
      field.name,
      selected ? selected?.map(selected => selected.value) : [],
    );
  };

  return (
    <div className="flex flex-col">
      <Select
        value={props?.value ? props?.value : selected}
        onChange={onChange}
        options={options}
        styles={{ ...customSelectStyles, ...styles }}
        isDisabled={disabled}
        isLoading={isLoading}
        placeholder={placeholder}
        maxMenuHeight={maxMenuHeight}
        menuPortalTarget={renderInPortal ? document.body : undefined}
        isMulti
        components={components}
      />
      {errorMessage && <FormError error={errorMessage} />}
    </div>
  );
};
export default MultiSelectFormField;
