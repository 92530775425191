import { paragon, SDK_EVENT, SDKIntegration } from "@useparagon/connect";
import { getParagonUserToken } from "api/paragon";
import { Observable } from "utils/observables";

import {
  IIntegrationService,
  Integration,
  IntegrationStatus,
} from "./IIntegrationService";
import {
  NO_NEED_WORKFLOW_CONFIG,
  PARAGON_PROJECT_ID,
} from "../../../constants";

interface IComboField {
  mainInput: string;
  dependentInputs: string;
}

interface IInputItem {
  id: string;
  required: boolean;
  sourceType: string;
}

interface IWorkflowMetaItem {
  inputs: IInputItem[];
}

export class ParagonIntegrationService implements IIntegrationService {
  constructor(
    private _teamId: number | undefined,
    private _listener: (() => void) | null = null,
    private _integrations: any = null,
    private _integrationsObservable: Observable<Integration[]> | null = null,
  ) {}

  getIntegrationsObservable(): Observable<Integration[]> {
    if (!this._integrationsObservable) {
      this._integrationsObservable = new Observable<Integration[]>([]);
    }
    return this._integrationsObservable;
  }

  showConfigurationMenu(integrationId: string): unknown {
    return paragon.connect(integrationId, {});
  }

  initialize(): void {
    if (!this._integrationsObservable) {
      this._integrationsObservable = new Observable<Integration[]>([]);
    }
    this._addSubscriptionsToObservable();
  }

  dispose(): void {
    if (!this._listener) return;
    paragon.unsubscribe(SDK_EVENT.ON_INTEGRATION_INSTALL, this._listener);
    paragon.unsubscribe(SDK_EVENT.ON_INTEGRATION_UNINSTALL, this._listener);
    paragon.unsubscribe(SDK_EVENT.ON_PORTAL_OPEN, this._listener);
    paragon.unsubscribe(SDK_EVENT.ON_PORTAL_CLOSE, this._listener);
    paragon.unsubscribe(SDK_EVENT.ON_WORKFLOW_CHANGE, this._listener);
    this._listener = null;
  }

  async _addSubscriptionsToObservable(): Promise<void> {
    this._listener = () => this._updateIntegrationsObservable();
    paragon.subscribe(SDK_EVENT.ON_INTEGRATION_INSTALL, this._listener);
    paragon.subscribe(SDK_EVENT.ON_INTEGRATION_UNINSTALL, this._listener);
    paragon.subscribe(SDK_EVENT.ON_PORTAL_OPEN, this._listener);
    paragon.subscribe(SDK_EVENT.ON_PORTAL_CLOSE, this._listener);
    paragon.subscribe(SDK_EVENT.ON_WORKFLOW_CHANGE, this._listener);

    const token = await this._getParagonUserToken();
    await Promise.all([
      this._authenticateParagon(token),
      this._loadParagonIntegrations(token),
    ]);
    this._updateIntegrationsObservable();
  }

  async _getParagonUserToken(): Promise<string> {
    const response = await getParagonUserToken({ team_id: this._teamId });
    return response.data.user_token;
  }

  async _getParagonIntegrations(token: string): Promise<any> {
    const response = await fetch(
      `https://api.useparagon.com/projects/${PARAGON_PROJECT_ID}/sdk/integrations`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.json();
  }

  async _authenticateParagon(token: string): Promise<void> {
    await paragon.authenticate(PARAGON_PROJECT_ID, token);
  }

  async _loadParagonIntegrations(token: string): Promise<void> {
    this._integrations = await this._getParagonIntegrations(token);
  }

  _updateIntegrationsObservable(): void {
    if (!this._integrationsObservable) return;

    const authedUser = paragon.getUser();

    if (!authedUser.authenticated || !this._integrations) {
      return;
    }

    const integrations: Integration[] = paragon
      .getIntegrationMetadata()
      .map(integrationMetadata => {
        const credentials =
          authedUser?.integrations?.[integrationMetadata.type];

        let integration = null;
        for (const item of this._integrations) {
          if (item.type === integrationMetadata.type) {
            integration = item;
            break;
          }
        }

        return {
          id: integrationMetadata.type,
          icon: integrationMetadata.icon,
          name: integrationMetadata.name,
          status:
            credentials && integration
              ? this._getIntegrationStatus(credentials, integration)
              : IntegrationStatus.DISCONNECTED,
        };
      });

    this._integrationsObservable.setValue(integrations);
  }

  _mondayConfigurationIsCorrect(
    credentials: SDKIntegration,
    integration: any,
  ): boolean {
    for (const integrationConfigItem of integration.configs) {
      for (const [workflowMetaId, workflowMetaItem] of Object.entries(
        integrationConfigItem.values.workflowMeta,
      )) {
        const boardItemId = (
          workflowMetaItem as IWorkflowMetaItem
        )?.inputs?.find(
          (inputItem: any) => inputItem.sourceType === "boards",
        )?.id;
        if (!boardItemId) continue;

        const boardId =
          credentials?.configuredWorkflows?.[workflowMetaId]?.settings[
            boardItemId
          ];
        if (!credentials?.configuredWorkflows?.[workflowMetaId]?.enabled)
          continue;

        for (const inputItem of (workflowMetaItem as IWorkflowMetaItem)
          .inputs) {
          if (inputItem.sourceType === "comboFields") {
            const comboField: IComboField = credentials?.configuredWorkflows?.[
              workflowMetaId
            ]?.settings[inputItem.id] as IComboField;
            if (
              comboField?.mainInput !== undefined &&
              comboField?.mainInput !== boardId
            ) {
              return false;
            }
          }
        }
      }
    }
    return true;
  }

  _getIntegrationStatus(
    credentials: SDKIntegration,
    integration: any,
  ): IntegrationStatus {
    if (!credentials.enabled) {
      return IntegrationStatus.DISCONNECTED;
    }
    let isWorkflowConfigured = false;
    if (
      credentials.credentialStatus === "VALID" &&
      Object.keys(credentials.configuredWorkflows || {}).length === 0 &&
      NO_NEED_WORKFLOW_CONFIG.includes(integration.type)
    ) {
      isWorkflowConfigured = true;
    }
    for (const [, worflowConfiguration] of Object.entries(
      credentials.configuredWorkflows || {},
    )) {
      if (worflowConfiguration?.enabled) {
        isWorkflowConfigured = true;
        break;
      }
    }
    if (!isWorkflowConfigured) {
      return IntegrationStatus.DISABLED;
    }

    if (
      integration.type === "monday.com" &&
      !this._mondayConfigurationIsCorrect(credentials, integration)
    ) {
      return IntegrationStatus.WRONG_CONFIGURATION;
    }

    for (const integrationConfigItem of integration.configs) {
      for (const [workflowMetaId, workflowMetaItem] of Object.entries(
        integrationConfigItem.values.workflowMeta,
      )) {
        if (!credentials?.configuredWorkflows?.[workflowMetaId]?.enabled)
          continue;
        for (const inputItem of (workflowMetaItem as any).inputs) {
          if (
            inputItem.required &&
            credentials?.configuredWorkflows?.[workflowMetaId]?.settings[
              inputItem.id
            ] === undefined
          ) {
            return IntegrationStatus.NOT_CONFIGURED;
          }
        }
      }
    }
    return IntegrationStatus.CONNECTED;
  }
}
