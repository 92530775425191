import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import Pica from "pica";
import { Cropper } from "react-cropper";
import { FiX } from "react-icons/fi";
import { useParams } from "react-router-dom";

// import { useQueryClient } from "react-query";

// import {
//   getGetMyProfilesQueryKey,
//   useProfileDeletePicture,
//   useProfileSetPicture,
// } from "apis";
// import { DefaultProfilePic } from "components/DefaultProfilePic";

// import { resizeFile, imageSize } from "../../utils";
// import { Spinner } from '../../components';

import { Box } from "./Box";
import DefaultProfilePic from "./DefaultProfilePic";
import { Divider } from "./Divider";
import { Icon, Spinner } from "../../components";
import { Image } from "../../components/Image";
import { Flex } from "../../components/Warpper";
import {
  useCreateIndividualQuery,
  useDeleteIndividualQuery,
} from "../../features/individualProfile/queries";
import { imageSize, resizeFile } from "../../utils/getCardScheme";

const DeleteProfilePic = ({ onDelete, isDeleting }) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100%"
      cursor="pointer"
      onClick={onDelete}
    >
      {isDeleting ? (
        <Spinner color="white" />
      ) : (
        <FiX size="24" color="var(--chakra-colors-contrast)" />
      )}
    </Flex>
  );
};

const UploadNewPic = ({ onUpload }) => {
  return (
    <div className="flex justify-center items-center w-full h-full">
      <div className="flex w-full h-full">
        <label
          className="flex justify-center items-center w-full h-full cursor-pointer"
          htmlFor="imageUploadInput"
        >
          <input
            id="imageUploadInput"
            type="file"
            accept="image/*;capture=camera÷"
            className="absolute w-0 h-0 opacity-0 pointer-events-none"
            onChange={onUpload}
          />
          <Icon name="camera" />
        </label>
      </div>
    </div>
  );
};

const UploaderActions = ({
  isDeleting,
  onDelete,
  onUpload,
  canDeleteImage,
}) => {
  return (
    <Box
      position="absolute"
      bottom="-27.5px"
      left="50%"
      transform="translate(-50%)"
      background="#FF643A"
      borderWidth="4px"
      borderStyle="solid"
      borderColor="white"
      minWidth="none"
      minHeight="none"
      width={canDeleteImage ? "115px" : "55px"}
      height="55px"
      borderRadius={canDeleteImage ? "56px" : "50%"}
    >
      <Flex
        width="100%"
        height="100%"
        alignItems="center"
        justifyContent="space-between"
      >
        {canDeleteImage && (
          <>
            <Flex
              justifyContent="center"
              width="100%"
              height="100%"
              className="cursor-pointer"
            >
              <DeleteProfilePic onDelete={onDelete} isDeleting={isDeleting} />
            </Flex>
            <Divider></Divider>
          </>
        )}
        <Flex
          justifyContent="center"
          width="100%"
          height="100%"
          margin={!canDeleteImage && "0.5rem 0 0 0"}
        >
          <UploadNewPic onUpload={onUpload} />
        </Flex>
      </Flex>
    </Box>
  );
};

const getResizedDimensions = (width, height, maxWidth, maxHeight) => {
  if (width <= maxWidth && height <= maxHeight) {
    return { width, height };
  }
  const scaleFactor = Math.max(width / maxWidth, height / maxHeight);
  const outputWidth = Math.min(maxWidth, Math.round(width / scaleFactor));
  const outputHeight = Math.min(maxHeight, Math.round(height / scaleFactor));
  return { width: outputWidth, height: outputHeight };
};

const getCroppedCanvas = async (cropper, maxWidth = 512, maxHeight = 512) => {
  const croppedCanvas = cropper.getCroppedCanvas({
    imageSmoothingEnabled: true,
    imageSmoothingQuality: "high",
  });
  if (croppedCanvas.width <= maxWidth && croppedCanvas.height <= maxHeight) {
    return croppedCanvas;
  }
  const { width, height } = getResizedDimensions(
    croppedCanvas.width,
    croppedCanvas.height,
    maxWidth,
    maxHeight,
  );
  /* Todo: Pica cannot be replaced by react-image-file-resizer, as pica runs on webworker to convert image
  and react-image-file-resizer run though chromium engine. In safari if file size is greater then 16777216
  and using react-image-file-resizer gives memory error whereas this error doesn't comes with Pica.
  */

  const pica = Pica();
  const resizedCanvas = document.createElement("canvas");
  resizedCanvas.width = width;
  resizedCanvas.height = height;
  await pica.resize(croppedCanvas, resizedCanvas);
  return resizedCanvas;
};

const CardUploader = forwardRef(
  ({ profilePic, handle, width, height, setImage }, ref) => {
    const cropperRef = useRef(null);
    const [profilePicPreview, setProfilePicPreview] = useState(profilePic);
    const [showCropper, setShowCropper] = useState(false);
    const { profileHandle } = useParams();

    const {
      mutate: deleteProfilePicture,
      isLoading: isProfilePictureDeleting,
    } = useDeleteIndividualQuery({
      type: "picture",
    });

    const { mutate: uploadingProfilePicture } = useCreateIndividualQuery({
      createType: "picture",
    });

    useImperativeHandle(ref, () => ({
      submitCroppedImage: async () => {
        const cropper = cropperRef?.current?.cropper;
        if (!cropper) return;
        const croppedCanvas = await getCroppedCanvas(cropper);
        const croppedImage = croppedCanvas.toDataURL("image/jpeg", 1.0);
        return await uploadingProfilePicture(
          { handle, profileData: { file: croppedImage } },
          {
            onSuccess: () => {
              setShowCropper(false);
              setInternalProfilePicPreview({ value: croppedImage });
            },
          },
        );
      },
    }));

    const handleProfilePicDelete = () => {
      deleteProfilePicture(
        {
          handle: profileHandle,
        },
        {
          onSuccess: () => {
            setInternalProfilePicPreview("");
          },
        },
      );
    };

    const [internalProfilePicPreview, setInternalProfilePicPreview] = useState(
      () => {
        return {
          value: profilePicPreview,
          onChange: setProfilePicPreview,
        };
      },
    );

    const handleImageUpload = async e => {
      e.preventDefault();
      const image = e.target?.files?.[0];
      const imageDimensions = await imageSize(URL.createObjectURL(image));
      let imageResized = image;
      setImage(image);
      /**
       * Pica does't work when image resolution are higher then 4065,
       * react-image-file-resizer will resize image and make pica work.
       */

      if (imageDimensions.width * imageDimensions.height >= 16777216) {
        imageResized = await resizeFile(image);
      }

      if (imageResized) {
        setInternalProfilePicPreview({
          value: URL.createObjectURL(imageResized),
        });
        setShowCropper(true);
      }
    };

    return (
      <>
        <div className="relative">
          {!showCropper && (
            <>
              {internalProfilePicPreview.value ? (
                <Image
                  height={height}
                  width={width}
                  objectFit="cover"
                  borderBottomLeftRadius="8%"
                  borderTopLeftRadius="8%"
                  src={internalProfilePicPreview.value}
                />
              ) : (
                <DefaultProfilePic height={height} width={width} />
              )}
              <UploaderActions
                canDeleteImage={!!internalProfilePicPreview.value}
                onUpload={handleImageUpload}
                onDelete={handleProfilePicDelete}
                isDeleting={isProfilePictureDeleting}
              />
            </>
          )}
        </div>
        {showCropper && (
          <div className="rounded-lg overflow-hidden">
            <Cropper
              ref={cropperRef}
              src={internalProfilePicPreview.value}
              viewMode={3}
              minContainerWidth={154}
              style={{ height: height || 215, width: width || 154 }}
              autoCropArea={1}
              rotatable={false}
              checkOrientation={false}
              movable
            />
          </div>
        )}
      </>
    );
  },
);

CardUploader.displayName = "CardUploader";

export default CardUploader;
