import { FC } from "react";

import { Menu } from "components";
import { IMultiTeams } from "../../types/ITeamSelector";
import MultiTeamMenuButton from "./MultiTeamMenuButton";
import MultiTeamMenuList from "./MultiTeamMenuList";
import defaultLogo from "assets/images/default-logo.png";

const MultiTeam: FC<IMultiTeams> = ({
  profile,
  currentTeam,
  teams,
  onChange,
}) => {
  return (
    <Menu closeOnSelect={false}>
      {({ isOpen }) => (
        <>
          <MultiTeamMenuButton
            isOpen={isOpen}
            logo={profile?.logo || defaultLogo}
            title={currentTeam?.title}
          />
          <MultiTeamMenuList
            currentTeam={currentTeam}
            teamsData={teams}
            onChange={onChange}
          />
        </>
      )}
    </Menu>
  );
};

export default MultiTeam;
