import { Box, Heading, Text } from "@chakra-ui/react";
import { FC, ReactElement } from "react";

import { theme } from "ovou-ui";
import { Flex } from "components";
import { useMediaLayout } from "use-media";

interface IPageBody {
  title?: string;
  titleElement?: ReactElement;
  subTitle?: string;
  rightBodyElement?: ReactElement;
  visibleInMobile?: boolean;
  blurContent?: boolean;
  children: ReactElement;
}
const blurProps = {
  filter: "blur(4px)",
  pointerEvents: "none",
  opacity: "0.5",
  maxHeight: "90vh",
  overflow: "hidden",
};
const PageBody: FC<IPageBody> = ({
  title = "",
  titleElement,
  subTitle = "",
  rightBodyElement,
  visibleInMobile = true,
  blurContent = false,
  children,
}) => {
  const isMobile = useMediaLayout({ maxWidth: "768px" });
  const disabledInMobile = isMobile && !visibleInMobile;

  return (
    <Flex height="100%" flexDirection="column">
      <Flex justifyContent="space-between" alignItems="center">
        <Flex flexDirection="column">
          <Box display={{ md: "flex" }} alignItems="center">
            <Flex>
              <Heading
                as="h1"
                fontSize="32px"
                fontWeight="800"
                marginBottom="8px"
              >
                {title}
              </Heading>
              {!disabledInMobile && isMobile && rightBodyElement && (
                <Box marginLeft="auto" sx={blurContent ? { ...blurProps } : {}}>
                  {rightBodyElement}
                </Box>
              )}
            </Flex>

            <Box>{titleElement && titleElement}</Box>
          </Box>

          {!disabledInMobile && subTitle && (
            <Text
              as="p"
              color={theme.palette.ui.greys.grey1}
              fontSize="16px"
              mb="40px"
            >
              {subTitle}
            </Text>
          )}
        </Flex>

        {!disabledInMobile && !isMobile && rightBodyElement && (
          <Box alignSelf="start" sx={blurContent ? { ...blurProps } : {}}>
            {rightBodyElement}
          </Box>
        )}
      </Flex>
      {disabledInMobile ? (
        <Text
          as="p"
          color={theme.palette.ui.greys.grey1}
          fontSize="16px"
          mt="50%"
          textAlign="center"
        >
          The current page is not optimized for mobile devices. To access the
          dashboard, please log in using a laptop or desktop computer.
        </Text>
      ) : (
        <Box sx={blurContent ? { ...blurProps } : {}}>{children}</Box>
      )}
    </Flex>
  );
};

export default PageBody;
