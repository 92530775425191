import { FC, useMemo } from "react";
import {
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
  Box,
} from "components";
import { IDeleteUserModal } from "./types";
import { useCheckPermission } from "hooks";
import { FEATURES, FEATURE_ACTION } from "constants/features";
import { USER_IAM_ROLE } from "types/TeamUsersResponse";

const DeleteUserModal: FC<IDeleteUserModal> = ({
  selectedRows,
  isLoading,
  isOpen,
  openDeleteModal,
  closeDeleteModal,
  handleSeatUnAssign,
}) => {
  const canEditUsers = useCheckPermission(
    FEATURE_ACTION.EDIT,
    FEATURES.MANAGE_USERS,
  );

  const handleCloseModal = () => {
    closeDeleteModal();
  };
  const owner = useMemo(() => {
    return selectedRows.find(
      row =>
        row.user_iam_role === USER_IAM_ROLE.TEAM_ACTIVE_OWNER ||
        row.user_iam_role === USER_IAM_ROLE.TEAM_LEGACY_OWNER,
    );
  }, [selectedRows]);

  return (
    <Box>
      <Button
        block
        onClick={() => {
          if (canEditUsers) {
            openDeleteModal();
          }
        }}
        variant="transparent"
        disabled={!selectedRows?.length || Boolean(owner)}
      >
        <Icon name="trash" className="mr-2" /> Remove
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={handleCloseModal}
        label="UnAssign Seats Confirm Modal"
      >
        <ModalHeader
          className="max-w-lg mx-auto"
          title="Are you sure you want to unassign seats from selected users?"
        />
        <ModalBody className="mb-0">
          <div className="mt-16 flex flex-col md:flex-row items-center">
            <Button
              onClick={handleCloseModal}
              variant="black"
              block
              style={{ flex: 2 }}
            >
              No, Cancel
            </Button>
            <Button
              variant="white"
              className="flex-1 ml-0 mt-3 md:ml-4 md:mt-0"
              onClick={handleSeatUnAssign}
              block
            >
              {isLoading ? <Spinner color="white" /> : "Yes, Proceed"}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </Box>
  );
};

export default DeleteUserModal;
