import React, { FC, useState } from "react";
import { Box, Loader } from "components";
import { BoxProps } from "@chakra-ui/react";

interface IIFrameComponent extends BoxProps {
  srcLink: string;
}

const IFrameComponent: FC<IIFrameComponent> = ({ srcLink, ...props }) => {
  const [isLoading, setIsLoading] = useState(true);
  return (
    <Box {...props}>
      {isLoading && (
        <Box
          justifyContent="center"
          alignItems="center"
          display="flex"
          height="100vh"
        >
          <Loader />
        </Box>
      )}
      <iframe
        src={srcLink}
        onLoad={() => setIsLoading(false)}
        sandbox="allow-scripts allow-modal allow-forms allow-same-origin allow-downloads"
        height="100%"
        width="100%"
        allow="web-share"
      />
    </Box>
  );
};

export default IFrameComponent;
