import React, { FC, useCallback, useState } from "react";
import { theme } from "ovou-ui";
import { Divider } from "@chakra-ui/react";

import { Modal } from "../../components/Modal";
import { ICard } from "../../types/Card";
import AssignDeviceForm from "./AssignDeviceForm";
import { Flex, Spinner, Text } from "../../components";
import { useTeamUsersQuery } from "features/manageUsers/api/useTeamUsersQuery";

interface IAssignDeviceModal {
  teamId: number;
  card: ICard | undefined;
  isOpen: boolean;
  isSubmitting?: boolean;
  onClose: () => void;
  onAssignCardToMember: (handle: string) => void;
  onAssignCardToBusiness: (card: ICard) => void;
}

export const AssignDeviceModal: FC<IAssignDeviceModal> = ({
  teamId,
  card,
  isOpen,
  isSubmitting = false,
  onClose,
  onAssignCardToMember,
  onAssignCardToBusiness,
}) => {
  const [searchQuery, setSearchQuery] = useState("");

  const { data: teamUsers, isLoading: isLoadingTeamUsers } = useTeamUsersQuery({
    teamId,
    searchQuery,
    limit: 300,
    sort: "email",
    serializer: "basic_info",
  });

  const handleFormSubmit = useCallback(
    (handle: string) => {
      onAssignCardToMember(handle);
    },
    [teamUsers, onAssignCardToMember],
  );

  const handleAssignToBusiness = useCallback(() => {
    onAssignCardToBusiness(card!);
  }, [onAssignCardToBusiness]);

  if (!card) return <></>;

  return (
    <Modal isOpen={isOpen} onClose={onClose} label={"Tags Modal"}>
      <Flex flexDirection="column" width={408}>
        <Text
          fontWeight="extrabold"
          fontSize={24}
          color={theme.palette.brand.primary.black}
          marginBottom={2}
        >
          Assign Card
        </Text>
        <Flex marginBottom={4} gap={1}>
          <Text fontSize={16} color={theme.palette.ui.greys.grey1}>
            Card Selected:
          </Text>
          <Text
            fontSize={16}
            fontWeight="bold"
            color={theme.palette.ui.greys.grey1}
          >
            {card.tag || "No card identifier"}
          </Text>
        </Flex>

        <AssignDeviceForm
          users={teamUsers?.results || []}
          isSubmitting={isSubmitting}
          onSubmit={handleFormSubmit}
          isLoading={isLoadingTeamUsers}
          handleSearch={searchQuery => setSearchQuery(searchQuery)}
        />
      </Flex>

      <Flex alignItems="center" marginY={9}>
        <Divider borderColor={theme.palette.ui.greys.grey4} paddingTop={1} />
        <Text
          paddingX={3}
          fontSize="0.875rem"
          textColor={theme.palette.ui.greys.grey2}
        >
          or
        </Text>
        <Divider borderColor={theme.palette.ui.greys.grey4} paddingTop={1} />
      </Flex>

      <Flex justifyContent="center">
        {isSubmitting ? (
          <Spinner color={theme.palette.brand.primary.orange} />
        ) : (
          <Text
            fontSize="0.875rem"
            fontWeight="semibold"
            textColor={theme.palette.brand.primary.orange}
            cursor="pointer"
            onClick={handleAssignToBusiness}
          >
            Link Card to Company Profile
          </Text>
        )}
      </Flex>
    </Modal>
  );
};
