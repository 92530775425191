import React, { FC } from "react";

import { TEXTS } from "../../constants";
import { Integrations } from "../../features";
import { PageSetup } from "../../components";
import { FEATURES } from "constants/features";

const IntegrationsPage: FC = () => {
  return (
    <PageSetup
      title={TEXTS.routes.INTEGRATIONS}
      subTitle={TEXTS.routes.INTEGRATIONS_SUB_TITLE}
      feature={FEATURES.INTEGRATIONS}
    >
      <Integrations />
    </PageSetup>
  );
};

export default IntegrationsPage;
