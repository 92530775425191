import React from "react";

const Integrations = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5958 2.73772C16.2053 2.34719 15.5721 2.34719 15.1816 2.73772C14.791 3.12824 14.791 3.76141 15.1816 4.15193L16.5854 5.55579H8.11193C7.02164 5.55579 5.976 5.9889 5.20505 6.75986C4.43409 7.53081 4.00098 8.57645 4.00098 9.66674V11.2222C4.00098 11.7745 4.44869 12.2222 5.00098 12.2222C5.55326 12.2222 6.00098 11.7745 6.00098 11.2222V9.66674C6.00098 9.10688 6.22338 8.56995 6.61926 8.17407C7.01514 7.77819 7.55207 7.55579 8.11193 7.55579H16.5854L15.1816 8.95962C14.791 9.35015 14.791 9.98331 15.1816 10.3738C15.5721 10.7644 16.2053 10.7644 16.5958 10.3738L19.6855 7.28408C19.8793 7.1017 20.0003 6.84287 20.0003 6.55579C20.0003 6.26885 19.8794 6.01014 19.6858 5.82778L16.5958 2.73772ZM8.81904 13.626C9.20956 14.0165 9.20956 14.6497 8.81904 15.0402L7.41519 16.4441H15.8893C16.4492 16.4441 16.9861 16.2217 17.382 15.8258C17.7779 15.4299 18.0003 14.893 18.0003 14.3331V12.7776C18.0003 12.2254 18.448 11.7776 19.0003 11.7776C19.5526 11.7776 20.0003 12.2254 20.0003 12.7776V14.3331C20.0003 15.4234 19.5671 16.4691 18.7962 17.24C18.0252 18.011 16.9796 18.4441 15.8893 18.4441H7.41519L8.81904 19.8479C9.20956 20.2385 9.20956 20.8716 8.81904 21.2621C8.42851 21.6527 7.79535 21.6527 7.40482 21.2621L4.29387 18.1512C4.09861 17.9559 4.00097 17.7 4.00098 17.4441C4.00098 17.3074 4.02839 17.1771 4.07803 17.0585C4.12418 16.9479 4.19107 16.8439 4.2787 16.7525C4.2843 16.7466 4.28997 16.7408 4.29571 16.7351L7.40482 13.626C7.79535 13.2355 8.42851 13.2355 8.81904 13.626Z"
      />
    </svg>
  );
};

export default React.memo(Integrations);
