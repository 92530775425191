import React, { FC } from "react";
import { Box, HStack, Text } from "@chakra-ui/react";
import { theme } from "ovou-ui";

import { TagColorIndicator } from "./TagColorIndicator";
import Icon from "../../../components/Icon";

interface ITagListItemProps {
  name: string;
  color: string;
  onDelete?: () => void;
}

const IconComponent = Icon as any;

export const TagListItem: FC<ITagListItemProps> = ({
  name,
  color,
  onDelete,
}) => {
  return (
    <HStack
      boxSizing="border-box"
      direction="row"
      justifyContent="center"
      width="max-content"
      height={8}
      paddingX={3}
      paddingY={2}
      gap={1}
      backgroundColor={theme.palette.brand.primary}
      borderRadius={6}
      borderColor={theme.palette.ui.greys.grey3}
      borderWidth={1}
    >
      <TagColorIndicator color={color} />
      <Text
        fontWeight="semibold"
        fontSize="14px"
        lineHeight="16px"
        align="center"
        color={theme.palette.brand.primary.black}
      >
        {name}
      </Text>
      {onDelete && (
        <Box
          onClick={onDelete}
          cursor="pointer"
          sx={{ svg: { color: theme.palette.ui.greys.grey2 } }}
        >
          <IconComponent name="close" width={16} height={16} color="red" />
        </Box>
      )}
    </HStack>
  );
};
