import React, { FC, useEffect, useState } from "react";
import { useWizard } from "react-use-wizard";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import { useMediaLayout } from "use-media";

import {
  useCardDesignsQuery,
  useUpdateProfileQuery,
  addProfileDesignQuery,
  useUpdateTeamMutation,
} from "../../settings/queries";
import { Flex, SchemePickerFormField } from "../../../components";
import { setProfile, useUser } from "../../../context";
import { generateMediaPath } from "../../../utils";
import { useProfileDataQuery } from "features/auth/queries";
import ImageUploadFormField from "./ImageUploadFormField";
import { StepSubmit } from "./StepSubmit";
import { ProfilePreview } from "./ProfilePreview";
import { StepSection } from "./StepSection";
import { IProfile } from "types/Profile";
import { ImageType } from "../helpers/constants";
import { WIZARD_STEP } from "types/Team";
import { useTracking } from "hooks";
import { ANALYTICS_EVENTS } from "constants/analyticEvents";
import { useProfileData } from "../context";

const validationSchema = yup.object({});

interface FormValues {
  logo: string | null;
  card_design_id: number | undefined;
  background: string | null;
  cardSchema: number;
}

export const BusinessDesignStep: FC = () => {
  const track = useTracking();
  const isMobile = useMediaLayout({ maxWidth: "768px" });
  const { nextStep } = useWizard();
  const default_profile = useProfileData();

  const [businessProfile, setBusinessProfile] = useState<IProfile>();

  const [{ currentTeam, currentProfile }, dispatch] = useUser();
  const { getProfileData } = useProfileDataQuery({});
  const { data: cardDesigns } = useCardDesignsQuery({
    teamId: currentTeam?.id,
  });

  const [updateTeam] = useUpdateTeamMutation({});
  const { mutate: updateProfile, isLoading } = useUpdateProfileQuery({});

  const handleSubmit = async ({ cardSchema, ...values }: any) => {
    const isCardSchemaObject = typeof cardSchema == "object";
    const cardDesignId = cardDesigns?.designs?.find(
      (design: any) =>
        design.id === (isCardSchemaObject ? cardSchema?.id : cardSchema),
    )?.id;

    if (isCardSchemaObject) {
      await addProfileDesignQuery({ cardSchema });
    }

    const updatedProfileData = await updateProfile({
      profileData: {
        ...values,
        card_design_id: cardDesignId,
      },
      handle: businessProfile?.handle,
    });

    dispatch(
      setProfile({
        ...currentProfile,
        ...updatedProfileData,
      }),
    );

    track({
      eventName: ANALYTICS_EVENTS.TEAM_ONBOARDING_BUSINESS_DESIGN_SUBMIT,
      customTrackingProps: {
        cardDesignId,
      },
    });

    nextStep();
  };

  const handleSkip = () => {
    track({
      eventName: ANALYTICS_EVENTS.TEAM_ONBOARDING_BUSINESS_DESIGN_SKIP,
    });

    nextStep();
  };

  const handleLogoChange = (croppedImage: string) => {
    setBusinessProfile(businessProfile => {
      return {
        ...businessProfile,
        logo: croppedImage,
      } as IProfile;
    });

    track({
      eventName: ANALYTICS_EVENTS.TEAM_ONBOARDING_BUSINESS_DESIGN_UPLOAD_LOGO,
    });
  };

  const handleLogoDelete = () => {
    setBusinessProfile(businessProfile => {
      return {
        ...businessProfile,
        logo: null,
      } as IProfile;
    });

    track({
      eventName: ANALYTICS_EVENTS.TEAM_ONBOARDING_BUSINESS_DESIGN_DELETE_LOGO,
    });
  };

  const handleCoverChange = (croppedImage: string) => {
    setBusinessProfile(businessProfile => {
      return {
        ...businessProfile,
        background: croppedImage,
      } as IProfile;
    });

    track({
      eventName: ANALYTICS_EVENTS.TEAM_ONBOARDING_BUSINESS_DESIGN_UPLOAD_COVER,
    });
  };

  const handleCoverDelete = () => {
    setBusinessProfile(businessProfile => {
      return {
        ...businessProfile,
        background: null,
      } as IProfile;
    });

    track({
      eventName: ANALYTICS_EVENTS.TEAM_ONBOARDING_BUSINESS_DESIGN_DELETE_COVER,
    });
  };

  const handleGetBusinessProfile = async () => {
    const data = await getProfileData();
    setBusinessProfile(data?.profiles?.[0]);
  };

  const handleColorSchemeChange = (design: any) => {
    setBusinessProfile(businessProfile => {
      return {
        ...businessProfile,
        design,
      } as IProfile;
    });
    track({
      eventName:
        ANALYTICS_EVENTS.TEAM_ONBOARDING_BUSINESS_DESIGN_SELECT_PROFILE_DESIGN,
      customTrackingProps: {
        designProps: {
          id: design?.id,
          title: design?.title,
        },
      },
    });
  };

  useEffect(() => {
    updateTeam({
      teamID: currentTeam?.id,
      teamData: {
        ...currentTeam,
        wizard_step: WIZARD_STEP.BUSINESS_DESIGN,
      },
    });
  }, []);

  useEffect(() => {
    handleGetBusinessProfile();
  }, []);

  const initialValues: FormValues = {
    logo: businessProfile?.logo
      ? generateMediaPath(businessProfile?.logo || "") || ""
      : null,
    card_design_id: businessProfile?.design?.id,
    background: businessProfile?.background
      ? generateMediaPath(businessProfile?.background || "") || ""
      : null,
    cardSchema:
      businessProfile?.design?.id ??
      ((cardDesigns?.designs?.[0].id || 1) as any),
  };

  return (
    <Flex justifyContent="center" gap={100}>
      <StepSection
        title="Let’s apply your branding"
        subTitle="Your logo, cover, and design choice applies to all team profies as well as your busines page."
      >
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ isValid }) => (
            <Form id="settings-form" autoComplete="off">
              <StepSection sectionType="subSection" title="Upload logo">
                <Field
                  component={ImageUploadFormField}
                  label="Logo"
                  name="logo"
                  imageType={ImageType.LOGO}
                  onChange={handleLogoChange}
                  onDelete={handleLogoDelete}
                />
              </StepSection>

              <StepSection sectionType="subSection" title="Upload cover">
                <Field
                  component={ImageUploadFormField}
                  label="background-image"
                  name="background"
                  imageType={ImageType.COVER}
                  onChange={handleCoverChange}
                  onDelete={handleCoverDelete}
                />
              </StepSection>

              <StepSection
                sectionType="subSection"
                title="Select your profile design"
              >
                <Field
                  component={SchemePickerFormField}
                  label="Profile URL"
                  name="cardSchema"
                  schemes={cardDesigns?.designs}
                  autoSubmitForm={false}
                  setDefaultColorScheme={handleColorSchemeChange}
                  cardSchema={
                    businessProfile?.design?.id ??
                    ((cardDesigns?.designs?.[0].id || 1) as number)
                  }
                />
              </StepSection>

              <StepSubmit
                isDisabled={isLoading || !isValid || !businessProfile?.handle}
                isLoading={isLoading}
                onSkip={handleSkip}
              />
            </Form>
          )}
        </Formik>
      </StepSection>

      {!isMobile && (
        <ProfilePreview
          profile={businessProfile}
          default_profile={default_profile}
        />
      )}
    </Flex>
  );
};
