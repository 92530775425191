import React from "react";

const Mail = ({ className }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M14.4201 14.7395H3.07445C2.2912 14.7395 1.65625 14.1047 1.65625 13.3217V4.75269C1.6893 3.9938 2.31467 3.39577 3.07445 3.39649H14.4201C15.2033 3.39649 15.8383 4.03129 15.8383 4.81437V13.3217C15.8383 14.1047 15.2033 14.7395 14.4201 14.7395ZM3.07445 6.94119V13.3217H14.4201V6.94119L8.74726 9.91874L3.07445 6.94119ZM3.07445 4.81437V5.52331L8.74726 8.21729L14.4201 5.52331V4.81437H3.07445Z"
        fill="#686868"
      />
    </svg>
  );
};

export default React.memo(Mail);
