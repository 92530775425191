import { IProfile } from "./Profile";

export interface ICard {
  id: string;
  profile: IProfile | null;
  state: CardState;
  tag: string;
}

export enum CardState {
  APPOINTED = "appointed",
  ASSIGNED = "assigned",
  UNASSIGNED = "unassigned",
}
