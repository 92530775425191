import React, { useState } from "react";

import { useToggleSetupByCompanyMutation } from "./useToggleSetupByCompanyMutation";

const SetupByCompanyToggle = ({ setUpByCompany, handle, refetch }) => {
  const [isSetupByCompany, setIsSetupByCompany] = useState(setUpByCompany);
  const { mutate, isLoading } = useToggleSetupByCompanyMutation();

  const handleIsUserEditableChange = e => {
    const isChecked = e.target.checked;
    if (isLoading) return;

    mutate(
      {
        toggleSetupByCompanyData: { isUserEditable: !isChecked, handle },
      },
      {
        onSuccess: () => {
          setIsSetupByCompany(p => !p);
          refetch();
        },
      },
    );
  };

  return (
    <div
      onClick={e => e.stopPropagation()}
      className="text-white mt-2"
      aria-hidden="true"
    >
      <label className="inline-flex items-center">
        <input
          type="checkbox"
          className="form-checkbox h-5 w-5 text-primary"
          onChange={handleIsUserEditableChange}
          checked={isSetupByCompany}
        />
        <span className="ml-2 font-bold text-white select-none">
          Setup by Company
        </span>
      </label>
    </div>
  );
};

export default SetupByCompanyToggle;
