import TutorialsList from "./TutorialsList";
import { PageSetup } from "../../components";
import { TEXTS } from "../../constants";
import { FEATURES } from "constants/features";

const Tutorials = () => {
  return (
    <PageSetup
      title={TEXTS.routes.TUTORIALS}
      subTitle={TEXTS.routes.TUTORIALS_SUB_TITLE}
      feature={FEATURES.TUTORIALS}
    >
      <div className="p-5 sm:py-10 sm:px-12 mb-5">
        <TutorialsList />
      </div>
    </PageSetup>
  );
};

export default Tutorials;
