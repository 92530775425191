import React, { useMemo } from "react";

import _groupBy from "lodash/groupBy";
import _isEmpty from "lodash/isEmpty";
import _mapValues from "lodash/mapValues";
import _omit from "lodash/omit";
import { useLocation } from "react-router-dom";

import ContactInformation from "./contactInformation/ContactInformation";
import { useProfileSectionQueryForContact } from "../../features/individualProfile/queries/useProfileSectionQuery";
import { getFieldType } from "utils";

export const FIELD_TYPES = {
  ADDRESS: "address",
  PHONE: "phone",
  EMAIL: "email",
  WEBSITE: "website",
};

export const SECTION_EMPTY_FIELD_VALUE = {
  field_type_id: "",
  value: "",
  id: null,
  order: -1,
};

export const EMPTY_PHONE_FIELD_VALUE = {
  ...SECTION_EMPTY_FIELD_VALUE,
  extension: "",
};

export const initialValues = {
  phones: [EMPTY_PHONE_FIELD_VALUE],
  emails: [SECTION_EMPTY_FIELD_VALUE],
  websites: [SECTION_EMPTY_FIELD_VALUE],
  locations: [{ ...SECTION_EMPTY_FIELD_VALUE, field_type_id: "location" }],
};

export const getSeparatedAddress = address => {
  const separatedAddress = address.split(";");

  return {
    street1: separatedAddress[2],
    street2: separatedAddress[1],
    city: separatedAddress[3],
    state: separatedAddress[4],
    postalCode: separatedAddress[5],
    country: separatedAddress[6],
  };
};

export const mapPhoneExtension = phoneWithExtension => {
  const [phone, extension] = phoneWithExtension.split(";");

  return {
    value: phone,
    extension,
  };
};

const EditContactInformationPage = () => {
  const location = useLocation();
  const { contactInformationItems } = location.state;

  const { data: sections, isLoading } = useProfileSectionQueryForContact();

  const contactInformationSubsections =
    sections?.sections_definition?.find(
      section => section.id === "contact_information",
    )?.subsections_definition || [];

  const contactInformationOptions = contactInformationSubsections?.map(
    contactInformationSections => ({
      [(contactInformationSections.id || "").toLowerCase()]:
        contactInformationSections.field_types?.map(
          ({ id, title, ...rest }) => ({
            label: title || "",
            value: id || "",
            parentId: contactInformationSections.id,
            ...rest,
          }),
        ),
    }),
  );

  const contactInformationOptionsObj = contactInformationOptions.reduce(
    (acc, cur) => {
      const key = Object.keys(cur)[0];
      return { ...acc, [key]: cur[key] };
    },
    {},
  );

  const profileContactInformation = useMemo(() => {
    if (!_isEmpty(contactInformationOptionsObj)) {
      const fieldTypes = contactInformationItems.map(field => {
        const fieldType = getFieldType(
          contactInformationOptionsObj,
          field?.field_type?.id || "",
        );
        return {
          field_type_id: field?.field_type?.id,
          value: field?.value,
          id: field.id,
          fieldType: fieldType?.toLowerCase(),
          order: field.order,
          ...(fieldType === FIELD_TYPES.ADDRESS &&
            getSeparatedAddress(field.value || "")),
          ...(fieldType === FIELD_TYPES.PHONE &&
            mapPhoneExtension(field.value || "")),
        };
      });
      return _mapValues(_groupBy(fieldTypes, "fieldType"), fieldTypes =>
        fieldTypes.map(fieldType => _omit(fieldType, "fieldType")),
      );
    }
    return null;
  }, [contactInformationOptionsObj, contactInformationItems]);

  return (
    <>
      {!isLoading && (
        <ContactInformation
          profileContactInformation={profileContactInformation}
          contactInformationOptions={contactInformationOptionsObj}
        />
      )}
    </>
  );
};

export default EditContactInformationPage;
