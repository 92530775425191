import { FC, useMemo } from "react";
import { Field, Form, Formik } from "formik";
import {
  Box,
  CButton,
  Flex,
  Icon,
  SelectFormField,
  Spinner,
  Stack,
  Text,
} from "components";
import { components, OptionProps } from "react-select";
import { IChangeRoleForm } from "./types";
import { theme } from "ovou-ui";

const OWNER = "Owner"
const OwnerPermissionDescription = "Full access to all features, including billing and administrative."
const ChangeRoleForm: FC<IChangeRoleForm> = ({
  userTypes,
  userIamRole,
  handleSubmit,
  isLoading,
}) => {
  const roleOptions = useMemo(() => {
    return userTypes?.map(userType => ({
      label: userType.metadata.label,
      value: userType.user_iam_role,
      description: userType.metadata.description,
      ...userType,
    })).concat([{
      label: OWNER,
      description: OwnerPermissionDescription,
      isDisabled: true
    }] as any)
  }, [userTypes]);

  return (
    <Formik
      initialValues={{
        role: userIamRole,
      }}
      onSubmit={({ role }) => {
        const { user_iam_role: user_iam_role } =
          userTypes?.find(option => option.user_iam_role === role) || {};
        handleSubmit(user_iam_role as string);
      }}
      enableReinitialize
    >
      {() => (
        <Form noValidate autoComplete="off">
          <Box marginTop={4} position="relative">
            <Field
              label="Role"
              name="role"
              placeholder="Role"
              options={roleOptions}
              component={SelectFormField}
              renderInPortal
              components={{ Option, SingleValue }}
              styles={{
                option: (styles: any, { isFocused, isSelected, data }: any) => {
                  return {
                    ...styles,
                    backgroundColor:
                      isFocused || isSelected ? "#F5F5F5" : "white",
                    color: "green",
                    padding: "0.5rem 0 0.5rem 0.5rem",
                    borderRadius: "0.5rem",
                    marginLeft: "0.625rem",
                    marginTop: "0.313rem",
                    marginRight: "0px",
                    width: "95%",
                    pointerEvents: data.isDisabled ? "none" : ""
                  };
                },
              }}
            />
          </Box>
          <CButton width="full" marginTop={8} type="submit" height="3.5rem">
            {isLoading ? <Spinner color="white" /> : "Save"}
          </CButton>
        </Form>
      )}
    </Formik>
  );
};

const Option = (props: OptionProps<any, any>) => {
  return (
    <components.Option {...props}>
      <Flex justifyContent="space-between" alignItems="center">
        <Stack>
          <Text fontSize="1rem" color={theme.palette.ui.greys.grey1}>
            {props.label}
          </Text>
          <Text fontSize="0.75rem" color={theme.palette.ui.greys.grey2}>
            {props.data.description}
          </Text>
        </Stack>
        {props.isSelected && (
          <Box
            marginRight="0.625rem"
            sx={{
              svg: {
                color: `${theme.palette.brand.primary.orange}`,
                height: "1rem",
                width: "1rem",
              },
            }}
          >
            <Icon name="check" />
          </Box>
        )}
      </Flex>
    </components.Option>
  );
};
const SingleValue = (props: any) => {
  return (
    <components.SingleValue {...props}>
      <Text size="lg">{props.data.label}</Text>
    </components.SingleValue>
  );
};

export default ChangeRoleForm;
