import React from "react";

const Tutorial = ({
  className,
  role,
  ignoreDefaultSize,
  width = 24,
  height = 24,
  strokeWidth = 2,
  onClick,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width={ignoreDefaultSize ? undefined : width}
      height={ignoreDefaultSize ? undefined : height}
      role={role}
      className={className}
      strokeWidth={strokeWidth}
      onClick={onClick}
    >
      <path
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={32}
        d="M32 192 256 64l224 128-224 128L32 192z"
        stroke="currentColor"
      />
      <path
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={32}
        d="M112 240v128l144 80 144-80V240m80 128V192M256 320v128"
        stroke="currentColor"
      />
    </svg>
  );
};

export default React.memo(Tutorial);
