import { useEffect, useState } from "react";

export class Observable<T> {
    constructor(private _value: T, private _listeners: Set<(value: T) => unknown> = new Set()) { }

    getValue() {
        return this._value;
    }

    setValue(value: T) {
        this._value = value;
        for (const callback of Array.from(this._listeners.values())) {
            callback(this._value);
        }
    }

    subscribe(callback: (value: T) => unknown): void {
        this._listeners.add(callback);
    }

    unsubscribe(callback: (value: T) => unknown): void {
        this._listeners.delete(callback);
    }
}

export const useObservableValue = <T>(observable: Observable<T>): T => {
    const [observableValue, setObservableValue] = useState(observable.getValue());

    useEffect(() => {
        const listener = (value: T) => {
            setObservableValue(value);
        };
        observable.subscribe(listener);
        return () => {
            observable.unsubscribe(listener);
        };
    }, [observable]);

    return observableValue;
};
