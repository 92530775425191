import { Flex, Text } from "@chakra-ui/react";
import { theme } from "ovou-ui";
import { FC, ReactElement } from "react";
import { FiArrowLeft } from "react-icons/fi";

interface ITopBar {
  showBackArrow?: boolean;
  handleBackButton?: () => void;
  backArrowText?: string;
  centerElement?: ReactElement;
  rightElement?: ReactElement;
}
const TopBar: FC<ITopBar> = ({
  rightElement,
  centerElement,
  showBackArrow,
  backArrowText = "",
  handleBackButton,
}) => {
  return (
    <Flex alignItems="center" width="100%" position="sticky" mb="16px">
      {showBackArrow && (
        <Flex
          flex="1"
          justifyContent="flex-start"
          mr="auto"
          align="center"
          minH="21px"
        >
          <FiArrowLeft
            cursor="pointer"
            color="#686868"
            size="16px"
            onClick={handleBackButton}
          />
          <Text fontSize="14px" ml="8px" color={theme.palette.ui.greys.grey1}>
            {backArrowText}
          </Text>
        </Flex>
      )}
      <Flex flex="1" justifyContent="center">
        {rightElement}
      </Flex>
      <Flex flex="1" justifyContent="flex-end" ml="auto">
        {centerElement}
      </Flex>
    </Flex>
  );
};

export default TopBar;
