import { useMemo } from "react";
import { useUserState } from "context/User";
import { ParagonIntegrationService } from "../services/ParagonIntegrationService";
import { IntegrationServiceProvider } from "./IntegrationServiceContext";

export const ParagonIntegrationServiceProvider = ({ children }: { children: any }) => {
    const { currentTeam } = useUserState();
    const service = useMemo(() => new ParagonIntegrationService(currentTeam?.id), [currentTeam?.id]);
    return (
        <IntegrationServiceProvider service={service}>{children}</IntegrationServiceProvider>
    );
};
