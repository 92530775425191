import { MutationResultPair, useMutation, useQuery } from "react-query";

import api from "../../../api";
import { IGetContactsProps } from "../types/Contact";
import { useCallback } from "react";

export enum TeamContactsSortOption {
  CREATED_ASCENDING = "created",
  CREATED_DESCENDING = "-created",
  FULL_NAME_ASCENDING = "full_name",
  FULL_NAME_DESCENDING = "-full_name",
}

type IUseGetTeamContactsProps = {
  teamId: number;
  queryAutomatically?: boolean;
  searchQuery?: string;
  tags?: number[];
  teamMembers?: number[];
  dateLowerBound?: string;
  dateUpperBound?: string;
  sort?: TeamContactsSortOption;
  offset?: number;
  limit?: number;
};

export const useGetTeamContacts = ({
  teamId,
  queryAutomatically = true,
  searchQuery = "",
  tags,
  teamMembers,
  dateLowerBound,
  dateUpperBound,
  sort,
  offset,
  limit,
}: IUseGetTeamContactsProps) => {
  return useQuery<IGetContactsProps>(
    [
      "team-contacts",
      {
        teamId,
        queryAutomatically,
        searchQuery,
        tags,
        teamMembers,
        dateLowerBound,
        dateUpperBound,
        sort,
        offset,
        limit,
      },
    ],
    async () => {
      const { data } = await api.get<IGetContactsProps>(
        `/profile/teams/${teamId}/contacts`,
        {
          params: {
            search: searchQuery?.length > 0 ? searchQuery : undefined,
            tags: tags?.toString(),
            team_members: teamMembers?.toString(),
            window_lower_bound: dateLowerBound,
            window_upper_bound: dateUpperBound,
            sort,
            offset,
            limit,
          },
        },
      );
      return data;
    },
    {
      enabled: teamId !== undefined && queryAutomatically,
      refetchOnWindowFocus: !queryAutomatically,
    },
  );
};

type IUseExportTeamContactsProps = {
  teamId: number;
  searchQuery?: string;
  tags?: number[];
  teamMembers?: number[];
  dateLowerBound?: string;
  dateUpperBound?: string;
  sort?: TeamContactsSortOption;
};

const exportTeamContactsService = async ({
  teamId,
  searchQuery = "",
  tags,
  teamMembers,
  dateLowerBound,
  dateUpperBound,
  sort,
}: IUseExportTeamContactsProps) => {
  const response = await api.get<Blob>(
    `/profile/teams/${teamId}/contacts/export`,
    {
      params: {
        search: searchQuery?.length > 0 ? searchQuery : undefined,
        tags: tags?.toString(),
        team_members: teamMembers?.toString(),
        window_lower_bound: dateLowerBound,
        window_upper_bound: dateUpperBound,
        sort,
      },
      responseType: "blob"
    },
  );

  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const currentDate = [year, month, day].join("-");
  const filename = `contact_list_${currentDate}.csv`;

  // create file link in browser's memory
  const href = URL.createObjectURL(response.data);

  // create "a" HTML element with href to file & click
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', filename); //or any other extension
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};

export const useExportTeamContacts = ({
  teamId,
  searchQuery = "",
  tags,
  teamMembers,
  dateLowerBound,
  dateUpperBound,
  sort,
}: IUseExportTeamContactsProps): MutationResultPair<void, any, {}, any> => {
  const mutation = useCallback(() => exportTeamContactsService({
    teamId,
    searchQuery,
    tags,
    teamMembers,
    dateLowerBound,
    dateUpperBound,
    sort,
  }), [
    teamId,
    searchQuery,
    tags,
    teamMembers,
    dateLowerBound,
    dateUpperBound,
    sort,
  ]);

  return useMutation(mutation);
};