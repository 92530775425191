import React from "react";

import { Box, Flex } from "@chakra-ui/layout";
import { Spinner, Image } from "@chakra-ui/react";
import { theme } from "ovou-ui";

import LOGO from "../../assets/images/logo-dark.svg";

const Loader = ({ fullScreen = true }) => {
  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      flex="1"
      sx={
        fullScreen
          ? {
              position: "absolute",
              inset: 0,
              background: theme.palette.brand.primary.white,
            }
          : {}
      }
      aria-label="Loader"
      role="presentation"
    >
      <Box mb="8">
        <Image src={LOGO} width={92} height={18} alt="dark-logo" />
      </Box>
      <Box>
        <Spinner
          thickness="3px"
          speed="0.65s"
          emptyColor="brand.gray.200"
          color="brand.primary.500"
          size="lg"
        />
      </Box>
    </Flex>
  );
};

export default Loader;
