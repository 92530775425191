import React, { FC } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { theme } from "ovou-ui";

import { getFormattedDate } from "../../../utils/dateFunctions";
import { Avatar, GlobalListItem } from "../../../components";
import { IContactNote } from "../types/ContactNote";

interface IContactNoteProps {
  contactNote: IContactNote;
}

export const ContactNote: FC<IContactNoteProps> = ({ contactNote }) => {
  const createdAt = new Date(contactNote.created);
  const formattedDate = getFormattedDate(createdAt);
  const profile = contactNote?.profiles?.[0];

  return (
    <Flex
      marginTop={3}
      backgroundColor={theme.palette.ui.greys.grey5}
      borderRadius="8px"
      flexDirection="column"
      padding={6}
    >
      <Flex alignItems="center">
        <Text
          flex={1}
          fontWeight="semibold"
          fontSize={14}
          color={theme.palette.brand.primary.black}
        >
          {contactNote.title}
        </Text>
        <Text
          width="120px"
          fontSize={12}
          color={theme.palette.ui.greys.grey1}
          textAlign="end"
        >
          {formattedDate}
        </Text>
      </Flex>

      <Text fontSize={16} color={theme.palette.ui.greys.grey1} marginTop={2}>
        {contactNote.message}
      </Text>

      {profile && (
        <Box marginTop={4}>
          <GlobalListItem
            title={`${profile.first_name} ${profile.last_name}`}
            leftElement={
              <Avatar
                name={profile.first_name}
                src={profile.picture}
                width="26px"
                height="36px"
                borderRadius="4px"
              />
            }
          />
        </Box>
      )}
    </Flex>
  );
};
