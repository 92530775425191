import React, { FC } from "react";
import { Text, Flex, Button } from "@chakra-ui/react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { theme } from "ovou-ui";

import { Modal } from "../Modal";
import { IContact } from "../../features/contacts/types/Contact";
import TagsToContactForm from "./TagsToContactForm";
import { ITag } from "../../features/contacts/types/Tag";
import { useAddTagToContact } from "../../features/contacts/queries/useTags";

interface ITagsToContactModal {
  tags: ITag[];
  contact: IContact | undefined;
  isOpen: boolean;
  onClose: () => void;
  onManageTags: () => void;
  refetchData?: () => void;
}

export const TagsToContactModal: FC<ITagsToContactModal> = ({
  tags,
  contact,
  isOpen,
  onClose,
  onManageTags,
  refetchData,
}) => {
  const { mutate, isLoading } = useAddTagToContact({
    refetchData,
  });

  const handleAddTagsToContact = (tagIds: number[]) => {
    mutate({ contactId: contact?.id, tagIds });
    onClose();
  };

  if (!contact) return <></>;

  return (
    <Modal isOpen={isOpen} onClose={onClose} label={"Tags Modal"}>
      <Flex flexDirection="column" width={408}>
        <Text
          fontWeight="extrabold"
          fontSize={24}
          color={theme.palette.brand.primary.black}
          marginBottom={4}
        >
          Add tags to contact
        </Text>

        {tags.length === 0 ? (
          <Flex flexDirection="column">
            <Flex
              alignItems="center"
              marginTop={6}
              padding={4}
              backgroundColor={theme.palette.ui.greys.grey5}
              borderRadius="8px"
            >
              <AiOutlineInfoCircle
                color={theme.palette.brand.primary.black}
                size={48}
              />
              <Text
                marginLeft={4}
                fontSize={14}
                lineHeight={5}
                color={theme.palette.brand.primary.black}
              >
                You have not created any tags. Please create one first.
              </Text>
            </Flex>

            <Button marginTop={4} onClick={onManageTags}>
              Manage Tags
            </Button>
          </Flex>
        ) : (
          <TagsToContactForm
            tags={tags}
            contact={contact}
            isAddingTagsToContact={isLoading}
            onSubmit={handleAddTagsToContact}
          />
        )}
      </Flex>
    </Modal>
  );
};
