import React, { FC, useEffect, useMemo } from "react";
import { DrawerComponent, IFrameComponent } from "components";
import { getLoginToOVOULink } from "utils/openLoginAsUserPage";

const ProfileEmbedded: FC<{
  isOpen: boolean;
  onClose: () => void;
  profileId: string;
  teamId: string;
  link?: string;
}> = ({ isOpen, onClose, profileId, teamId, link }) => {
  const loginLink = useMemo(() => {
    return getLoginToOVOULink({
      profileID: profileId,
      teamID: teamId,
    });
  }, [profileId, teamId]);

  useEffect(() => {
    const handleClick = (event: MessageEvent<any>) => {
      if (event.data.close) {
        onClose();
      }
    };
    window.addEventListener("message", handleClick, false);
    return () => {
      window.removeEventListener("message", handleClick, false);
    };
  }, []);
  return (
    <DrawerComponent isOpen={isOpen} onClose={onClose}>
      <IFrameComponent
        srcLink={link ? link : loginLink}
        height="100%"
        width="100%"
      />
    </DrawerComponent>
  );
};

export default ProfileEmbedded;
