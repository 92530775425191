import React from "react";
import ReactModal from "react-modal";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import "react-phone-input-2/lib/material.css";
import { initializeDatadogRum } from './service/datadog'; // Assume this is where your function is defined

import "./main.css";
import "./App.css";

import App from "./App";
import { PermissionsProvider, UserProvider } from "./context";
import * as serviceWorker from "./serviceWorker";
import { API_MOCKING } from "constants";
import { FeatureFlagProvider } from "providers/FeatureFlagProvider";
import { AnalyticsProvider } from "context/Analytics";
import { ParagonIntegrationServiceProvider } from "features/integrations/context/ParagonIntegrationServiceProvider";

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
// require mocks only if enabled for development
if (API_MOCKING === "enabled") require("./mocks");
// Allows ReactModal to handle hiding any content not related to the opened modal. This is only for accessibility purposes.
ReactModal.setAppElement("#root");

initializeDatadogRum();

root.render(
  <BrowserRouter>
    <AnalyticsProvider>
      <UserProvider>
        <PermissionsProvider>
          <ToastProvider autoDismiss>
            <FeatureFlagProvider>
              <ParagonIntegrationServiceProvider>
                <App />
              </ParagonIntegrationServiceProvider>
            </FeatureFlagProvider>
          </ToastProvider>
        </PermissionsProvider>
      </UserProvider>
    </AnalyticsProvider>
  </BrowserRouter>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
