import { FC } from "react";
import {
  Button,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
} from "@chakra-ui/react";
import { theme } from "ovou-ui";

const IconComponent = Icon as any;

const InfoPopover: FC<{ title: string; text: string }> = ({ title, text }) => {
  return (
    <Popover placement="top" closeOnBlur={false}>
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <IconButton
              variant="gray"
              aria-label="Info"
              icon={<IconComponent name="alert" />}
              isRound
            />
          </PopoverTrigger>
          <PopoverContent width="22rem">
            <PopoverArrow />
            <PopoverHeader
              border="0"
              fontWeight={800}
              fontSize={21}
              mt="1.5rem"
              ml="1.5rem"
              p={0}
            >
              {title}
            </PopoverHeader>
            <PopoverBody
              ml="1.5rem"
              mr="13px"
              mt="8px"
              fontWeight={400}
              fontSize="14px"
              color={theme.palette.ui.greys.grey1}
              p={0}
            >
              {text}
            </PopoverBody>
            <PopoverFooter
              margin="24px"
              border="0"
              display="flex"
              alignItems="center"
              p={0}
            >
              <Button
                variant="outline"
                borderRadius="8px"
                height="2.5rem"
                width="310px"
                fontWeight="bold"
                fontSize={14}
                onClick={onClose}
              >
                OK
              </Button>
            </PopoverFooter>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};

export default InfoPopover;
