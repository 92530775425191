import { FC, useMemo } from "react";
import { useDisclosure } from "@chakra-ui/react";
import { CButton, ProfileEmbedded, Section } from "components";
import { useUser } from "context";
import { getToken } from "utils";
import { useTracking } from "hooks";
import { ANALYTICS_EVENTS } from "constants/analyticEvents";

const EmbeddedContent: FC = () => {
  const [{ currentProfile }] = useUser();
  const track = useTracking();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const getItemLinkParams = useMemo(() => {
    return new URLSearchParams({
      token: String(getToken().accessToken),
      refreshToken: String(getToken().refreshToken),
      isBusinessProfile: "true",
      profileID: String(currentProfile?.id),
      redirectTo: "/o/my-profile/edit-card-details",
    }).toString();
  }, [currentProfile?.id]);

  return (
    <>
      <Section
        label="Content"
        subLabel="This is an easy way to apply changes to all your team profiles. Add contents to your business page and then decide which sections you’d like to apply to all profiles."
        showBorder={false}
      >
        <CButton
          width={["100%", "49%"]}
          onClick={() => {
            onOpen();
            track({ eventName: ANALYTICS_EVENTS.COMPANY_PROFILE_EDIT_PROFILE });
          }}
        >
          Edit profile
        </CButton>
      </Section>
      <ProfileEmbedded
        isOpen={isOpen}
        onClose={onClose}
        profileId="1"
        teamId="1"
        link={`${process.env.REACT_APP_SITE_URL}/o/auth/login-with-token?${getItemLinkParams}`}
      />
    </>
  );
};

export default EmbeddedContent;
