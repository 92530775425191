import React, { useMemo, useEffect, useState } from "react";

import { getIn } from "formik";
import Select from "react-select";
import useChangeDebounce from "hooks/useChangeDebounce";
import { components } from "react-select";
import { Box } from "components";
import { customSelectStyles } from "./styles";
import FormError from "../../FormError";
import { FaSearch } from "react-icons/fa";

const normalizeFieldValue = value => {
  if (value === null || value === undefined) return "";
  return value.toString();
};

const findSelectedOption = (options, value) => {
  return options.filter(option => option.value === value)[0];
};

const ValueContainer = ({ children, hasValue, ...props }) => {
  return (
    components.ValueContainer && (
      <components.ValueContainer {...props}>
        {!!children && !hasValue && (
          <Box position="absolute" left="0">
            <FaSearch />
          </Box>
        )}
        {children}
      </components.ValueContainer>
    )
  );
};

const SelectFormField = ({
  field,
  form,
  options,
  disabled,
  isLoading,
  placeholder,
  renderInPortal,
  handleOnChange,
  components = {},
  styles,
  handleSearch,
  menuIsOpen,
  showSearchIcon = false,
  isClearable = false,
  ...props
}) => {
  const onChangeDebounced = useChangeDebounce(handleSearch);

  const isFiledTouched = useMemo(
    () => getIn(form.touched, field.name),
    [field.name, form.touched],
  );

  const errorMessage = useMemo(
    () => getIn(form.errors, field.name),
    [field.name, form.errors],
  );

  const [selected, setSelected] = useState(() =>
    findSelectedOption(options, normalizeFieldValue(field.value)),
  );

  useEffect(() => {
    setSelected(findSelectedOption(options, normalizeFieldValue(field.value)));
  }, [field.value, options, setSelected]);

  const onChange = selected => {
    if (handleOnChange) {
      handleOnChange();
    }
    setSelected(selected);
    form.setFieldValue(field.name, selected?.value);
  };
  return (
    <div className="flex flex-col">
      <Select
        value={props?.value || selected}
        onChange={onChange}
        onInputChange={handleSearch ? onChangeDebounced : undefined}
        options={options}
        styles={{
          ...customSelectStyles,
          ...styles,
          placeholder: provided => ({
            ...provided,
            marginLeft: showSearchIcon ? "25px" : "inherit",
          }),
          input: provided => ({
            ...provided,
            marginLeft: showSearchIcon ? "25px" : "inherit",
          }),
        }}
        menuIsOpen={menuIsOpen}
        isDisabled={disabled}
        isLoading={isLoading}
        placeholder={placeholder}
        menuPortalTarget={renderInPortal ? document.body : undefined}
        components={
          showSearchIcon ? { ...components, ValueContainer } : components
        }
        isClearable={isClearable}
      />
      {isFiledTouched && errorMessage && <FormError error={errorMessage} />}
    </div>
  );
};
export default SelectFormField;
