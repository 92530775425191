import React from "react";

const Website = ({ className }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.07445 4.79484V7.6306H14.4201V4.79484H3.07445ZM3.07445 14.0111V9.04848H14.4201V14.0111H3.07445ZM1.65625 4.71143C1.65625 3.99393 2.22661 3.37695 2.97315 3.37695H14.5214C15.2679 3.37695 15.8383 3.99393 15.8383 4.71143V14.0945C15.8383 14.812 15.2679 15.429 14.5214 15.429H2.97315C2.22661 15.429 1.65625 14.812 1.65625 14.0945V4.71143ZM4.49265 5.50378C4.10112 5.50378 3.78355 5.82118 3.78355 6.21272C3.78355 6.60425 4.10112 6.92166 4.49265 6.92166C4.88419 6.92166 5.20175 6.60425 5.20175 6.21272C5.20175 5.82118 4.88419 5.50378 4.49265 5.50378ZM6.61996 5.50378C6.22842 5.50378 5.91085 5.82118 5.91085 6.21272C5.91085 6.60425 6.22842 6.92166 6.61996 6.92166C7.01149 6.92166 7.32906 6.60425 7.32906 6.21272C7.32906 5.82118 7.01149 5.50378 6.61996 5.50378ZM8.03816 6.21272C8.03816 5.82118 8.35572 5.50378 8.74726 5.50378C9.13879 5.50378 9.45636 5.82118 9.45636 6.21272C9.45636 6.60425 9.13879 6.92166 8.74726 6.92166C8.35572 6.92166 8.03816 6.60425 8.03816 6.21272Z"
        fill="#686868"
      />
    </svg>
  );
};

export default React.memo(Website);
