import {
  TAnalyticsClient,
  TTrackCallback,
  TIdentifyCallback,
  TLoadService,
  TResetCallback,
  TPageCallback,
} from "../types";
import * as google from "./google";
import * as segment from "./segment";
import { ENVIRONMENT, HIGHER_ENVIRONMENT } from "constants/index";

const providers: TAnalyticsClient[] = HIGHER_ENVIRONMENT.includes(ENVIRONMENT)
  ? [google, segment]
  : [];

export const track: TTrackCallback = (...args) => {
  for (const item of providers) {
    item?.track?.(...args);
  }
};

export const page: TPageCallback = (...args) => {
  for (const item of providers) {
    item?.page?.(...args);
  }
};

export const identify: TIdentifyCallback = (...args) => {
  for (const item of providers) {
    item?.identify?.(...args);
  }
};

export const reset: TResetCallback = (...args) => {
  for (const item of providers) {
    item?.reset?.(...args);
  }
};

export const loadService: TLoadService = (...args) => {
  for (const item of providers) {
    item?.loadService?.(...args);
  }
};
