import {
  ITeamUserObject,
  ITeamUsersResponse,
  ITeamUserTypeObject,
  USER_IAM_ROLE,
  USER_SEATS_STATUS,
} from "types/TeamUsersResponse";
import { IProfile } from "types/Profile";

export type ISelectedUserToEdit = {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  status: USER_SEATS_STATUS;
  user_iam_role: USER_IAM_ROLE;
};

export type SelectedRow = ISelectedUserToEdit & {
  profile: IProfile;
};

export interface IManageUsersTable {
  teamId: number;
  openAssignSeatsModal: () => void;
}

export type ManageUsersTableColumnParams = {
  selectedRows: ITeamUserObject[];
  setSelectedUserToEdit: (user: ISelectedUserToEdit) => void;
  setEditRole: (user: SelectedRow) => void;
  teamId: number;
  refetch: () => Promise<ITeamUsersResponse | undefined>;
};

export interface IProfileNameCol {
  row: ITeamUserObject;
  setSelectedUserToEdit: (arg0: ISelectedUserToEdit) => void;
}

export interface IDeleteUserModal {
  selectedRows: SelectedRow[];
  isLoading: boolean;
  isOpen: boolean;
  openDeleteModal: () => void;
  closeDeleteModal: () => void;
  handleSeatUnAssign?: () => void;
}

export interface IChangeInvitationModal {
  teamId: number;
  isOpen: boolean;
  onClose: () => void;
  selectedUser: ISelectedUserToEdit;
  refetch: () => Promise<ITeamUsersResponse | undefined>;
}

export interface IChangeRoleModal {
  userTypes?: ITeamUserTypeObject[];
  user: SelectedRow;
  isOpen: boolean;
  onClose: () => void;
  refetch: () => Promise<ITeamUsersResponse | undefined>;
}
export interface IChangeRoleForm {
  userTypes?: ITeamUserTypeObject[];
  userIamRole: USER_IAM_ROLE;
  handleSubmit: (user_iam_role: string) => void;
  isLoading: boolean;
}

export interface IEditPermissionCol {
  row: ITeamUserObject;
}

export interface IProfileActionCol {
  row: ITeamUserObject;
  teamId: number;
  refetchUsers: () => Promise<ITeamUsersResponse | undefined>;
}
export interface IChangeRoleColumn {
  row: ITeamUserObject;
  onClick: (selectRow: ITeamUserObject) => void;
}

export enum USER_TYPE_LABELS {
  USER_TYPE = "User Type",
  MEMBER_READONLY = "Member (View only)",
  MEMBER = "Member",
  ADMIN = "Admin",
  OWNER = "Owner",
}
export type TUserTypeFilter = {
  filter: USER_IAM_ROLE;
  label: USER_TYPE_LABELS;
};
