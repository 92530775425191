import React, { FC } from "react";
import { theme } from "ovou-ui";
import { ITableProfileColumnProps } from "../types";

import { GlobalListItem, Flex, Text } from "../../../components";
import defaultPictureSmall from "../../../assets/images/profile-pic-placeholder-sm.svg";
import defaultLogo from "../../../assets/images/default-logo.png";
import { Image } from "@chakra-ui/react";

export const TableProfileColumn: FC<ITableProfileColumnProps> = ({
  profile,
}) => {
  if (!profile) return <></>;

  const isBusiness = profile.state === "is_business";

  const profileName = isBusiness
    ? "Company Profile"
    : `${profile.first_name} ${profile.last_name}`;

  return (
    <Flex justifyContent="flex-start">
      <GlobalListItem
        title={
          <Text fontSize="1.125rem" fontWeight="bold">
            {profileName}
          </Text>
        }
        subTitle={
          <Text
            textColor={theme.palette.ui.greys.grey1}
            fontSize="0.875rem"
            marginTop={2}
          >
            {profile.eff_email}
          </Text>
        }
        leftElement={
          isBusiness ? (
            <Image
              alt="Company Logo"
              src={profile.logo || defaultLogo}
              maxHeight="3rem"
              borderRadius="md"
              marginRight="0.75rem"
            />
          ) : (
            <Image
              alt={profileName}
              src={profile.picture || defaultPictureSmall}
              width="2.5rem"
              height="3.5rem"
              borderRadius="0.25rem"
              marginRight="0.75rem"
            />
          )
        }
        customStyles={{
          containerProps: {
            flex: "none",
          },
        }}
      />
    </Flex>
  );
};
