import React, { useEffect } from "react";

import { useUserDispatch, userActions } from "context";
import api from "api";
import { Settings } from "../../features";

const SettingsPage = () => {
  const dispatch = useUserDispatch();

  const permissionsServiceApi = async () => {
    const { data} = await api.get(`auth/role`);
    if (data)
      // Todo: Move this to patent component, this is also being used in OnboardingPage.tsx
      dispatch({ type: userActions.SET_PERMISSIONS, payload: data[0] && data[0]?.permission?.default_permission });
  };

  useEffect(() => {
    permissionsServiceApi();
  }, []);

  return <Settings />;
};

export default SettingsPage;
