import React from "react";

const Instagram = ({ className }) => {
  return (
    <svg
      width="24"
      height="23"
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M11.995 2.18555C6.71901 2.18555 2.62793 6.07478 2.62793 11.3256C2.62793 14.0723 3.7473 16.4468 5.5692 18.0864C5.72141 18.223 5.81508 18.4162 5.81977 18.6235L5.87128 20.3007C5.88768 20.8355 6.43565 21.1841 6.92274 20.9674L8.78211 20.1429C8.93901 20.0722 9.11698 20.0605 9.28325 20.1052C10.138 20.3408 11.0466 20.468 11.995 20.468C17.271 20.468 21.3621 16.5788 21.3621 11.3279C21.3621 6.07713 17.271 2.18555 11.995 2.18555Z"
        fill="url(#paint0_radial_10888_4650)"
      />
      <path
        d="M6.44657 13.804L9.16055 9.7733C9.59248 9.13107 10.5164 8.97321 11.1654 9.42732L13.3251 10.9431C13.5237 11.0815 13.7963 11.0815 13.9926 10.941L16.9075 8.86942C17.2956 8.59263 17.8037 9.02944 17.545 9.4165L14.8287 13.445C14.3968 14.0873 13.4729 14.2451 12.8238 13.791L10.6642 12.2752C10.4656 12.1368 10.193 12.1368 9.99669 12.2773L7.08176 14.3489C6.69372 14.6257 6.18557 14.191 6.44657 13.804Z"
        fill="white"
      />
      <defs>
        <radialGradient
          id="paint0_radial_10888_4650"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(6.23426 20.9284) scale(20.4128 20.534)"
        >
          <stop stop-color="#0099FF" />
          <stop offset="0.6098" stop-color="#A033FF" />
          <stop offset="0.9348" stop-color="#FF5280" />
          <stop offset="1" stop-color="#FF7061" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default React.memo(Instagram);
