import { useMutation } from "react-query";

import api from "../../../api";

const createThumbnailService = async ({ profileData, handle }) => {
  const postData = {
    thumbnail_links: [profileData],
  };

  const { data } = await api.post(`/p/${handle}/thumbnail_link`, {
    ...postData,
  });
  return data;
};

const createFieldService = async ({ profileData, handle }) => {
  const postData = {
    fields: profileData,
  };

  const { data } = await api.post(`/p/${handle}/field`, {
    ...postData,
  });
  return data;
};

const createVideoService = async ({ profileData, handle }) => {
  const postData = {
    videos: [profileData],
  };

  const { data } = await api.post(`/p/${handle}/video`, {
    ...postData,
  });
  return data;
};

const createPictureService = async ({ profileData, handle }) => {
  const formData = new FormData();
  formData.append("file", profileData.file);

  const { data } = await api.post(`/p/${handle}/picture`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return data;
};

const create = createType => {
  if (createType === "thumbnail") {
    return createThumbnailService;
  }
  if (createType === "field") {
    return createFieldService;
  }
  if (createType === "video") {
    return createVideoService;
  }
  if (createType === "picture") {
    return createPictureService;
  }
};

export const useCreateIndividualQuery = ({
  onSuccess = () => {},
  createType,
}) => {
  const [mutate, { status, error }] = useMutation(create(createType), {
    onSuccess,
  });

  return {
    mutate,
    status,
    error,
  };
};
