export enum FEATURES {
  MANAGE_USERS = "manage_users",
  MANAGE_PROFILES = "manage_profiles",
  CONTACTS = "contacts",
  ANALYTICS = "analytics",
  GROUPS = "groups",
  SETTINGS = "settings",
  COMPANY_PROFILE = "company_profile",
  MANAGE_DEVICES = "manage_devices",
  TUTORIALS = "tutorials",
  INTEGRATIONS = "integrations",
  MANAGE_SUBSCRIPTION = "manage_subscription",
  INVITE_USERS_MODAL = "invite_users_modal",
  MANAGE_USERS_DELETE_USER = "manage_users_delete_user",
  MANAGE_USERS_RESEND_EMAIL = "manage_users_resend_email",
  MANAGE_USERS_CHANGE_EMAIL = "manage_users_change_email",
  UPGRADE_NOW = "upgrade_now",
  ORDER_MORE_CARDS = "order_more_cards",
  MANAGE_USERS_CHANGE_ROLE = "manage_users_change_role",
  MANAGE_USERS_SETUP_PROFILE = "manage_users_setup_profile",
  MANAGE_USERS_EDIT_PROFILE = "manage_users_edit_profile",
}

export enum FEATURE_ACTION {
  VIEW = "view",
  EDIT = "edit",
}
