import { generateMediaPath } from "./generateMediaPath";

export const getCardBG = cardScheme => {
  const bgPath =
    (cardScheme?.raw_file && generateMediaPath(cardScheme?.raw_file)) || "";

  return cardScheme?.background_color === "null"
    ? `url(${bgPath})`
    : cardScheme?.background_color?.includes("gradient")
    ? cardScheme?.background_color
    : `#${cardScheme?.background_color}`;
};
