import { useMutation } from "react-query";

import api from "../../../api";

const groupCreateEditService = async ({ directoryID, formData, teamID }) => {
  let response;
  if (directoryID) {
    const { data } = await api.put(`/directory/${directoryID}`, {
      ...formData,
    });
    response = data;
  } else {
    const { data } = await api.post(`/directory/for/${teamID}`, {
      ...formData,
    });
    response = data;
  }
  return response;
};

export const useGroupCreateEditQuery = ({ onSuccess }) => {
  const [mutate, { status, error }] = useMutation(groupCreateEditService, {
    onSuccess,
  });

  return {
    mutate,
    status,
    error,
  };
};
