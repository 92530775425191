import { FC } from "react";
import { Flex, Box, Text } from "components";
import { theme } from "ovou-ui";
import { Skeleton, SkeletonCircle, SkeletonText } from '@chakra-ui/react';

interface ICardsCounterProps {
  assignedCards: number;
  appointedCards: number;
  unassignedCards: number;
}

export const CardsCounter: FC<ICardsCounterProps> = ({
  assignedCards,
  appointedCards,
  unassignedCards,
}): any => {
  const totalCards = assignedCards + appointedCards + unassignedCards;
  if (totalCards == 0) return <></>
  return (
    <Flex gap={1}>
      <Text
        color={theme.palette.brand.primary.orange}
        fontSize="1rem"
        fontWeight="semibold"
      >
        {`${assignedCards + appointedCards
          } Assigned, ${unassignedCards} Unassigned`}
      </Text>
      <Text color={theme.palette.ui.greys.grey1} fontSize="1rem">
        {`/ ${totalCards} Total cards`}
      </Text>
    </Flex>
  );
};

export const ShimmerPlaceholder: any = (): any => (
  <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" width="100%">
    <Skeleton height="10px" width="150px" startColor="gray.100" endColor="gray.200" />
  </Box>
);


