import { IProfile } from "types/Profile";
import { CardState, ICard } from "../../../types/Card";

export interface IAssignButtonProps {
  buttonText: string;
  variant?: "primary" | "secondary";
  onClick: () => void;
}

export interface ITableTagColumnProps {
  tag: string;
}

export interface ITableProfileColumnProps {
  profile: IProfile | null;
}

export interface ITableStateColumnProps {
  state: CardState;
}

export interface ITableAssignColumnProps {
  currentCard: ICard;
}

export interface IGetDevicesTableColumnsProps {
  onAssignCard: (card: ICard) => void;
  onUnassignCard: (card: ICard) => void;
}

export interface ICardStateFilter {
  onSelect: (states: CardState[]) => void;
}

export enum ICardStateFilterValue {
  DEFAULT = "Card Status",
  ACTIVE = "Assigned",
  INACTIVE = "Unassigned",
}
