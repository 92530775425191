import {
  Box,
  BoxProps,
  Container,
  ContainerProps,
  Flex,
  FlexProps,
  Grid,
  GridItem,
  GridItemProps,
  GridProps,
  HStack,
  Stack,
  StackProps,
  VStack,
} from "@chakra-ui/react";
import { forwardRef } from "@chakra-ui/react";

export const ContainerComponent = forwardRef<ContainerProps, "div">(
  ({ children, ...restProps }, ref) => (
    <Container ref={ref} {...restProps}>
      {children}
    </Container>
  ),
);

export const BoxComponent = forwardRef<BoxProps, "div">(
  ({ children, ...restProps }, ref) => (
    <Box ref={ref} {...restProps}>
      {children}
    </Box>
  ),
);

export const FlexComponent = forwardRef<FlexProps, "div">(
  ({ children, ...restProps }, ref) => (
    <Flex ref={ref} {...restProps}>
      {children}
    </Flex>
  ),
);

export const GridComponent = forwardRef<GridProps, "div">(
  ({ children, ...restProps }, ref) => (
    <Grid ref={ref} {...restProps}>
      {children}
    </Grid>
  ),
);

export const GridItemComponent = forwardRef<GridItemProps, "div">(
  ({ children, ...restProps }, ref) => (
    <GridItem ref={ref} {...restProps}>
      {children}
    </GridItem>
  ),
);

export const HStackComponent = forwardRef<StackProps, "div">(
  ({ children, ...restProps }, ref) => (
    <HStack ref={ref} {...restProps}>
      {children}
    </HStack>
  ),
);

export const VStackComponent = forwardRef<StackProps, "div">(
  ({ children, ...restProps }, ref) => (
    <VStack ref={ref} {...restProps}>
      {children}
    </VStack>
  ),
);

export const StackComponent = forwardRef<StackProps, "div">(
  ({ children, ...restProps }, ref) => (
    <Stack ref={ref} {...restProps}>
      {children}
    </Stack>
  ),
);

export { Wrap, WrapItem } from "@chakra-ui/react";
