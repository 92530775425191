import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  useContext,
  useReducer,
} from "react";

import { ITeam } from "types/Team";
import { IProfile } from "types/Profile";
import { TUserActions, userActions } from "./actions";

type TDispatch = (action: TUserActions) => void;
type TState = {
  isAuthenticated: boolean;
  currentProfile: IProfile | undefined;
  currentTeam: ITeam | undefined;
  permissions: object;
  dispatch: Dispatch<any>;
};
const initialState: TState = {
  isAuthenticated: false,
  currentProfile: undefined,
  currentTeam: undefined,
  permissions: {},
  dispatch: (() => undefined) as Dispatch<any>,
};

const UserContext = createContext<[TState, TDispatch] | undefined>(undefined);

const UserReducer = (state: TState, action: TUserActions): TState => {
  switch (action.type) {
    case userActions.SET_PROFILE: {
      return {
        ...state,
        currentProfile: action.payload,
        isAuthenticated: true,
      };
    }
    case userActions.SET_TEAM: {
      return { ...state, currentTeam: action.payload };
    }
    case userActions.LOGIN_USER: {
      return { ...state, isAuthenticated: true };
    }
    case userActions.LOGOUT_USER: {
      return {
        ...state,
        currentProfile: undefined,
        currentTeam: undefined,
        isAuthenticated: false,
      };
    }
    case userActions.SET_PERMISSIONS: {
      let permission = action?.payload as any;
      return {
        ...state,
        permissions: {
          canEditCover:
            permission?.edit_setting_branding_cover_enabled?.enabled,
          canEditLogo: permission?.edit_setting_branding_logo_enabled?.enabled,
          canEditCustomColor:
            permission?.edit_setting_branding_custom_color_enabled?.enabled,
        },
      };
    }
    default: {
      throw new Error("Unhandled action type");
    }
  }
};

const UserProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(UserReducer, initialState);

  return (
    <UserContext.Provider value={[state, dispatch]}>
      {children}
    </UserContext.Provider>
  );
};

const useUser = (): [TState, TDispatch] => {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};

const useUserState = (): TState => {
  const [state] = useUser();

  return state;
};

const useUserDispatch = (): TDispatch => {
  const [, dispatch] = useUser();

  return dispatch;
};

export { UserProvider, useUser, useUserState, useUserDispatch };
