import styled from "styled-components";

export const Divider = styled.div`
  opacity: 0.6;
  border: 0;
  border-color: inherit;
  border-style: solid;
  border-left-width: 1px;
  height: 3rem;
`;
