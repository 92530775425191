import { theme } from "ovou-ui";
import React, { FC } from "react";
import ReactPaginate from "react-paginate";
import Select from "react-select";

import { Box, Flex, Icon, Text } from "../../../components";
import { customSelectStyles } from "../../../components/FormFields/SelectFormField/styles";
import { PaginationWrapper } from "../../../components/Table/Table";

const IconComponent = Icon as any;

const PAGE_SIZE_OPTIONS = [
  { label: "20 Items", value: 20 },
  { label: "30 Items", value: 30 },
  { label: "50 Items", value: 50 },
];

interface TablePaginationProps {
  currentPage: number;
  pageCount: number;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  onPageClick: (event: any) => void;
}

export const TablePagination: FC<TablePaginationProps> = ({
  currentPage,
  pageCount,
  pageSize,
  setPageSize,
  onPageClick,
}) => {
  return (
    <Flex justifyContent="space-between" marginTop={8}>
      <Flex alignItems="center">
        <Text
          fontWeight="semibold"
          textColor={theme.palette.brand.primary.black}
          marginRight={2}
        >
          Show by
        </Text>
        <Box width="112px">
          <Select
            value={PAGE_SIZE_OPTIONS.find(option => option.value === pageSize)}
            onChange={(option: any) => setPageSize(option.value)}
            options={PAGE_SIZE_OPTIONS}
            styles={customSelectStyles}
            menuPlacement="top"
          />
        </Box>
      </Flex>

      <PaginationWrapper>
        <ReactPaginate
          breakLabel="..."
          nextLabel={<IconComponent name="chevronRight" />}
          onPageChange={onPageClick}
          pageRangeDisplayed={2}
          marginPagesDisplayed={1}
          pageCount={pageCount}
          previousLabel={<IconComponent name="chevronLeft" />}
          forcePage={currentPage}
        />
      </PaginationWrapper>
    </Flex>
  );
};
