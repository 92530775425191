export enum ImageType {
  LOGO = "logo",
  COVER = "cover",
  PROFILE_PICTURE = "profile_picture",
}

export type RatioOption = {
  ratio: number;
  label: string;
  example: string;
};

export const imageRatio: {
  [name: string]: number;
} = {
  [ImageType.LOGO]: 1,
  [ImageType.COVER]: 16 / 9,
  [ImageType.PROFILE_PICTURE]: 12 / 16,
};

export const imageRatioOptions: {
  [name: string]: RatioOption[];
} = {
  [ImageType.LOGO]: [
    { ratio: 1, label: "1:1", example: "h-4 w-4" },
    { ratio: 2, label: "2:1", example: "h-4 w-8" },
  ],
};

export const COMPANY_SIZES = [
  "5-10",
  "10-50",
  "51-100",
  "101-500",
  "501-1000",
  "1000+",
];
