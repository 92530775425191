import React from "react";

const Business = ({
  className,
  role,
  ignoreDefaultSize,
  width = 24,
  height = 24,
  strokeWidth,
  onClick,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={ignoreDefaultSize ? undefined : width}
      height={ignoreDefaultSize ? undefined : height}
      viewBox="0 0 24 24"
      role={role}
      className={className}
      strokeWidth={strokeWidth}
      onClick={onClick}
    >
      <path
        d="M20 6h-3V4c0-1.103-.897-2-2-2H9c-1.103 0-2 .897-2 2v2H4c-1.103 0-2 .897-2 2v11c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V8c0-1.103-.897-2-2-2zm-5-2v2H9V4h6zM8 8h12v3H4V8h4zM4 19v-6h6v2h4v-2h6l.001 6H4z"
        stroke="none"
      />
    </svg>
  );
};

export default React.memo(Business);
