import { FC, useState } from "react";
import { DateRange, DayPicker } from "react-day-picker";
import { Button } from "@chakra-ui/react";
import { theme } from "ovou-ui";
import "react-day-picker/dist/style.css";

const css = `
  .rdp-day_selected : {
    background-color: ${theme.palette.brand.primary.orange};
  }
  .my-today { 
    border-color: ${theme.palette.brand.primary.orange};
    border-radius: 100px;
  }
  .my-selected {
    border-color: ${theme.palette.brand.primary.orange};
    color: white;
  }
  .my-selected.my-range-end.my-range-start {
    background-color: ${theme.palette.brand.primary.orange};
    border-radius: 100px;
  }
  .my-range-start {
    background-color: ${theme.palette.brand.primary.orange};
    border-radius: 0.5rem 0 0 0.5rem;
  }
  .my-range-middle {
    background-color: rgba(255, 100, 58, .1);
    color: black;
    border-radius: 0;
    border: 0;
  }
  .my-range-end {
    background-color: ${theme.palette.brand.primary.orange};
    border-radius: 0 0.5rem 0.5rem 0;
  }
`;

interface IDatePicker {
  dateRange?: DateRange;
  handleRangeChange: (range?: DateRange) => void;
}

const DatePicker: FC<IDatePicker> = ({ dateRange, handleRangeChange }) => {
  const [range, setRange] = useState<DateRange | undefined>(dateRange);
  const footer = (
    <Button
      float="right"
      pt="5px"
      color={theme.palette.brand.primary.orange}
      variant="link"
      _focus={{
        outline: "none",
      }}
      onClick={() => handleRangeChange(range)}
    >
      Select
    </Button>
  );
  return (
    <>
      <style>{css}</style>

      <DayPicker
        mode="range"
        selected={range}
        footer={footer}
        modifiersClassNames={{
          today: "my-today",
          selected: "my-selected",
          range_start: "my-range-start",
          range_middle: "my-range-middle",
          range_end: "my-range-end",
        }}
        onSelect={range => {
          setRange(range);
        }}
      />
    </>
  );
};

export default DatePicker;
