import React, { FC } from "react";
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  SystemStyleObject,
} from "@chakra-ui/react";
import { Flex } from "components";
import { FiArrowLeft } from "react-icons/fi";
import { theme } from "ovou-ui";

interface DrawerComponentProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  children: React.ReactNode;
  footerContent?: React.ReactNode;
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "full";
  showCloseButton?: boolean;
  maxWidth?: string;
}

const baseStyles: SystemStyleObject = {
  width: "100%",
  p: 4,
  alignItems: "center",
  position: "sticky",
  top: 0,
  zIndex: 999,
};

const DrawerComponent: FC<DrawerComponentProps> = ({
  isOpen,
  onClose,
  children,
  footerContent,
  title,
  size = "md",
  showCloseButton = false,
  maxWidth = "36rem",
}) => {
  return (
    <Drawer isOpen={isOpen} onClose={onClose} size={size}>
      <DrawerOverlay />

      <DrawerContent maxWidth={maxWidth}>
        {showCloseButton && (
          <Flex sx={{ ...baseStyles }} onClick={onClose}>
            <FiArrowLeft
              cursor="pointer"
              size="24"
              color={theme.palette.ui.greys.grey2}
              role="button"
              aria-label="Back"
            />
          </Flex>
        )}
        {title && <DrawerHeader>{title}</DrawerHeader>}

        <DrawerBody padding="0px">{children}</DrawerBody>
        {footerContent && <DrawerFooter>{footerContent}</DrawerFooter>}
      </DrawerContent>
    </Drawer>
  );
};

export default DrawerComponent;
