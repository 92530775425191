import React, { FC, useState } from "react";
import { MenuItem, TableFilterMenuButton, Menu, MenuList } from "components";
import { theme } from "ovou-ui";

import { USER_SEATS_STATUS } from "types/TeamUsersResponse";

export enum FILTER_DISPLAY_VALUES {
  USER_STATUS = "User Status",
  ACTIVE = "Active",
  PENDING = "Pending",
}

const FILTER_OPTIONS = [
  { value: "active", label: FILTER_DISPLAY_VALUES.ACTIVE },
  { value: "pending", label: FILTER_DISPLAY_VALUES.PENDING },
];

const ManageUsersStatusFilter: FC<{
  onFiltersChange: (filters: { status: string | null; value: string }) => void;
}> = ({ onFiltersChange }) => {
  const [filterValue, setFilterValue] = useState(
    FILTER_DISPLAY_VALUES.USER_STATUS,
  );

  const handleChange = (filters: { status: string | null; value: string }) => {
    onFiltersChange(filters);
  };

  const handleClear = () => {
    setFilterValue(FILTER_DISPLAY_VALUES.USER_STATUS);
    handleChange({ status: null, value: "" });
  };

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <TableFilterMenuButton
            buttonText={filterValue}
            isOpen={isOpen}
            minW="110px"
          />

          <MenuList>
            {FILTER_OPTIONS?.map(({ value, label }) => (
              <MenuItem
                key={value}
                value={value}
                _focus={{
                  backgroundColor: theme.palette.ui.greys.grey5,
                  outline: "none",
                }}
                onClick={() => {
                  setFilterValue(label);
                  handleChange({
                    status:
                      value === FILTER_DISPLAY_VALUES.ACTIVE.toLowerCase()
                        ? USER_SEATS_STATUS.ASSIGNED
                        : USER_SEATS_STATUS.APPOINTED,
                    value,
                  });
                }}
              >
                {label}
              </MenuItem>
            ))}

            {filterValue !== FILTER_DISPLAY_VALUES.USER_STATUS && (
              <MenuItem
                marginTop={2}
                fontWeight="semibold"
                fontSize={14}
                cursor="pointer"
                onClick={handleClear}
                color={theme.palette.brand.primary.orange}
                _focus={{
                  outline: "none",
                }}
              >
                Clear
              </MenuItem>
            )}
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export default ManageUsersStatusFilter;
