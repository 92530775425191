import { useQuery } from "react-query";

import api from "../../../api";
import { IInteractions } from "../types";

export enum TeamInteractionsSortOption {
  CREATED_ASCENDING = "timestamp",
  CREATED_DESCENDING = "-timestamp",
}

interface IUseGetTeamInteractionsProps {
  teamId: string;
  sort?: TeamInteractionsSortOption;
  offset?: number;
  limit?: number;
  lowerBoundDate?: string;
  upperBoundDate?: string;
}

const interactionsService = async ({
  teamId,
  sort,
  offset,
  limit,
  lowerBoundDate,
  upperBoundDate,
}: IUseGetTeamInteractionsProps) => {
  const { data } = await api.get(`/analytics/teams/${teamId}/interactions`, {
    params: {
      sort,
      offset,
      limit,
      window_lower_bound: lowerBoundDate,
      window_upper_bound: upperBoundDate,
    },
  });
  return data;
};

export const useInteractionsQuery = ({
  teamId,
  sort,
  offset,
  limit,
  lowerBoundDate,
  upperBoundDate,
}: IUseGetTeamInteractionsProps) => {
  const query = useQuery<IInteractions>(
    [
      "interactions",
      {
        teamId,
        sort,
        offset,
        limit,
        lowerBoundDate,
        upperBoundDate,
      },
    ],
    () =>
      interactionsService({
        teamId,
        sort,
        offset,
        limit,
        lowerBoundDate,
        upperBoundDate,
      }),
  );

  return {
    ...query,
  };
};
