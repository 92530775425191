import { ANALYTICS_EVENTS, getTrackingProps } from "constants/analyticEvents";
import { GA_TRACKING_ID } from "constants/index";
import { loadClient } from "utils/loadClient";
import { TEventProperties, TPageCallback, TTrackCallback } from "../types";

const google = {
  scriptSrc: `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`,
  script: `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${GA_TRACKING_ID}', {
      page_path: window.location.pathname,
    });
  `,
};

export const track: TTrackCallback = (
  eventName: string,
  properties: TEventProperties,
) => {
  if (!("gtag" in window)) return;

  const trackingProps = getTrackingProps(
    eventName as ANALYTICS_EVENTS,
    properties,
  );

  if (!trackingProps) {
    return;
  }

  const { action, category, label, value } = trackingProps;

  (window as any).gtag("event", action, {
    event_category: category,
    event_label: label,
    ...(value ? { value } : {}),
  });
};

export const page: TPageCallback = (path: string, location: string) => {
  if (!("gtag" in window)) return;

  if (GA_TRACKING_ID) {
    (window as any).gtag("event", "page_view", {
      page_path: path,
      page_location: location,
    });
  }
};

export const loadService = () => {
  const { head, srcScript, dataLayerScript } = loadClient();
  if (GA_TRACKING_ID) {
    srcScript.src = google.scriptSrc;

    dataLayerScript.innerHTML = google.script;

    head.appendChild(srcScript);
    head.appendChild(dataLayerScript);
  }
};
