import React from "react";

import Alert from "./svg/Alert";
import Business from "./svg/Business";
import Camera from "./svg/Camera";
import Cards from "./svg/Cards";
import CardsStack from "./svg/CardsStack";
import Check from "./svg/Check";
import ChevronLeft from "./svg/ChevronLeft";
import ChevronRight from "./svg/ChevronRight";
import Clock from "./svg/Clock";
import Close from "./svg/Close";
import Delete from "./svg/Delete";
import Drop from "./svg/Drop";
import ExternalLink from "./svg/ExternalLink";
import Eye from "./svg/Eye";
import EyeOff from "./svg/EyeOff";
import Info from "./svg/Info";
import LeftArrow from "./svg/LeftArrow";
import Link from "./svg/Link";
import LogOut from "./svg/LogOut";
import Menu from "./svg/Menu";
import Pen from "./svg/Pen";
import Plus from "./svg/Plus";
import PlusCircle from "./svg/PlusCircle";
import Search from "./svg/Search";
import Settings from "./svg/Settings";
import SignIn from "./svg/SignIn";
import Trash from "./svg/Trash";
import Tutorial from "./svg/Tutorial";
import Upload from "./svg/Upload";
import Users from "./svg/Users";
import Waiting from "./svg/Waiting";
import Download from "./svg/Download";
import Exchange from "./svg/Exchange";
import PointClick from "./svg/PointClick";
import LinkClick from "./svg/LinkClick";
import SquareStack from "./svg/SquareStack";
import Contacts from "./svg/Contacts";
import Analytics from "./svg/Analytics";
import PersonStack from "./svg/PersonStack";
import Cog from "./svg/Cog";
import Integrations from "./svg/Integrations";
import Card from "./svg/Card";
import Person from "./svg/Person";
import LogoutArrow from "./svg/LogoutArrow";
import Company from "./svg/Company";
import Phone from "./svg/Phone";
import Website from "./svg/Website";
import Mail from "./svg/Mail";
import Location from "./svg/Location";
import Facebook from "./svg/Facebook";
import Youtube from "./svg/Youtube";
import Instagram from "./svg/Instagram";
import LinkedIn from "./svg/LinkedIn";
import Edit from "./svg/Edit";

// SVG Icons converted to components using https://svg2jsx.com
export const iconsList: any = {
  search: Search,
  logOut: LogOut,
  menu: Menu,
  users: Users,
  settings: Settings,
  cards: Cards,
  chevronRight: ChevronRight,
  close: Close,
  delete: Delete,
  signIn: SignIn,
  cardsStack: CardsStack,
  plus: Plus,
  plusCircle: PlusCircle,
  pen: Pen,
  trash: Trash,
  waiting: Waiting,
  info: Info,
  externalLink: ExternalLink,
  business: Business,
  check: Check,
  eye: Eye,
  clock: Clock,
  chevronLeft: ChevronLeft,
  eyeOff: EyeOff,
  alert: Alert,
  tutorial: Tutorial,
  drop: Drop,
  link: Link,
  upload: Upload,
  leftArrow: LeftArrow,
  camera: Camera,
  download: Download,
  exchange: Exchange,
  pointClick: PointClick,
  linkClick: LinkClick,
  squareStack: SquareStack,
  contacts: Contacts,
  analytics: Analytics,
  personStack: PersonStack,
  cog: Cog,
  company: Company,
  integrations: Integrations,
  card: Card,
  person: Person,
  logoutArrow: LogoutArrow,
  phone: Phone,
  website: Website,
  mail: Mail,
  location: Location,
  facebook: Facebook,
  youtube: Youtube,
  instagram: Instagram,
  linkedIn: LinkedIn,
  edit: Edit,
};

const Icon = ({
  name,
  className,
  customClassName = "",
  width,
  height,
  ignoreDefaultSize,
  strokeWidth,
  color,
  onClick,
  ...props
}: {
  name?: any;
  className?: any;
  customClassName?: any;
  width?: any;
  height?: any;
  ignoreDefaultSize?: any;
  strokeWidth?: any;
  color?: any;
  onClick?: any;
}) => {
  return React.createElement(iconsList[name], {
    className: customClassName || `icon fill-current ${className}`,
    role: "icon",
    width,
    height,
    ignoreDefaultSize,
    strokeWidth,
    color,
    onClick,
    ...props,
  });
};

export default Icon;
