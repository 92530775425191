import React from "react";

import styled from "styled-components";

import { theme } from "../../theme";

const TextWrapper = styled.p`
  color: ${({ color }) => color || theme.palette.brand.primary};
  cursor: ${({ cursor }) => cursor || "default"};
  position: ${({ position }) => position};
  font-weight: ${({ fontWeight }) => fontWeight};
  text-transform: ${({ textTransform }) => textTransform};
  font-size: ${({ fontSize }) => fontSize}px;
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  text-decoration: ${({ textDecoration }) => textDecoration};
`;

export const Text = {
  XXXL(props) {
    return <TextWrapper fontSize={32} {...props} />;
  },
  XXL(props) {
    return <TextWrapper fontSize={30} {...props} />;
  },
  XL(props) {
    return <TextWrapper fontSize={25} {...props} />;
  },
  LG(props) {
    return <TextWrapper fontSize={20} {...props} />;
  },
  MD(props) {
    return <TextWrapper fontSize={16} {...props} />;
  },
  SM(props) {
    return <TextWrapper fontSize={12} {...props} />;
  },
  XS(props) {
    return <TextWrapper fontSize={10} {...props} />;
  },
  XXS(props) {
    return <TextWrapper fontSize={8} {...props} />;
  },
};
