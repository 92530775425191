import React, { FC, useEffect } from "react";

import { Input, Button, useClipboard } from "@chakra-ui/react";
import { theme, Zapier as ZapierIcon } from "ovou-ui";

import { useGetAccountQuery } from "../../hooks";
import { Text, Box, Flex } from "components";
import { ZAPIER_TEXTS } from "../../constants/Texts";

const Zapier: FC = () => {
  const { data: { api_key: apiKey } = {} } = useGetAccountQuery();
  const { hasCopied, onCopy, setValue, value } = useClipboard(apiKey || "");

  const { TITLE, SUB_TITLE, desc } = ZAPIER_TEXTS.subscription || {};

  useEffect(() => {
    if (apiKey) setValue(apiKey);
  }, [apiKey, setValue]);

  const displayMaskedKey = () => {
    const firstPart = value.slice(0, 50).replace(/./g, "*");
    const lastPart = value.slice(-5);
    return `${firstPart}${lastPart}`;
  };

  return (
    <Box
      width="50%"
      padding="4"
      borderRadius="10px"
      border={`1px solid ${theme.palette.ui.greys.grey4}`}
      sx={{ "@media (max-width: 1200px)": { width: "100%" } }}
    >
      <Flex alignItems="center">
        <ZapierIcon size={48} />
        <Text marginLeft="3" fontSize={20} fontWeight={800}>
          {TITLE}
        </Text>
      </Flex>
      <Text
        marginTop="4"
        fontSize={16}
        fontWeight={400}
        color={theme.palette.ui.greys.grey1}
      >
        {SUB_TITLE}
      </Text>
      <Text marginY="1.5rem" fontSize={14} color={theme.palette.ui.greys.grey1}>
        {desc}
      </Text>
      <Box>
        <Text fontSize={12} color={theme.palette.ui.greys.grey2}>
          API Key
        </Text>
        <Input
          name="apiKey"
          id="apiKey"
          isReadOnly
          value={displayMaskedKey()}
        />
      </Box>
      <Button width="full" marginTop={4} onClick={onCopy}>
        {hasCopied ? "Copied" : "Copy"}
      </Button>
    </Box>
  );
};

export default Zapier;
