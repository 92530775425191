import React from "react";

const LogOut = ({
  className,
  role,
  ignoreDefaultSize,
  width = 62,
  height = 62,
  strokeWidth,
  onClick,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={ignoreDefaultSize ? undefined : width}
      height={ignoreDefaultSize ? undefined : height}
      viewBox="0 0 62 62"
      role={role}
      className={className}
      strokeWidth={strokeWidth}
      onClick={onClick}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="currentColor"
        d="M44.142 40H8.213a5.165 5.165 0 0 1-5.167-5.167V14.167A5.165 5.165 0 0 1 8.213 9h35.929a5.165 5.165 0 0 1 5.167 5.167v20.666A5.167 5.167 0 0 1 44.142 40z"
        transform="translate(4.823 14.25)"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="currentColor"
        d="M4.782 13.75v-2.583A5.165 5.165 0 0 1 9.949 6h26.957a5.165 5.165 0 0 1 5.167 5.167v2.583"
        transform="translate(7.572 9.5)"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="currentColor"
        d="M6.516 10.75V8.167A5.165 5.165 0 0 1 11.683 3h18a5.165 5.165 0 0 1 5.167 5.167v2.583"
        transform="translate(10.317 4.75)"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="currentColor"
        d="M12 12v15.5"
        transform="translate(19 19)"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="currentColor"
        d="M24.083 14.9H9.1"
        transform="translate(14.408 23.592)"
      />
    </svg>
  );
};

export default React.memo(LogOut);
