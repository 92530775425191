import React from "react";

const PersonStack = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M16.0909 19.2272V17.5908C16.0909 16.7229 15.7461 15.8904 15.1324 15.2767C14.5186 14.6629 13.6862 14.3181 12.8182 14.3181H6.27273C5.40475 14.3181 4.57232 14.6629 3.95856 15.2767C3.3448 15.8904 3 16.7229 3 17.5908V19.2272"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.54616 11.0455C11.3536 11.0455 12.8189 9.5802 12.8189 7.77273C12.8189 5.96525 11.3536 4.5 9.54616 4.5C7.73869 4.5 6.27344 5.96525 6.27344 7.77273C6.27344 9.5802 7.73869 11.0455 9.54616 11.0455Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.9996 19.2273V17.5909C20.999 16.8658 20.7577 16.1614 20.3134 15.5883C19.8692 15.0152 19.2471 14.6058 18.545 14.4246"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2734 4.60645C15.9774 4.78669 16.6014 5.19611 17.047 5.77015C17.4925 6.3442 17.7344 7.05022 17.7344 7.7769C17.7344 8.50358 17.4925 9.2096 17.047 9.78365C16.6014 10.3577 15.9774 10.7671 15.2734 10.9474"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default React.memo(PersonStack);
