import React from "react";

const LinkedIn = ({ className }) => {
  return (
    <svg
      width="24"
      height="23"
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M7.60386 19.6187H4.31001V8.92728H7.60386V19.6187ZM5.95428 7.46866C5.57401 7.47117 5.20155 7.35995 4.88407 7.1491C4.5666 6.93824 4.3184 6.63723 4.1709 6.28419C4.0234 5.93114 3.98325 5.54196 4.05552 5.16591C4.1278 4.78987 4.30925 4.44389 4.57689 4.17179C4.84454 3.89969 5.18634 3.71371 5.559 3.63741C5.93166 3.56112 6.31842 3.59793 6.6703 3.74319C7.02218 3.88844 7.32334 4.13562 7.53564 4.4534C7.74794 4.77118 7.86183 5.14527 7.86289 5.52829C7.86326 6.03991 7.66286 6.53094 7.30536 6.89439C6.94785 7.25785 6.46222 7.46426 5.95428 7.46866V7.46866ZM19.9412 19.6187H16.652V14.4141C16.652 13.1737 16.6269 11.5829 14.9382 11.5829C13.2244 11.5829 12.962 12.9308 12.962 14.3247V19.6187H9.66881V8.92728H12.8302V10.3859H12.8765C13.1927 9.84086 13.6498 9.39245 14.1991 9.08846C14.7483 8.78447 15.3692 8.63628 15.9955 8.65971C19.3311 8.65904 19.9446 10.8717 19.9446 13.7455V19.6173L19.9412 19.6187Z"
        fill="#0069C6"
      />
    </svg>
  );
};

export default React.memo(LinkedIn);
