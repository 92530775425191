import React from "react";

import { DragDropContext, Droppable } from "react-beautiful-dnd";

import { Box } from "../Container";

const DragAndDropWrapper = ({ onDragEnd, droppableId, children }) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={droppableId}>
        {provided => (
          <Box
            width="100%"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {children}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DragAndDropWrapper;
