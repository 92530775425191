import { FC, useEffect, useState } from "react";

import { ANALYTICS_TEXT } from "constants/Texts";
import { Box, Flex, Grid, GridItem, PageSetup } from "components";
import { theme } from "ovou-ui";
import StatCounters from "./StatCounters";
import TeamAndGroupsList from "./TeamAndGroupList/TeamAndGroupsList";
import SourceList from "./SourceList";
import InteractionsList from "./InteractionsList";
import StatChart from "./StatChart";
import { IStat, METRIC } from "./types";
import { useAnalyticsQuery } from "./queries/useAnalyticsQuery";
import {
  TeamInteractionsSortOption,
  useInteractionsQuery,
} from "./queries/useInteractionsQuery";
import DateSelector from "./DateSelector";
import { useUserState } from "context";
import { getAllTime } from "utils/dateFunctions";
import { FEATURES } from "constants/features";
import { useMetricsStats } from "hooks/useMetricsStats";

const { STATS_PAGE } = ANALYTICS_TEXT;
const INTERACTIONS_TO_DISPLAY = 10;

const Analytics: FC = () => {
  const { currentTeam } = useUserState();
  const dateTime = new Date();
  const teamId = String(currentTeam?.id);
  const [metric, setMetric] = useState<METRIC>(METRIC.VIEW_COUNT);
  const [upperAndLowerBoundDates, setUpperAndLowerBoundDates] = useState({
    lowerBoundDate: getAllTime(dateTime).lowerBound,
    upperBoundDate: getAllTime(dateTime).upperBound,
  });
  const { lowerBoundDate, upperBoundDate } = upperAndLowerBoundDates;

  const {
    data: teamStatsData,
    isLoading: statsLoading,
    refetch,
  } = useAnalyticsQuery(teamId, lowerBoundDate, upperBoundDate);
  const { data: interactionsData, isLoading: interactionsLoading } =
    useInteractionsQuery({
      teamId,
      sort: TeamInteractionsSortOption.CREATED_DESCENDING,
      limit: INTERACTIONS_TO_DISPLAY,
      lowerBoundDate,
      upperBoundDate,
    });
  const {
    view_count,
    save_count,
    exchange_count,
    social_media_click_count,
    thumbnail_link_click_count,
    sources,
    profiles,
    groups,
  } = teamStatsData || {};

  const { dataSet } = useMetricsStats({
    metric,
    lowerBoundDate,
    upperBoundDate,
  });

  const handleStatChange = (stat: IStat): void => {
    setMetric(stat.metric);
  };

  useEffect(() => {
    refetch();
  }, [upperAndLowerBoundDates]);

  return (
    <PageSetup
      title={STATS_PAGE.TITLE}
      subTitle={STATS_PAGE.SUB_TITLE}
      visibleInMobile={false}
      rightBodyElement={
        <DateSelector
          dateTime={dateTime}
          handleChange={(lowerBoundDate, upperBoundDate) => {
            setUpperAndLowerBoundDates({
              lowerBoundDate,
              upperBoundDate,
            });
          }}
        />
      }
      feature={FEATURES.ANALYTICS}
    >
      <>
        <Grid
          height="85%"
          width="100%"
          flex="1"
          templateColumns="repeat(3, 1fr)"
          gap={10}
          position="relative"
        >
          <>
            <GridItem
              colSpan={2}
              bg="white"
              border={`1px solid ${theme.palette.ui.greys.grey4}`}
              borderRadius="14px"
            >
              <Box p="10px">
                <StatCounters
                  viewCount={view_count!}
                  saveCount={save_count!}
                  exchangeCount={exchange_count!}
                  socialClickCount={social_media_click_count!}
                  thumbnailClickCount={thumbnail_link_click_count!}
                  handleStatChange={handleStatChange}
                  isLoading={statsLoading}
                />
              </Box>
              <Box>
                <StatChart dataSet={dataSet} />
              </Box>
              <Flex>
                <Box width="100%" padding="24px">
                  <TeamAndGroupsList
                    teamMembers={profiles?.details}
                    groups={groups?.details}
                    isLoading={statsLoading}
                  />
                </Box>
                <Box width="100%" padding="24px">
                  <SourceList
                    sources={sources?.details}
                    isLoading={statsLoading}
                  />
                </Box>
              </Flex>
            </GridItem>
            <GridItem colSpan={1} bg="white" position="relative">
              <InteractionsList
                isLoading={interactionsLoading}
                interactions={interactionsData}
              />
            </GridItem>
          </>
        </Grid>
      </>
    </PageSetup>
  );
};

export default Analytics;
