import { useMutation } from "react-query";
import { useToasts } from "react-toast-notifications";

import api from "../../../api";

const updateProfileService = async ({ profileData, handle }) => {
  const { data } = await api.put(`/p/${handle}/info`, {
    ...profileData,
  });
  return data;
};

const updateProfileVideoService = async ({ profileData, handle }) => {
  const postData = {
    videos: [profileData],
  };

  const { data } = await api.put(`/p/${handle}/video`, {
    ...postData,
  });
  return data;
};

const updateProfileThumbnailService = async ({ profileData, handle }) => {
  const postData = {
    thumbnail_links: profileData,
  };

  const { data } = await api.put(`/p/${handle}/thumbnail_link`, {
    ...postData,
  });
  return data;
};

const updateProfileFieldService = async ({ profileData, handle }) => {
  const postData = {
    fields: profileData,
  };

  const { data } = await api.put(`/p/${handle}/field`, {
    ...postData,
  });
  return data;
};

const update = updateType => {
  if (updateType === "profile") {
    return updateProfileService;
  }
  if (updateType === "video") {
    return updateProfileVideoService;
  }
  if (updateType === "thumbnail") {
    return updateProfileThumbnailService;
  }
  if (updateType === "field") {
    return updateProfileFieldService;
  }
};

export const useUpdateIndividualProfileQuery = ({
  onSuccess = () => {},
  showToasts = true,
  successMsg,
  updateType,
}) => {
  const { addToast } = useToasts();

  const [mutate, { status, error, isLoading }] = useMutation(
    update(updateType),
    {
      onSuccess: data => {
        onSuccess && onSuccess(data);
        showToasts &&
          addToast(successMsg, {
            appearance: "success",
          });
      },
      onError: error => {
        showToasts &&
          addToast(
            error?.response?.data?.detail || "Failed to update profile data.",
            { appearance: "error" },
          );
      },
    },
  );

  return {
    mutate,
    status,
    error,
    isLoading,
  };
};
