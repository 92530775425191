import React, { useState } from "react";

import { Draggable } from "react-beautiful-dnd";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

import AddLinks from "./AddLinks";
import {
  Button,
  DragAndDropWrapper,
  DragHandle,
  Icon,
  ListItem,
} from "../../components";
import { Flex } from "../../components/Container";
import {
  useCreateIndividualQuery,
  useDeleteIndividualQuery,
} from "../../features/individualProfile/queries";
import { useUpdateIndividualProfileQuery } from "../../features/individualProfile/queries/useUpdateIndividualProfileQuery";
import { reorderItems } from "../../utils";
import Card from "../individualProfile/Card";

function EditLinksPage() {
  const { profileHandle } = useParams();
  const location = useLocation();
  const { addToast } = useToasts();
  const [thumbnailLinks, setThumbnailLinks] = useState(
    location.state?.thumbnailLinks,
  );
  const [state, setState] = useState(() => {
    if (thumbnailLinks.length) {
      return { action: null, payload: null };
    }
    return { action: "ADD", payload: null };
  });
  const history = useHistory();

  const { mutate: updateThumbnails, status: updateStatus } =
    useUpdateIndividualProfileQuery({
      showToasts: true,
      updateType: "thumbnail",
      successMsg: "Links updated successfully",
    });

  const { mutate: createThumbnails, status: createStatus } =
    useCreateIndividualQuery({
      showToasts: true,
      createType: "thumbnail",
      onSuccess: () => {
        addToast("Success", {
          appearance: "success",
        });
      },
    });

  const { mutate: deleteIndividual, status: deleteStatus } =
    useDeleteIndividualQuery({
      type: "generic",
      onSuccess: () => {
        addToast("Link Removed Successfully", {
          appearance: "success",
        });
      },
    });

  const isLoading =
    updateStatus === "loading" ||
    createStatus === "loading" ||
    deleteStatus === "loading";

  const handleSubmit = async values => {
    if (!values?.thumbnail.includes("data:image")) {
      values = {
        id: values.id,
        link: values.link,
        order: values.order,
        title: values.title,
      };
    }
    if (state.action === "EDIT") {
      const data = await updateThumbnails(
        {
          handle: profileHandle,
          profileData: [values],
        },
        {
          onError: () => {
            addToast("Failed to update thumbnail, please contact support for assistance.", {
              appearance: "error",
            });
          },
        },
      );
      const { thumbnail_links } = data;
      const updateIndex = thumbnailLinks.findIndex(
        thumbnailLink => thumbnailLink.id === thumbnail_links[0].id,
      );
      thumbnailLinks[updateIndex] = thumbnail_links[0];
      updateList(thumbnailLinks);
      setState({ action: null, payload: null });
    }
    if (state.action === "ADD") {
      const data = await createThumbnails(
        {
          handle: profileHandle,
          profileData: {
            id: null,
            ...values,
          },
        },
        {
          onError: () => {
            addToast("Failed to create Thumbnail: Please check the image and try again, or contact support for assistance.", {
              appearance: "error",
            });
          },
        },
      );
      const { thumbnail_links } = data;
      const updatedThumbnailLinks = [...thumbnailLinks, ...thumbnail_links];
      updateList(updatedThumbnailLinks);
      setState({ action: null, payload: null });
    }
  };

  const handleDelete = linkId => {
    deleteIndividual(
      {
        handle: profileHandle,
        category: "thumbnail_link",
        id: linkId,
      },
      {
        onSuccess: () => {
          const updatedThumbnailLinks = thumbnailLinks.filter(
            link => link.id !== linkId,
          );
          updateList(updatedThumbnailLinks);
        },
      },
    );
  };

  const handleDragEnd = async result => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const reorderedLinks = reorderItems(
      thumbnailLinks,
      result.source.index,
      result.destination.index,
    );

    const updatedLinksList = reorderedLinks.map(link => ({
      id: link.id,
      order: link.order,
    }));

    await updateThumbnails(
      {
        handle: profileHandle,
        profileData: updatedLinksList,
      },
      {
        onError: () => {
          addToast("Failed to Update Thumbnail: Please check the image and try again, or contact support for assistance.", {
            appearance: "error",
          });
        },
      },
    );
    updateList(reorderedLinks);
  };

  const updateList = list => {
    setThumbnailLinks(list);
    const state = {
      thumbnailLinks: list,
    };
    history.replace({ ...history.location, state });
  };

  const navigate = () => {
    if (state.action === null || !thumbnailLinks.length) {
      history.go(-1);
      return;
    }
    setState({ action: null, payload: null });
  };

  return (
    <>
      <Card
        helmetTitle="OVOU | Edit Links"
        pageTitle="Edit Links"
        label="Here you can add or edit links"
        showBorder={false}
      >
        {(state.action === "ADD" || state.action === "EDIT") && (
          <>
            <AddLinks
              profileHandle={profileHandle}
              handleSubmit={handleSubmit}
              status={isLoading && "loading"}
              thumbnailLink={state.payload}
            ></AddLinks>
          </>
        )}
        {!state.action && (
          <DragAndDropWrapper onDragEnd={handleDragEnd} droppableId="linksList">
            {thumbnailLinks.map((link, i) => (
              <Draggable
                draggableId={link?.id?.toString()}
                key={link?.id}
                index={i}
              >
                {(provided, snapshot) => (
                  <Flex
                    width="100%"
                    {...provided.draggableProps}
                    ref={provided.innerRef}
                    isDragging={snapshot.isDragging}
                    {...provided.dragHandleProps}
                  >
                    <div className="mt-4">
                      <DragHandle />
                    </div>
                    <ListItem
                      key={link.id}
                      variant="link"
                      image={link?.thumbnail}
                      link={link?.link}
                      label={link?.title}
                      showBorder={i + 1 !== thumbnailLinks.length}
                      isEditable={true}
                      onDelete={() => handleDelete(link.id)}
                      onEdit={() => setState({ action: "EDIT", payload: link })}
                    />
                  </Flex>
                )}
              </Draggable>
            ))}
          </DragAndDropWrapper>
        )}
        {!state.action && (
          <div className="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-center mt-6 sm:mt-5 md:mt-12">
            <div className="col-start-1">
              <Button
                size="small"
                variant="white"
                block
                onClick={() => setState({ action: "ADD", payload: null })}
              >
                <Icon name="plusCircle" className="mr-2 text-current" />
                Add Link
              </Button>
            </div>
          </div>
        )}
      </Card>
    </>
  );
}

export default EditLinksPage;
