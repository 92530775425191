import React from "react";

import { ProfileCard } from "../ProfileCard";

export const UpdateProfileCard = ({ children, ...props }) => {
  return (
    <>
      <ProfileCard UpdateComponent={children} {...props} />
    </>
  );
};
