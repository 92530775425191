import React from "react";

import NotFoundImage from "../../assets/images/notfound.png";

const NoResultsFound = ({ children }) => {
  return (
    <div className="flex flex-col flex-1 items-center justify-center">
      <div className="flex flex-col items-center justify-center text-center">
        <img src={NotFoundImage} alt="No directories has been found" />
        <h1 className="text-custom-gray-500 font-bold text-2xl md:text-4xl leading-none transform  -translate-y-4 md:-translate-y-16">
          Create your first group!
        </h1>
      </div>
      <div>{children}</div>
    </div>
  );
};

export default NoResultsFound;
