import { useQuery } from "react-query";
import { IPermissions } from "types/Permissions";

import api from "../api";

const permissionsService = async () => {
  const { data } = await api.get(`auth/role`);
  return data[0];
};

export const usePermissionsQuery = ({ enabled }: { enabled: boolean }) => {
  const query = useQuery<IPermissions>(["my-permissions"], permissionsService, {
    enabled: enabled,

    refetchInterval: 60000,
  });

  return {
    ...query,
  };
};
