import React, { FC, useState } from "react";
import { useToasts } from "react-toast-notifications";

import {
  Box,
  CButton,
  CardsCounter,
  Flex,
  PageSetup,
  ShimmerPlaceholder,
} from "../../components";
import { DEVICES_TEXTS } from "../../constants/Texts";
import { EmptyState } from "../../components/EmptyState";
import SearchInput from "../../features/contacts/components/SearchInput";
import { useCardsPoolQuery } from "../../features/cards/queries";
import { TablePagination } from "../../features/contacts/components/TablePagination";
import { DevicesTable } from "./DevicesTable";
import { CardState, ICard } from "../../types/Card";
import {
  useCardsPoolStatsQuery,
  useUnassignCardsMutation,
} from "../../features/manageCards/api";
import { useUser } from "context";
import { BiCart } from "react-icons/bi";
import { FEATURES } from "constants/features";
import { AssignDeviceModal } from "./AssignDeviceModal";
import CardStateFilter from "./components/CardStateFilter";
import { useBatchCardAssignMutation } from "features/business/queries";
import { useCanOrderCompanyCard } from "hooks/useCanOrderCompanyCard";

interface IDevicesProps {
  teamId: number;
}

export const Devices: FC<IDevicesProps> = ({ teamId }) => {
  const { addToast } = useToasts();
  const [devices, setDevices] = useState<ICard[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [statesFilter, setStatesFilter] = useState<CardState[]>([]);
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(20);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [isAssignCardModalOpen, setIsAssignCardModalOpen] =
    useState<boolean>(false);
  const [currentCard, setCurrentCard] = useState<ICard>();

  const [{ currentProfile: businessProfile }] = useUser();

  const {
    data: cardsStats,
    refetch: refetchStats,
    isLoading: isStatsLoading,
  } = useCardsPoolStatsQuery({
    teamID: teamId,
    onSuccess: () => {},
  });
  const { orderAddonCardUrl } = useCanOrderCompanyCard();

  const {
    data: { count: teamDevicesCount } = {},
    isFetching,
    refetch: refetchCards,
  } = useCardsPoolQuery({
    teamID: teamId,
    searchQuery,
    offset,
    limit,
    states: statesFilter,
    onSuccess: (data: any) => setDevices(data.results || []),
  } as any);

  const { mutate: fireBatchAssign, isLoading: isAssigningCard } =
    useBatchCardAssignMutation(false);
  const [fireBatchUnAssign] = useUnassignCardsMutation(false);

  const handleOpenAssignCardModal = (card: ICard) => {
    setCurrentCard(card);
    setIsAssignCardModalOpen(true);
  };

  const handleAssignCard = async (
    handle: string,
    card: ICard,
    isMember?: boolean,
  ) => {
    await fireBatchAssign(
      {
        batchAssignCardData: { card_ids: [card.id] },
        handle,
        isMember,
      },
      {
        onSuccess: () => {
          addToast("Card assigned successfully.", {
            appearance: "success",
          });
          refetchCards();
        },
      },
    );
    setIsAssignCardModalOpen(false);
    refetchStats();
  };

  const handleAssignCardToMember = (handle: string) => {
    handleAssignCard(handle, currentCard!, true);
  };

  const handleAssignCardToBusiness = (card: ICard) => {
    handleAssignCard(businessProfile?.handle || "", card);
  };

  const handleUnassignCard = async (card: ICard) => {
    await fireBatchUnAssign(
      { card_ids: [card.id] },
      {
        onSuccess: () => {
          const updatedDevices = devices.map(device => {
            if (device.id === card.id) {
              return {
                ...device,
                profile: null,
                state: CardState.UNASSIGNED,
              };
            }

            return device;
          });

          setDevices(updatedDevices);

          addToast("Card unassigned successfully.", {
            appearance: "success",
          });
        },
      },
    );
    refetchStats();
  };

  const handleSetPageSize = (pageSize: number) => {
    setCurrentPage(0);
    setOffset(0);
    setLimit(pageSize);
  };

  const handlePageClick = (event: any) => {
    setCurrentPage(event.selected);
    setOffset(event.selected * limit);
  };

  const pageCount = Boolean(teamDevicesCount)
    ? Math.max(Math.ceil(teamDevicesCount / limit), 1)
    : 1;
  const hasFilters = Boolean(searchQuery) || Boolean(statesFilter.length);

  return (
    <PageSetup
      title={DEVICES_TEXTS.DEVICES_PAGE.TITLE}
      titleElement={
        <Box marginLeft="20px">
          {isStatsLoading ? (
            <ShimmerPlaceholder />
          ) : (
            <CardsCounter
              appointedCards={cardsStats?.appointed || 0}
              assignedCards={cardsStats?.assigned || 0}
              unassignedCards={cardsStats?.unassigned || 0}
            />
          )}
        </Box>
      }
      subTitle={DEVICES_TEXTS.DEVICES_PAGE.SUB_TITLE}
      rightBodyElement={
        <CButton
          as="a"
          target="_blank"
          href={orderAddonCardUrl}
          fontSize="0.875rem"
          paddingX={4}
          paddingY={2}
          gap={2}
        >
          <BiCart size="1.5rem" />
          Order Cards
        </CButton>
      }
      feature={FEATURES.MANAGE_DEVICES}
    >
      <>
        {teamDevicesCount === 0 && !hasFilters ? (
          <EmptyState
            title={DEVICES_TEXTS.EMPTY_STATE.TITLE}
            subTitle={DEVICES_TEXTS.EMPTY_STATE.SUB_TITLE}
          />
        ) : (
          <>
            <Flex flexDirection="column" width="full" maxWidth="500px" gap={8}>
              <SearchInput
                defaultValue={searchQuery}
                onChange={setSearchQuery}
              />

              <CardStateFilter onSelect={setStatesFilter} />
            </Flex>

            <DevicesTable
              devices={devices}
              isFetching={isFetching}
              onAssignCard={handleOpenAssignCardModal}
              onUnassignCard={handleUnassignCard}
            />

            <TablePagination
              currentPage={currentPage}
              pageCount={pageCount}
              pageSize={limit}
              setPageSize={handleSetPageSize}
              onPageClick={handlePageClick}
            />

            <AssignDeviceModal
              teamId={teamId}
              card={currentCard}
              isOpen={isAssignCardModalOpen}
              isSubmitting={isAssigningCard}
              onClose={() => setIsAssignCardModalOpen(false)}
              onAssignCardToMember={handleAssignCardToMember}
              onAssignCardToBusiness={handleAssignCardToBusiness}
            />
          </>
        )}
      </>
    </PageSetup>
  );
};
