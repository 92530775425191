import { FC, useMemo, useState } from "react";
import { Box, Flex, FormatNumber, Icon, SkeletonText, Text } from "components";
import { IStat, IStatCounters, METRIC } from "./types";
import { theme } from "ovou-ui";
import { ANALYTICS_EVENTS } from "constants/analyticEvents";
import { useTracking } from "hooks";

const IconComponent = Icon as any;

const StatCounters: FC<IStatCounters> = ({
  viewCount,
  saveCount,
  exchangeCount,
  socialClickCount,
  thumbnailClickCount,
  handleStatChange,
  isLoading,
}) => {
  const track = useTracking();
  const [activeStateCount, setActiveStateCount] = useState(0);
  const stats: IStat[] = useMemo(() => {
    return [
      {
        title: "Interactions",
        icon: "eye",
        count: viewCount,
        metric: METRIC.VIEW_COUNT,
        event: ANALYTICS_EVENTS.ANALYTICS_INTERACTIONS,
        iconStyles: {
          color: `${theme.palette.ui.greys.grey2}`,
          height: "24px",
          width: "24px",
        },
      },
      {
        title: "Save Contacts",
        icon: "download",
        count: saveCount,
        metric: METRIC.SAVE_COUNT,
        event: ANALYTICS_EVENTS.ANALYTICS_SAVE_CONTACTS,
        iconStyles: {
          color: `${theme.palette.ui.greys.grey2}`,
          height: "24px",
          width: "24px",
        },
      },
      {
        title: "Exchange Contact",
        icon: "exchange",
        count: exchangeCount,
        metric: METRIC.EXCHANGE_COUNT,
        event: ANALYTICS_EVENTS.ANALYTICS_EXCHANGE_CONTACT,
        iconStyles: {
          color: `${theme.palette.ui.greys.grey2}`,
          height: "24px",
          width: "24px",
        },
      },
      {
        title: "Social Clicks",
        icon: "pointClick",
        count: socialClickCount,
        metric: METRIC.SOCIAL_MEDIA_CLICK_COUNT,
        event: ANALYTICS_EVENTS.ANALYTICS_SOCIAL_CLICKS,
        iconStyles: {
          color: `${theme.palette.ui.greys.grey2}`,
          height: "24px",
          width: "24px",
        },
      },
      {
        title: "Link Clicks",
        icon: "linkClick",
        count: thumbnailClickCount,
        metric: METRIC.THUMBNAIL_LINK_CLICK_COUNT,
        event: ANALYTICS_EVENTS.ANALYTICS_LINK_CLICKS,
        iconStyles: {
          stroke: `${theme.palette.ui.greys.grey2}`,
          height: "24px",
          width: "24px",
        },
      },
    ];
  }, [
    viewCount,
    saveCount,
    exchangeCount,
    socialClickCount,
    thumbnailClickCount,
  ]);

  const onStatClick = (stat: IStat, index: number): void => {
    handleStatChange(stat);
    setActiveStateCount(index);
    track({
      eventName: stat.event,
    });
  };

  return (
    <Flex>
      {stats.map((stat, index) => (
        <Box
          key={stat.title}
          width="30%"
          height="100%"
          borderRadius="10px"
          cursor="pointer"
          border={
            activeStateCount === index
              ? `1px solid ${theme.palette.ui.greys.grey3}`
              : ""
          }
          position="relative"
          background={
            activeStateCount === index
              ? theme.palette.brand.primary.white
              : theme.palette.ui.greys.grey5
          }
          m="8px"
          onClick={() => onStatClick(stat, index)}
        >
          {activeStateCount === index && (
            <Box
              position="absolute"
              height="4px"
              width="80%"
              background="#FF643A"
              top="0"
              left="10%"
              borderRadius="10px"
            />
          )}
          <Flex flexDirection="column" justifyContent="center" p="12px">
            <Box
              sx={{
                svg: {
                  ...stat.iconStyles,
                  [stat.iconStyles.color ? "color" : "stroke"]: `${
                    activeStateCount === index
                      ? theme.palette.brand.primary.black
                      : theme.palette.ui.greys.grey2
                  }`,
                },
              }}
            >
              <IconComponent name={stat.icon} />
            </Box>
            <Text
              fontSize={{ base: "10px", lg: "10px", xl: "10px", "2xl": "12px" }}
              mt="9px"
              color={
                activeStateCount === index
                  ? theme.palette.brand.primary.black
                  : theme.palette.ui.greys.grey1
              }
            >
              {stat.title}
            </Text>
            <SkeletonText
              noOfLines={1}
              skeletonHeight={6}
              isLoaded={!isLoading}
            >
              <FormatNumber
                fontWeight={800}
                fontSize="21px"
                count={stat.count}
              />
            </SkeletonText>
          </Flex>
        </Box>
      ))}
    </Flex>
  );
};

export default StatCounters;
