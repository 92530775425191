import { FC } from "react";

import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  TimeScale,
  Tooltip,
  Title,
} from "chart.js";
import "chartjs-adapter-date-fns";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Tooltip,
  Title,
);

interface ILineGraph {
  data: any;
}
const LineGraph: FC<ILineGraph> = ({ data }) => {
  return (
    <Line
      options={{
        responsive: true,
        scales: {
          x: {
            type: "time",
            time: {
              unit: "month",
            },
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              title: context => {
                const d = new Date(context[0].parsed.x);
                const formattedDate = d.toLocaleString([], {
                  month: "short",
                  day: "2-digit",
                  year: "numeric",
                });
                return formattedDate;
              },
            },
          },
        },
      }}
      data={{
        datasets: [
          {
            label: "",
            data: data?.length
              ? data?.map(({ count, date }: { count: number; date: Date }) => {
                  return {
                    y: count,
                    x: new Date(date),
                  };
                })
              : [],
            backgroundColor: "#155CB7",
            borderColor: "#155CB7",
            pointBorderColor: "white",
          },
        ],
      }}
      height="186px"
      width="680px"
    />
  );
};

export default LineGraph;
