import React, { useState } from "react";
import { getIn } from "formik";
import { Input, Flex, Grid, Box } from "@chakra-ui/react";
import { FormControl, FormErrorMessage } from "@chakra-ui/form-control";
import PhoneNumber from "react-phone-input-2";
import Select from "react-select";
import styled from "styled-components";

import { useWindowSize } from "utils/hooks";
import { customSelectStyles } from "../SelectFormField/styles";
import Icon from "../../Icon";

const Wrapper = ({ className, handleRemoveItem, ...props }) => {
  return (
    <Flex className={className} alignItems="center">
      <PhoneNumber {...props} />
    </Flex>
  );
};

const Phone = styled(Wrapper)`
  .react-tel-input .special-label {
    display: none !important;
  }
  #phone-input {
    border: none;
    border-radius: 0 !important;
    border-bottom: 1px solid #cacaca;
    padding: 18.5px 14px 11.5px 58px;
  }
`;

const PhoneNumberInput = props => {
  const { options, handleRemoveItem, phone, form, field } = props;

  const [selectedValue, setSelectValue] = useState(() => {
    return options.find(item => item.value === phone.field_type_id) || null;
  });

  const errorMessage =
    getIn(form.touched, field.name) && getIn(form.errors, field.name);

  const handlePhoneNumberChange = value => {
    form.setFieldValue(field.name, {
      ...field.value,
      ["value"]: value.startsWith("+") ? value : `+${value}`,
    });
    form.setFieldTouched(field.name, true);
  };

  const handleExtensionChange = event => {
    const value = event.target?.value;
    form.setFieldValue(field.name, {
      ...field.value,
      ["extension"]: value,
    });
    form.setFieldTouched(field.name, true);
  };

  const handleSelectChange = selected => {
    setSelectValue(selected);
    form.setFieldValue(field.name, {
      ...field.value,
      ["field_type_id"]: selected?.value,
    });
    form.setFieldTouched(field.name, true);
  };

  const width = useWindowSize();
  const isMobile = width <= 768;

  return (
    <FormControl isInvalid={!!errorMessage}>
      <Flex maxWidth="full" flexDirection="column" marginTop={8}>
        <Flex justifyContent="flex-end">
          <Box cursor="pointer" onClick={handleRemoveItem}>
            <Icon name="close" width="20px" height="20px" />
          </Box>
        </Flex>

        <Grid
          templateColumns="1fr 2fr 0.7fr"
          gap="2"
          flex="1"
          alignItems="flex-start"
        >
          <Select
            styles={{
              ...customSelectStyles,
              container: provided => ({
                ...provided,
                minWidth: "110px",
                height: "100%",
              }),
              control: provided => ({
                ...provided,
                border: "none !important",
                borderBottom: "1px solid #d1d2d3 !important",
                boxShadow: "none",
                borderRadius: "none",
                height: "100%",
              }),
            }}
            options={options}
            onChange={handleSelectChange}
            value={selectedValue}
            placeholder="Phone"
          />
          <Phone
            isMobile={isMobile}
            country="ca"
            inputProps={{
              id: "phone-input",
            }}
            inputStyle={{
              width: "100%",
              height: "100%",
            }}
            enableSearch={false}
            onChange={handlePhoneNumberChange}
            value={phone.value}
          />
          <Input
            id="extension"
            placeholder="Ext."
            width="100%"
            height="100%"
            value={phone.extension}
            onChange={handleExtensionChange}
          />
        </Grid>

        {Boolean(errorMessage) && (
          <FormErrorMessage>
            {errorMessage?.field_type_id ||
              errorMessage?.value ||
              errorMessage?.extension}
          </FormErrorMessage>
        )}
      </Flex>
    </FormControl>
  );
};

export default PhoneNumberInput;
