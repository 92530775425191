import { useMutation } from "react-query";

import api from "../../../api";

const updateTeamService = async ({ teamID, teamData }) => {
  const { data } = await api.put(`/team/${teamID}/update`, teamData);
  return data;
};

export const useUpdateTeamMutation = ({ onSuccess = () => {} }) => {
  return useMutation(updateTeamService, {
    onSuccess,
  });
};
