import React from "react";

const Download = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M4 14C4.55228 14 5 14.4477 5 15V18.3333C5 18.4886 5.06518 18.6516 5.20464 18.7823C5.34628 18.9151 5.55142 19 5.77778 19H18.2222C18.4486 19 18.6537 18.9151 18.7954 18.7823C18.9348 18.6516 19 18.4886 19 18.3333V15C19 14.4477 19.4477 14 20 14C20.5523 14 21 14.4477 21 15V18.3333C21 19.0622 20.6906 19.747 20.1632 20.2414C19.6381 20.7337 18.9389 21 18.2222 21H5.77778C5.06114 21 4.36191 20.7337 3.83676 20.2414C3.30942 19.747 3 19.0622 3 18.3333V15C3 14.4477 3.44772 14 4 14Z" />
      <path d="M12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L7.29289 11.7071C6.90237 11.3166 6.90237 10.6834 7.29289 10.2929C7.68342 9.90237 8.31658 9.90237 8.70711 10.2929L12 13.5858L15.2929 10.2929C15.6834 9.90237 16.3166 9.90237 16.7071 10.2929C17.0976 10.6834 17.0976 11.3166 16.7071 11.7071L12.7071 15.7071Z" />
      <path d="M12 16C11.4477 16 11 15.5523 11 15L11 4C11 3.44771 11.4477 3 12 3C12.5523 3 13 3.44771 13 4L13 15C13 15.5523 12.5523 16 12 16Z" />
    </svg>
  );
};

export default React.memo(Download);
