import React from "react";

const Waiting = ({
  className,
  role,
  ignoreDefaultSize,
  width = 36,
  height = 36,
  strokeWidth = 2.5,
  onClick,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={ignoreDefaultSize ? undefined : width}
      height={ignoreDefaultSize ? undefined : height}
      viewBox="0 -3 27 33"
      role={role}
      className={className}
      strokeWidth={strokeWidth}
      onClick={onClick}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="currentColor"
        d="M20.566 7.786a.551.551 0 1 1-.779 0 .551.551 0 0 1 .779 0"
        transform="translate(4.807 -.828)"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="currentColor"
        d="M23.574 5.231A13.2 13.2 0 0 0 16.226 3 13.225 13.225 0 0 0 3 16.226"
        transform="translate(-3 -3)"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="currentColor"
        d="M4.566 15.786a.551.551 0 1 1-.779 0 .551.551 0 0 1 .779 0"
        transform="translate(-2.707 2.928)"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="currentColor"
        d="M7 23a13.2 13.2 0 0 0 7.348 2.226A13.225 13.225 0 0 0 27.574 12"
        transform="translate(-1.122 1.226)"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="currentColor"
        d="M15.661 13.878l1.915 2.469a1.494 1.494 0 0 1 .313.916v1.749a.748.748 0 0 1-.747.747H9.71a.748.748 0 0 1-.747-.747v-1.776a1.5 1.5 0 0 1 .284-.876l1.8-2.482-1.8-2.478a1.5 1.5 0 0 1-.284-.876V8.747A.748.748 0 0 1 9.71 8h7.433a.748.748 0 0 1 .747.747v1.747a1.5 1.5 0 0 1-.313.916z"
        transform="translate(-.2 -.652)"
      />
    </svg>
  );
};

export default React.memo(Waiting);
