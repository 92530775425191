import React from "react";

import { SchemePicker } from "../../SchemePicker";

const SchemePickerFormField = ({
  form,
  field,
  setDefaultColorScheme = () => {},
  ...restProps
}) => {
  const handleSchemePick = scheme => {
    form.setFieldValue(field.name, scheme?.id);
    form.setFieldTouched(field.name, true);
    setDefaultColorScheme(scheme);
  };

  return (
    <>
      <SchemePicker
        onPick={handleSchemePick}
        pickedSchemeIndex={Number(field.value)}
        form={form}
        field={field}
        {...field}
        {...restProps}
      />
    </>
  );
};

export default SchemePickerFormField;
