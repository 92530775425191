import React, { useEffect } from "react";

import { Box, Flex, Icon, Image } from "components";
import logo from "../../assets/images/logo.svg";

import { ISidebar } from "../../types/ISidebar";
import TeamSelector from "./TeamSelector";
import { useMyTeamsQuery, useProfileDataQuery } from "features/auth/queries";

const IconComponent = Icon as any;

const SidebarHeader = ({
  isMobile,
  close,
}: Pick<ISidebar, "isMobile" | "close">) => {
  const { data: teamsData } = useMyTeamsQuery({}, true);
  const { data: profileData } = useProfileDataQuery({}, true);

  return (
    <>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        flexShrink={0}
        width="full"
        height={isMobile ? 16 : 24}
        paddingX={4}
      >
        <Box
          cursor="pointer"
          _focus={{
            outline: "none",
          }}
        >
          <IconComponent name="menu" onClick={close} />
        </Box>
        <Image width={28} src={logo} alt="OVOU Logo" />
      </Flex>
      {teamsData && profileData && (
        <TeamSelector teamsData={teamsData} profileData={profileData} />
      )}
    </>
  );
};

export default SidebarHeader;
