import React from "react";

const Facebook = ({ className }) => {
  return (
    <svg
      width="24"
      height="23"
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M21.3875 11.6665C21.3875 6.42976 17.1943 2.18555 12.0204 2.18555C6.84656 2.18555 2.65332 6.42976 2.65332 11.6665C2.65332 16.3996 6.07816 20.3216 10.5568 21.0327V14.4071H8.17844V11.6665H10.5568V9.57773C10.5568 7.20194 11.9545 5.88905 14.0951 5.88905C15.1196 5.88905 16.1917 6.07422 16.1917 6.07422V8.40743H15.0098C13.8463 8.40743 13.484 9.13887 13.484 9.88883V11.6665H16.0819L15.6666 14.4071H13.484V21.0327C17.9627 20.3216 21.3875 16.3996 21.3875 11.6665Z"
        fill="#1877F2"
      />
    </svg>
  );
};

export default React.memo(Facebook);
