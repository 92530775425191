import { useQuery } from "react-query";

import api from "../../../api";

export const useSettings: () => any = () => {
  return useQuery(["get-settings"], async () => {
    const { data } = await api.get(`/settings`);
    return data;
  });
};
