import { FC } from "react";
import {
  Box,
  FormatNumber,
  GlobalListItem,
  Image,
  Skeleton,
  Text,
} from "components";
import { theme } from "ovou-ui";
import AnalyticsSection from "./AnalyticsSection";
import { ISourceList } from "./types";
import { ANALYTICS_TEXT } from "constants/Texts";

const { POPOVER } = ANALYTICS_TEXT;
const SourceList: FC<ISourceList> = ({ sources, isLoading }) => {
  return (
    <AnalyticsSection
      title="Source"
      rightElement={
        <Text fontSize={12} color={theme.palette.ui.greys.grey2}>
          interactions
        </Text>
      }
      showPopover
      popoverTitle={POPOVER.TITLE}
      popoverText={POPOVER.SUB_TITLE}
    >
      {isLoading ? (
        [...Array(5)].map((_, index) => (
          <Skeleton h="24px" mb="24px" key={index} />
        ))
      ) : (
        <>
          {sources?.map(({ name, icon, count, id }) => (
            <Box mb="24px" key={id}>
              <GlobalListItem
                title={name}
                leftElement={
                  <Image height="1.5rem" width="1.5rem" src={icon} />
                }
                rightElement={<FormatNumber count={count} />}
              />
            </Box>
          ))}
        </>
      )}
    </AnalyticsSection>
  );
};

export default SourceList;
