import React from "react";

const Search = ({
  className,
  role,
  ignoreDefaultSize,
  width = 24,
  height = 24,
  onClick,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={ignoreDefaultSize ? undefined : width}
      height={ignoreDefaultSize ? undefined : height}
      viewBox="0 0 28 28"
      role={role}
      className={className}
      onClick={onClick}
    >
      <path d="M24.162 22.505l-3.967-3.955a9.24 9.24 0 001.972-5.717 9.333 9.333 0 10-9.334 9.334 9.24 9.24 0 005.717-1.972l3.955 3.967a1.167 1.167 0 001.657 0 1.167 1.167 0 000-1.657zM5.833 12.833a7 7 0 117 7 7 7 0 01-7-7z" />
    </svg>
  );
};

export default React.memo(Search);
