import React, { useEffect } from "react";

import queryString from "query-string";
import { useHistory } from "react-router-dom";

import {
  setProfile,
  setTeam,
  usePermissionsState,
  useUserDispatch,
} from "../../../context";
import {
  useMyTeamsQuery,
  useProfileDataQuery,
} from "../../../features/auth/queries";
import { removeToken, setToken } from "../../../utils";
import { IProfileResponse } from "types/Profile";
import { ITeamResponse } from "types/Team";
import { Loader } from "../../../components/Spinner";
import { isNeedsWizard } from "utils/userStatus";

const LoginWithTokenPage = () => {
  const history = useHistory();
  const dispatch = useUserDispatch();
  const { status: userStatus } = usePermissionsState();

  const { getProfileData } = useProfileDataQuery({
    onSuccess: (data: IProfileResponse) => {
      dispatch(setProfile(data?.profiles?.[0]));
    },
  });
  const {
    data: teamsData,
    getMyTeams,
    isSuccess: wasGetTeamsDataSuccesful,
  } = useMyTeamsQuery({
    onSuccess: (data: ITeamResponse) => {
      const team = data?.teams?.[0];
      dispatch(setTeam(team));
    },
  });

  const { accessToken, refreshToken } = queryString.parse(
    history.location.search,
  );

  useEffect(() => {
    const login = async () => {
      localStorage.clear();
      setToken(accessToken, refreshToken);
      await getProfileData();
      await getMyTeams();
    };
    if (accessToken && refreshToken) {
      login();
    }
  }, [history, refreshToken, accessToken, getProfileData, getMyTeams]);

  useEffect(() => {
    if (wasGetTeamsDataSuccesful && userStatus) {
      const team = teamsData?.teams?.[0];
      if (!team) {
        removeToken();
        history.replace("/auth/logout", { notOwner: true });
      } else {
        if (isNeedsWizard(team.wizard_step)) {
          history.replace("/onboarding");
        } else {
          history.replace("/");
        }
      }
    }
  }, [wasGetTeamsDataSuccesful, userStatus]);

  return <Loader />;
};

export default LoginWithTokenPage;
