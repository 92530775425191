import React from "react";

import { SetupByCompanyToggle } from "..";
import { TEXTS } from "../../constants";
import Icon from "../Icon";

const CardHover = ({ state, firstName, cardData, refetch, tag }) => {
  return (
    <div
      id="hover-el"
      className="absolute inset-0 bg-black z-20 text-white bg-opacity-75"
    >
      <div className="flex flex-col w-full h-full text-center justify-center items-center cursor-pointer">
        <Icon
          name={state === "delete" ? "delete" : "signIn"}
          width={40}
          height={40}
          strokeWidth="2.2px"
        />
        <p className="uppercase text-xl font-bold mt-2">
          {state === "delete"
            ? TEXTS.common.UNASSIGN
            : `${
                !cardData.is_user_editable
                  ? cardData?.is_registered
                    ? `Login as ${firstName}`
                    : `Setup ${tag ? tag + "'s" : ""} Card`
                  : `Open ${firstName}'s Profile`
              }`}
        </p>
        <p className="font-light text-base text-custom-gray-300">
          {state === "delete" ? `${firstName}'s Card` : tag ? `(${tag})` : ""}
        </p>

        <SetupByCompanyToggle
          setUpByCompany={!cardData?.is_user_editable}
          handle={cardData?.handle}
          refetch={refetch}
        />
      </div>
    </div>
  );
};

export default CardHover;
