import { LocationDescriptor } from "history";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";

/**
 * A wrapper for react-router-dom
 *
 */
const useRouter = () => {
  const history = useHistory();

  const push = useCallback(
    (to: LocationDescriptor<unknown>) => history.push(to),
    [],
  );

  const replace = useCallback(
    (to: LocationDescriptor<unknown>, state?: unknown) =>
      history.replace(to, state),
    [],
  );

  const go = useCallback((delta: number) => history.go(delta), []);

  const back = useCallback(() => history.go(-1), []);

  const forward = useCallback(() => history.go(1), []);

  return {
    back,
    forward,
    go,
    push,
    replace,
  };
};

export default useRouter;
