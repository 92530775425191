import { useQuery } from "react-query";

import api from "../../../api";

const profileService = async ({ profileHandle }) => {
  const { data } = await api.get(`/p/${profileHandle}`);
  return data;
};

export const useIndividualProfileQuery = ({ profileHandle }) => {
  const { data, isLoading } = useQuery(
    ["individualProfile", profileHandle],
    () => profileService({ profileHandle }),
  );
  return { data, isLoading };
};
