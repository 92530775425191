import { ANALYTICS_EVENTS } from "constants/analyticEvents";
import { usePermissionsState, useUserState } from "context";
import { useAnalytics } from "context/Analytics";
import { TEventProperties } from "context/Analytics/types";
import { useCallback, useMemo } from "react";
import { USER_IAM_ROLE } from "types/TeamUsersResponse";

const useTracking = () => {
  const analytics = useAnalytics();
  const { permission, status } = usePermissionsState();
  const { currentTeam, currentProfile } = useUserState();

  const defaultTrackingProps = useMemo(() => {
    return {
      handle: currentProfile?.handle,
      teamId: currentTeam?.id,
      publicId: currentProfile?.public_id,
      role: permission?.user_iam_role,
      state: permission?.state,
      status: status,
    };
  }, [
    currentProfile?.handle,
    currentTeam?.id,
    currentProfile?.public_id,
    permission?.user_iam_role,
    permission?.state,
    status,
  ]);

  const track = useCallback(
    ({
      eventName,
      customTrackingProps,
      upgrade = false,
    }: {
      eventName?: ANALYTICS_EVENTS;
      customTrackingProps?: TEventProperties;
      upgrade?: boolean;
    }) => {
      if (eventName) {
        analytics?.track?.(eventName, {
          ...defaultTrackingProps,
          ...customTrackingProps,
        });
      }
      if (upgrade && permission) {
        analytics.track(
          upgradeEvents[permission.user_iam_role as USER_IAM_ROLE],
          { ...defaultTrackingProps, ...customTrackingProps },
        );
      }
    },
    [permission, defaultTrackingProps],
  );

  return track;
};

const upgradeEvents = {
  [`${USER_IAM_ROLE.TEAM_LIMITED_OWNER}`]:
    ANALYTICS_EVENTS.TEAM_LIMITED_OWNER_UPGRADE,
  [`${USER_IAM_ROLE.TEAM_LIMITED_ADMIN}`]:
    ANALYTICS_EVENTS.TEAM_LIMITED_ADMIN_UPGRADE,
  [`${USER_IAM_ROLE.TEAM_ACTIVE_ADMIN}`]:
    ANALYTICS_EVENTS.TEAM_ACTIVE_ADMIN_MANAGE_SUBSCRIPTION,
  [`${USER_IAM_ROLE.TEAM_LEGACY_OWNER}`]:
    ANALYTICS_EVENTS.TEAM_LEGACY_OWNER_UPGRADE,
  [`${USER_IAM_ROLE.TEAM_LEGACY_ADMIN}`]:
    ANALYTICS_EVENTS.TEAM_LEGACY_ADMIN_UPGRADE,
};

export default useTracking;
