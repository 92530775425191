import React, { FC } from "react";
import { useParams } from "react-router-dom";

import { ContactDetail } from "../../features";
import { useUserState } from "../../context";

export const ContactDetailPage: FC = () => {
  const { contactId } = useParams<{ contactId: string }>();
  const { currentTeam }: { currentTeam: any } = useUserState();
  const teamId = currentTeam?.id as number;

  return <ContactDetail contactId={Number(contactId)} teamId={teamId} />;
};
