import React from "react";

import Collapsible from "react-collapsible";
import styled from "styled-components";

import Header from "./Header";

const Wrapper = styled.div`
  width: 100%;
  .Collapsible {
    .Collapsible__trigger {
      position: relative;
      display: block;
      &:after {
        content: "";
        cursor: pointer;
        position: absolute;
        right: 22px;
        top: 15px;
        width: 24px;
        height: 24px;
        display: block;
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGcgZGF0YS1uYW1lPSJMYXllciAyIj48ZyBkYXRhLW5hbWU9ImNoZXZyb24tZG93biI+PHJlY3Qgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0IiBvcGFjaXR5PSIwIi8+PHBhdGggZD0iTTEyIDE1LjVhMSAxIDAgMCAxLS43MS0uMjlsLTQtNGExIDEgMCAxIDEgMS40Mi0xLjQyTDEyIDEzLjFsMy4zLTMuMThhMSAxIDAgMSAxIDEuMzggMS40NGwtNCAzLjg2YTEgMSAwIDAgMS0uNjguMjh6Ii8+PC9nPjwvZz48L3N2Zz4=");
        transition: transform 150ms;
      }

      &.is-open {
        &:after {
          transform: rotate(180deg);
        }
      }
    }
  }
`;

const Accordion = ({
  onDelete,
  onEdit,
  onOpen,
  title,
  id,
  children,
  testid,
  isOpen,
}) => {
  const handleDelete = e => {
    e.stopPropagation();
    onDelete(id);
  };
  const handleEdit = e => {
    e.stopPropagation();
    onEdit(id);
  };

  return (
    <Wrapper data-testid={`accordion-wrapper-${testid}`}>
      <Collapsible
        transitionTime={150}
        onOpen={() => onOpen && onOpen(id)}
        open={isOpen}
        trigger={
          <Header title={title} onDelete={handleDelete} onEdit={handleEdit} />
        }
      >
        <div className="my-6">{children}</div>
      </Collapsible>
    </Wrapper>
  );
};

export default Accordion;
