import { useMutation } from "react-query";

import api from "../../api";

const toggleSetupByCompanyService = async ({ toggleSetupByCompanyData }) => {
  const { data } = await api.put(
    `/p/${toggleSetupByCompanyData.handle}/access`,
    {
      is_user_editable: toggleSetupByCompanyData.isUserEditable,
    },
  );
  return data;
};

export const useToggleSetupByCompanyMutation = () => {
  const [mutate, { status, error, isLoading }] = useMutation(
    toggleSetupByCompanyService,
  );

  return {
    mutate,
    status,
    error,
    isLoading,
  };
};
