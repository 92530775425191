import React, { FC } from "react";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import { theme } from "ovou-ui";
import { ButtonOutlined } from "../../components/ButtonOutlined";
import { MenuButton, MenuButtonProps } from "../../components/MenuComponent";

interface ITableFilterMenuButtonProps extends MenuButtonProps {
  buttonText: string;
  isOpen?: boolean;
}

const TableFilterMenuButton: FC<ITableFilterMenuButtonProps> = ({
  buttonText,
  isOpen,
  ...props
}) => {
  return (
    <MenuButton
      as={ButtonOutlined}
      rightIcon={
        isOpen ? (
          <HiChevronUp color={theme.palette.ui.greys.grey2} size={20} />
        ) : (
          <HiChevronDown color={theme.palette.ui.greys.grey2} size={20} />
        )
      }
      height={8}
      _focus={{
        outline: "none",
      }}
      {...props}
    >
      {buttonText}
    </MenuButton>
  );
};
export default TableFilterMenuButton;
