import React, { FC } from "react";
import { Text } from "@chakra-ui/react";
import { theme } from "ovou-ui";
import { ITableTagColumnProps } from "../types";

export const TableTagColumn: FC<ITableTagColumnProps> = ({ tag }) => {
  return (
    <Text
      fontSize="16px"
      fontWeight="semibold"
      color={theme.palette.brand.primary.black}
    >
      {tag}
    </Text>
  );
};
