import React from "react";

import classNames from "classnames";
import { Field, Form, Formik } from "formik";
import { useHistory, useParams } from "react-router-dom";
import * as yup from "yup";

import { Button, Icon, Spinner, TextFormField } from "../../components";
import { MEDIA_URL, VALIDATION_MESSAGES } from "../../constants";
import { profileLinkRegex } from "../../constants/regexes";
import { useUpdateIndividualProfileQuery } from "../../features/individualProfile/queries";
import Card from "../individualProfile/Card";

const validationSchema = yup.object({
  handle: yup
    .string()
    .min(3, VALIDATION_MESSAGES.MIN_LENGTH("Profile URL", 3))
    .matches(profileLinkRegex, VALIDATION_MESSAGES.PROFILE_URL)
    .required(VALIDATION_MESSAGES.IS_REQUIRED("Profile URL")),
});

const EditProfileLinkPage = () => {
  const { profileHandle } = useParams();
  const history = useHistory();

  const { mutate: updateProfile, status } = useUpdateIndividualProfileQuery({
    showToasts: true,
    updateType: "profile",
    successMsg: "Profile Link updated successfully",
    onSuccess: () => {
      history.go(-2);
    },
  });
  const isLoading = status === "loading";

  const handleSubmit = async values => {
    await updateProfile({
      handle: profileHandle,
      profileData: {
        ...values,
        handle: values.handle.toLowerCase(),
      },
    });
  };

  return (
    <Card
      helmetTitle="OVOU | Profile Link"
      pageTitle="Profile Link"
      label="Here you can edit the OVOU profile link"
      showBorder={false}
    >
      <div className="">
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            handle: profileHandle,
          }}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ isValid, dirty }) => (
            <Form autoComplete="off">
              <div
                className={classNames({
                  "sm:grid sm:grid-cols-3": true,
                })}
              >
                <div className="col-span-2">
                  <Field
                    name="handle"
                    label="Your profile URL"
                    autoCapitalize="none"
                    component={TextFormField}
                    preVal={MEDIA_URL}
                  />
                  <Button
                    className="mt-6"
                    disabled={isLoading || !isValid || !dirty}
                    size="small"
                    block
                    data-testid="company-profile-profile-save-button"
                  >
                    {isLoading ? <Spinner color="white" /> : "Save Change"}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Card>
  );
};

export default EditProfileLinkPage;
