import React from "react";

const SquareStack = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8423 5.15771C18.9433 5.25869 19 5.39565 19 5.53846L19 12.4615C19 12.6043 18.9433 12.7413 18.8423 12.8423C18.7413 12.9433 18.6043 13 18.4615 13L17.6923 13C17.14 13 16.6923 13.4477 16.6923 14C16.6923 14.5523 17.14 15 17.6923 15L18.4615 15C19.1348 15 19.7804 14.7326 20.2565 14.2565C20.7326 13.7804 21 13.1348 21 12.4615L21 5.53846C21 4.86522 20.7326 4.21955 20.2565 3.7435C19.7804 3.26744 19.1348 3 18.4615 3L11.5385 3C10.8652 3 10.2196 3.26744 9.7435 3.7435C9.26744 4.21955 9 4.86522 9 5.53846L9 6.30769C9 6.85998 9.44771 7.30769 10 7.30769C10.5523 7.30769 11 6.85998 11 6.30769L11 5.53846C11 5.39565 11.0567 5.25869 11.1577 5.15771C11.2587 5.05673 11.3957 5 11.5385 5L18.4615 5C18.6043 5 18.7413 5.05673 18.8423 5.15771ZM12.4615 11C12.7589 11 13 11.2411 13 11.5385L13 18.4615C13 18.7589 12.7589 19 12.4615 19L5.53846 19C5.24108 19 5 18.7589 5 18.4615L5 11.5385C5 11.2411 5.24108 11 5.53846 11L12.4615 11ZM15 11.5385C15 10.1365 13.8635 9 12.4615 9L5.53846 9C4.13651 9 3 10.1365 3 11.5385L3 18.4615C3 19.8635 4.13651 21 5.53846 21L12.4615 21C13.8635 21 15 19.8635 15 18.4615L15 11.5385Z"
      />
    </svg>
  );
};

export default React.memo(SquareStack);
