import React, { useEffect, useRef, useCallback, useState } from "react";

import { getIn } from "formik";

import { Wrapper, StyledInput, Input } from "./Styles";
import FormError from "../../FormError";
import Icons from "../../Icon";

const TextFormField = ({
  field,
  form,
  icon: Icon,
  onIconClick,
  semiEditableValue,
  type,
  preVal,
  leftIcon,
  rightIcon,
  widthIcon,
  padding,
  marginBottom,
  ...props
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const inputRef = useRef(null);
  const labelRef = useRef(null);

  useEffect(() => {
    const { value } = inputRef.current;
    if (value.trim().length) {
      labelRef.current.classList.add("filled");
    } else {
      labelRef.current.classList.remove("filled");
    }
  }, [field]);

  const handleInput = useCallback(() => {
    let { value } = inputRef.current;
    if (semiEditableValue && value.indexOf(semiEditableValue) === -1) {
      value = semiEditableValue;
    }
    form.setFieldValue(field.name, value);

    if (value.trim().length) {
      labelRef.current.classList.add("filled");
    } else {
      labelRef.current.classList.remove("filled");
    }
  }, [field.name, form, semiEditableValue]);

  const handleFocus = () => {
    labelRef.current.classList.add("focused");
  };

  const handleBlur = event => {
    labelRef.current.classList.remove("focused");
    field.onBlur(event);
  };

  const errorText =
    getIn(form.touched, field.name) && getIn(form.errors, field.name);
  const isPassword = type === "password";
  return (
    <>
      <Wrapper
        icon={Icon || isPassword}
        error={!!errorText}
        marginBottom={marginBottom}
        preVal={preVal}
      >
        <label htmlFor={field.name} ref={labelRef}>
          {props.label}
        </label>

        <StyledInput className={"inputWithIcon"}>
          {rightIcon && (
            <div className="right-icon cursor-pointer">
              <Icons
                onClick={onIconClick}
                name={rightIcon}
                className="relative top-0"
                width={widthIcon}
              />
            </div>
          )}
          {preVal && (
            <div className="pre-val">
              <span>{preVal}</span>
            </div>
          )}
          <Input
            id={field.name}
            name={field.name}
            ref={inputRef}
            onChange={handleInput}
            onBlur={handleBlur}
            value={field.value}
            onFocus={handleFocus}
            type={isPassword ? (isPasswordVisible ? "text" : "password") : type}
            {...props}
            padding={padding}
          />

          {leftIcon && (
            <div className="left-icon">
              <Icons name={leftIcon} className="relative top-0" />
            </div>
          )}
        </StyledInput>

        {isPassword ? (
          <div>
            <Icons
              name={isPasswordVisible ? "eyeOff" : "eye"}
              onClick={() => setIsPasswordVisible(p => !p)}
            />
          </div>
        ) : (
          Icon && <Icon onClick={onIconClick} />
        )}

        {errorText && <FormError error={errorText} />}
      </Wrapper>
    </>
  );
};
export default TextFormField;
