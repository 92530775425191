import React, { FC } from "react";
import { Box } from "@chakra-ui/react";

interface ITagColorIndicatorProps {
  color: string;
}

export const TagColorIndicator: FC<ITagColorIndicatorProps> = ({ color }) => {
  return (
    <Box
      minWidth={2}
      minHeight={2}
      width={2}
      height={2}
      borderRadius="full"
      backgroundColor={color}
    />
  );
};
