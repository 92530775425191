import { FC, useState } from "react";
import { theme } from "ovou-ui";

import { Menu, MenuList, TableFilterMenuButton, MenuItem } from "components";

import { ICardStateFilter, ICardStateFilterValue } from "../types";
import { CardState } from "types/Card";

const FILTER_OPTIONS: ICardStateFilterValue[] = [
  ICardStateFilterValue.ACTIVE,
  ICardStateFilterValue.INACTIVE,
];

const CardStateFilter: FC<ICardStateFilter> = ({ onSelect }) => {
  const [filterValue, setFilterValue] = useState<ICardStateFilterValue>(
    ICardStateFilterValue.DEFAULT,
  );

  const handleChange = (filterValue: ICardStateFilterValue) => {
    const stateFilters =
      filterValue === ICardStateFilterValue.ACTIVE
        ? [CardState.APPOINTED, CardState.ASSIGNED]
        : [CardState.UNASSIGNED];

    setFilterValue(filterValue);
    onSelect(stateFilters);
  };

  const handleClear = () => {
    setFilterValue(ICardStateFilterValue.DEFAULT);
    onSelect([]);
  };

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <TableFilterMenuButton
            buttonText={filterValue}
            isOpen={isOpen}
            width="9rem"
          />

          <MenuList>
            {FILTER_OPTIONS?.map(option => (
              <MenuItem
                key={option}
                value={option}
                _focus={{
                  backgroundColor: theme.palette.ui.greys.grey5,
                }}
                onClick={() => handleChange(option)}
              >
                {option}
              </MenuItem>
            ))}

            {filterValue !== ICardStateFilterValue.DEFAULT && (
              <MenuItem
                marginTop={2}
                fontWeight="semibold"
                fontSize={14}
                cursor="pointer"
                textColor={theme.palette.brand.primary.orange}
                onClick={handleClear}
              >
                Clear
              </MenuItem>
            )}
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export default CardStateFilter;
