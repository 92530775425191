import React, { FC, useEffect, useMemo } from "react";
import { useWizard } from "react-use-wizard";
import { Formik, Form, Field } from "formik";
import { useToasts } from "react-toast-notifications";
import * as yup from "yup";
import { theme } from "ovou-ui";

import { TextFormField, SelectFormField, Flex, Box } from "../../../components";
import { VALIDATION_MESSAGES } from "../../../constants";
import { setProfile, setTeam, useUser } from "../../../context";
import { useUpdateTeamMutation } from "../../settings/queries";
import { useWorkCategoryQuery } from "../../business/queries";
import { StepSubmit } from "./StepSubmit";
import { StepSection } from "./StepSection";
import { FormLabel } from "@chakra-ui/react";
import { useUpdateIndividualProfileQuery } from "features/individualProfile/queries";
import { WIZARD_STEP } from "types/Team";
import { useTracking } from "hooks";
import { ANALYTICS_EVENTS } from "constants/analyticEvents";
import { COMPANY_SIZES } from "../helpers/constants";
import { useProfileData } from "../context";

type IFormValues = {
  company_name: string;
  work_category: string;
  company_size: string;
};

const validationSchema = yup.object({
  company_name: yup
    .string()
    .trim()
    .max(80, VALIDATION_MESSAGES.MAX_LENGTH("Company name", 80))
    .required(VALIDATION_MESSAGES.IS_REQUIRED("Company name")),
  work_category: yup
    .string()
    .required(VALIDATION_MESSAGES.IS_REQUIRED("Work category")),
  company_size: yup
    .string()
    .required(VALIDATION_MESSAGES.IS_REQUIRED("Company size")),
}) as yup.SchemaOf<IFormValues>;

export const BusinessDetailsStep: FC = () => {
  const track = useTracking();
  const { nextStep } = useWizard();
  const { addToast } = useToasts();
  const { work_category, company_size } = useProfileData();

  const [{ currentTeam, currentProfile }, dispatch] = useUser();
  const [updateTeam, { isLoading: isUpdatingTeam }] = useUpdateTeamMutation({
    onSuccess: (_data: any, { teamData: { title } }: any) => {
      dispatch(
        setTeam({
          ...currentTeam,
          title,
        } as any),
      );
    },
  } as any);

  const { mutate: updateProfile, status } = useUpdateIndividualProfileQuery({
    showToasts: false,
    updateType: "profile",
    successMsg: "Info updated successfully",
  });
  const isUpdatingProfile = status === "loading";

  const { data: workCategoryData, isLoading: isWorkCategoryDataLoading } =
    useWorkCategoryQuery({});
  const workCategoryOptions = useMemo(
    () =>
      workCategoryData?.work_categories?.map((workCategoryItem: any) => ({
        label: workCategoryItem?.title || "",
        value: workCategoryItem?.id?.toString() || "",
      })) || [],
    [workCategoryData],
  );

  const companySizeOptions = useMemo(
    () =>
      COMPANY_SIZES?.map((companySize: any) => ({
        label: companySize,
        value: companySize,
      })) || [],
    [],
  );

  const handleError = () => {
    addToast("Failed to update team, please contact support for assistance.", {
      appearance: "error",
    });
  };

  const handleSubmit = async (values: any) => {
    await updateTeam(
      {
        teamID: currentTeam?.id,
        teamData: {
          title: values?.company_name,
          allow_self_assign: currentTeam?.allow_self_assign || false,
          non_editable_profile_fields:
            currentTeam?.non_editable_profile_fields || {},
        },
      },
      {
        onError: handleError,
      },
    );
    const updatedProfileData = await updateProfile(
      {
        handle: currentProfile?.handle,
        profileData: {
          work_category: values.work_category
            ? Number(values.work_category)
            : null,
          company_size: values.company_size,
        },
      },
      {
        onError: handleError,
      },
    );

    dispatch(
      setProfile({
        ...currentProfile,
        ...updatedProfileData,
      }),
    );

    track({
      eventName: ANALYTICS_EVENTS.TEAM_ONBOARDING_BUSINESS_DETAILS_SUBMIT,
      customTrackingProps: {
        companyName: values?.company_name,
        workCategory: values?.work_category,
        companySize: values?.company_size,
      },
    });

    nextStep();
  };

  useEffect(() => {
    updateTeam({
      teamID: currentTeam?.id,
      teamData: {
        ...currentTeam,
        wizard_step: WIZARD_STEP.BUSINESS_DETAILS,
      },
    });
  }, []);

  const initialValues: IFormValues = {
    company_name: currentTeam?.title || "",
    work_category: currentProfile?.work_category || work_category || "",
    company_size: currentProfile?.company_size || company_size || "",
  };

  return (
    <Flex justifyContent="center">
      <StepSection title="Tell us about your company">
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ isValid }) => (
            <Form autoComplete="off">
              <Field
                component={TextFormField}
                label="Company name"
                name="company_name"
                autoComplete="off"
              />
              <Box marginTop={6}>
                <FormLabel
                  htmlFor="work_category"
                  margin={0}
                  fontSize={14}
                  fontWeight="normal"
                  textColor={theme.palette.ui.greys.grey2}
                >
                  Industry
                </FormLabel>
                <Field
                  component={SelectFormField}
                  name="work_category"
                  options={workCategoryOptions}
                  disabled={isWorkCategoryDataLoading}
                  showLabel={true}
                />
              </Box>
              <Box marginTop={6}>
                <FormLabel
                  htmlFor="company_size"
                  margin={0}
                  fontSize={14}
                  fontWeight="normal"
                  textColor={theme.palette.ui.greys.grey2}
                >
                  Company Size
                </FormLabel>
                <Field
                  component={SelectFormField}
                  name="company_size"
                  options={companySizeOptions}
                  showLabel={true}
                />
              </Box>

              <StepSubmit
                isDisabled={isUpdatingTeam || isUpdatingProfile || !isValid}
                isLoading={isUpdatingTeam || isUpdatingProfile}
              />
            </Form>
          )}
        </Formik>
      </StepSection>
    </Flex>
  );
};
