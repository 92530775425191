import { useUserState } from "context";
import { useMetricsQuery } from "features/analytics/queries/useMetricsQuery";
import { METRIC } from "features/analytics/types";
import { useEffect, useMemo } from "react";

type MetricStatsProps = {
  metric: METRIC;
  lowerBoundDate: string;
  upperBoundDate: string;
};

export const useMetricsStats = ({
  metric,
  lowerBoundDate,
  upperBoundDate,
}: MetricStatsProps) => {
  const { currentTeam } = useUserState();
  const teamId = currentTeam?.id;
  const { data: metricsData, refetch } = useMetricsQuery({
    metric,
    window_lower_bound: lowerBoundDate,
    window_upper_bound: upperBoundDate,
    teamId,
  });
  const { metrics } = metricsData || {};
  const groupedByMonthDataSet = useMemo(() => {
    const groupedByMonth = metrics?.reduce((acc, metric) => {
      const date = new Date(metric.date);
      const dateKey = `${date.getFullYear()}-${date.getMonth() + 1}-01`;
      return {
        ...acc,
        [dateKey]: (acc[dateKey] || 0) + metric.count,
      };
    }, {} as any);

    return groupedByMonth
      ? Object.keys(groupedByMonth)?.map(key => ({
          date: key,
          count: groupedByMonth[key],
        }))
      : [];
  }, [metrics]);

  useEffect(() => {
    async () => {
      await refetch();
    };
  }, [metric, lowerBoundDate, upperBoundDate]);

  return {
    dataSet: groupedByMonthDataSet,
  };
};
