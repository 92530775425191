import CropperModal from "components/CropperModal";
import Icon from "components/Icon";
import React, { forwardRef, useRef, useState } from "react";
import { LOGO_MAX_WIDTH } from "../../constants";

const ThumbnailUploader = ({ onUpload, thumbnail, name }) => {
  const uploaderRef = useRef(null);

  const handleOpenFilePicker = () => {
    if (uploaderRef.current) {
      uploaderRef.current.click();
    }
  };

  const handleUpload = uploadedThumbnail => {
    onUpload(uploadedThumbnail);
  };

  return (
    <div className="flex items-center">
      {thumbnail && (
        <img
          src={thumbnail}
          className="w-16 h-16 rounded-lg object-cover cursor-pointer"
          alt=""
        />
      )}
      <Uploader
        ref={uploaderRef}
        onUpload={handleUpload}
        thumbnail={thumbnail}
      />
      <div onClick={handleOpenFilePicker} aria-hidden="true">
        <div className="ml-3 cursor-pointer">
          <span className="text-primary text-sm font-semibold">
            {thumbnail ? "Change" : "Upload"} Thumbnail
          </span>
        </div>
      </div>
    </div>
  );
};

const Uploader = forwardRef(({ onUpload, thumbnail }, ref) => {
  const [isCropperModalOpen, setIsCropperModalOpen] = useState(false);
  const [image, setImage] = useState("");

  const handleUpload = e => {
    e.preventDefault();

    const image = e.target?.files?.[0];
    setImage(URL.createObjectURL(image));
    setIsCropperModalOpen(true);
  };
  return (
    <div
      className={`w-16 h-16 rounded-lg border-gray-200 ${
        thumbnail ? "hidden" : ""
      }`}
    >
      <label
        className="cursor-pointer"
        htmlFor="uploader-profileLink.thumbnail"
      >
        <input
          ref={ref}
          type="file"
          accept="image/*"
          id="uploader-profileLink.thumbnail"
          className="absolute w-0 h-0"
          onChange={handleUpload}
        />
        <div className="flex items-center justify-center rounded-lg w-16 h-16 border border-gray-300 bg-gray-150">
          <Icon name="upload" className="mr-2 text-current" />
        </div>
      </label>
      <CropperModal
        isOpen={isCropperModalOpen}
        onClose={() => setIsCropperModalOpen(false)}
        image={image}
        getCroppedImage={croppedImage => onUpload(croppedImage)}
        title="Upload Image"
        format="jpeg"
        maxWidth={LOGO_MAX_WIDTH}
      />
    </div>
  );
});

Uploader.displayName = "Uploader";

export default ThumbnailUploader;
