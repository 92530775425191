export const VALIDATION_MESSAGES = {
  IS_REQUIRED: inputName => `${inputName} is required.`,
  FORMAT_IS_WRONG: inputName => `${inputName} format is wrong.`,
  MIN_LENGTH: (inputName, minLength) =>
    `${inputName} at least must be ${minLength} characters.`,
  MAX_LENGTH: (inputName, maxLength) =>
    `Max length of ${inputName} is ${maxLength} characters.`,
  ONLY_NUMBER: inputName => `${inputName} only should contain numbers.`,
  PROFILE_URL:
    "Profile URL may contain only English letters, numbers, and _ character",
};
