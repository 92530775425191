import { ANALYTICS_EVENTS, getTrackingProps } from "constants/analyticEvents";
import { SEGMENT_WRITE_KEY } from "constants/index";
import { loadClient } from "utils/loadClient";
import {
  TEventProperties,
  TIdentifyCallback,
  TPageCallback,
  TTrackCallback,
} from "../types";

export const segment = {
  script: `
    !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){if(window.analytics.initialized)return window.analytics[e].apply(window.analytics,arguments);var i=Array.prototype.slice.call(arguments);i.unshift(e);analytics.push(i);return analytics}};for(var i=0;i<analytics.methods.length;i++){var key=analytics.methods[i];analytics[key]=analytics.factory(key)}analytics.load=function(key,i){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=i};analytics._writeKey="${SEGMENT_WRITE_KEY}";;analytics.SNIPPET_VERSION="4.16.1";
    analytics.load("${SEGMENT_WRITE_KEY}");
    }}();
    `,
};

export const page: TPageCallback = () => {
  (window as any).analytics?.page();
};

export const track: TTrackCallback = (
  eventName: string,
  properties: TEventProperties,
) => {
  const trackingProps = getTrackingProps(
    eventName as ANALYTICS_EVENTS,
    properties,
  );
  (window as any).analytics?.track(eventName, trackingProps);
};

export const identify: TIdentifyCallback = (
  userId: number,
  handle: string,
  publicId: string,
  state: string,
  teamId?: number,
  role?: string,
  userType?: string,
  userStatus?: string,
) => {
  const userIdString = String(userId);
  (window as any).analytics?.alias(userIdString);
  (window as any).analytics?.identify(userIdString, {
    role,
    userType,
    userStatus,
    handle,
    teamId,
    publicId,
    state,
  });
};

export const loadService = () => {
  const { head, dataLayerScript } = loadClient();
  if (SEGMENT_WRITE_KEY) {
    dataLayerScript.innerHTML = segment.script;
    head.appendChild(dataLayerScript);
  }
};
