import React from "react";

const Cog = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5003 2.13397C11.8097 1.95534 12.1909 1.95534 12.5003 2.13397L20.2945 6.63397C20.6039 6.81261 20.7945 7.14273 20.7945 7.5V16.5C20.7945 16.8573 20.6039 17.1874 20.2945 17.366L12.5003 21.866C12.1909 22.0447 11.8097 22.0447 11.5003 21.866L3.70605 17.366C3.39665 17.1874 3.20605 16.8573 3.20605 16.5V7.5C3.20605 7.14273 3.39665 6.81261 3.70605 6.63397L11.5003 2.13397ZM5.20605 8.07735V15.9226L12.0003 19.8453L18.7945 15.9226V8.07735L12.0003 4.1547L5.20605 8.07735ZM12.0003 10C10.8957 10 10.0003 10.8954 10.0003 12C10.0003 13.1046 10.8957 14 12.0003 14C13.1049 14 14.0003 13.1046 14.0003 12C14.0003 10.8954 13.1049 10 12.0003 10ZM8.00028 12C8.00028 9.79086 9.79115 8 12.0003 8C14.2094 8 16.0003 9.79086 16.0003 12C16.0003 14.2091 14.2094 16 12.0003 16C9.79115 16 8.00028 14.2091 8.00028 12Z"
      />
    </svg>
  );
};

export default React.memo(Cog);
