import React, { useEffect } from "react";

import ReactPaginate from "react-paginate";
import Select from "react-select";
import {
  useTable,
  useFlexLayout,
  useRowSelect,
  usePagination,
} from "react-table";
import styled from "styled-components";

import { Icon, Spinner } from "..";
import { useLatestRef } from "../../utils";
import { customSelectStyles } from "../FormFields/SelectFormField/styles";

const PAGE_SIZE_OPTIONS = [
  { label: "20 Items", value: 20 },
  { label: "30 Items", value: 30 },
  { label: "50 Items", value: 50 },
];

export const PaginationWrapper = styled.div`
  ul {
    display: flex;
  }
  li {
    background: #fff;
    color: #111;
    border: 1px solid #d4d4d4;
    min-width: 40px;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;
    border-radius: 8px;
    transition: border-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
    max-width: 46px;
    max-height: 36px;
    a {
      padding: 5px 10px;
      display: block;
      height: 100%;
      width: 100%;
      svg {
        width: 100%;
        height: 100%;
      }
    }
    &:not(:last-child) {
      margin-right: 8px;
    }
    &:not(.disabled, .selected):hover {
      background-color: #fafafa;
    }
    &.disabled a {
      cursor: not-allowed;
      filter: grayscale(1) opacity(0.4);
    }
    &.selected {
      background: #ff643a;
      color: #fff;
      border-color: transparent;
    }
  }
`;

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input
          className="form-checkbox h-5 w-5 text-primary"
          type="checkbox"
          ref={resolvedRef}
          {...rest}
        />
      </>
    );
  },
);

IndeterminateCheckbox.displayName = "IndeterminateCheckboxs";

const Table = ({
  columns,
  data = [],
  isLoading,
  onRowSelect,
  showPagination,
  pageCount: controlledPageCount,
  fetchData,
  footer,
  showFooter = true,
  showPageSize = true,
  noResultsText = "No results found",
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    pageCount,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 20 },
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    useFlexLayout,
    usePagination,
    useRowSelect,
    hooks => {
      onRowSelect &&
        hooks.allColumns.push(columns => [
          {
            id: "selection",
            disableResizing: true,
            minWidth: 64,
            width: 64,
            maxWidth: 64,
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ]);
      hooks.useInstanceBeforeDimensions.push(({ headerGroups }) => {
        const selectionGroupHeader = headerGroups[0].headers[0];
        selectionGroupHeader.canResize = false;
      });
    },
  );

  const onRowSelectRef = useLatestRef(onRowSelect);
  const onFetchMoreRef = useLatestRef(fetchData);

  const getStyles = (props, align = "left") => [
    props,
    {
      style: {
        justifyContent:
          align === "center"
            ? "center"
            : align === "left"
            ? "flex-start"
            : "flex-end",
        alignItems: "center",
        display: "flex",
      },
    },
  ];

  const handlePageClick = event => {
    gotoPage(event.selected);
  };

  useEffect(() => {
    onFetchMoreRef.current({ pageIndex, pageSize });
  }, [onFetchMoreRef, pageIndex, pageSize]);

  useEffect(() => {
    if (!onRowSelectRef.current) return;

    onRowSelectRef.current(selectedFlatRows.map(row => row.original));
  }, [selectedFlatRows, onRowSelectRef]);

  useEffect(() => {
    gotoPage(0);
  }, [pageCount, gotoPage]);

  const headerProps = (props, { column }) => getStyles(props, column.align);
  const cellProps = (props, { cell }) => getStyles(props, cell.column.align);

  return (
    <>
      <div className="overflow-auto block">
        <table
          {...getTableProps()}
          style={{ borderSpacing: "0" }}
          className="w-full"
        >
          <thead className="overflow-x-hidden overflow-y-auto bg-gray-150">
            {headerGroups.map((headerGroup, index) => (
              <tr
                key={index}
                {...headerGroup.getHeaderGroupProps()}
                className="h-12"
              >
                {headerGroup.headers.map((column, index) => (
                  <th
                    key={index}
                    {...column.getHeaderProps(headerProps)}
                    className="border-b border-custom-gray-300 font-semibold text-gray-700 text-sm uppercase p-4"
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody
            {...getTableBodyProps()}
            className="overflow-y-auto overflow-x-hidden"
          >
            {!isLoading && !data?.length && (
              <tr>
                <td>
                  <div className="text-center py-20">
                    <span>{noResultsText}</span>
                  </div>
                </td>
              </tr>
            )}
            {isLoading ? (
              <tr>
                <td>
                  <div className="text-center py-20">
                    <Spinner />
                  </div>
                </td>
              </tr>
            ) : (
              rows.map((row, index) => {
                prepareRow(row);
                return (
                  <tr
                    key={index}
                    {...row.getRowProps()}
                    className="min-h-24 border-b border-custom-gray-300"
                  >
                    {row.cells.map((cell, index) => {
                      const hideCheckbox =
                        cell.column?.id === "selection" &&
                        row.original?.permission_metadata?.label.toLowerCase() ===
                          "owner";

                      return (
                        <td
                          {...cell.getCellProps(cellProps)}
                          className="p-4"
                          key={index}
                        >
                          {!hideCheckbox && cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
      {showPagination && (
        <div className="mt-8 flex flex-col items-center lg:justify-between lg:flex-row">
          {showFooter && (
            <div className="table-footer order-1 mt-6 lg:order-none lg:mt-0">
              {footer}
            </div>
          )}
          <div className="flex flex-col items-center flex-wrap-reverse lg:justify-end lg:flex-row">
            {showPageSize && (
              <div className="flex items-center">
                <span className="font-semibold text-black mr-2">Show by</span>
                <div className="w-28 mr-6">
                  <Select
                    value={PAGE_SIZE_OPTIONS.find(
                      option => option.value === pageSize,
                    )}
                    onChange={option => setPageSize(option.value)}
                    options={PAGE_SIZE_OPTIONS}
                    styles={customSelectStyles}
                    menuPlacement="top"
                  />
                </div>
              </div>
            )}
            <PaginationWrapper className="mt-6 lg:mt-0">
              <ReactPaginate
                breakLabel="..."
                nextLabel={<Icon name="chevronRight" />}
                onPageChange={handlePageClick}
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                pageCount={pageCount}
                previousLabel={<Icon name="chevronLeft" />}
                renderOnZeroPageCount={null}
                forcePage={pageIndex}
              />
            </PaginationWrapper>
          </div>
        </div>
      )}
    </>
  );
};

export default Table;
