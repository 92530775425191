import { FC } from "react";

import ChangePasswordForm from "./ChangePasswordForm";
import CompanyForm from "./CompanyForm";
import BrandingForm from "./BrandingForm";

import { TabNavigation } from "components";

const SettingsTabs: FC = () => {
  const tabs = [
    {
      title: "Branding",
      content: <BrandingForm />,
    },
    {
      title: "Global Permission",
      content: <CompanyForm />,
    },
    {
      title: "Account",
      content: <ChangePasswordForm />,
    },
  ];
  return <TabNavigation fontSize={[14, 16]} tabs={tabs} />;
};

export default SettingsTabs;
