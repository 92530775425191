import { createContext, useContext, useEffect, useState } from "react";
import { useUserState } from "context/User";
import { usePermissionsQuery } from "hooks/usePermissionsQuery";

import { IPermissions } from "types/Permissions";

type TPermissionState = IPermissions;

const InitialPermissionState: TPermissionState = {
  status: null,
  permission: null,
};

interface IPermissionContext {
  permissions: TPermissionState;
  setPermissions: React.Dispatch<React.SetStateAction<TPermissionState>>;
}
const PermissionContext = createContext<IPermissionContext | undefined>(
  undefined,
);

const PermissionsProvider = ({ children }: { children: JSX.Element }) => {
  const [permissions, setPermissions] = useState<TPermissionState>(
    InitialPermissionState,
  );
  const { isAuthenticated } = useUserState();

  const { data: permissionsData } = usePermissionsQuery({
    enabled: isAuthenticated,
  });

  useEffect(() => {
    if (permissionsData) {
      setPermissions(permissionsData);
    }
  }, [permissionsData]);

  const contextValue: IPermissionContext = {
    permissions,
    setPermissions,
  };

  return (
    <PermissionContext.Provider value={contextValue}>
      {children}
    </PermissionContext.Provider>
  );
};

const usePermissionsState = (): TPermissionState => {
  const context = useContext(PermissionContext);

  if (context === undefined) {
    throw new Error(
      "usePermissionsState must be used within a PermissionsProvider",
    );
  }
  return context.permissions;
};
const useSetPermissions = (): React.Dispatch<
  React.SetStateAction<TPermissionState>
> => {
  const context = useContext(PermissionContext);

  if (context === undefined) {
    throw new Error(
      "useSetPermissions must be used within a PermissionsProvider",
    );
  }
  return context.setPermissions;
};

export { PermissionsProvider, usePermissionsState, useSetPermissions };
