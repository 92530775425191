import { FC, useMemo } from "react";
import { Flex, Modal, Text } from "components";
import ChangeRoleForm from "./ChangeRoleForm";
import { IChangeRoleModal } from "./types";
import { useAssignRoleMutation } from "./api/useAssignRoleMutation";
import { theme } from "ovou-ui";
import { Divider } from "@chakra-ui/react";
import { useTracking } from "hooks";
import { ANALYTICS_EVENTS } from "constants/analyticEvents";
import { useQueryCache } from "react-query";
import { useUserState } from "context";
import { useFullName } from "hooks"
const ChangeRoleModal: FC<IChangeRoleModal> = ({
  user,
  userTypes,
  isOpen,
  onClose,
  refetch,
}) => {
  const queryCache = useQueryCache();
  const track = useTracking();
  const { currentTeam } = useUserState();
  const { mutate: fireAssignRole, isLoading } = useAssignRoleMutation();
  const { email, user_iam_role, id: userId } = user;
  const fullName = useFullName(user, user?.profile);

  const handleSubmit = (user_iam_role: string): void => {
    fireAssignRole(
      { email: email, user_iam_role: user_iam_role },
      {
        onSuccess: async () => {
          queryCache.invalidateQueries([
            `team-users`,
            { teamId: currentTeam?.id },
          ]);
          await refetch();

          onClose();
          track({
            eventName: ANALYTICS_EVENTS.MANAGE_USERS_CHANGE_ROLE,
            customTrackingProps: {
              email,
              user_iam_role,
            },
          });
        },
        onError: async () => {
          onClose();
        },
      },
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} label="">
      <Flex flexDirection="column" width={408}>
        <Flex alignItems="center">
          <Text
            as="h2"
            fontWeight="extrabold" 
            fontSize={24}
            color={theme.palette.brand.primary.black}
          >
            Change the role
          </Text>
        </Flex>

        <Text
          marginTop="2.8rem"
          fontWeight="normal"
          fontSize={16}
          color={theme.palette.ui.greys.grey2}
        >
          {fullName || email}
        </Text>
        <Divider
          opacity={1}
          marginTop="0.5rem"
          color={theme.palette.ui.greys.grey3}
        />
        <ChangeRoleForm
          userIamRole={user_iam_role}
          handleSubmit={handleSubmit}
          isLoading={isLoading}
          userTypes={userTypes}
        />
      </Flex>
    </Modal>
  );
};

export default ChangeRoleModal;
