import React from "react";

import classNames from "classnames";
import { Formik, Form, Field } from "formik";
import { Link } from "react-router-dom";
import * as yup from "yup";

import {
  Section,
  Button,
  Spinner,
  MultiSelectFormField,
} from "../../../components";
import { useUser } from "../../../context";
import { useDirectoryQuery } from "../../directory/queries";
import {
  useBatchActiveDirectoryMutation,
  useBatchDeActiveDirectoryMutation,
} from "../queries";
import { useTracking } from "hooks";
import { ANALYTICS_EVENTS } from "constants/analyticEvents";

const validationSchema = yup.object({});

const MeetTheTeamForm = () => {
  const [{ currentTeam }] = useUser();
  const track = useTracking();
  const {
    data: directoriesResponse,
    isLoading: isDirectoryLoading,
    refetch: refetchDirectories,
  } = useDirectoryQuery({
    teamID: currentTeam?.id,
    withProfiles: false,
  });

  const { mutate: fireBatchActive, isLoading: isBatchActivatingDirectories } =
    useBatchActiveDirectoryMutation();
  const {
    mutate: fireBatchDeActive,
    isLoading: isBatchDeActivatingDirectories,
  } = useBatchDeActiveDirectoryMutation();

  const deActiveDirectoriesOption =
    directoriesResponse?.directories
      ?.filter(directory => !directory?.active)
      ?.map(directory => ({
        value: directory.id.toString(),
        label: directory.title,
      })) || [];

  const activeDirectoriesOption =
    directoriesResponse?.directories
      ?.filter(directory => directory?.active)
      ?.map(directory => ({
        value: directory.id.toString(),
        label: directory.title,
      })) || [];

  const activeDirectoryIds = activeDirectoriesOption?.map(
    activeDirectoryOption => activeDirectoryOption.value,
  );

  const deActiveDirectoryIds = deActiveDirectoriesOption?.map(
    activeDirectoryOption => activeDirectoryOption.value,
  );

  const handleSubmit = ({ directory_ids }) => {
    const directoriesToActive = directory_ids?.filter(directoryId =>
      deActiveDirectoryIds.includes(directoryId),
    );
    const directoriesToDeActive = activeDirectoryIds?.filter(
      directoryId => !directory_ids.includes(directoryId),
    );

    if (directoriesToActive?.length) {
      fireBatchActive(
        {
          batchActiveDirectoryData: { directory_ids: directoriesToActive },
          teamId: currentTeam?.id,
        },
        {
          onSuccess: () => {
            refetchDirectories();
            track({
              eventName: ANALYTICS_EVENTS.COMPANY_PROFILE_MEET_THE_TEAM,
            });
          },
        },
      );
    }
    if (directoriesToDeActive?.length) {
      fireBatchDeActive(
        {
          batchDeActiveDirectoryData: { directory_ids: directoriesToDeActive },
          teamId: currentTeam?.id,
        },
        {
          onSuccess: () => {
            refetchDirectories();
          },
        },
      );
    }
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{ directory_ids: activeDirectoryIds }}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ isValid, dirty }) => (
        <Form autoComplete="off">
          <Section
            label="Meet the team"
            subLabel={
              <span className="block text-sm font-medium leading-5 text-gray-500 mt-2">
                Showcase your entire team on your business page. If you have
                already setup your{" "}
                <Link className="text-primary" to="/directory">
                  Group
                </Link>
                , you can add categories here.
              </span>
            }
          >
            <Field
              component={MultiSelectFormField}
              placeholder="Add From Group"
              name="directory_ids"
              options={[
                ...deActiveDirectoriesOption,
                ...activeDirectoriesOption,
              ]}
              isLoading={isDirectoryLoading}
              disabled={isDirectoryLoading}
              maxMenuHeight={150}
              isMulti
            />
          </Section>
          <div
            className={classNames({
              "sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center mt-6 sm:mt-5 md:mt-12": true,
            })}
          >
            <div className="col-start-2">
              <Button
                disabled={
                  isBatchActivatingDirectories ||
                  isBatchDeActivatingDirectories ||
                  !isValid ||
                  !dirty
                }
                size="small"
                block
                data-testid="company-profile-meet-the-team-save-button"
              >
                {isBatchActivatingDirectories ||
                isBatchDeActivatingDirectories ? (
                  <Spinner color="white" />
                ) : (
                  "Save Changes"
                )}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default MeetTheTeamForm;
