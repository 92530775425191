import { FC } from "react";

import { Box, LineGraph } from "components";

interface IStatChart {
  dataSet: {
    date: string;
    count: any;
  }[];
}
const StatChart: FC<IStatChart> = ({ dataSet }) => {
  return (
    <Box p="24px">
      <LineGraph data={dataSet} />
    </Box>
  );
};

export default StatChart;
