export const loadClient = () => {
  const head = document.getElementsByTagName("head")[0];
  const srcScript = document.createElement("script");
  srcScript.async = true;
  const dataLayerScript = document.createElement("script");

  return {
    head,
    srcScript,
    dataLayerScript,
  };
};
