import React from "react";

import ReactPlayer from "react-player/lazy";
import { useMediaLayout } from "use-media";

import { useTutorialsQuery } from "./queries";
import { Spinner } from "../../components";

const TutorialListItem = ({ tutorial }) => {
  return (
    <div className="w-full h-full">
      <div className="aspect-w-16 aspect-h-9 rounded-xl overflow-hidden">
        <ReactPlayer width="100%" height="100%" url={tutorial.link} />
      </div>
      <div className="mt-4">
        <p className="font-semibold">{tutorial.title}</p>
      </div>
    </div>
  );
};

const TutorialsList = () => {
  const isMobile = useMediaLayout({ maxWidth: "640px" });
  const { data: tutorials, isLoading: isTutorialsLoading } =
    useTutorialsQuery();

  return (
    <div
      style={{
        gridTemplateColumns: isMobile
          ? "repeat(auto-fill, minmax(220px, 1fr))"
          : "repeat(auto-fill, minmax(350px, 1fr))",
      }}
      className="grid row-gap-8 col-gap-4 md:row-gap-12 md:col-gap-6"
    >
      {isTutorialsLoading ? (
        <Spinner />
      ) : tutorials?.length ? (
        <>
          {tutorials.map(tutorial => (
            <TutorialListItem key={tutorial.id} tutorial={tutorial} />
          ))}
        </>
      ) : (
        <p className="mx-auto">No tutorials found</p>
      )}
    </div>
  );
};

export default TutorialsList;
