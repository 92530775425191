import React, { FC } from "react";

const Edit: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4861 2.58504C11.7152 1.8141 10.4652 1.8141 9.6943 2.58504L2.20015 10.0792C2.07637 10.203 2.00684 10.3708 2.00684 10.5459V13.3335C2.00684 13.698 2.30233 13.9935 2.66684 13.9935H5.45446C5.62951 13.9935 5.79738 13.924 5.92115 13.8002L13.4153 6.30604C14.1862 5.53511 14.1862 4.28518 13.4153 3.51424L12.4861 2.58504ZM10.6277 3.51842C10.8831 3.26298 11.2973 3.26298 11.5527 3.51842L12.4819 4.44763C12.7373 4.70305 12.7373 5.11722 12.4819 5.37266L11.2269 6.62661L9.37312 4.77284L10.6277 3.51842ZM3.32684 10.8193L8.4395 5.70644L10.2932 7.56018L5.18107 12.6735H3.32684V10.8193Z"
        fill="#010101"
      />
    </svg>
  );
};

export default Edit;
