import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import { Flex, Text } from "@chakra-ui/react";
import { theme } from "ovou-ui";

import { IContact } from "../types/Contact";

interface ITableNameColumnProps {
  contactDetails: IContact;
}

export const TableNameColumn: FC<ITableNameColumnProps> = ({
  contactDetails,
}) => {
  const jobDescription = `${contactDetails.job_title}${
    contactDetails.job_title && contactDetails.workplace && " at "
  }${contactDetails.workplace}`;

  return (
    <Flex
      flexDirection="column"
      as={NavLink}
      to={`/contacts/${contactDetails.id}`}
    >
      <Text
        fontWeight="semibold"
        fontSize="0.875rem"
        color={theme.palette.brand.primary.black}
      >
        {contactDetails.full_name}
      </Text>
      <Text fontSize="0.875rem" color={theme.palette.brand.primary.black}>
        {jobDescription}
      </Text>
      <Text fontSize="0.75rem" color={theme.palette.ui.greys.grey2}>
        {contactDetails.email}
      </Text>
    </Flex>
  );
};
