import React from "react";

const Menu = ({
  className,
  role,
  ignoreDefaultSize,
  width = 32,
  height = 11,
  onClick,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={ignoreDefaultSize ? undefined : width}
      height={ignoreDefaultSize ? undefined : height}
      viewBox="0 0 32 11"
      role={role}
      className={className}
      onClick={onClick}
    >
      <path d="M1.153 10.338h19.208a1.312 1.312 0 000-2.605H1.153a1.312 1.312 0 000 2.605z" />
      <path d="M1.154 2.605h29.1a1.312 1.312 0 000-2.605h-29.1A1.239 1.239 0 000 1.3a1.239 1.239 0 001.154 1.305z" />
    </svg>
  );
};

export default React.memo(Menu);
