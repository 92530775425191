import { FC, useEffect, useState } from "react";
import { Wizard } from "react-use-wizard";

import { Header } from "./Header";
import { BusinessDetailsStep } from "./components/BusinessDetailsStep";
import { BusinessDesignStep } from "./components/BusinessDesignStep";
import { AdminProfileStep } from "./components/AdminProfileStep";
import { AssignSeatsStep } from "./components/AssignSeatsStep";
import { setTeam, setProfile, useUser } from "../../context";
import { useMyTeamsQuery, useProfileDataQuery } from "../auth/queries";
import { Flex, Spinner } from "components";
import { ComponentSteps, ITeamResponse, WIZARD_STEP } from "types/Team";
import { IProfileResponse } from "types/Profile";
import { useRouter } from "hooks";
import { MANAGE_USERS_ROUTE } from "../../constants";
import { ProfileDataProvider } from "./context";
import { isNeedsWizard } from "utils/userStatus";

const WIZARD_STEP_COMPONENTS: {
  [key in ComponentSteps]: JSX.Element;
} = {
  [WIZARD_STEP.BUSINESS_DETAILS]: <BusinessDetailsStep />,
  [WIZARD_STEP.BUSINESS_DESIGN]: <BusinessDesignStep />,
  [WIZARD_STEP.ADMIN_PROFILE]: <AdminProfileStep />,
  [WIZARD_STEP.ASSIGN_SEATS]: <AssignSeatsStep />,
};

export const Onboarding: FC = () => {
  const router = useRouter();
  const [_, dispatch] = useUser();
  const { getMyTeams, isLoading: isGetMyTeamsLoading } = useMyTeamsQuery({
    onSuccess: (data: ITeamResponse) => {
      dispatch(setTeam(data?.teams?.[0]));
    },
  });
  const {
    data: profileData,
    getProfileData,
    isLoading: isGetProfileDataLoading,
  } = useProfileDataQuery({
    filterByState: false,
    onSuccess: (data: IProfileResponse) => {
      const businessProfile = data.profiles.find(p => p.is_business);
      businessProfile && dispatch(setProfile(businessProfile));
    },
  });

  const [startIndex, setStartIndex] = useState<number>(0);
  const [stepsToRender, setStepsToRender] = useState<JSX.Element[]>([]);

  // Sets the starting step the user will start its onboarding in.
  const init = async () => {
    const teamsData = await getMyTeams();
    const currentTeam = teamsData?.teams?.[0];

    if (!isNeedsWizard(currentTeam?.wizard_step)) {
      router.push(MANAGE_USERS_ROUTE);
    }

    const profilesData = await getProfileData();
    const adminProfile = profilesData?.profiles.find(profile =>
      Boolean(profile.is_team_admin),
    );

    const wizardStepsOrder: ComponentSteps[] = [
      WIZARD_STEP.BUSINESS_DETAILS,
      WIZARD_STEP.BUSINESS_DESIGN,
      WIZARD_STEP.ASSIGN_SEATS,
    ];

    // The ADMIN_PROFILE step is only available if an admin profile exists for the team. Old teams might not have one.
    const isAdminProfileStepEnabled = Boolean(adminProfile);
    if (isAdminProfileStepEnabled) {
      wizardStepsOrder.splice(2, 0, WIZARD_STEP.ADMIN_PROFILE);
    }

    let startIndex = 0;

    const stepsToRender = wizardStepsOrder.map((step, index) => {
      if (currentTeam?.wizard_step === step) {
        startIndex = index;
      }

      return WIZARD_STEP_COMPONENTS[step];
    });

    setStepsToRender(stepsToRender);
    setStartIndex(startIndex);
  };

  useEffect(() => {
    init();
  }, []);

  if (
    !Boolean(profileData) ||
    isGetMyTeamsLoading ||
    isGetProfileDataLoading ||
    stepsToRender.length === 0
  )
    return (
      <Flex
        width="100vw"
        height="100vh"
        justifyContent="center"
        alignItems="center"
      >
        <Spinner />
      </Flex>
    );

  return (
    <ProfileDataProvider profileData={profileData as any}>
      <Wizard header={<Header />} startIndex={startIndex}>
        {stepsToRender.map(step => step)}
      </Wizard>
    </ProfileDataProvider>
  );
};
