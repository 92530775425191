import jwtDecode from "jwt-decode";

import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from "../constants";

const logout = () => {
  return { isValid: false };
};

export const getToken = () => {
  return {
    accessToken: localStorage.getItem(ACCESS_TOKEN_KEY),
    refreshToken: localStorage.getItem(REFRESH_TOKEN_KEY),
  };
};

export const setToken = (accessToken, refreshToken) => {
  if (accessToken) {
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
  }
  if (refreshToken) {
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  }
};

export const removeToken = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
};

export const decodeToken = token => {
  return jwtDecode(token);
};

export const validateToken = () => {
  const { accessToken } = getToken();
  try {
    if (accessToken) {
      const decodedToken = decodeToken(accessToken);
      // Expiration date has not arrived yet
      if (Date.now() < decodedToken.exp * 1000) {
        return { isValid: true, decodedToken };
      }
    }
  } catch (e) {
    console.error(e);
  }
  removeToken();
  return logout();
};
