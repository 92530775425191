import { useMutation } from "react-query";
import { ITeamsUserInvite } from "types/TeamUsersResponse";

import api from "../api";

const userInviteService = async ({
  teamId,
  emails,
}: {
  teamId: number;
  emails: string[];
}) => {
  const { data } = await api.post<ITeamsUserInvite>(
    `/profile/teams/${teamId}/users`,
    {
      emailIds: emails,
    },
  );
  return data;
};

export const useUserInvite = () => {
  const [mutate, { ...mutationProps }] = useMutation(userInviteService);

  return {
    mutate,
    ...mutationProps,
  };
};
