import { FC } from "react";
import { MenuItemOption } from "@chakra-ui/react";
import { Box, Flex, Icon, MenuDivider, MenuOptionGroup } from "components";
import { theme } from "ovou-ui";
import { DATE_OPTIONS, IMenuOptions } from "./types";

const IconComponent = Icon as any;

const menuOptions = [
  {
    option: DATE_OPTIONS.ALL_TIME,
    display: "All Time",
  },
  {
    option: DATE_OPTIONS.CURRENT_MONTH,
    display: "This Month",
  },
  {
    option: DATE_OPTIONS.PREV_MONTH,
    display: "Last Month",
  },
  {
    option: DATE_OPTIONS.LAST_YEAR,
    display: "Last Year",
  },
];

const MenuOptions: FC<IMenuOptions> = ({ value, onChange, onClick }) => {
  return (
    <MenuOptionGroup defaultValue={value} type="radio" onChange={onChange}>
      {menuOptions?.map(({ option, display }) => (
        <MenuItemOption
          key={option}
          value={option}
          flexDir="row-reverse"
          color={value === option ? theme.palette.brand.primary.orange : ""}
          _focus={{
            backgroundColor: theme.palette.ui.greys.grey5,
          }}
        >
          {display}
        </MenuItemOption>
      ))}
      <MenuDivider />
      <MenuItemOption
        value={DATE_OPTIONS.CUSTOM}
        onClick={onClick}
        flexDir="row-reverse"
        color={
          value === DATE_OPTIONS.CUSTOM
            ? theme.palette.brand.primary.orange
            : ""
        }
        _focus={{
          backgroundColor: theme.palette.ui.greys.grey5,
        }}
      >
        <Flex>
          <Box
            sx={{
              svg: {
                color: `${theme.palette.brand.primary.black}`,
                height: "25px",
                width: "25px",
              },
            }}
          >
            <IconComponent name="plus" />
          </Box>
          Custom Date Range
        </Flex>
      </MenuItemOption>
    </MenuOptionGroup>
  );
};

export default MenuOptions;
