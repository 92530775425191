import React, { FC, ReactElement } from "react";
import { theme } from "ovou-ui";

import { Flex, Text } from "components";

const configuration = {
  section: {
    titleFontSize: 32,
    titleFontWeight: 9,
    subTitleFontSize: 16,
    subTitleFontWeight: 5,
  },
  subSection: {
    titleFontSize: 21,
    titleFontWeight: 7,
    subTitleFontSize: 14,
    subTitleFontWeight: 4,
  },
};

interface IStepHeaderProps {
  title: string;
  subTitle?: string;
  sectionType?: "section" | "subSection";
  children?: ReactElement;
}

export const StepSection: FC<IStepHeaderProps> = ({
  title,
  subTitle,
  sectionType = "section",
  children,
}) => {
  return (
    <Flex
      flex={1}
      flexDirection="column"
      maxWidth={"33.5rem"}
      minWidth={"22.5rem"}
      marginTop={sectionType === "subSection" ? 7 : 0}
    >
      <Flex flexDirection="column" marginBottom={8}>
        <Text
          fontSize={configuration[sectionType].titleFontSize}
          lineHeight={configuration[sectionType].titleFontWeight}
          fontWeight="extrabold"
          textColor={theme.palette.brand.primary.black}
        >
          {title}
        </Text>

        {subTitle && (
          <Text
            fontSize={configuration[sectionType].subTitleFontSize}
            lineHeight={configuration[sectionType].subTitleFontWeight}
            marginTop={3}
            textColor={theme.palette.ui.greys.grey1}
          >
            {subTitle}
          </Text>
        )}
      </Flex>

      {children}
    </Flex>
  );
};
