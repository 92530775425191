export const createImage = url =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", error => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

export function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export function rotateSize(width, height, rotation) {
  const rotRad = getRadianAngle(rotation);

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export default async function getCroppedImg(
  imageSrc,
  pixelCrop,
  format = "jpeg",
  maxWidth = 1024,
  rotation = 0,
  flip = { horizontal: false, vertical: false },
) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  const canvas2 = document.createElement("canvas");
  const ctx2 = canvas2.getContext("2d");

  if (!(ctx && ctx2) ) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation,
  );

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  // use second canvas for cropping
  canvas2.width = pixelCrop.width;
  canvas2.height = pixelCrop.height;

  // place image to right position
  ctx2.drawImage(canvas, -pixelCrop.x, -pixelCrop.y);

  // return cropped image if resizing is not needs
  if (canvas2.width <= maxWidth) {
    return canvas2.toDataURL(`image/${format}`);
  }

  // resize image
  canvas.width = maxWidth;
  canvas.height = Math.round(maxWidth * (pixelCrop.height / pixelCrop.width));

  ctx.drawImage(
    canvas2,
    0,
    0,
    canvas2.width,
    canvas2.height,
    0,
    0,
    canvas.width,
    canvas.height,
  );

  return canvas.toDataURL(`image/${format}`);
}
