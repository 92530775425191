import { useUserState } from "context";
import { useIntegrationServiceContext } from "./context/IntegrationServiceContext";
import { Integration } from "./services/IIntegrationService";
import { useEffect, useMemo } from "react";
import { useObservableValue } from "utils/observables";

type UseIntegrationServiceProps = {
    integrations: Integration[];
    showConfigurationMenu(integrationId: string): unknown;
};

export default function useIntegrationService(): UseIntegrationServiceProps {
    const { isAuthenticated } = useUserState();
    const service = useIntegrationServiceContext();
    useEffect(() => {
        if (isAuthenticated) {
            service.initialize();
        }
        return () => {
            service.dispose();
        };
    }, [service]);
    const integrationsObservable = useMemo(() => service.getIntegrationsObservable(), [service]);
    const integrations = useObservableValue(integrationsObservable);
    return {
        integrations,
        showConfigurationMenu: service.showConfigurationMenu,
    };
}
