import React, { forwardRef } from "react";

// import Card, { ICardProps } from "components/Card/Card";
// import { ICardUploaderHandles } from "components/Card/CardUploader";
import Card from "./Card";

// interface ICardFormFieldProps
//   extends ICardProps,
//     Omit<FieldProps<string>, "meta"> {
//   innerRef: RefObject<ICardUploaderHandles>;
// }

const CardFormField = forwardRef((props, ref) => {
  return <Card ref={ref} {...props} />;
});
CardFormField.displayName = "CardFormField";

export const CardFormFieldComponent = props => {
  return <CardFormField ref={props.innerRef} {...props} />;
};

export default CardFormField;
