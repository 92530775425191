import Resizer from "react-image-file-resizer";

import { generateMediaPath } from "./generateMediaPath";

export const getCardScheme = cardScheme => {
  return {
    accent_color: addSharpToColorHex(cardScheme?.accent_color || ""),
    background_color: getCardBG(cardScheme),
    career_foreground_color: addSharpToColorHex(
      cardScheme?.career_foreground_color || "",
    ),
    full_name_foreground_color: addSharpToColorHex(
      cardScheme?.full_name_foreground_color || "",
    ),
    foreground_active_color: isGradient(
      cardScheme?.foreground_active_color || "",
    )
      ? cardScheme?.foreground_active_color
      : addSharpToColorHex(cardScheme?.foreground_active_color || ""),
  };
};

const addSharpToColorHex = hexColor => {
  return hexColor.includes("#") ? hexColor : `#${hexColor}`;
};

export const getCardBG = cardScheme => {
  const bgPath =
    (cardScheme?.raw_file && generateMediaPath(cardScheme?.raw_file)) || "";

  return cardScheme?.background_color === "null"
    ? `url(${bgPath})`
    : isGradient(cardScheme?.background_color || "")
    ? cardScheme?.background_color || ""
    : addSharpToColorHex(cardScheme?.background_color || "");
};

export const isGradient = bg => {
  return bg?.includes("gradient");
};

export const resizeFile = async file => {
  return await new Promise(resolve => {
    Resizer.imageFileResizer(
      file,
      4065,
      4065,
      "JPEG",
      100,
      0,
      uri => {
        resolve(uri);
      },
      "file",
    );
  });
};

export const imageSize = url => {
  const img = document.createElement("img");

  const promise = new Promise((resolve, reject) => {
    img.onload = () => {
      const width = img.naturalWidth;
      const height = img.naturalHeight;
      resolve({ width, height });
    };
    img.onerror = reject;
  });
  img.src = url;

  return promise;
};
