import parsePhoneNumberFromString, { AsYouType } from "libphonenumber-js";
import { useLayoutEffect, useRef } from "react";

import { makeSelectType } from "./makeSelectType";

export const addSharpToColorHex = hexColor => {
  return hexColor?.includes("#") ? hexColor : `#${hexColor}`;
};

export const mapProfileToSelectOption = profiles => {
  return (
    profiles?.reduce((assignedProfile, currentProfile) => {
      if (currentProfile.user_role.status !== "assigned") {
        return assignedProfile;
      }

      const firstName = currentProfile.profile?.first_name;
      const lastName = currentProfile.profile?.last_name;
      const label =
        firstName || lastName
          ? `${firstName} ${lastName}`.trim()
          : currentProfile.profile?.handle;

      assignedProfile.push(
        makeSelectType({
          label,
          value: currentProfile?.profile?.handle,
        }),
      );

      return assignedProfile;
    }, []) || []
  );
};

export const useLatestRef = value => {
  const ref = useRef(value);
  useLayoutEffect(() => {
    ref.current = value;
  });
  return ref;
};

export const humanize = str => {
  return str
    .replace(/^[\s_]+|[\s_]+$/g, "")
    .replace(/[_\s]+/g, " ")
    .replace(/^[a-z]/, m => m.toUpperCase());
};

export const isBrowser = () => {
  return typeof window !== "undefined";
};

export const reorderItems = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result.map((item, i) => ({ ...item, order: i }));
};

export const capitalize = str => {
  const lower = str.toLowerCase();
  return str.charAt(0).toUpperCase() + lower.slice(1);
};
export const contactInformationSubsections = sections => {
  return (
    sections?.sections_definition?.find(
      section => section.id === "contact_information",
    )?.subsections_definition || []
  );
};

export const contactInformationOptions = contactInformationSubsections => {
  return contactInformationSubsections?.map(contactInformationSections => ({
    [(contactInformationSections.id || "").toLowerCase()]:
      contactInformationSections.field_types?.map(({ id, title, ...rest }) => ({
        label: title || "",
        value: id || "",
        parentId: contactInformationSections.id,
        ...rest,
      })),
  }));
};

export const contactInformationOptionsObj = contactInformationOptions => {
  return contactInformationOptions.reduce((acc, cur) => {
    const key = Object.keys(cur)[0];
    return { ...acc, [key]: cur[key] };
  }, {});
};

export const allContactInformationOptions = sections => {
  const subSections = contactInformationSubsections(sections);
  const contactOptions = contactInformationOptions(subSections);
  const contactOptionsObj = contactInformationOptionsObj(contactOptions);
  return contactOptionsObj;
};

export const getFieldType = (allContactInformationOptions, keyToFind) => {
  const fieldTypeArray = Object.values(allContactInformationOptions);
  const flattenTypeArray = fieldTypeArray.flat();
  const matchedField = flattenTypeArray.find(
    type => type?.value.toLowerCase() === keyToFind.toLowerCase(),
  );

  return matchedField ? matchedField.parentId || "" : "";
};

export const urlLinkRegex = new RegExp(
  /^((?:https?:\/\/)?[^./]+(?:\.[^./]+)+(?:\/.*)?)$/,
);

export const formatPhoneNumber = (
  phoneNumberValue,
  formatInternational = true,
) => {
  if (phoneNumberValue === "+") {
    return "";
  }

  if (!formatInternational) {
    return new AsYouType("US").input(phoneNumberValue);
  }

  const formattedNumber = phoneNumberValue?.startsWith("+")
    ? phoneNumberValue
    : `+${phoneNumberValue}`;

  const phoneNumber = parsePhoneNumberFromString(formattedNumber);
  return phoneNumber ? phoneNumber.formatInternational() : formattedNumber;
};

const EMAIL_REGEXP = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const isValidEmail = email => EMAIL_REGEXP.test(email);
