import { useRouter } from "hooks";
import { useEffect } from "react";
import { PaymentsAPIClient } from "./PaymentsAPIClient";
import instance from "api";
import { PAGES } from "constants/pages";
import { BUSINESS_SITE_URL } from "constants/index";
import { Loader } from "../../components/Spinner";

export const UpgradePage = () => {
  const router = useRouter();
  useEffect(() => {
    redirectToUpgradeCheckoutSession(router);
  }, []);

  return <Loader fullScreen />;
};

const compileDestination = (path: string): string => {
  const url = new URL(path, BUSINESS_SITE_URL);
  return url.toString();
};

const redirectToUpgradeCheckoutSession = async (
  router: ReturnType<typeof useRouter>,
) => {
  const url = new URL(window.location.href);
  const query = url.searchParams;

  let rawTeamId = query.get("teamId");
  const teamId = rawTeamId ? Number(rawTeamId) : undefined;
  if (!teamId || Number.isNaN(teamId)) {
    router.replace(PAGES.MANAGE_USERS);
    return;
  }

  let cancelUrl =
    query.get("cancelUrl") ?? compileDestination(PAGES.MANAGE_USERS);
  let successUrl =
    query.get("successUrl") ?? compileDestination(PAGES.MANAGE_USERS);
  let interval = query.get("interval") ?? undefined;
  let currency = query.get("currency");
  if (!currency) {
    router.replace(PAGES.MANAGE_USERS);
    return;
  }

  const params = {
    cancelUrl,
    successUrl,
    interval,
    teamId,
    currency,
  };

  const client = new PaymentsAPIClient(instance);

  try {
    const upgradeURL = await client.getUpgradeURL(params);
    window.location.href = upgradeURL;
    return;
  } catch (error) {
    console.error({
      message: "Failed to obtain upgrade URL.",
      params: {
        ...params,
        error: String(error),
      },
    });
    router.replace(PAGES.MANAGE_USERS);
    return;
  }
};
