import React, { useMemo } from "react";

import { Icon } from "../../components";
import { addSharpToColorHex, getCardBG, useFlag } from "../../utils";
import { ColorPicker } from "ovou-ui";
import { useFormikContext } from "formik";
import { useUserState } from "context/User";

const Picker = ({ scheme, label, onPick, picked, disabled }) => {
  return (
    <div
      className="relative rounded-lg overflow-hidden flex-shrink-0 select-none shadow-sm border border-solid border-gray-200"
      style={{
        width: "60px",
        height: "60px",
        cursor: disabled ? "not-allowed" : "pointer",
        filter: `opacity(${disabled ? "0.6" : "1"})`,
      }}
      onClick={onPick}
      aria-hidden="true"
    >
      <div
        className="absolute inset-0 bg-no-repeat bg-right bg-cover"
        style={{
          clipPath: "polygon(100% 0, 0 0, 0 100%)",
          background: getCardBG(scheme),
        }}
      />
      <div
        className={`flex absolute items-center justify-center shadow-sm ${
          picked ? "bg-primary" : "bg-white"
        } rounded-full w-6 h-6 z-10`}
        style={{
          left: "50%",
          transform: "translate(-50%, -50%)",
          top: "50%",
        }}
      >
        <>
          {picked && <Icon name="check" className="text-white w-4 h-4" />}
          {!picked && (
            <p className="text-gray-600 text-xs font-semibold">{label}</p>
          )}
        </>
      </div>
      <div
        className="absolute inset-0 bg-no-repeat bg-right bg-cover"
        style={{
          clipPath: "polygon(100% 0, 100% 100%, 0 100%)",
          background: addSharpToColorHex(scheme.accent_color || ""),
        }}
      />
    </div>
  );
};

const SchemePicker = ({
  schemes,
  onPick,
  pickedSchemeIndex,
  disabled,
  handleSubmit,
  form,
  field,
  autoSubmitForm = true,
}) => {
  const { permissions } = useUserState();
  const canEditCustomColor = permissions?.canEditCustomColor
  const { submitForm } = useFormikContext();

  const { defaultSchemes, customScheme } = useMemo(() => {
    let defaultSchemes = [];
    let customScheme = null;

    schemes?.forEach(scheme => {
      if (scheme.team) {
        customScheme = scheme;
      } else {
        defaultSchemes.push(scheme);
      }
    });

    return {
      defaultSchemes,
      customScheme,
    };
  }, [schemes]);

  const handleSchemePick = scheme => {
    if (disabled) return;
    onPick(scheme);
  };

  return (
    <div className="flex space-x-2 whitespace-no-wrap overflow-x-auto w-full pb-1">
      {Boolean(customScheme) && (
        <ColorPicker
          disabled={!canEditCustomColor}
          defaultSchema={customScheme}
          style={{ width: "60px", height: "60px" }}
          isCustomDesign={true}
          picker={Picker}
          handleSubmit={selected => {
            if (!canEditCustomColor) {
              return;
            }
            handleSchemePick(selected);
            form.setFieldValue(field.name, selected);
            autoSubmitForm && submitForm(selected);
          }}
        />
      )}

      {defaultSchemes?.map((scheme, index) => (
        <Picker
          key={index}
          handleSubmit={handleSubmit}
          scheme={scheme}
          label={index + 1}
          onPick={() => {
            if (!canEditCustomColor) {
              return;
            }
            handleSchemePick(scheme);
          }}
          picked={scheme?.id === pickedSchemeIndex}
          disabled={disabled || !canEditCustomColor}
        />
      ))}
    </div>
  );
};

export default SchemePicker;
