import { Checkbox } from "@chakra-ui/checkbox";
import {
  Flex,
  Menu,
  MenuItem,
  MenuList,
  Text,
  ButtonOutlined,
  TableFilterMenuButton,
} from "../../components";
import { theme } from "ovou-ui";
import React, { FC, useState } from "react";
import { HiOutlinePencil } from "react-icons/hi";
import { useDebouncedCallback } from "use-debounce/lib";
import { TagColorIndicator } from "./components/TagColorIndicator";
import { ITag } from "./types/Tag";

interface ITagsFilterMenuProps {
  tags: ITag[];
  onSelectedTagsChange: (tags: number[]) => void;
  onManageTags: () => void;
  onClearTags: () => void;
}

export const TagsFilterMenu: FC<ITagsFilterMenuProps> = ({
  tags,
  onSelectedTagsChange,
  onManageTags,
  onClearTags,
}) => {
  const [onSelectedTabsDebounce] = useDebouncedCallback((tags: number[]) => {
    onSelectedTagsChange(tags);
  }, 500);

  const [selectedTags, setSelectedTags] = useState<number[]>([]);

  const handleTagFilterSelection = (selectedTagId: number) => {
    let newSelectedTags: number[];

    const tagIdIndex = selectedTags.findIndex(tagId => tagId === selectedTagId);

    if (tagIdIndex === -1) {
      newSelectedTags = [...selectedTags, selectedTagId];
      setSelectedTags(newSelectedTags);
    } else {
      newSelectedTags = selectedTags.filter(tagId => tagId !== selectedTagId);
      setSelectedTags(newSelectedTags);
    }

    onSelectedTabsDebounce(newSelectedTags);
  };

  const handleClearTags = () => {
    setSelectedTags([]);
    onClearTags();
  };

  return (
    <Menu closeOnSelect={false}>
      {({ isOpen, onClose }) => (
        <>
          <TableFilterMenuButton buttonText="Tags" isOpen={isOpen} />
          <MenuList maxWidth="200px" paddingY={4}>
            {tags?.length === 0 ? (
              <Text marginLeft={3} fontWeight="semibold" fontSize={14}>
                You have not created any tags.
              </Text>
            ) : (
              tags?.map((tag: ITag) => (
                <MenuItem
                  padding={0}
                  _focus={{
                    backgroundColor: theme.palette.ui.greys.grey5,
                  }}
                >
                  <Checkbox
                    width="full"
                    paddingX={3}
                    paddingY={1.5}
                    isChecked={selectedTags.includes(tag.id)}
                    onChange={() => handleTagFilterSelection(tag.id)}
                  >
                    <Flex alignItems="center" gap={2}>
                      <TagColorIndicator color={tag.color} />
                      <Text fontSize={14} noOfLines={1}>
                        {tag.name}
                      </Text>
                    </Flex>
                  </Checkbox>
                </MenuItem>
              ))
            )}

            <ButtonOutlined
              marginX={3}
              marginTop={4}
              leftIcon={
                <HiOutlinePencil color={theme.palette.ui.greys.grey2} />
              }
              onClick={() => {
                onManageTags();
                onClose();
              }}
            >
              Manage Tags
            </ButtonOutlined>

            {selectedTags.length > 0 && (
              <Text
                marginX={3}
                marginTop={4}
                fontWeight="semibold"
                fontSize={14}
                color={theme.palette.brand.primary.orange}
                cursor="pointer"
                onClick={handleClearTags}
              >
                Clear all
              </Text>
            )}
          </MenuList>
        </>
      )}
    </Menu>
  );
};
