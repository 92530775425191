import { FC, ReactElement } from "react";

import { AccessTokenGuard } from "./AccessTokenGuard";
import { Box } from "../components";

const OnboardingLayout: FC<{ children: ReactElement }> = ({ children }) => {
  return (
    <AccessTokenGuard>
      <Box minHeight="100vh" padding={8} backgroundColor="white">
        {children}
      </Box>
    </AccessTokenGuard>
  );
};

export default OnboardingLayout;
