import { useQuery } from "react-query";

import api from "../../../api";
import { IStatsResponse } from "../types";

const analyticsService = async (
  teamId: string,
  window_lower_bound: string,
  window_upper_bound: string,
) => {
  const { data } = await api.get<IStatsResponse>(
    `/analytics/teams/${teamId}/stats`,
    {
      params: {
        window_lower_bound,
        window_upper_bound,
      },
    },
  );
  return data;
};

export const useAnalyticsQuery = (
  teamId: string,
  window_lower_bound: string,
  window_upper_bound: string,
) => {
  const query = useQuery<IStatsResponse>(
    ["analytics", { window_lower_bound, window_upper_bound }],
    () => analyticsService(teamId, window_lower_bound, window_upper_bound),
  );

  return {
    ...query,
  };
};
