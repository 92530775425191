import { min } from "date-fns";

export const styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    borderRadius: "12px",
    border: "none !important",
    boxShadow: "1px 1px 2px 0 rgba(0, 0, 0, 0.02)",
    padding: "30px 40px",
    minWidth: "300px",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};
