import React from "react";

const LogoutArrow = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.1812 20.0978C19.6481 20.6669 18.9106 21 18.1267 21L14.8483 21C14.2961 21 13.8483 20.5523 13.8483 20C13.8483 19.4477 14.2961 19 14.8483 19L18.1267 19C18.3364 19 18.5519 18.9117 18.7215 18.7306C18.8931 18.5473 19 18.2856 19 18L19 6C19 5.71444 18.8931 5.45268 18.7215 5.2694C18.5519 5.08835 18.3364 5 18.1267 5L14.8483 5C14.2961 5 13.8483 4.55228 13.8483 4C13.8483 3.44772 14.2961 3 14.8483 3L18.1267 3C18.9106 3 19.6481 3.33308 20.1812 3.90217C20.7121 4.46905 21 5.2247 21 6L21 18C21 18.7753 20.7121 19.531 20.1812 20.0978ZM8.71984 17.3194C8.30673 17.686 7.67469 17.6482 7.30814 17.2351L3.25199 12.6637C2.916 12.285 2.916 11.715 3.25199 11.3363L7.30814 6.76488C7.67469 6.35177 8.30673 6.31402 8.71984 6.68057C9.13295 7.04711 9.1707 7.67915 8.80415 8.09226L6.22417 11L14.6155 11C15.1678 11 15.6155 11.4477 15.6155 12C15.6155 12.5523 15.1678 13 14.6155 13L6.22417 13L8.80415 15.9077C9.1707 16.3208 9.13295 16.9529 8.71984 17.3194Z"
      />
    </svg>
  );
};

export default React.memo(LogoutArrow);
