import React, { FC } from "react";
import { Text } from "@chakra-ui/react";
import { theme } from "ovou-ui";

import { getFormattedDate } from "../../../utils/dateFunctions";

interface ITableDateColumnProps {
  createdAt: Date;
}

export const TableDateColumn: FC<ITableDateColumnProps> = ({ createdAt }) => {
  const date = new Date(createdAt);

  return (
    <Text
      display="flex"
      justifyContent="center"
      fontSize="0.875rem"
      color={theme.palette.brand.primary.black}
    >
      {getFormattedDate(date)}
    </Text>
  );
};
