import { FC, useEffect, useState } from "react";

import {
  Box,
  DatePicker,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuList,
  TableFilterMenuButton,
} from "components";
import { HiChevronDown } from "react-icons/hi";

import { DateRange } from "react-day-picker";

import { theme } from "ovou-ui";
import { DATE_OPTIONS, IDateSelector } from "./types";
import MenuOptions from "./MenuOptions";
import {
  getAllTime,
  getCurrentMonth,
  getCustomDate,
  getLastMonth,
  getLastYear,
} from "utils/dateFunctions";
import { ANALYTICS_EVENTS } from "constants/analyticEvents";
import { useTracking } from "hooks";

const getDateRange = (value: string, dateTime: Date) => {
  if (value === DATE_OPTIONS.ALL_TIME) return getAllTime(dateTime);
  if (value === DATE_OPTIONS.CURRENT_MONTH) return getCurrentMonth(dateTime);
  if (value === DATE_OPTIONS.PREV_MONTH) return getLastMonth(dateTime);
  if (value === DATE_OPTIONS.LAST_YEAR) return getLastYear(dateTime);
};
const dateEvents: Record<DATE_OPTIONS, ANALYTICS_EVENTS> = {
  [DATE_OPTIONS.ALL_TIME]: ANALYTICS_EVENTS.ANALYTICS_ALL_TIME,
  [DATE_OPTIONS.CURRENT_MONTH]: ANALYTICS_EVENTS.ANALYTICS_CURRENT_MONTH,
  [DATE_OPTIONS.PREV_MONTH]: ANALYTICS_EVENTS.ANALYTICS_PREV_MONTH,
  [DATE_OPTIONS.LAST_YEAR]: ANALYTICS_EVENTS.ANALYTICS_LAST_YEAR,
  [DATE_OPTIONS.CUSTOM]: ANALYTICS_EVENTS.ANALYTICS_CUSTOM_DATE_RANGE,
};

const DateSelector: FC<IDateSelector> = ({
  dateTime = new Date(),
  menuButtonType = "dateRange",
  handleChange,
}) => {
  const track = useTracking();
  const [menuOptionValue, setMenuOptionValue] = useState(DATE_OPTIONS.ALL_TIME);
  const [dayPickerVisible, setDayPickerVisible] = useState(false);
  const [rangeDisplay, setRangeDisplay] = useState(() => {
    return getAllTime(dateTime);
  });
  const [range, setRange] = useState<DateRange | undefined>();

  const handleDateChange = (value: string | string[]): void => {
    if (value !== DATE_OPTIONS.CUSTOM) {
      setRange(undefined);
    }
    const dateRange = getDateRange(value as string, dateTime);
    setMenuOptionValue(value as DATE_OPTIONS);
    dateRange && setRangeDisplay(dateRange);

    track({
      eventName: dateEvents[value as DATE_OPTIONS],
      customTrackingProps: {
        dateRange: dateRange?.rangeDisplay || range,
      },
    });
  };
  const handleRangeChange = (range?: DateRange): void => {
    setRange(range);
    if (range?.from && range.to) {
      setMenuOptionValue(DATE_OPTIONS.CUSTOM);
      const customDate = getCustomDate(range.from, range.to);
      setRangeDisplay(customDate);
      setDayPickerVisible(false);
      track({
        eventName: ANALYTICS_EVENTS.ANALYTICS_CUSTOM_DATE_RANGE,
        customTrackingProps: {
          dateRange: range,
        },
      });
    }
  };

  useEffect(() => {
    handleChange(rangeDisplay.lowerBound!, rangeDisplay.upperBound!);
  }, [rangeDisplay]);

  return (
    <Menu
      closeOnSelect={false}
      onClose={() => {
        setDayPickerVisible(false);
      }}
    >
      {({ isOpen }) => (
        <>
          {menuButtonType === "tableFilter" ? (
            <TableFilterMenuButton buttonText="Date" isOpen={isOpen} />
          ) : (
            <MenuButton
              borderRadius="6px"
              border={`1px solid ${theme.palette.ui.greys.grey3}`}
              height="32px"
              padding="8px"
              minWidth="240px"
              _hover={{
                backgroundColor: theme.palette.ui.greys.grey5,
              }}
              _focus={{
                backgroundColor: theme.palette.ui.greys.grey5,
              }}
            >
              <Flex alignItems="center" justifyContent="space-around">
                <Text fontSize="14px" fontWeight={600}>
                  {rangeDisplay?.rangeDisplay}
                </Text>
                <Box ml="8px">
                  <HiChevronDown color={theme.palette.ui.greys.grey2} />
                </Box>
              </Flex>
            </MenuButton>
          )}

          <MenuList minWidth="240px">
            {dayPickerVisible ? (
              <DatePicker
                dateRange={range}
                handleRangeChange={handleRangeChange}
              />
            ) : (
              <MenuOptions
                value={menuOptionValue}
                onChange={handleDateChange}
                onClick={() => setDayPickerVisible(true)}
              />
            )}
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export default DateSelector;
