import React from "react";

const Clock = ({
  className,
  role,
  ignoreDefaultSize,
  width = 24,
  height = 24,
  strokeWidth = 2,
  onClick,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={ignoreDefaultSize ? undefined : width}
      height={ignoreDefaultSize ? undefined : height}
      role={role}
      className={className}
      strokeWidth={strokeWidth}
      onClick={onClick}
    >
      <path
        d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Z"
        stroke="#A1A1A1"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M12 6v6l4 2"
        stroke="#A1A1A1"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  );
};

export default React.memo(Clock);
