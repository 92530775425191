import React, { useState, useCallback } from "react";

import Cropper from "react-easy-crop";

import getCroppedImg from "./cropImage";
import Button from "../Button";
import { Modal, ModalHeader, ModalBody } from "../Modal";
import Icon from "../Icon";
import CropperRatioSelector from "./CropperRatioSelector";

const CropperModal = ({
  isOpen,
  onClose,
  image,
  getCroppedImage,
  ratio = 1,
  ratioOptions = null,
  title,
  format = "jpeg",
  maxWidth,
  restrictPosition = true,
  closable = false,
  customCropperStyles = {},
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [zoom, setZoom] = useState(1);
  const [minZoom, setMinZoom] = useState(1);
  const [aspect, setAspect] = useState(
    ratioOptions?.length ? ratioOptions[0].ratio : ratio,
  );

  const onMediaLoaded = useCallback(
    mediaSize => {
      if (restrictPosition) {
        return setMinZoom(1);
      }

      const minZoom =
        mediaSize.naturalHeight > mediaSize.naturalWidth
          ? mediaSize.naturalWidth / mediaSize.naturalHeight
          : mediaSize.naturalHeight / mediaSize.naturalWidth;

      setMinZoom(Math.floor(minZoom * 90) / 100);
    },
    [restrictPosition],
  );

  const onCropComplete = useCallback((_croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const cropImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        format,
        maxWidth,
      );
      getCroppedImage(croppedImage);
      onClose();
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, image, getCroppedImage, onClose, format]);

  const cancelCropping = useCallback(() => {
    getCroppedImage(undefined);
    onClose();
  }, [getCroppedImage, onClose]);

  return (
    <Modal isOpen={isOpen} closable={false}>
      <ModalHeader
        align={closable && "left"}
        title={title}
        icon={
          closable && (
            <Icon name="close" height="1.5em" onClick={cancelCropping} />
          )
        }
      />
      <ModalBody className="relative mb-0">
        <div
          className="relative"
          style={{ width: "50vw", height: "50vh", ...customCropperStyles }}
        >
          <Cropper
            image={image}
            aspect={aspect}
            crop={crop}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            zoom={zoom}
            onZoomChange={setZoom}
            minZoom={minZoom}
            restrictPosition={restrictPosition}
            onMediaLoaded={onMediaLoaded}
          />
        </div>
        {Boolean(Array.isArray(ratioOptions) && ratioOptions.length > 1) ? (
          <CropperRatioSelector
            ratioOptions={ratioOptions}
            activeRatio={aspect}
            onSelect={setAspect}
          />
        ) : null}
      </ModalBody>
      <div className="w-full flex justify-center items-center">
        <Button
          className="mt-4"
          variant="black"
          block
          onClick={cropImage}
          data-testid="team-settings-crop-image-button"
        >
          Crop
        </Button>
      </div>
    </Modal>
  );
};

export default CropperModal;
