import axios from "axios";

import { API_URL, ERROR } from "../constants";
import { getToken, removeToken, setToken } from "../utils";

let isRefreshing = false;
let refreshSubscribers = [];
const REFRESH_TOKEN_ENDPOINT = `${API_URL}/auth/refresh`;

if (typeof API_URL === "undefined") {
  throw new Error("API_URL is not defined.");
}

const instance = axios.create({
  baseURL: API_URL,
});

/**
 * This interceptor automatically will add stored token in localStorage
 * to the request header
 */
instance.interceptors.request.use(
  config => {
    if (config.baseURL === API_URL && !config.headers.Authorization) {
      const { accessToken } = getToken();
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
    }
    return config;
  },
  error => Promise.reject(error),
);

const parseAxiosError = error => {
  let errorMessage = "An unknown error occurred! Please contact support.";

  if (error.response) {
    const data = error.response.data;

    if (data.message) {
      return data.message;
    }

    if (data.non_field_errors && Array.isArray(data.non_field_errors)) {
      errorMessage = data.non_field_errors.join("; ");
    } else if (typeof data === "object" && data !== null) {
      const errors = Object.keys(data).map(key => {
        const value = data[key];
        return Array.isArray(value) ? value.join(", ") : value;
      });
      errorMessage = errors.join("; ");
    } else if (typeof data === "string") {
      errorMessage = data;
    }
  } else if (error.request) {
    errorMessage = "No response was received for the request";
  } else {
    errorMessage = error.message;
  }
  return errorMessage;
};

instance.interceptors.response.use(
  response => response,
  error => {
    if (!error.status && error.message === ERROR.NETWORK.TYPE) {
      return Promise.reject(ERROR.NETWORK.MESSAGE);
    }

    const {
      config,
      response: { status },
    } = error;
    const originalRequest = config;
    if (status === 401 && !originalRequest?.url.includes("/auth/token")) {
      if (originalRequest.url === REFRESH_TOKEN_ENDPOINT) {
        removeToken();
        window.location.href = "/auth/login";
        return Promise.reject(error);
      }
      if (originalRequest.url.includes("/auth/token")) {
        // return error?.response?.data?.detail;
        return Promise.reject(error);
      }
      if (!isRefreshing && originalRequest.url !== REFRESH_TOKEN_ENDPOINT) {
        isRefreshing = true;
        refreshAccessToken().then(res => {
          if (res.status === 200) {
            const accessToken = res?.data?.access;
            setToken(accessToken);
            instance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
            isRefreshing = false;
            onRrefreshed(accessToken);
          }
        });
      }

      const retryOrigReq = new Promise(resolve => {
        subscribeTokenRefresh(token => {
          originalRequest.headers.Authorization = `Bearer ${token}`;
          resolve(axios(originalRequest));
        });
      });
      return retryOrigReq;
    }

    const parsedErrorMessage = parseAxiosError(error);

    return Promise.reject(parsedErrorMessage);
  },
);
const subscribeTokenRefresh = cb => {
  refreshSubscribers.push(cb);
};

const onRrefreshed = token => {
  refreshSubscribers.map(cb => cb(token));
  refreshSubscribers = [];
};

const refreshAccessToken = () => {
  const { refreshToken } = getToken();
  return instance.post(REFRESH_TOKEN_ENDPOINT, {
    refresh: refreshToken,
  });
};

export default instance;
