import React from "react";

import { FastField } from "formik";
import { useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import * as Yup from "yup";

import Contact from "./Contact";
import { TextFormField } from "../../../components";
import { VALIDATION_MESSAGES } from "../../../constants";
import { useDeleteIndividualQuery } from "../../../features/individualProfile/queries";
import { SECTION_EMPTY_FIELD_VALUE } from "../editContactInformationPage";

export const emailValidationSchema = Yup.array().of(
  Yup.object()
    .shape({
      field_type_id: Yup.string(),
      value: Yup.string()
        .email(VALIDATION_MESSAGES.FORMAT_IS_WRONG("Email")),
        // .required(VALIDATION_MESSAGES.IS_REQUIRED("Email")),
      id: Yup.string().nullable(),
      order: Yup.number(),
    })
    .optional(),
);

const Email = props => {
  const { emails, remove, push } = props;

  const { profileHandle } = useParams();
  const { addToast } = useToasts();

  const handleAddNewField = e => {
    e.preventDefault();
    push({
      ...SECTION_EMPTY_FIELD_VALUE,
      field_type_id: "emails",
      id: `new-${emails.length + 1}`,
      order: emails.length + 1,
    });
  };

  const { mutate: deleteIndividual } = useDeleteIndividualQuery({
    type: "generic",
    onSuccess: () => {
      addToast("Email Removed Successfully", {
        appearance: "success",
      });
    },
  });

  const handleRemove = (emailId, index) => {
    if (emailId && emailId.toString().includes("new")) {
      remove(index);
      return;
    }
    deleteIndividual(
      {
        handle: profileHandle,
        category: "field",
        id: emailId,
      },
      {
        onSuccess: () => {
          remove(index);
        },
      },
    );
  };

  return (
    <Contact
      title="Email"
      label="email"
      handleAddNewField={handleAddNewField}
      handleRemove={handleRemove}
    >
      {emails?.map((email, index) => (
        <FastField
          key={`emails.${index}.value`}
          name={`emails.${index}.value`}
          label="Email"
          type="email"
          rightIcon="close"
          widthIcon="20"
          onIconClick={() => handleRemove(email.id, index)}
          component={TextFormField}
        />
      ))}
    </Contact>
  );
};

export default Email;
