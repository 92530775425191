import React from "react";

const Location = ({ className }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.91061 13.397C6.77464 14.3992 7.72341 15.3294 8.747 16.1778C9.77058 15.3294 10.7194 14.3992 11.5834 13.397C12.88 11.892 14.4198 9.65063 14.4198 7.5148C14.4246 6.05184 13.8269 4.64781 12.7601 3.61564C11.1377 2.03758 8.69666 1.56518 6.576 2.41887C4.45534 3.27257 3.07307 5.2841 3.07422 7.5148C3.07422 9.65063 4.61397 11.892 5.91061 13.397ZM4.69501 7.5148C4.69769 5.34112 6.51026 3.57965 8.747 3.57705C10.9837 3.57965 12.7963 5.34112 12.799 7.5148C12.799 8.43229 12.3719 10.0224 10.3402 12.3889C9.8388 12.9693 9.30702 13.5242 8.747 14.0515C8.18702 13.5235 7.6555 12.9678 7.15457 12.3866C5.12209 10.0231 4.69501 8.43308 4.69501 7.5148ZM9.73983 7.5287C9.73983 8.07685 9.29537 8.52121 8.74709 8.52121C8.19882 8.52121 7.75435 8.07685 7.75435 7.5287C7.75435 6.98054 8.19882 6.53618 8.74709 6.53618C9.29537 6.53618 9.73983 6.98054 9.73983 7.5287ZM11.158 7.5287C11.158 8.85992 10.0786 9.9391 8.74709 9.9391C7.41557 9.9391 6.33615 8.85992 6.33615 7.5287C6.33615 6.19747 7.41557 5.1183 8.74709 5.1183C10.0786 5.1183 11.158 6.19747 11.158 7.5287Z"
        fill="#686868"
      />
    </svg>
  );
};

export default React.memo(Location);
