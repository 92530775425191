import styled, { css } from "styled-components";

const Wrapper = styled.div`
  position: relative;
  label {
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
    transform: translateY(20px);
    display: block;
    cursor: text;
    font-size: 14px;
    color: #adadad;
  }
  #charCounter {
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
    transform: translateY(0);
    position: absolute;
    right: 0;
    color: #adadad;
    font-size: 13px;
    font-weight: 400;
  }
  label.filled {
    transform: translateY(0);
    ~ #charCounter {
      transform: translateY(-17px);
    }
  }
  label.focused {
    color: #ff643a;
  }

  input,
  textarea {
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
    border: none;
    border-bottom: 1px solid #d1d2d3;
    border-radius: 0;
    font-size: 16px;
    outline: none;
    width: 100%;
    padding-bottom: 4px;
    background-color: #ffffff;

    &:disabled {
      background-color: #ffffff;
      color: #adadad;
    }
  }
  input:focus,
  textarea:focus {
    border-color: #ff643a;
  }
  svg {
    position: absolute;
    right: 0;
    top: 13rem;
  }
  ${({ error }) =>
    error &&
    css`
      input,
      textarea {
        border-color: #e53e3e;
      }
      label {
        color: #e53e3e;
      }
    `}
`;

export { Wrapper };
