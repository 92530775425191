import React, { useEffect } from "react";

import { useHistory } from "react-router-dom";

import { ResetPassword } from "../../../features";

const ResetPasswordPage = () => {
  const history = useHistory();

  useEffect(() => {
    const email = localStorage.getItem("forgotPasswordEmail");
    if (!email) {
      history.replace("/auth/login");
    }
  }, [history]);

  return <ResetPassword />;
};

export default ResetPasswordPage;
