import React from "react";

const ChevronLeft = ({
  className,
  role,
  ignoreDefaultSize,
  width = 24,
  height = 24,
  onClick,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={ignoreDefaultSize ? undefined : width}
      height={ignoreDefaultSize ? undefined : height}
      viewBox="0 0 24 24"
      role={role}
      className={className}
      onClick={onClick}
    >
      <path fill="none" d="M0 0h24v24H0V0z" stroke="none" />
      <path
        d="M15.41 7.41 14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z"
        stroke="none"
      />
    </svg>
  );
};

export default React.memo(ChevronLeft);
