export const getBackground = cardDesignID => {
  switch (cardDesignID) {
    case 1:
      return "#010101";
    case 2:
      return "#23254a";
    case 3:
      return "#fff";
    case 4:
      return "#87ECFE";
    default:
      return "#fff";
  }
};

const whiteColor = "ffffff";

export const getBackgroundCard = profileData => {
  return profileData?.design?.background_color?.includes("linear")
    ? profileData?.design?.background_color?.replace(";", "")
    : `#${profileData?.design?.background_color || whiteColor}`;
};
