import React from "react";

const Contacts = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 2.90015H7C5.34315 2.90015 4 4.24329 4 5.90015V5.95015C4 5.95015 4.57378 5.69486 5 5.60015C5.38122 5.51543 6 5.45015 6 5.45015V5.40015C6 4.89269 6.37798 4.89564 6.86776 4.89946C6.91103 4.8998 6.95517 4.90015 7 4.90015H17C17.4091 4.90015 17.7609 5.14584 17.9157 5.49774C17.9157 5.49774 18.582 5.52271 19 5.60015C19.4293 5.67967 20 5.95015 20 5.95015C20 4.29329 18.6569 2.90015 17 2.90015ZM6 7.0001H18C19.6569 7.0001 21 8.34324 21 10.0001V18.0001C21 19.657 19.6569 21.0001 18 21.0001H6C4.34315 21.0001 3 19.657 3 18.0001V10.0001C3 8.34324 4.34315 7.0001 6 7.0001ZM6 9.0001C5.44772 9.0001 5 9.44781 5 10.0001V18.0001C5 18.5524 5.44772 19.0001 6 19.0001H18C18.5523 19.0001 19 18.5524 19 18.0001V10.0001C19 9.44781 18.5523 9.0001 18 9.0001H6ZM10.0004 13.6C11.0221 13.6 11.8504 12.7717 11.8504 11.75C11.8504 10.7283 11.0221 9.9 10.0004 9.9C8.97866 9.9 8.15039 10.7283 8.15039 11.75C8.15039 12.7717 8.97866 13.6 10.0004 13.6ZM8.5 14.2501C7.8725 14.2501 7.25041 14.4706 6.77498 14.8932C6.29571 15.3192 6 15.924 6 16.5834V17.2501C6 17.8024 6.44772 18.2501 7 18.2501C7.55228 18.2501 8 17.8024 8 17.2501V16.5834C8 16.5356 8.02036 16.4621 8.1037 16.388C8.19088 16.3105 8.33186 16.2501 8.5 16.2501H11.5C11.6681 16.2501 11.8091 16.3105 11.8963 16.388C11.9796 16.4621 12 16.5356 12 16.5834V17.2501C12 17.8024 12.4477 18.2501 13 18.2501C13.5523 18.2501 14 17.8024 14 17.2501V16.5834C14 15.924 13.7043 15.3192 13.225 14.8932C12.7496 14.4706 12.1275 14.2501 11.5 14.2501H8.5ZM14 11.0001C14 10.4478 14.4477 10.0001 15 10.0001H17C17.5523 10.0001 18 10.4478 18 11.0001C18 11.5524 17.5523 12.0001 17 12.0001H15C14.4477 12.0001 14 11.5524 14 11.0001ZM15 13.0001C14.4477 13.0001 14 13.4478 14 14.0001C14 14.5524 14.4477 15.0001 15 15.0001H16C16.5523 15.0001 17 14.5524 17 14.0001C17 13.4478 16.5523 13.0001 16 13.0001H15Z"
      />
    </svg>
  );
};

export default React.memo(Contacts);
