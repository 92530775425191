import { useMutation } from "react-query";
import { useToasts } from "react-toast-notifications";

import api from "../../../api";

const batchDeActiveDirectoryService = async ({
  batchDeActiveDirectoryData,
  teamId,
}) => {
  const { data } = await api.post(
    `/directory/${teamId}/deactivate_batch_directories`,
    batchDeActiveDirectoryData,
  );
  return data;
};

export const useBatchDeActiveDirectoryMutation = () => {
  const { addToast } = useToasts();

  const [mutate, { status, error, isLoading }] = useMutation(
    batchDeActiveDirectoryService,
    {
      onSuccess: () => {
        addToast("Success", {
          appearance: "success",
        });
      },
      onError: () => {
        addToast("Failed to deactive user, please contact support for assistance.", {
          appearance: "error",
        });
      },
    },
  );

  return {
    mutate,
    status,
    error,
    isLoading,
  };
};
