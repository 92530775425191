import React, { FC } from "react";
import { Box, Flex, Skeleton } from "@chakra-ui/react";
import { FormatNumber, GlobalListItem, StackedAvatars, Text } from "components";
import { IGroup } from "../types";
import defaultPictureSmall from "../../../assets/images/profile-pic-placeholder-sm.svg";

const GroupsList: FC<{ groups?: IGroup[]; isLoading?: boolean }> = ({
  groups,
  isLoading,
}) => {
  return (
    <Box py="24px">
      {isLoading ? (
        [...Array(5)].map((_, index) => (
          <Skeleton h="24px" mb="24px" key={index} />
        ))
      ) : (
        <>
          {groups?.map(({ group, count }) => (
            <Box mb="24px" key={group.id}>
              <GlobalListItem
                leftElement={
                  <Flex width="100%">
                    <Box mr="100px" width="100%">
                      <StackedAvatars
                        max={5}
                        images={group.profiles.map(
                          ({ picture }) => picture || defaultPictureSmall,
                        )}
                        text={group?.title}
                      />
                    </Box>
                  </Flex>
                }
                rightElement={<FormatNumber count={count} />}
              />
            </Box>
          ))}
        </>
      )}
    </Box>
  );
};

export default GroupsList;
