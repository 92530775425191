import { FC, useEffect, useState } from "react";
import { useWizard } from "react-use-wizard";

import { useUser } from "context";
import { MultiEmailInput, SeatsCounter, Flex } from "components";
import { StepSection } from "./StepSection";
import { useMultiEmail, useRouter, useTracking } from "hooks";
import { StepSubmit } from "./StepSubmit";
import { useUserInvite } from "hooks/useUserInvite";
import { useTeamUsersQuery } from "features/manageUsers/api/useTeamUsersQuery";
import { useUpdateTeamMutation } from "features/settings/queries";
import { WIZARD_STEP } from "types/Team";
import { ANALYTICS_EVENTS } from "constants/analyticEvents";
import { MANAGE_USERS_ROUTE } from "../../../constants";
import { useSetPermissions } from "context/Permissions";
import { usePermissionsQuery } from "hooks/usePermissionsQuery";

export const AssignSeatsStep: FC = () => {
  const track = useTracking();
  const router = useRouter();
  const { nextStep } = useWizard();
  const [{ currentTeam }] = useUser();
  const { num_seats: numSeats } = currentTeam || {};
  const { data: teamUsers } = useTeamUsersQuery({ teamId: currentTeam?.id });
  const { mutate: fireSendUserInvite, isLoading } = useUserInvite();
  const [updateTeam] = useUpdateTeamMutation({});
  const setPermissions = useSetPermissions();
  const { refetch: refetchPermission } = usePermissionsQuery({
    enabled: true,
  });

  const { results } = teamUsers || {};
  const userList = results?.map(({ email }) => email);
  const [assignedSeats, setAssignedSeats] = useState(0);

  const multiEmailInputProps = useMultiEmail({
    limit: numSeats! - userList?.length!,
    userList,
    emailsEntered: (value: number) => {
      setAssignedSeats(value);
    },
  });

  const handleSubmit = async (emails: string[]) => {
    await fireSendUserInvite({ teamId: currentTeam?.id as number, emails });

    track({
      eventName: ANALYTICS_EVENTS.TEAM_ONBOARDING_ASSIGN_SEATS_SUBMIT,
      customTrackingProps: {
        emails,
      },
    });

    finishOnboarding();
  };

  const handleSkip = () => {
    track({
      eventName: ANALYTICS_EVENTS.TEAM_ONBOARDING_ASSIGN_SEATS_SKIP,
    });

    finishOnboarding();
  };

  const finishOnboarding = async () => {
    await updateTeam({
      teamID: currentTeam?.id,
      teamData: {
        ...currentTeam,
        wizard_step: WIZARD_STEP.COMPLETE,
      },
    });
    nextStep();
    const permissions = await refetchPermission();
    if (permissions) setPermissions(permissions);
    router.push(MANAGE_USERS_ROUTE);
  };

  useEffect(() => {
    setAssignedSeats(userList?.length as number);
  }, [userList?.length]);

  useEffect(() => {
    updateTeam({
      teamID: currentTeam?.id,
      teamData: {
        ...currentTeam,
        wizard_step: WIZARD_STEP.ASSIGN_SEATS,
      },
    });
  }, []);

  return (
    <Flex justifyContent="center">
      <StepSection
        title="Inviting Team Members"
        subTitle="Invite your team members now."
      >
        <Flex flexDirection="column" gap={4}>
          <SeatsCounter
            pendingSeats={0}
            assignedSeats={assignedSeats}
            numberOfSeats={numSeats || 0}
          />

          <MultiEmailInput
            {...multiEmailInputProps}
            onSubmit={emails => {
              if (Boolean(emails.length)) {
                handleSubmit(emails);
              }
            }}
          >
            <StepSubmit
              isDisabled={
                isLoading ||
                Boolean(multiEmailInputProps?.emailsThatAlreadyExist?.length) ||
                !Boolean(multiEmailInputProps?.emails?.length)
              }
              isLoading={isLoading}
              submitText="Done"
              onSkip={handleSkip}
            />
          </MultiEmailInput>
        </Flex>
      </StepSection>
    </Flex>
  );
};
