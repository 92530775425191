import React, { useCallback, useState } from "react";

import styled from "styled-components";

import { COVER_MAX_WIDTH, MEDIA_URL } from "../../../constants";
import Button from "../../Button";
import CropperModal from "../../CropperModal";
import { useUserState } from "context/User";

const ImageWrapper = styled.span`
  background: ${({ bg }) => bg};
  background-size: cover;
`;

const BackgroundUploadFormField = ({
  field,
  form,
  attachMediaURL,
  background,
  onBGDelete,
}) => {
  const { permissions } = useUserState();
  const canEditCover = permissions?.canEditCover
  const [image, setImage] = useState();
  const [isCropperModalOpen, setIsCropperModalOpen] = useState(false);

  const handleInputChange = useCallback(e => {
    if (!canEditCover) {
      return;
    }
    e.preventDefault();
    if (!e.target.files?.length) return;
    setImage(URL.createObjectURL(e.target.files[0]));
    setIsCropperModalOpen(true);
  }, [canEditCover]);

  const setCroppedImage = useCallback(
    croppedImage => {
      setImage(croppedImage);

      if (!!croppedImage) {
        form.setFieldValue(field.name, croppedImage);
      }
    },
    [form],
  );

  const handleBGDelete = () => {
    if (!canEditCover) {
      return;
    }
    form.setFieldValue(field.name, "");
    setImage(undefined);
    onBGDelete();
  };

  const showPlaceholder = !image && !field.value;

  return (
    <>
      <div className="flex flex-col items-start md:flex-row md:items-center">
        <ImageWrapper
          bg={showPlaceholder ? background : null}
          className="h-40 w-72 rounded-lg overflow-hidden p-1"
        >
          {image && (
            <img src={image} alt="" className="w-full h-full object-cover" />
          )}
          {!image && field.value && (
            <img
              alt=""
              src={`${
                attachMediaURL ? `${MEDIA_URL}${field.value}` : field.value
              }`}
              className="w-full h-full object-cover"
            />
          )}
        </ImageWrapper>
        <div className="flex flex-row items-start">
          <label
            className={`ml-1 md:ml-5 ${canEditCover ? "cursor-pointer" : ""}`}
            htmlFor={field.name}
          >
            <Button
              className="pointer-events-none"
              htmlFor={field.name}
              variant="white"
              size="small"
              type="button"
              disabled={!canEditCover}
            >
              Change
            </Button>
          </label>
          <input
            type="file"
            accept=".png, .jpg, .jpeg"
            id={field.name}
            className="invisible w-0 h-0 absolute left-0 top-0"
            onChange={handleInputChange}
            data-testid="background-upload-input"
            disabled={!canEditCover}
          />
          {(image || field.value) && (
            <Button
              onClick={handleBGDelete}
              className="ml-4"
              variant="red"
              size="small"
              type="button"
              disabled={!canEditCover}
            >
              Delete
            </Button>
          )}
        </div>
      </div>
      {isCropperModalOpen && (
        <CropperModal
          closable
          isOpen={isCropperModalOpen}
          onClose={() => setIsCropperModalOpen(false)}
          image={image}
          getCroppedImage={setCroppedImage}
          ratio={16 / 9}
          maxWidth={COVER_MAX_WIDTH}
          title="Crop Your Cover photo"
        />
      )}
    </>
  );
};

export default BackgroundUploadFormField;
