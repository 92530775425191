import { useQuery } from "react-query";

import api from "../../../api";

interface IUseGetContactProps {
  contactId: number;
  queryAutomatically?: boolean;
}

export const useGetContact: (props: IUseGetContactProps) => any = ({
  contactId,
  queryAutomatically = true,
}) => {
  return useQuery(
    [
      "contact",
      {
        contactId,
      },
    ],
    async () => {
      const { data } = await api.get(`/contacts/connections/${contactId}`);
      return data;
    },
    {
      enabled: contactId !== undefined && queryAutomatically,
      refetchOnWindowFocus: !queryAutomatically,
    },
  );
};
