import React, { FC } from "react";

import { Flex } from "../../../components";
import {
  IGetDevicesTableColumnsProps,
  ITableAssignColumnProps,
} from "../types";
import { AssignButton } from "./AssignButton";

export const TableAssignColumn: FC<
  ITableAssignColumnProps & IGetDevicesTableColumnsProps
> = ({ currentCard, onAssignCard, onUnassignCard }) => {
  const isCardAssigned = Boolean(currentCard?.profile);

  return (
    <Flex justifyContent="flex-end" gap={4}>
      <AssignButton
        buttonText={isCardAssigned ? "Unassign" : "Assign"}
        variant={isCardAssigned ? "secondary" : "primary"}
        onClick={() =>
          isCardAssigned
            ? onUnassignCard(currentCard)
            : onAssignCard(currentCard)
        }
      />
    </Flex>
  );
};
