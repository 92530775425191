import { FC } from "react";
import { Text, TextProps } from "@chakra-ui/react";
import { theme } from "ovou-ui";

interface IFormatNumber extends TextProps {
  count: number;
}

const FormatNumber: FC<IFormatNumber> = ({
  count,
  fontSize = 14,
  fontWeight = 600,
  color = theme.palette.brand.primary.black,
  ...props
}) => {
  const formattedNumber = Intl.NumberFormat("en-US").format(count);
  return (
    <Text fontSize={fontSize} fontWeight={fontWeight} color={color} {...props}>
      {formattedNumber}
    </Text>
  );
};

export default FormatNumber;
