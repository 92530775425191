import { Checkbox } from "@chakra-ui/checkbox";

import {
  Avatar,
  GlobalListItem,
  Menu,
  MenuItem,
  MenuList,
  TableFilterMenuButton,
  Text,
} from "components";
import { PROFILE_REPRESENTATIVE_STATE } from "../../constants";
import { theme } from "ovou-ui";
import React, { FC, useMemo, useState } from "react";
import { useDebouncedCallback } from "use-debounce/lib";
import { useCardsPoolQuery } from "../../features/cards/queries";

interface ITeamMembersFilterMenuProps {
  teamId: number;
  onSelectedTeamMembersChange: (teamMembers: number[]) => void;
  onClearTeamMembers: () => void;
}

export const TeamMembersFilterMenu: FC<ITeamMembersFilterMenuProps> = ({
  teamId,
  onSelectedTeamMembersChange,
  onClearTeamMembers,
}) => {
  const [onSelectedTeamMembersDebounce] = useDebouncedCallback(
    (teamMembers: number[]) => {
      onSelectedTeamMembersChange(teamMembers);
    },
    500,
  );

  const { data: cardsPoolData } = useCardsPoolQuery({
    teamID: teamId,
    states: ["assigned"],
  } as any);

  const teamMembers = useMemo(
    () =>
      cardsPoolData?.cards?.filter(
        (card: any) => card?.profile?.state === PROFILE_REPRESENTATIVE_STATE,
      ),
    [cardsPoolData?.cards],
  );

  const [selectedTeamMembers, setSelectedTeamMembers] = useState<number[]>([]);

  const handleTeamMemberFilterSelection = (
    selectedTeamMemberUserId: number,
  ) => {
    let newSelectedTeamMembers: number[];

    const teamMemberUserIdIndex = selectedTeamMembers.findIndex(
      teamMemberUserId => teamMemberUserId === selectedTeamMemberUserId,
    );

    if (teamMemberUserIdIndex === -1) {
      newSelectedTeamMembers = [
        ...selectedTeamMembers,
        selectedTeamMemberUserId,
      ];
      setSelectedTeamMembers(newSelectedTeamMembers);
    } else {
      newSelectedTeamMembers = selectedTeamMembers.filter(
        teamMemberUserId => teamMemberUserId !== selectedTeamMemberUserId,
      );
      setSelectedTeamMembers(newSelectedTeamMembers);
    }

    onSelectedTeamMembersDebounce(newSelectedTeamMembers);
  };

  const handleClearTeamMembers = () => {
    setSelectedTeamMembers([]);
    onClearTeamMembers();
  };

  return (
    <Menu closeOnSelect={false}>
      {({ isOpen }) => (
        <>
          <TableFilterMenuButton buttonText="Team Members" isOpen={isOpen} />
          <MenuList maxWidth="200px">
            {teamMembers?.map(({ profile }: any) => (
              <MenuItem
                padding={0}
                _focus={{
                  backgroundColor: theme.palette.ui.greys.grey5,
                }}
              >
                <Checkbox
                  width="full"
                  paddingX={3}
                  paddingY={1.5}
                  isChecked={selectedTeamMembers.includes(profile?.user?.id)}
                  onChange={() =>
                    handleTeamMemberFilterSelection(profile?.user?.id)
                  }
                >
                  <GlobalListItem
                    title={`${profile.first_name} ${profile.last_name}`}
                    subTitle={profile.handle}
                    leftElement={
                      <Avatar
                        name={profile.first_name}
                        src={profile.picture}
                        width="26px"
                        height="36px"
                        borderRadius="4px"
                      />
                    }
                  />
                </Checkbox>
              </MenuItem>
            ))}

            <Text
              marginX={3}
              marginTop={4}
              fontWeight="semibold"
              fontSize={14}
              color={theme.palette.brand.primary.orange}
              cursor="pointer"
              onClick={handleClearTeamMembers}
            >
              Clear all
            </Text>
          </MenuList>
        </>
      )}
    </Menu>
  );
};
