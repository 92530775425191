import React from "react";

import styled from "styled-components";

import CardHover from "./CardHover";
import { generateMediaPath } from "../../utils";
import Icon from "../Icon";
import Spinner from "../Spinner";
import { ProfileCard } from "ovou-ui";
import { getBackgroundCard } from "utils/getBackground";

const Wrapper = styled.div`
  #hover-el {
    transition: 300ms ease-in-out;
    visibility: hidden;
    opacity: 0;
  }
  &:hover {
    #hover-el {
      visibility: visible;
      opacity: 1;
    }
  }
  .max-lines-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    overflow-wrap: break-word;
  }
`;

const Card = ({
  state = "signIn",
  loader,
  onClick,
  hasHover = true,
  cardData,
  refetch,
  tag,
}) => {
  const profileCardProps = {
    user: {
      firstName: cardData?.first_name || "",
      lastName: `${cardData?.last_name}` || "",
      profileImage: cardData?.picture || "",
    },
    trade: {
      designation: cardData?.job_title || "",
      organization: `${cardData?.workplace}`,
      logo: generateMediaPath(cardData?.logo) || "",
      showCompanyName: cardData?.show_company_name,
      companyNameSeparator: cardData?.company_name_separator,
    },
    canvasContext: {
      mode: "react",
    },
    cardConfig: {
      width: 320,
      template: {
        backgroundColor: getBackgroundCard(cardData),
        fullNameForegroundColor: `#${cardData?.design?.full_name_foreground_color}`,
        careerForegroundColor: `#${cardData?.design?.career_foreground_color}`,
      },
    },
    boxShadow: true,
  };

  console.log(profileCardProps);

  return (
    <Wrapper onClick={onClick}>
      {!loader && (
        <>
          <ProfileCard {...profileCardProps} />

          {hasHover && (
            <div className="text-white absolute right-0 bottom-0 m-4">
              <Icon name={state === "delete" ? "delete" : "signIn"} />
            </div>
          )}

          {hasHover && (
            <CardHover
              state={state}
              firstName={cardData?.first_name || ""}
              cardData={cardData}
              refetch={refetch}
              tag={tag}
            />
          )}
        </>
      )}
      {loader && <Spinner />}
    </Wrapper>
  );
};

export default Card;
