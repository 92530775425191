import { Observable } from "utils/observables";

export interface IIntegrationService {
    getIntegrationsObservable(): Observable<Integration[]>;
    showConfigurationMenu(integrationId: string): unknown;
    initialize(): void;
    dispose(): void;
}

export type Integration = {
    status: IntegrationStatus,
    id: string,
    name: string,
    icon: string,
};

export enum IntegrationStatus {
    CONNECTED,
    NOT_CONFIGURED,
    DISABLED,
    DISCONNECTED,
    WRONG_CONFIGURATION
};
