import { FC } from "react";

import { Formik, Form } from "formik";

import { theme } from "ovou-ui";
import {
  Box,
  Fade,
  Input,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  Wrap,
  WrapItem,
} from "components";
import { IChipList, IMultiEmailInput } from "types/IMultiEmailInput";
import { TEXTS } from "../../constants/Texts";
const ChipList: FC<IChipList> = ({ emails = [], onClose }) => {
  return (
    <>
      {emails.map(({ email, alreadyExists }) => (
        <WrapItem key={email}>
          <Tag
            borderRadius="md"
            variant="outline"
            color={alreadyExists ? theme.palette.brand.primary.orange : ""}
            paddingY="12px"
            border={`1px solid ${theme.palette.ui.greys.grey3}`}
            fontSize="14px"
            fontWeight="600"
            minHeight="3rem"
          >
            <TagLabel>{email}</TagLabel>
            <TagCloseButton
              color={theme.palette.ui.greys.grey2}
              onClick={() => {
                onClose(email);
              }}
            />
          </Tag>
        </WrapItem>
      ))}
    </>
  );
};

const MultiEmailInput: FC<IMultiEmailInput> = ({
  children,
  emails,
  errorText,
  inputValue,
  isLimitExceeded,
  handleChange,
  handleCloseClick,
  handleKeyDown,
  handleBlur,
  onSubmit,
}) => {
  return (
    <>
      <Formik
        initialValues={{
          email: "",
        }}
        onSubmit={() => {
          if (onSubmit) {
            const userEmails = emails.map(({ email }) => email);
            onSubmit(userEmails);
          }
        }}
        enableReinitialize
      >
        <Form>
          <Box marginBottom="24px" minWidth="343px">
            <Wrap
              align="center"
              borderBottom={
                !isLimitExceeded
                  ? `1px solid ${theme.palette.ui.greys.grey3}`
                  : ""
              }
              paddingBottom="8px"
              minHeight="55px"
              maxHeight={300}
              width="100%"
              overflow="auto"
              overflowX="hidden"
            >
              <ChipList emails={emails} onClose={handleCloseClick} />
              <WrapItem flexGrow={1}>
                {!isLimitExceeded && (
                  <Input
                    width="100%"
                    marginLeft="2.5px"
                    minHeight="3rem"
                    border="none"
                    name="email"
                    type="email"
                    autoComplete="off"
                    placeholder={
                      Boolean(emails.length)
                        ? ""
                        : "Separate each email address with a comma"
                    }
                    onKeyDown={handleKeyDown}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={inputValue}
                    _placeholder={{
                      color: `${theme.palette.ui.greys.grey3}`,
                      fontSize: "16px",
                      fontWeight: 400,
                    }}
                    sx={{
                      caretColor: isLimitExceeded ? "transparent" : "",
                    }}
                  />
                )}
              </WrapItem>
              {!Boolean(errorText) && isLimitExceeded && (
                <Box width="100%">
                  <Fade
                    in={!Boolean(errorText) && isLimitExceeded}
                    unmountOnExit
                  >
                    <WrapItem>
                      <Text
                        color={theme.palette.brand.primary.orange}
                        fontSize="14px"
                        fontWeight="600"
                        marginTop="14px"
                      >
                        {TEXTS.REACH_LIMIT}
                      </Text>
                    </WrapItem>
                  </Fade>
                </Box>
              )}
            </Wrap>
            {Boolean(errorText) && (
              <Fade in={Boolean(errorText)}>
                <Text
                  color={theme.palette.brand.primary.orange}
                  fontSize="14px"
                  fontWeight="600"
                  marginTop="14px"
                >
                  {errorText}
                </Text>
              </Fade>
            )}
          </Box>
          {children}
        </Form>
      </Formik>
    </>
  );
};

export default MultiEmailInput;
