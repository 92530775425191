import { useMutation, useQuery } from "react-query";

import api from "../../../api";
import { IGetTeamTags } from "../types/Tag";

interface IUseGetTeamTagsProps {
  teamId: number;
  queryAutomatically?: boolean;
}

interface IUseCreateTeamTagProps {
  teamId: number;
  refetchTags?: () => void;
}

interface ICreateTeamTagMutationProps {
  name: string;
  color: string;
}

interface IUseDeleteTagProps {
  refetchTags?: () => void;
}

interface IDeleteTagMutationProps {
  tagId: number;
}

interface IUseAddTagToContactProps {
  refetchData?: () => void;
}

interface IAddTagToContactMutationProps {
  contactId: number;
  tagIds: number[];
}

export const useGetTeamTags: (props: IUseGetTeamTagsProps) => any = ({
  teamId,
  queryAutomatically = true,
}) => {
  return useQuery<IGetTeamTags>(
    ["get-team-tags"],
    async () => {
      const { data } = await api.get(`/profile/teams/${teamId}/tags`);
      return data;
    },
    {
      enabled: teamId !== undefined && queryAutomatically,
      refetchOnWindowFocus: !queryAutomatically,
    },
  );
};

export const useCreateTeamTag: (props: IUseCreateTeamTagProps) => any = ({
  teamId,
  refetchTags,
}) => {
  const [createTag, createTagProps] = useMutation(
    async (props: ICreateTeamTagMutationProps) => {
      const { data } = await api.post(`/profile/teams/${teamId}/tags`, props);
      return data;
    },
    {
      onSuccess: () => refetchTags && refetchTags(),
    },
  );

  return {
    mutate: createTag,
    ...createTagProps,
  };
};

export const useDeleteTag: (props: IUseDeleteTagProps) => any = ({
  refetchTags,
}) => {
  const [deleteTag, deleteTagProps] = useMutation(
    async (props: IDeleteTagMutationProps) => {
      const { data } = await api.delete(`/tags/${props.tagId}`);
      return data;
    },
    {
      onSuccess: () => refetchTags && refetchTags(),
    },
  );

  return {
    mutate: deleteTag,
    ...deleteTagProps,
  };
};

export const useAddTagToContact: (props: IUseAddTagToContactProps) => any = ({
  refetchData,
}) => {
  const [addTagToContact, addTagToContactProps] = useMutation(
    async (props: IAddTagToContactMutationProps) => {
      const { data } = await api.put(
        `/contacts/connections/${props.contactId}`,
        {
          tags: props.tagIds.map(tagId => {
            return { id: tagId };
          }),
        } as any,
      );
      return data;
    },
    {
      onSuccess: () => refetchData && refetchData(),
    },
  );

  return {
    mutate: addTagToContact,
    ...addTagToContactProps,
  };
};
