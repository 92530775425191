import { useMutation } from "react-query";

import api from "../../../api";

const deleteIndividualService = async ({ handle, category, id }) => {
  const { data } = await api.delete(`/p/${handle}/${category}/${id}`);
  return data;
};

const deleteProfilePicture = async ({ handle }) => {
  const { data } = await api.delete(`/p/${handle}/picture`);
  return data;
};

const deleteType = type => {
  if (type === "picture") {
    return deleteProfilePicture;
  }
  if (type === "generic") {
    return deleteIndividualService;
  }
};

export const useDeleteIndividualQuery = ({ onSuccess, type }) => {
  const [mutate, { status, error }] = useMutation(deleteType(type), {
    onSuccess,
  });

  return {
    mutate,
    status,
    error,
  };
};
