import { Flex, Link, Stack, StackDivider, Text } from "@chakra-ui/react";
import React, { FC, useMemo, useState } from "react";

import { ButtonOutlined, PageSetup } from "../../components";
import { TagListItem } from "./components/TagListItem";
import { ITag } from "./types/Tag";
import { useGetContact } from "./queries/useGetContact";
import { useGetContactNotes } from "./queries/useGetContactNotes";
import { useDeleteTag, useGetTeamTags } from "./queries/useTags";
import { ManageTagsModal } from "../../components/Tags";
import { TagsToContactModal } from "../../components/Tags/TagsToContactModal";
import { HiOutlinePencil } from "react-icons/hi";
import { theme } from "ovou-ui";
import { FiMail } from "react-icons/fi";
import { BsPhone } from "react-icons/bs";
import { ContactNote } from "./components/ContactNote";
import { IContactNote } from "./types/ContactNote";
import { FEATURES } from "constants/features";

interface IContactDetailProps {
  contactId: number;
  teamId: number;
}

export const ContactDetail: FC<IContactDetailProps> = ({
  contactId,
  teamId,
}) => {
  const { data: contactData, refetch: refetchContactData } = useGetContact({
    contactId,
  });
  const { data: contactNotes } = useGetContactNotes({
    contactId,
  });
  const { data: teamTagsData } = useGetTeamTags({
    teamId,
  });
  const { mutate: deleteTag } = useDeleteTag({
    refetchTags: refetchContactData,
  });

  const [isManageTagsModalOpen, setIsManageTagsModalOpen] =
    useState<boolean>(false);
  const [isTagsToContactModalOpen, setIsTagsToContactModalOpen] =
    useState<boolean>(false);

  const contact = useMemo(() => {
    if (!contactData) return null;

    // This entity ID includes the entity type "lead_capture"
    const contactId = contactData.id.split("/")[1];

    return {
      ...contactData,
      id: contactId,
    };
  }, [contactData]);

  const teamTags = teamTagsData?.results || [];

  return (
    <PageSetup
      title={contact?.full_name || ""}
      subTitle={
        Boolean(contact)
          ? `${contact?.job_title || ""}${
              contact?.job_title && contact?.workplace && " at "
            }${contact?.workplace || ""}`
          : ""
      }
      visibleInMobile={false}
      backUrl="/contacts"
      showBackArrow={true}
      backArrowText="Back to Contacts"
      feature={FEATURES.CONTACTS}
    >
      <>
        <Flex flexWrap="wrap" gap={2}>
          {contact?.tags?.map((tag: ITag) => (
            <TagListItem
              name={tag.name}
              color={tag.color}
              onDelete={() => deleteTag({ tagId: tag.id })}
            />
          ))}

          <ButtonOutlined
            leftIcon={<HiOutlinePencil color={theme.palette.ui.greys.grey2} />}
            onClick={() => {
              setIsTagsToContactModalOpen(true);
              isManageTagsModalOpen && setIsManageTagsModalOpen(false);
            }}
          >
            Set Contact Tags
          </ButtonOutlined>

          <ButtonOutlined
            leftIcon={<HiOutlinePencil color={theme.palette.ui.greys.grey2} />}
            onClick={() => {
              setIsManageTagsModalOpen(true);
              isTagsToContactModalOpen && setIsTagsToContactModalOpen(false);
            }}
          >
            Manage Team Tags
          </ButtonOutlined>
        </Flex>

        <Flex flexDirection="column" marginTop={14}>
          <Text
            fontWeight="extrabold"
            fontSize={18}
            color={theme.palette.brand.primary.black}
          >
            Contact
          </Text>

          <Stack
            marginTop={4}
            divider={
              <StackDivider borderColor={theme.palette.ui.greys.grey4} />
            }
          >
            <Flex
              as={Link}
              gap={6}
              cursor="pointer"
              href={contact?.email && `mailto:${contact.email}`}
            >
              <FiMail size={24} color={theme.palette.ui.greys.grey2} />
              <Text
                fontWeight="semibold"
                fontSize={14}
                color={theme.palette.brand.primary.black}
              >
                {contact?.email || "An email was not provided."}
              </Text>
            </Flex>
            <Flex
              as={Link}
              gap={6}
              cursor="pointer"
              href={contact?.phone && `tel:${contact.phone}`}
            >
              <BsPhone size={24} color={theme.palette.ui.greys.grey2} />
              <Text
                fontWeight="semibold"
                fontSize={14}
                color={theme.palette.brand.primary.black}
              >
                {contact?.phone || "A phone number was not provided."}
              </Text>
            </Flex>
          </Stack>
        </Flex>

        {contact?.message && (
          <Flex flexDirection="column" marginTop={14}>
            <Text
              fontWeight="extrabold"
              fontSize={18}
              color={theme.palette.brand.primary.black}
            >
              {`Introductory message from ${contact?.full_name}`}
            </Text>
            <Text
              marginTop={3}
              fontSize={16}
              color={theme.palette.ui.greys.grey1}
            >
              {contact.message}
            </Text>
          </Flex>
        )}

        <Flex flexDirection="column" marginTop={14}>
          <Text
            fontWeight="extrabold"
            fontSize={18}
            color={theme.palette.brand.primary.black}
          >
            Notes
          </Text>
          {contactNotes?.results?.length === 0 ? (
            <Text
              marginTop={3}
              fontSize={16}
              color={theme.palette.ui.greys.grey1}
            >
              Notes have not been added to this contact.
            </Text>
          ) : (
            contactNotes?.results?.map((contactNote: IContactNote) => (
              <ContactNote contactNote={contactNote} />
            ))
          )}
        </Flex>

        <ManageTagsModal
          teamId={teamId}
          isOpen={isManageTagsModalOpen}
          onClose={() => setIsManageTagsModalOpen(false)}
        />

        <TagsToContactModal
          tags={teamTags}
          contact={contact}
          isOpen={isTagsToContactModalOpen}
          onClose={() => setIsTagsToContactModalOpen(false)}
          onManageTags={() => {
            setIsTagsToContactModalOpen(false);
            setIsManageTagsModalOpen(true);
          }}
          refetchData={refetchContactData}
        />
      </>
    </PageSetup>
  );
};
