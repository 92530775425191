import { FC, useCallback } from "react";
import { Flex, Icon, IconButton, Text } from "components";
import { IChangeRoleColumn } from "./types";
import { TEAM_OWNER_ROLES } from "types/TeamUsersResponse";
import { theme } from "ovou-ui";
import { useCheckPermission, useIsActionAllowed, useTracking } from "hooks";
import { FEATURES, FEATURE_ACTION } from "constants/features";
import { ANALYTICS_EVENTS } from "constants/analyticEvents";

const ChangeRoleColumn: FC<IChangeRoleColumn> = ({ row, onClick }) => {
  const track = useTracking();
  const canEditRole = useCheckPermission(
    FEATURE_ACTION.EDIT,
    FEATURES.MANAGE_USERS_CHANGE_ROLE,
  );
  const { handleAction } = useIsActionAllowed();
  const { permission_metadata: metadata, user_iam_role: userIamRole } = row;

  const handleClick = useCallback(() => {
    handleAction(canEditRole, FEATURES.MANAGE_USERS_CHANGE_ROLE, () => {
      onClick(row);
      track({
        eventName: ANALYTICS_EVENTS.MANAGE_USERS_OPEN_CHANGE_ROLE_MODAL,
      });
    });
  }, [canEditRole, row]);

  return (
    <Flex alignItems="center">
      <Text width="100px">{metadata.label}</Text>

      {!TEAM_OWNER_ROLES.includes(userIamRole) && (
        <IconButton
          aria-label="Edit user role"
          variant="grey"
          borderRadius="0.5rem"
          border={`1px solid ${theme.palette.ui.greys.grey3}`}
          size="md"
          icon={<Icon name="edit" strokeWidth="1.5" />}
          onClick={handleClick}
        />
      )}
    </Flex>
  );
};

export default ChangeRoleColumn;
