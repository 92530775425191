import React, { FC, useState } from "react";
import { SortingState } from "@tanstack/table-core";
import { Flex } from "@chakra-ui/react";

import { ManageTagsModal } from "../../components/Tags";
import { EmptyState } from "../../components/EmptyState";
import { ContactsTable } from "./ContactsTable";
import {
  TeamContactsSortOption,
  useGetTeamContacts,
} from "./queries/useContactsTable";
import { IContact } from "./types/Contact";
import { TablePagination } from "./components/TablePagination";
import { TagsFilterMenu } from "./TagsFilterMenu";
import { TeamMembersFilterMenu } from "./TeamMembersFilterMenu";
import DateSelector from "../../features/analytics/DateSelector";
import SearchInput from "./components/SearchInput";
import { PageSetup } from "../../components";
import { TagsToContactModal } from "../../components/Tags/TagsToContactModal";
import { useGetTeamTags } from "./queries/useTags";
import { ExportContacts } from "./components/ExportContacts";
import { FEATURES } from "constants/features";
import { CONTACTS_TEXTS } from "constants/Texts";

interface IContactsProps {
  teamId: number;
}

export const Contacts: FC<IContactsProps> = ({ teamId }) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [tags, setTags] = useState<number[]>([]);
  const [teamMembers, setTeamMembers] = useState<number[]>([]);
  const [dateLowerBound, setDateLowerBound] = useState<string>();
  const [dateUpperBound, setDateUpperBound] = useState<string>();
  const [sort, setSort] = useState<TeamContactsSortOption>(
    TeamContactsSortOption.CREATED_DESCENDING,
  );
  const [offset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState<number>(20);
  const [isManageTagsModalOpen, setIsManageTagsModalOpen] =
    useState<boolean>(false);
  const [isTagsToContactModalOpen, setIsTagsToContactModalOpen] =
    useState<boolean>(false);
  const [currentContact, setCurrentContact] = useState<IContact>();
  const [currentPage, setCurrentPage] = useState<number>(0);

  const {
    data: teamContactsData,
    refetch: refetchContactsData,
    isLoading: isLoadingTeamContactsData,
  } = useGetTeamContacts({
    teamId,
    searchQuery,
    tags,
    teamMembers,
    dateLowerBound,
    dateUpperBound,
    sort,
    offset,
    limit,
  });

  const { data: teamTagsData } = useGetTeamTags({
    teamId,
  });

  const handleSelectedDateChange = (lowerBound: string, upperBound: string) => {
    setDateLowerBound(lowerBound);
    setDateUpperBound(upperBound);
  };

  const handleSortStateChange = (sortingState: SortingState) => {
    if (sortingState.length === 0) return;

    const { id: filterOption, desc: descending } = sortingState[0];

    switch (filterOption) {
      case "full_name":
        setSort(
          descending
            ? TeamContactsSortOption.FULL_NAME_DESCENDING
            : TeamContactsSortOption.FULL_NAME_ASCENDING,
        );
        break;
      case "created":
        setSort(
          descending
            ? TeamContactsSortOption.CREATED_DESCENDING
            : TeamContactsSortOption.CREATED_ASCENDING,
        );
        break;
    }

    setCurrentPage(0);
    setOffset(0);
  };

  const handleTagsToContactModalOpen = (contact: IContact) => {
    setCurrentContact(contact);
    setIsTagsToContactModalOpen(true);
  };

  const handleTagsToContactModalClose = () => {
    setCurrentContact(undefined);
    setIsTagsToContactModalOpen(false);
  };

  const handleSetPageSize = (pageSize: number) => {
    setCurrentPage(0);
    setOffset(0);
    setLimit(pageSize);
  };

  const handlePageClick = (event: any) => {
    setCurrentPage(event.selected);
    setOffset(event.selected * limit);
  };

  const teamContacts: IContact[] = teamContactsData?.results || [];
  const teamTags = teamTagsData?.results || [];
  const pageCount = teamContactsData
    ? Math.max(Math.ceil(teamContactsData.count / limit), 1)
    : 1;
  const hasFilters =
    Boolean(searchQuery) || Boolean(tags.length) || Boolean(teamMembers.length);

  return (
    <PageSetup
      title={CONTACTS_TEXTS.CONTACTS_PAGE.TITLE}
      subTitle={CONTACTS_TEXTS.CONTACTS_PAGE.SUB_TITLE}
      visibleInMobile={false}
      rightBodyElement={
        <ExportContacts
          canExport={Boolean(teamContacts.length)}
          filters={{
            teamId,
            searchQuery,
            tags,
            teamMembers,
            dateLowerBound,
            dateUpperBound,
            sort,
          }}
        />
      }
      feature={FEATURES.CONTACTS}
    >
      <>
        {teamContactsData?.count === 0 && !hasFilters ? (
          <EmptyState
            title={CONTACTS_TEXTS.EMPTY_STATE.TITLE}
            subTitle={CONTACTS_TEXTS.EMPTY_STATE.SUB_TITLE}
          />
        ) : (
          <>
            <Flex flexDirection="column" width="max">
              <SearchInput
                defaultValue={searchQuery}
                onChange={setSearchQuery}
              />

              <Flex marginTop={6} gap={2}>
                <TagsFilterMenu
                  tags={teamTags}
                  onSelectedTagsChange={setTags}
                  onManageTags={() => setIsManageTagsModalOpen(true)}
                  onClearTags={() => setTags([])}
                />

                <TeamMembersFilterMenu
                  teamId={teamId}
                  onSelectedTeamMembersChange={setTeamMembers}
                  onClearTeamMembers={() => setTeamMembers([])}
                />

                <DateSelector
                  menuButtonType="dateRange"
                  handleChange={handleSelectedDateChange}
                />
              </Flex>
            </Flex>

            <ContactsTable
              contacts={teamContacts}
              isLoadingContacts={isLoadingTeamContactsData}
              onManageContactTags={handleTagsToContactModalOpen}
              onSortStateChange={handleSortStateChange}
            />

            <TablePagination
              currentPage={currentPage}
              pageCount={pageCount}
              pageSize={limit}
              setPageSize={handleSetPageSize}
              onPageClick={handlePageClick}
            />

            <ManageTagsModal
              teamId={teamId}
              isOpen={isManageTagsModalOpen}
              onClose={() => setIsManageTagsModalOpen(false)}
            />

            <TagsToContactModal
              tags={teamTags}
              contact={currentContact}
              isOpen={isTagsToContactModalOpen}
              onClose={handleTagsToContactModalClose}
              onManageTags={() => {
                setIsTagsToContactModalOpen(false);
                setIsManageTagsModalOpen(true);
              }}
              refetchData={refetchContactsData}
            />
          </>
        )}
      </>
    </PageSetup>
  );
};
