import React, { FC } from "react";
import { theme } from "ovou-ui";

import { ButtonOutlined, Text } from "../../../components";
import { IAssignButtonProps } from "../types";

export const AssignButton: FC<IAssignButtonProps> = ({
  buttonText,
  variant = "primary",
  onClick,
}) => (
  <ButtonOutlined
    height="3rem"
    width="6rem"
    paddingX="1rem"
    paddingY={0}
    borderRadius="0.5rem"
    borderColor={theme.palette.brand.primary.black}
    onClick={onClick}
    {...(variant === "primary" && {
      backgroundColor: theme.palette.brand.primary.black,
    })}
    _hover={{
      opacity: 0.75,
      backgroundColor: theme.palette.brand.primary.black,
    }}
    _focus={{
      opacity: 0.75,
      backgroundColor: theme.palette.brand.primary.black,
    }}
    _active={{
      opacity: 0.75,
      backgroundColor: theme.palette.brand.primary.black,
    }}
  >
    <Text
      fontWeight="bold"
      fontSize="0.875rem"
      color={theme.palette.brand.primary.black}
      noOfLines={2}
      {...(variant === "primary" && {
        textColor: theme.palette.brand.primary.white,
      })}
      _hover={{
        textColor: theme.palette.brand.primary.white,
      }}
      _focus={{
        textColor: theme.palette.brand.primary.white,
      }}
      _active={{
        textColor: theme.palette.brand.primary.white,
      }}
    >
      {buttonText}
    </Text>
  </ButtonOutlined>
);
