import React from "react";

import { Formik, Form, Field } from "formik";
import { useHistory } from "react-router-dom";
import * as yup from "yup";

import { TextFormField, Button, Spinner, Error } from "../../../components";
import { VALIDATION_MESSAGES, TEXTS, PAGES } from "../../../constants";
import { useSendConfirmationEmailMutation } from "../queries";

const validationSchema = yup.object({
  email: yup
    .string()
    .required(VALIDATION_MESSAGES.IS_REQUIRED("Email"))
    .email(VALIDATION_MESSAGES.FORMAT_IS_WRONG("Email")),
});

const ForgotPasswordForm = () => {
  const history = useHistory();

  const {
    isLoading,
    error,
    mutate: sendConfirmationEmail,
  } = useSendConfirmationEmailMutation({
    onSuccess: (_data, { email }) => {
      localStorage.setItem("forgotPasswordEmail", email);
      history.push(PAGES.RESET_PASSWORD);
    },
  });

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{ email: "" }}
      onSubmit={values => sendConfirmationEmail({ email: values.email })}
      enableReinitialize
    >
      {({ isValid }) => (
        <Form>
          <div className="mb-8">
            <Field label="Email" name="email" component={TextFormField} />
          </div>
          <div className="mb-32 text-right">
            {error && <Error {...{ error }} />}
          </div>
          <div>
            <Button
              variant="black"
              disabled={!isValid || isLoading}
              block
              uppercase
            >
              {isLoading ? (
                <Spinner color="white" />
              ) : (
                TEXTS.forgotPasswordPage.BUTTON
              )}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ForgotPasswordForm;
