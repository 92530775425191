import React from "react";

const ChevronRight = ({
  className,
  role,
  ignoreDefaultSize,
  width = 24,
  height = 24,
  onClick,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={ignoreDefaultSize ? undefined : width}
      height={ignoreDefaultSize ? undefined : height}
      viewBox="0 0 24 24"
      role={role}
      className={className}
      onClick={onClick}
    >
      <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
      <path fill="none" d="M0 0h24v24H0z" />
    </svg>
  );
};

export default React.memo(ChevronRight);
