export const getFormattedDate = (date: Date): string => {
  return date.toLocaleDateString(navigator.language, {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};

export const getCurrentMonth = (dateTime: Date) => {
  const firstDay = new Date(dateTime.getFullYear(), dateTime.getMonth(), 1);

  return {
    rangeDisplay: `${getFormattedDate(firstDay)} - ${getFormattedDate(
      dateTime,
    )}`,
    lowerBound: firstDay.toISOString(),
    upperBound: dateTime.toISOString(),
  };
};

export const getLastMonth = (dateTime: Date) => {
  const firstDayOfPrevMonth = new Date(
    dateTime.getFullYear(),
    dateTime.getMonth() - 1,
    1,
  );
  const lastDayOfPrevMonth = new Date(new Date().setDate(0));

  return {
    rangeDisplay: `${getFormattedDate(
      firstDayOfPrevMonth,
    )} - ${getFormattedDate(lastDayOfPrevMonth)}`,
    lowerBound: firstDayOfPrevMonth.toISOString(),
    upperBound: lastDayOfPrevMonth.toISOString(),
  };
};

export const getLastYear = (dateTime: Date) => {
  const theDateYearFromNow = new Date(
    dateTime.getFullYear() - 1,
    dateTime.getMonth(),
    dateTime.getDate() + 1,
  );

  return {
    rangeDisplay: `${getFormattedDate(theDateYearFromNow)} - ${getFormattedDate(
      dateTime,
    )}`,
    lowerBound: theDateYearFromNow.toISOString(),
    upperBound: dateTime.toISOString(),
  };
};

export const getCustomDate = (from?: Date, to?: Date) => {
  return {
    rangeDisplay: `${getFormattedDate(from!)} - ${getFormattedDate(to!)}`,
    lowerBound: from?.toISOString()!,
    upperBound: to?.toISOString()!,
  };
};

export const getAllTime = (dateTime: Date) => {
  const OVOU_START_DATE = new Date("1 January 2020");

  return {
    rangeDisplay: `${getFormattedDate(OVOU_START_DATE)} - ${getFormattedDate(
      dateTime,
    )}`,
    lowerBound: OVOU_START_DATE.toISOString(),
    upperBound: dateTime.toISOString(),
  };
};

export const getFormattedTime = (date: Date): string => {
  return date.toLocaleTimeString("en-US").replace(/ /g, ""); // Removing space between time and AM/PM
};

export const getFormattedDateTime = (date: Date): string => {
  const newDate = new Date(date);

  return `${getFormattedDate(newDate)} ${getFormattedTime(newDate)}`;
};
