import { useQuery } from "react-query";

import api from "../../../api";

const directoryService = async (
  _key,
  { searchQuery, businessProfileHandle, teamID, withProfiles = true },
) => {
  const searchURL = `/p/${businessProfileHandle}/directories`;
  const directoriesURL = `/directory/for/${teamID}`;
  const URL = searchQuery ? searchURL : directoriesURL;

  const { data } = await api.get(URL, {
    params: {
      search: searchQuery,
      with_profiles: withProfiles,
    },
  });
  return data;
};

export const useDirectoryQuery = ({
  onSuccess,
  searchQuery,
  businessProfileHandle,
  withProfiles,
  teamID,
}) => {
  const { status, isLoading, isFetching, data, error, refetch } = useQuery(
    [
      `directory-${searchQuery}`,
      { searchQuery, businessProfileHandle, withProfiles, teamID },
    ],
    directoryService,
    {
      onSuccess,
      enabled: !!businessProfileHandle || teamID !== undefined,
    },
  );

  return {
    isLoading,
    isFetching,
    status,
    data,
    error,
    refetch,
  };
};
