import React from "react";

const LogOut = ({
  className,
  role,
  ignoreDefaultSize,
  width = 30,
  height = 30,
  onClick,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={ignoreDefaultSize ? undefined : width}
      height={ignoreDefaultSize ? undefined : height}
      viewBox="0 0 30 30"
      role={role}
      className={className}
      onClick={onClick}
    >
      <path
        d="M3 12h15"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="currentColor"
        transform="translate(-.917 3)"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="currentColor"
        d="M3.947 16.077a9.053 9.053 0 1 0 0-8.047"
        transform="translate(6.401 2.947)"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="currentColor"
        d="M15.75 9L12 12.75l3.75 3.75"
        className="prefix__cls-2"
        transform="translate(-9.917 2.25)"
      />
    </svg>
  );
};

export default React.memo(LogOut);
