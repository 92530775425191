import { createContext, FC, useContext, useEffect } from "react";
import { TAnalyticsClient } from "./types";
import * as analytics from "./services/index";

const AnalyticsContext = createContext<TAnalyticsClient | undefined>(undefined);

const AnalyticsProvider = ({ children }: { children: JSX.Element }) => {
  useEffect(() => {
    analytics.loadService();
  }, []);
  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
};

const useAnalytics = (): TAnalyticsClient => {
  const result = useContext(AnalyticsContext);
  if (!result) {
    throw new Error("Context used outside of its Provider!");
  }
  return result;
};

export { AnalyticsProvider, useAnalytics };
