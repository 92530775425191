import React, { createContext, useContext, useMemo } from "react";

const ProfileDataContext = createContext<any>({});
export const useProfileData = () => useContext(ProfileDataContext);

export const ProfileDataProvider = ({
  children,
  profileData,
}: {
  children: React.ReactNode;
  profileData: any;
}) => {
  const memoizedProfileData = useMemo(() => {
    let result = profileData?.profiles?.filter(
      ({ state }: any) => state !== "is_business",
    )[0];
    return result;
  }, [profileData]);

  return (
    <ProfileDataContext.Provider value={memoizedProfileData as any}>
      {children}
    </ProfileDataContext.Provider>
  );
};
