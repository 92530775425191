import React, { FC, useMemo } from "react";
import { Formik, Form, Field } from "formik";
import { Button } from "@chakra-ui/react";
import * as yup from "yup";

import { SelectFormField, Spinner } from "../../components";
import { VALIDATION_MESSAGES } from "../../constants";
import { ITeamUserObject } from "types/TeamUsersResponse";

const validationSchema = yup.object({
  email: yup.string().required(VALIDATION_MESSAGES.IS_REQUIRED("Email")),
});

interface IAssignDeviceForm {
  users: ITeamUserObject[];
  isLoading: boolean;
  isSubmitting: boolean;
  onSubmit: (email: string) => void;
  handleSearch: (searchQuery: string) => void;
}

export const AssignDeviceForm: FC<IAssignDeviceForm> = ({
  users,
  isLoading,
  isSubmitting,
  onSubmit,
  handleSearch,
}) => {
  const emailOptions = useMemo(
    () =>
      users?.map(user => ({
        label: user?.email,
        value: user?.profile?.handle,
      })) || [],
    [users],
  );

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{
        email: "",
      }}
      onSubmit={values => onSubmit(values.email)}
      enableReinitialize
    >
      {({ isValid, dirty, submitForm }) => (
        <Form noValidate autoComplete="off">
          <Field
            component={SelectFormField}
            label="Email"
            name="email"
            placeholder="Search for user's email"
            options={emailOptions}
            isLoading={isLoading}
            disabled={isSubmitting}
            handleSearch={handleSearch}
            showSearchIcon
            isClearable
            renderInPortal
          />
          <Button
            width="full"
            marginTop={4}
            isDisabled={isSubmitting || !isValid || !dirty}
            onClick={submitForm}
          >
            {isSubmitting ? <Spinner color="white" /> : "Assign"}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default AssignDeviceForm;
