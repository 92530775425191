export const customSelectStyles = {
  control: provided => ({
    ...provided,
    border: "none !important",
    borderBottom: "1px solid #d1d2d3 !important",
    boxShadow: "none",
    borderRadius: "none",
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: "none",
  }),
  singleValue: provided => ({
    ...provided,
    fontSize: "14px",
  }),
  placeholder: provided => ({
    ...provided,
    fontSize: "14px",
    color: "#adadad",
  }),
  valueContainer: provided => ({
    ...provided,
    padding: "",
  }),
};
