import { useMutation } from "react-query";
import { useToasts } from "react-toast-notifications";

import api from "../../../api";

const unassignSeatsService = async ({
  teamId,
  userIds,
}: {
  teamId: number;
  userIds: number[];
}) => {
  const { data } = await api.delete(`profile/teams/${teamId}/users`, {
    data: {
      user_id: userIds,
    },
  });
  return data;
};

export const useUnassignSeatsMutation = () => {
  const { addToast } = useToasts();

  const [mutate, { ...mutationProps }] = useMutation(unassignSeatsService, {
    onSuccess: () => {
      addToast("Success", {
        appearance: "success",
      });
    },
    onError: () => {
      addToast("Failed to unassign seats, please contact support for assistance.", {
        appearance: "error",
      });
    },
  });
  return {
    mutate,
    ...mutationProps,
  };
};
