import { ITeam } from "types/Team";
import { IProfile } from "types/Profile";

export enum userActions {
  SET_PROFILE = "SET_PROFILE",
  SET_TEAM = "SET_TEAM",
  LOGIN_USER = "LOGIN_USER",
  LOGOUT_USER = "LOGOUT_USER",
  SET_PERMISSIONS = "SET_PERMISSIONS",
}
export const setProfile = (profile: IProfile) => {
  return {
    type: userActions.SET_PROFILE,
    payload: profile,
  } as const;
};

export const setTeam = (team: ITeam) => {
  return {
    type: userActions.SET_TEAM,
    payload: team,
  } as const;
};

export const loginUser = () => {
  return {
    type: userActions.LOGIN_USER,
    payload: undefined,
  };
};

export const logoutUser = () => {
  return {
    type: userActions.LOGOUT_USER,
    payload: undefined,
  };
};

export const setPermission = (permission: any) => {
  return {
    type: userActions.SET_PERMISSIONS,
    payload: permission,
  };
};

export type TUserActions = ReturnType<
  | typeof setProfile
  | typeof setTeam
  | typeof loginUser
  | typeof logoutUser
>;
