import { FC, ReactElement } from "react";

import { Box, Flex, Text, Divider, FlexProps } from "@chakra-ui/react";
import { Icon } from "components";
import { theme } from "ovou-ui";

const IconComponent = Icon as any;

interface IGlobalListItem {
  title?: string | ReactElement;
  subTitle?: string | ReactElement;
  leftElement?: ReactElement;
  rightElement?: ReactElement;
  showBorder?: boolean;
  showChevron?: boolean;
  customStyles?: {
    containerProps?: FlexProps;
  };
}
const GlobalListItem: FC<IGlobalListItem> = ({
  title,
  subTitle,
  leftElement,
  rightElement,
  showBorder = false,
  showChevron = false,
  customStyles,
}) => {
  return (
    <>
      <Flex alignItems="center" flex="1" {...customStyles?.containerProps}>
        {leftElement}
        <Flex flexDirection="column" px="10px">
          {title && typeof title === "string" && (
            <Text fontWeight={600} fontSize="14px">
              {title}
            </Text>
          )}
          {title && typeof title !== "string" && title}
          <Text fontWeight={600} fontSize="14px"></Text>
          <Box>
            {subTitle && typeof subTitle === "string" && (
              <Text as="p" fontSize="12px" color={theme.palette.ui.greys.grey2}>
                {subTitle}
              </Text>
            )}
            {subTitle && typeof subTitle !== "string" && subTitle}
          </Box>
        </Flex>
        {!showChevron && (
          <Flex flex="1" justifyContent="flex-end" ml="auto">
            {rightElement}
          </Flex>
        )}
        {showChevron && (
          <Flex flex="1" justifyContent="flex-end" ml="auto" alignSelf="start">
            {rightElement}
            <Box
              sx={{
                svg: {
                  color: `${theme.palette.ui.greys.grey2}`,
                  height: "25px",
                  width: "25px",
                },
              }}
            >
              <IconComponent name="chevronRight" />
            </Box>
          </Flex>
        )}
      </Flex>
      {showBorder && (
        <Divider mt="16px" size="1px" color={theme.palette.ui.greys.grey4} />
      )}
    </>
  );
};

export default GlobalListItem;
